export class DataTableToCsv {
    static convert(dataTable, csvSepatator, page, nbrRowsInPage, onlyFields) {
        let csv = '';
        const headers = [];
        const rows = [];
        for (const field of dataTable.fields) {
            if (onlyFields && field.businessType && onlyFields.indexOf(field.businessType) === -1) {
                continue;
            }
            else if ((onlyFields && field.businessType && onlyFields.indexOf(field.businessType) > -1) || (!onlyFields && field.visible)) {
                headers.push(field.name);
            }
        }
        // const dataTableToSend: DataTable  = JSON.parse(JSON.stringify(dataTable));
        let start = 0;
        let end = dataTable.data.length;
        if (page && nbrRowsInPage) {
            start = (page - 1) * nbrRowsInPage;
            end = page * nbrRowsInPage;
            end = end > dataTable.data.length ? dataTable.data.length : end;
        }
        for (let i = start; i < end; i++) {
            const rowValues = [];
            for (const fs of headers) {
                const currentField = dataTable.getFieldByName(fs);
                const value = dataTable.data[i].values[dataTable.getFieldIndex(fs)];
                if ((currentField.type === 'string' || currentField.type === 'date') && (value + '').length > 0) {
                    if (!value || value.length === 0) {
                        rowValues.push('');
                    }
                    else {
                        const valueString = (value + '').replaceAll('"', '');
                        rowValues.push('"' + valueString + '"');
                    }
                }
                else {
                    rowValues.push(value);
                }
            }
            rows.push(rowValues);
        }
        csv += headers.join(csvSepatator) + '\n';
        for (const row of rows) {
            csv += row.join(csvSepatator) + '\n';
        }
        return csv;
    }
}
