import { ckmeans } from 'simple-statistics';

export class ClassificationUtils {
  public static classifyCKMeans(data: number[], nClusters: number): number[] {
    const clusters = ckmeans(data, nClusters - 1);
    let result = clusters.map((cluster) => cluster[0]);
    const lastCluster = clusters[clusters.length - 1];
    result.push(lastCluster[lastCluster.length - 1]);
    return result;
  }
}
