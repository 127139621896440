import { Locale } from '@galigeo-store/ui-lightning';

export class LocaleWizardComponent extends Locale {
  constructor() {
    super();
    this.languages = {
      en: {
        upload_a_logo: 'Upload a logo',
        upload_a_csv: 'Upload a CSV or XLSX file',
        upload_csv_label: 'Import a file',
        upload_logo_label: 'Import a logo',
        drop_zone_csv_text: 'or drag the file here',
        drop_zone_logo_text: 'or drag the file here',
        step_network_name_title: 'Network Name',
        step_network_name_label: 'Network Name',
        step_network_name_description:
          'Enter a name for the network',
        step_network_name_btn_cancel_label: 'Cancel',
        step_network_name_btn_previous_label: 'Previous',
        step_network_name_btn_next_label: 'Next',
        step_network_name_btn_finish_label: 'Finish',
        step_add_logo_title: 'Add a logo',
        step_add_logo_label: 'Add a logo',
        step_add_logo_description:
          'Only image format files (jpg, png)',
        step_add_logo_btn_cancel_label: 'Cancel',
        step_add_logo_btn_previous_label: 'Previous',
        step_add_logo_btn_next_label: 'Next',
        step_add_logo_btn_finish_label: 'Finish',
        step_add_file_title: 'Import stores',
        step_add_file_label: 'Import stores',
        step_add_file_description: 'Select a CSV/Excel file',
        step_add_file_btn_cancel_label: 'Cancel',
        step_add_file_btn_previous_label: 'Previous',
        step_add_file_btn_next_label: 'Next',
        step_add_file_btn_finish_label: 'Finish',
        step_select_columns_info: 'Selecting a unique identifier will allow you to update the network',
        step_select_columns_title: 'Identify the columns',
        step_select_columns_label: 'Identify the columns',
        step_select_columns_description:
          'Identify at least the address or Lat, Long columns',
        step_select_columns_btn_cancel_label: 'Cancel',
        step_select_columns_btn_previous_label: 'Previous',
        step_select_columns_btn_next_label: 'Next',
        step_select_columns_btn_finish_label: 'Finish en',
        step_geocodage_title: 'Check locations',
        step_geocodage_label: 'Check locations',
        step_geocodage_description:
          'Correct incorrectly positioned store locations',
        step_geocodage_btn_cancel_label: 'Cancel en',
        step_geocodage_btn_previous_label: 'Previous',
        step_geocodage_btn_next_label: 'Next',
        step_geocodage_btn_finish_label: 'Finish',
        step_network_name_text_placeholder:
          'E.g.: Stores, Franchises, Own network ',
          step_network_name_text_error: 'Please do not exceed 30 characters maximum',
        step_add_logo_btn_delete_label: 'Delete',
        step_add_file_success: 'Your file was successfully imported',
        step_add_file_btn_delete_label: 'Delete file',
        step_add_file_view_file: 'File preview',
        step_geocodage_alert_result_label: 'Result',
        step_geocodage_alert_footer_btn: 'OK',
        step_geocodage_alert_title: 'Address Search',
        step_geocodage_loading:
          'Please wait while your network loads',
        step_select_columns_col_file: 'File Columns',
        step_select_columns_col_values: 'Corresponding Values',
        step_select_columns_choise_field: 'Select a value',
        loading_spinner: 'Loading',
        confirm_modal_title: 'Important information',
        alert_title_inconsistencies_error: 'Unable to continue',
     },
      fr: {
        upload_a_logo: 'Importer un logo',
        upload_a_csv: 'Importer un CSV ou un XLSX',
        upload_csv_label: 'Importer un fichier',
        upload_logo_label: 'Importer un logo',
        drop_zone_csv_text: 'ou Glissez un fichier ici',
        drop_zone_logo_text: 'ou Glissez un fichier ici',
        step_network_name_title: 'Nom du réseau',
        step_network_name_label: 'Nom du réseau',
        step_network_name_description:
          'Entrer un nom pour le réseau',
        step_network_name_btn_cancel_label: 'Annuler',
        step_network_name_btn_previous_label: 'Précédent',
        step_network_name_btn_next_label: 'Suivant',
        step_network_name_btn_finish_label: 'Terminer',
        step_add_logo_title: 'Ajoutez un logo',
        step_add_logo_label: 'Ajouter un logo',
        step_add_logo_description:
          'Fichiers format image uniquement (jpg, png)',
        step_add_logo_btn_cancel_label: 'Annuler',
        step_add_logo_btn_previous_label: 'Précédent',
        step_add_logo_btn_next_label: 'Suivant',
        step_add_logo_btn_finish_label: 'Terminer',
        step_add_file_title: 'Importer un fichier',
        step_add_file_label: 'Importer un fichier',
        step_add_file_description:
          'Veuillez sélectionner un fichier CSV/Excel',
        step_add_file_btn_cancel_label: 'Annuler',
        step_add_file_btn_previous_label: 'Précédent',
        step_add_file_btn_next_label: 'Suivant',
        step_add_file_btn_finish_label: 'Terminer',
        step_select_columns_info: 'La sélection d\'un identifiant unique, vous permettera de mettre à jour le réseau',
        step_select_columns_title: 'Identifiez les colonnes',
        step_select_columns_label: 'Identifiez vos colonnes',
        step_select_columns_description:
          'Identifier au minimum les colonnes d\'adresse ou Lat, Long',
        step_select_columns_btn_cancel_label: 'Annuler',
        step_select_columns_btn_previous_label: 'Précédent',
        step_select_columns_btn_next_label: 'Suivant',
        step_select_columns_btn_finish_label: 'Terminer',
        step_geocodage_title: 'Vérifier les emplacements',
        step_geocodage_label: 'Vérifier les emplacements',
        step_geocodage_description:
          'Corriger les emplacements des magasins mal positionnés',
        step_geocodage_btn_cancel_label: 'Annuler',
        step_geocodage_btn_previous_label: 'Précédent',
        step_geocodage_btn_next_label: 'Suivant',
        step_geocodage_btn_finish_label: 'Terminer',

        step_network_name_text_placeholder:
          'Ex: Magasins, Franchises, Réseau propre',
          step_network_name_text_error: 'Veuillez ne pas dépasser 30 caractères maximum',
        step_add_logo_btn_delete_label: 'Supprimer',
        step_add_file_success: 'Votre fichier a été importé avec succès',
        step_add_file_btn_delete_label: 'Supprimer le fichier',
        step_add_file_view_file: 'Aperçu du fichier',
        step_geocodage_alert_result_label: 'Résultat',
        step_geocodage_alert_footer_btn: 'Ok',
        step_geocodage_alert_title: 'Recherche d’adresse',
        step_geocodage_loading:
          'Veuillez patienter pendant le chargement de votre réseau',
        step_select_columns_col_file: 'Colonne du fichier',
        step_select_columns_col_values: 'Valeurs correspondantes',
        step_select_columns_choise_field: 'Sélectionner une valeur',
        loading_spinner: 'Chargement en cours',
        confirm_modal_title: 'Information importante',
        alert_title_inconsistencies_error: 'Impossible de continuer',
     },
    };
    this.genrateMap();
  }
}
