export class CsvToJson {
    convert(csv, fileTextseparator) {
        const lines = csv.split('\n');
        const data = [];
        if (lines.length > 0) {
            const headers = lines[0].split(fileTextseparator);
            for (let i = 1; i < lines.length - 1; i++) {
                const obj = {};
                const currentline = lines[i].split(fileTextseparator);
                for (let j = 0; j < headers.length; j++) {
                    obj[headers[j].replaceAll('"', '')] = currentline[j].replaceAll('"', '').replaceAll(String.fromCharCode(92), '');
                }
                data.push(obj);
            }
        }
        return data;
    }
}
