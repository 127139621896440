import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-lightning-tooltips',
  templateUrl: './tooltips.component.html',
  styleUrls: ['./tooltips.component.css']
})
export class TooltipsComponent implements OnInit {

  showText: boolean;
  icon = 'info';
  constructor() {
      this.showText = false;
  }

  ngOnInit(): void {
  }

}
