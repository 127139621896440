import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { PROGRESS_TYPE, MESSAGE_TYPE, ConfirmOptions, AlertOptions, ProgressOptions, ToastOptions } from '@galigeo-store/shared-models';
import { MessageConfirmComponent } from './confirm/message-confirm.component';
import { MessageAlertComponent } from './alert/message-alert.component';
import { MessageProgressComponent } from './progress/message-progress.component';
import { MessageToastComponent } from './toast/message-toast.component';



@Injectable({
  providedIn: 'root'
})
export class MessageService {
  vrc: ViewContainerRef | null;
  singleton: Map<string, any> = new Map<string, any>();
  public event: Subject<any> = new Subject<any>();

  /**
   * Singleton for progress bar
   */
  private progressComponentRef: ComponentRef<MessageProgressComponent> | null = null;

  constructor() {
    this.vrc = null;
    this.event.subscribe((options) => {
      console.log('Message received', options);
      switch (options.type) {
        case MESSAGE_TYPE.CONFIRM:
          this.confirm(options);
          break;
        case MESSAGE_TYPE.PROGRESS:
          this.progress(options);
          break;
        default:
          this.alert(options);
      }
    });
  }

  public initMessages(vrc: ViewContainerRef): Subject<any> {
    console.log('Init messages');
    this.vrc = vrc;
    return this.event;
  }

  public confirm(options: ConfirmOptions) {
    if (this.vrc !== null) {
      const messageConfirmRef = this.vrc.createComponent(MessageConfirmComponent);
      messageConfirmRef.instance.setOptions(options);
      this.singleton.set('confirm', messageConfirmRef);
    }
  }
  public alert(options: AlertOptions) {
    if (this.vrc !== null) {
      const messageConfirmRef = this.vrc.createComponent(MessageAlertComponent);
      messageConfirmRef.instance.setOptions(options);
      this.singleton.set('alert', messageConfirmRef);
    }
  }
  public toast(options: ToastOptions) {
    if (this.vrc !== null) {
      const messageConfirmRef = this.vrc.createComponent(MessageToastComponent);
      messageConfirmRef.instance.setOptions(options);
    }
  }

  public progress(options: ProgressOptions) {
    if (this.vrc !== null) {
      if (this.progressComponentRef === null || this.progressComponentRef.instance.options.progressType === PROGRESS_TYPE.STOP) {
        this.progressComponentRef = this.vrc.createComponent(MessageProgressComponent);
        this.singleton.set('progress', this.progressComponentRef);
      }
      this.progressComponentRef.instance.setOptions(options);
    }
  }

}
