import { MapBoxLayer } from '../layer/MapBoxLayer.js';

/**
 * Allow to drag some points on a layer
 */
export class DragHandler {
  public static dragInProgress: boolean = false; // public singleton

  public dragEnabled: boolean = true;

  private layer: MapBoxLayer;
  private map: maplibregl.Map;
  private startPosition: number[] | undefined; // [lng, lat]
  private lock: boolean = false; // used to prevent too many moves
  public waitForMoveTime: number = 300; // ms

  /**
   * Used to prevent too many moves
   */
  private lastMove: number | undefined;

  private currentFeature: any; // the feature that is been dragged

  constructor(map: maplibregl.Map, layer: MapBoxLayer) {
    this.layer = layer;
    this.map = map;
  }

  public registerDrag(): void {
    this.map.on('mousedown', this.layer.getId(), (e) => {
      if (!this.dragEnabled) return; // drag is disabled

      // Prevent the default map drag behavior.
      e.preventDefault();

      const canvas = this.map.getCanvasContainer();
      canvas.style.cursor = 'grab';

      // Find features intersecting the bounding box.
      const selectedFeatures = this.map.queryRenderedFeatures(e.point, {
        layers: [this.layer.getId()],
      });

      if (!this.currentFeature) {
        this.lock = true;
        setTimeout(() => {
          this.lock = false;
        }, this.waitForMoveTime);
        // search by properties, is there a better way?
        this.currentFeature = this.searchFeature(selectedFeatures[0].properties, this.layer.getData().features);
        this.startPosition = [e.lngLat.lng, e.lngLat.lat];
        console.log('Start dragging');
      }
    });

    this.map.on('mousemove', (e) => {
      if (this.currentFeature && !this.lock) {
        this.lock = true;
        if (this.currentFeature.geometry.coordinates[0] !== e.lngLat.lng || this.currentFeature.geometry.coordinates[1] !== e.lngLat.lat) {
          this.currentFeature.geometry.coordinates = [e.lngLat.lng, e.lngLat.lat];
          const source = this.map.getSource(this.layer.getSourceId()) as maplibregl.GeoJSONSource;
          source.setData(this.layer.getData());
          DragHandler.dragInProgress = true;
          this.lastMove = Date.now();
          setTimeout(() => {
            this.lock = false;
          }, 15);
        }
      }
    });
    this.map.on('mouseup', (e) => {
      try {
        if (this.currentFeature) {
          if (DragHandler.dragInProgress) {
            console.log('Stop dragging', e);
            this.lastMove = undefined;
            this.layer.fireEvent('dragend', { feature: this.currentFeature, startPosition: this.startPosition });
            this.startPosition = undefined;
          }
        }
        this.lock = false; // just in case
      } finally {
        this.currentFeature = null;
        DragHandler.dragInProgress = false;
      }
    });
  }

  private onDragMove(e: any) {
    console.log('drag move', e.point);

    // Set a UI indicator for dragging.
    const canvas = this.map.getCanvasContainer();
    canvas.style.cursor = 'grabbing';

    // Find features intersecting the bounding box.
    const selectedFeatures = this.map.queryRenderedFeatures(e.point, {
      layers: [this.layer.getId()],
    });

    if (!this.currentFeature) {
      // search by properties, is there a better way?
      this.currentFeature = this.searchFeature(selectedFeatures[0].properties, this.layer.getData().features);
    }
    if (this.currentFeature) {
      // update the source with the new coordinates
      if (this.currentFeature.geometry.coordinates[0] !== e.lngLat.lng || this.currentFeature.geometry.coordinates[1] !== e.lngLat.lat) {
        console.log('Update coordinates');
        this.currentFeature.geometry.coordinates = [e.lngLat.lng, e.lngLat.lat];
        const source = this.map.getSource(this.layer.getSourceId()) as maplibregl.GeoJSONSource;
        source.setData(this.layer.getData());
      }
    } else {
      // nothing to drag?
      this.map.off('mousemove', this.onDragMove);
    }
  }

  private onDragUp(e: any) {
    console.log('drag up', e.point);
    const canvas = this.map.getCanvasContainer();
    canvas.style.cursor = '';

    this.currentFeature = null;

    // Unbind mouse/touch events
    this.map.off('mousemove', this.onDragMove);
  }

  private searchFeature(searchProps: any, features: any[]) {
    for (const feature of features) {
      let found = true;
      for (const prop in searchProps) {
        if (typeof feature.properties[prop] === 'object') continue; // ignore objects
        if (feature.properties[prop] !== searchProps[prop]) {
          found = false;
          break;
        }
      }
      if (found) {
        return feature;
      }
    }
    return null;
  }
}
