import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LanguageService } from '@galigeo-store/ui-lightning';
import { LocaleMapAlertsComponent } from '../locales/localeMapAlertsComponent';
import { Alert } from '../models/alert';

@Component({
  selector: 'retail-map-alerts',
  templateUrl: './map-alerts.component.html',
  styleUrls: ['./map-alerts.component.css']
})
export class MapAlertsComponent {
  @Input() alerts!: Alert[];
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  closeAlertGeocodage() {
   this.close.emit();
  }
  constructor(private LanguageService: LanguageService) {
    this.LanguageService.addTranslation('MapAlertsComponent', new LocaleMapAlertsComponent().locale);
  }
}
