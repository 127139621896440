import { Locale } from '../../shared/components';

export class LocaleMapAlertsComponent extends Locale {
  constructor() {
    super();
    this.languages = {
      en: {
        step_geocodage_alert_title: 'Geocoding results',
        step_geocodage_alert_result_label: 'Results',
        step_geocodage_alert_footer_btn: 'Ok, I got it',
      },
      fr: {
        step_geocodage_alert_title: 'Résultat du géocodage des adresses',
        step_geocodage_alert_result_label: 'Résultats',
        step_geocodage_alert_footer_btn: 'Ok, j\'ai compris',
      },
    };
    this.genrateMap();
  }
}
