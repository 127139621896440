import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { EventEmitter, Injectable } from '@angular/core';
import {
  AlertOptions,
  DataTable,
  MessageOptions,
  ProgressOptions,
  PROGRESS_TYPE,
  MESSAGE_LEVEL,
} from '@galigeo-store/shared-models';
import { Observable, firstValueFrom, lastValueFrom, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

/**
 * Intercept ajax queries to inject token in headers (and other stuffs)
 */
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  event: EventEmitter<MessageOptions> = new EventEmitter();

  constructor(
    private _http: HttpClient,
    private route: ActivatedRoute,
  ) {
    console.log('Query Params', this.route.queryParams);
  }

  public static getQueryParams(): any {
    let href: string = window.location.href;
    const queryParams: any = {};
    if (href.indexOf('?') !== -1) {
      href = decodeURIComponent(href);
      const paramsUrl = href.split('?')[1];
      const paramsArray: string[] = paramsUrl.split('&');
      paramsArray.forEach((el) => {
        const tabSegments = el.split('=');
        queryParams[tabSegments[0]] = tabSegments[1];
      });
    }
    return queryParams;
  }

  /**
   * Get a query parameter from the URL
   * @param paramName Name of the query parameter
   * @returns
   */
  public static getQueryParam(paramName: string): string | undefined {
    let href: string = window.location.href;
    const queryParams: any = {};
    if (href.indexOf('?') !== -1) {
      href = decodeURIComponent(href);
      const paramsUrl = href.split('?')[1];
      const paramsArray: string[] = paramsUrl.split('&');
      for (let el of paramsArray) {
        const tabSegments = el.split('=');
        if (tabSegments[0] === paramName) {
          return tabSegments[1];
        }
      }
    }
    return undefined;
  }

  /**
   * Add the current query parameters to the url
   * @param url
   */
  public static addQueryParams(url: string): string {
    let _url = url;
    const queryParams = HttpService.getQueryParams();
    if (queryParams) {
      _url += '?';
      for (const key of Object.keys(queryParams)) {
        _url += `${key}=${encodeURIComponent(queryParams[key])}&`;
      }
      _url = _url.substring(0, _url.length - 1);
    }
    return _url;
  }

  private _getHeaders(headers?: HttpHeaders): HttpHeaders {
    if (!headers) {
      headers = new HttpHeaders();
    }
    headers = headers.append('Access-Control-Allow-Origin', '*');
    let token = HttpService.getQueryParam('ggo_token');
    if (!token) {
      const localStorageToken = localStorage.getItem('ggo_token');
      if (localStorageToken) {
        token = localStorageToken;
      }
    }
    if (token) {
      token = token.replace('"', '');
      return headers.append('authorization', `Bearer ${token}`);
    } else {
      return headers;
    }
  }

  /**
   * Get the base URL of the rest services
   */
  public getBaseUrl(): string {
    const url = HttpService.getQueryParam('url');
    if (url) {
      console.log('Use URL from query parameter', url);
      return url;
    }

    // Default url
    const { protocol, hostname, port } = window.location;
    return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  }

  public get(url: string, headers?: HttpHeaders): Observable<any> {
    return this._http.get(url, { headers: this._getHeaders(headers) });
  }

  public post(
    url: string,
    body?: any,
    headers?: HttpHeaders,
    params?: HttpParams,
  ): Observable<any> {
    return this._http.post(url, body, { headers: this._getHeaders(headers) });
  }

  public put(url: string, body?: any, headers?: HttpHeaders): Observable<any> {
    return this._http.put(url, body, { headers: this._getHeaders(headers) });
  }

  private static addParamsToUrl(url: string, params: any): string {
    let _url = url;
    if (params) {
      if (!_url.includes('?')) _url += '?';
      else _url += '&';
      for (const key of Object.keys(params)) {
        _url += `${key}=${encodeURIComponent(params[key])}&`;
      }
      _url = _url.substring(0, _url.length - 1);
    }
    return _url;
  }

  /**
   * HTTP Get dedicated to query a datatable
   *
   * @param url
   * @param where
   * @returns
   */
  public doGet(urlOptions: {
    url: string;
    where?: string;
    errorMessage?: string;
    async?: boolean;
  }): Observable<any> {
    let _url = urlOptions.url;

    if (!_url.startsWith('http') && !_url.startsWith('/')) {
      _url = this.getBaseUrl() + '/' + _url;
    }

    if (urlOptions.where) {
      _url += '?where=' + encodeURIComponent(urlOptions.where);
    }
    console.log('get', _url);

    if (urlOptions.async) {
      _url = HttpService.addParamsToUrl(_url, { async: true });
      return this.runAsyncJob(
        this._http.get(_url, { headers: this._getHeaders() }),
      );
    }

    return this._http.get(_url, { headers: this._getHeaders() }).pipe(
      map((response: any) => {
        if (response && response.error) {
          this.event.emit(
            new AlertOptions({
              message: response.error,
              level: MESSAGE_LEVEL.ERROR,
            }),
          );
          return throwError(() => new Error('Failed to query ' + _url));
        }
        return response;
      }),
      catchError((err, caught) => {
        console.log(
          `Failed to query ${urlOptions.url}:  ${JSON.stringify(err)}`,
        );
        this.event.emit(
          new AlertOptions({
            message: urlOptions.errorMessage
              ? urlOptions.errorMessage
              : 'An error occured.',
            level: MESSAGE_LEVEL.ERROR,
            error: err,
          }),
        );
        return throwError(() => new Error('Failed to query url'));
      }),
    );
  }

  /**
   * A HTTP Post dedicated to query a datatable
   *
   * @param url
   * @param body
   * @returns
   */
  public doPostForm(urlOptions: {
    url: string;
    body: string;
    errorMessage?: string;
    async?: boolean;
  }): Observable<DataTable> {
    const _header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    let _url = urlOptions.url;

    if (!_url.startsWith('http') && !_url.startsWith('/')) {
      _url = this.getBaseUrl() + '/' + _url;
    }

    if (urlOptions.async) {
      _url = HttpService.addParamsToUrl(_url, { async: true });
      return this.runAsyncJob(
        this._http.post(_url, urlOptions.body, {
          headers: this._getHeaders(_header),
        }),
      );
    }

    console.log('postForm', _url);
    return this._http
      .post(_url, urlOptions.body, { headers: this._getHeaders(_header) })
      .pipe(
        map((response: any) => {
          if (response && response.error) {
            this.event.emit(
              new AlertOptions({
                message: response.error,
                level: MESSAGE_LEVEL.ERROR,
              }),
            );
            return throwError(() => new Error('Failed to query ' + _url));
          }
          return response;
        }),
        catchError((err, caught) => {
          console.log(
            `Failed to query ${urlOptions.url}:  ${JSON.stringify(err)}`,
          );
          this.event.emit(
            new AlertOptions({
              message: urlOptions.errorMessage
                ? urlOptions.errorMessage
                : 'An error occured.',
              level: MESSAGE_LEVEL.ERROR,
              error: err,
            }),
          );
          return throwError(() => new Error('Failed to query url'));
        }),
      );
  }

  /**
   * Start run an async job
   * @param input Observable that will start the job
   * @returns An observable that will emit the result of the job
   */
  private runAsyncJob(input: Observable<any>): Observable<any> {
    return new Observable((observer) => {
      input.subscribe(async (response: any) => {
        const job = response;
        job.progressPercent = job.progress;
        this.event.emit(new ProgressOptions(job));
        //const result = await this.listenAsyncJob(job);
        this.listenAsyncJob(job)
          .then((result) => {
            observer.next(result);
          })
          .catch((err) => {
            console.error('Job async error', err);
            this.event.emit(
              new ProgressOptions({
                progressType: PROGRESS_TYPE.STOP,
                message: err,
              }),
            );
            setTimeout(() => {
              this.event.emit(
                new AlertOptions({ message: err, level: MESSAGE_LEVEL.ERROR }),
              );
              observer.error(err);
            }, 1000);
          });
      });
    });
  }

  /**
   * Listen to an async job
   * @param job
   * @returns
   */
  private listenAsyncJob(job: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let done = false;
        for (let i = 0; i < 200; i++) {
          if (!job || !job.jobId) {
            throw new Error('Failed to run action. No job id');
          }
          const jobStatus = await this.getAsyncJob(job.jobId);
          console.log('getAsyncJob', job.jobId, i, jobStatus);
          if (jobStatus.status === 'RUNNING') {
            this.event.emit(new ProgressOptions(jobStatus));
            await new Promise((resolve) => setTimeout(resolve, 4000));
            done = true;
          } else if (jobStatus.status === 'DONE') {
            this.event.emit(
              new ProgressOptions({ progressType: PROGRESS_TYPE.STOP }),
            );
            done = true;
            resolve(jobStatus.data);
            break;
          } else {
            console.error('Job async error', jobStatus);
            let errorMsg = 'An error occured';
            if (jobStatus?.data?.error) errorMsg = jobStatus.data.error;
            else if (jobStatus?.error) errorMsg = jobStatus.error;
            reject(errorMsg);
            break;
          }
        }
        if (!done) {
          reject('Timeout after 20 seconds');
        }
      } catch (err: any) {
        reject(err);
      }
    });
  }

  /**
   * Fetch the status of an async job from the server
   * @param jobId
   * @returns
   */
  private async getAsyncJob(jobId: number): Promise<any> {
    return await lastValueFrom(
      this._http.get(`${this.getBaseUrl()}/rest/job?jobId=${jobId}`, {
        headers: this._getHeaders(),
      }),
    );
  }
}
