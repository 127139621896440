import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { LookupItem, LookupOptions } from '../../lookup/model';
import { GeocodeDataGouv } from '../models/geocodeDataGouv';
import { BusinessType, DataTable } from '@galigeo-store/shared-models';
import { LanguageService } from '../../locale/language.service';
import { LocaleSearchAddress } from '../../locale/lang-components/searchAddress';

@Component({
  selector: 'ui-lightning-search-adress',
  templateUrl: './search-address.component.html',
  styleUrls: ['./search-address.component.css'],
})
export class SearchAddressComponent implements OnInit {
  @Output() onSelect = new EventEmitter<LookupItem>();
  @Output() onClose = new EventEmitter<any>();
  @Output() onChange = new EventEmitter<any>();
  public loading: boolean = false;
  public geocodeResultLookupItems!: LookupItem[];
  @Input() defaultValue!: string;
  @Input() lookupOptions: LookupOptions = {
    debounceTime: 150,
    maxLines: 5,
    icon: 'utility:search',
    showCancel: true,
  };

  constructor(private languageService: LanguageService) {
    this.geocodeResultLookupItems = [];
    this.languageService.addTranslation(
      'SearchAddress',
      new LocaleSearchAddress().locale,
    );
  }

  ngOnInit(): void {
    this.lookupOptions.placeholder = this.languageService.getTranslation(
      'SearchAddress',
      'search_placeholder',
    );
  }
  onClick(event: any) {
    this.onSelect.emit(event);
    // this.geocodeResultLookupItems = [];
  }
  async onChangeLookup(event: string) {
    this.onChange.emit(event);
    this.geocodeResultLookupItems = [];
    if (event.trim().length < 3) {
      return;
    }
    this.loading = true;
    const geocoder = new GeocodeDataGouv();
    const geocodeResult: DataTable = await geocoder.search(event);

    for (let record of geocodeResult.data) {
      const addressIndex = geocodeResult.getFieldIndex(
        BusinessType.GEOCODER_ADDRESS,
      );
      const postalCodeIndex = geocodeResult.getFieldIndex(
        BusinessType.GEOCODER_POSTAL_CODE,
      );
      const cityIndex = geocodeResult.getFieldIndex(BusinessType.GEOCODER_CITY);

      if (addressIndex !== -1 && postalCodeIndex !== -1 && cityIndex !== -1) {
        this.geocodeResultLookupItems.push(
          new LookupItem({
            label:
              record.values[addressIndex] +
              ' ' +
              record.values[postalCodeIndex] +
              ' ' +
              record.values[cityIndex],
            value: new DataTable({
              id: 'search',
              fields: geocodeResult.fields,
              data: [record],
            }),
            icon: 'utility:checkin',
          }),
        );
      }
    }
    this.loading = false;
  }
  closeInput() {
    this.onClose.emit(true);
  }
}
