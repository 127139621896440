import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchAddressComponent } from './search-geocode/search-address.component';
import { LookupModule } from '../lookup/index';
import { LocaleModule } from '../locale/locale.module';



@NgModule({
  declarations: [
    SearchAddressComponent
  ],
  imports: [
    CommonModule,
    LookupModule,
    LocaleModule
  ], exports: [
    SearchAddressComponent, LocaleModule
  ]
})
export class UiLightningGeocodeModule { }
