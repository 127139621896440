import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import * as scrollama_ from 'scrollama';
// const scrollama = scrollama_;
declare const scrollama: any;

@Component({
  selector: 'ui-lightning-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.css']
})
export class StoryComponent implements OnInit, AfterViewInit {

  protected scroller: any;
  @Input() stories: any;
  @Input() loading: boolean;
  @Input() scrollOptions: any;
  @Output() onScroll = new EventEmitter<any>();

  constructor() {
    this.scroller = scrollama();
    this.loading = false;
    this.scrollOptions = {
      behavior: 'smooth',
      block: 'center'
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const interval = setInterval(() => {
      if(this.stories && this.stories.length > 0) {
        clearInterval(interval);
        setTimeout(() => {
          // Setup Scroller
          this.setupScroller();
        }, 1000);
      }
    },500);
  }

  // Setup scroller
  setupScroller(): void {
    // Setup the scroller
    this.scroller
    .setup({
      container: '#the-story-panel',
      step: '.step',
      offset: 0.25,
      debug: false
    })
    .onStepEnter((response: any) => {
      // Change selected element
      document.querySelectorAll(".slide-bar-bullet").forEach((bullet:any) => {
        bullet.classList.remove("slider-bullet-selected");
      })
      console.log(document.getElementById("slider-bullet-" + response.element.id.split('-')[1]))
      document.getElementById("slider-bullet-" + response.element.id.split('-')[1])?.classList.add("slider-bullet-selected");
      // Emit event
      this.onScroll.emit(response.element.id.split("-")[1]);
    }
    )
    .onStepExit((response: any) => {
      // TO DO
    }
    );
  }

  // Scroll to step
  scrollToStep(id:any) : void {
    document.getElementById(id)?.scrollIntoView(this.scrollOptions)
  }

}
