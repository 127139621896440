import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonGroupModule, FileImportModule, IconModule, LocaleModule, LookupModule, ModalModule, SpinnerModule, StepperModule, TableModule, TooltipsModule, UiLightningSharedModule } from '../shared/components/index';
import { SharedModule } from '../shared/shared.module';
import { WizardAddComponent } from './wizard-add/wizard-add.component';
import { WizardUpdateComponent } from './wizard-update/wizard-update.component';



@NgModule({
  declarations: [
    WizardAddComponent,
    WizardUpdateComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    UiLightningSharedModule,
    SharedModule,
    LookupModule,
    StepperModule,
    FileImportModule,
    SpinnerModule,
    FormsModule,
    IconModule,
    ModalModule,
    ButtonGroupModule,
    LocaleModule,
    TooltipsModule,
  ],
  exports: [WizardAddComponent]
})
export class WizardModule { }
