import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-lightning-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {
  @Input() items!: any[];
  @Input() tooltip?: string;
  showMainMenu = false;
  showSubMenu!: number;
  subMenu = false;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  to!: any;
  mouseOnSubMenu = false;

  constructor() {
  }

  ngOnInit(): void {
    console.log(this.items);
    document.addEventListener('click', this.offClickHandler.bind(this));
  }

  offClickHandler(event: any) {
    if (!event.target.closest('ui-lightning-menu')) {
      this.showMainMenu = false;
      this.showSubMenu = -1;
    }
  }

  clickMenuBtn(e: any) {
    console.log('click', e);
    this.showMainMenu = !this.showMainMenu;
  }

  menuClick(i: any) {
    if (this.items[i].subitems) {
      if (this.showSubMenu === i) {
        this.showSubMenu = -1;
      } else {
        this.showSubMenu = i;
      }
    } else {
      this.showSubMenu = -1;
    }
  }

  clickSubMenuBtn() {
    this.subMenu = !this.subMenu;
  }

  menuAction(action: any) {
    console.log(action);
    this.showSubMenu = -1;
    this.showMainMenu = false;
    this.action.emit({ action: action })
  }

  itemMouseLeave(item: any) {
    if (item.subitems) {
      this.showSubMenu = -1
    }
  }

  itemMouseEnter(item: any, i: any) {
    if (item.subitems && item.subitems.length > 0) {
      this.menuClick(i)
      this.mouseOnSubMenu = true;
    }
  }
}
