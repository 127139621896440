export var FieldType;
(function (FieldType) {
    FieldType["STRING"] = "string";
    FieldType["DATE"] = "date";
    FieldType["INTEGER"] = "integer";
    FieldType["DOUBLE"] = "double";
    FieldType["BOOLEAN"] = "boolean";
    FieldType["GEOMETRY"] = "geometry";
    FieldType["OBJECT"] = "object";
    FieldType["ARRAY"] = "array";
    FieldType["BASE64"] = "base64";
    FieldType["IMAGELINK"] = "imagelink";
})(FieldType || (FieldType = {}));
export var BusinessType;
(function (BusinessType) {
    BusinessType["GGO_ID"] = "ggo_id";
    BusinessType["POI_SET_ID"] = "poi_set_id";
    BusinessType["NETWORK_ID"] = "network_id";
    BusinessType["ID"] = "id";
    BusinessType["NAME"] = "name";
    BusinessType["DESCRIPTION"] = "description";
    BusinessType["CATEGORY"] = "category";
    BusinessType["ADDRESS"] = "address";
    BusinessType["CITY"] = "city";
    BusinessType["COUNTRY"] = "country";
    BusinessType["AREA_ID"] = "area_id";
    BusinessType["POSTAL_CODE"] = "postal_code";
    BusinessType["LONG"] = "long";
    BusinessType["LAT"] = "lat";
    BusinessType["AREA_CODE"] = "geounit_code";
    BusinessType["GEOUNIT_CODE"] = "geounit_code";
    BusinessType["GEOCODER_ADDRESS"] = "geocoder_address";
    BusinessType["GEOCODER_CITY"] = "geocoder_city";
    BusinessType["GEOCODER_CITY_CODE"] = "geocoder_city_code";
    BusinessType["GEOCODER_ACCURACY"] = "geocoder_accuracy";
    BusinessType["GEOCODER_LATITUDE"] = "geocoder_latitude";
    BusinessType["GEOCODER_LONGITUDE"] = "geocoder_longitude";
    BusinessType["GEOCODER_POSTAL_CODE"] = "geocoder_postal_code";
    BusinessType["GEOCODER_COUNTRY"] = "geocoder_country";
    BusinessType["CREATED_AT"] = "created_at";
    BusinessType["CREATED_BY"] = "created_by";
    BusinessType["LAST_UPDATED_AT"] = "last_updated_at";
    BusinessType["LAST_UPDATED_BY"] = "last_updated_by";
    BusinessType["GEOMETRY"] = "geom";
    BusinessType["GEOCODER_ID"] = "id_geocoder";
    BusinessType["IMAGE"] = "image";
    BusinessType["OPEN_DATE"] = "open_date";
    BusinessType["CLOSE_DATE"] = "close_date";
    BusinessType["REVENUE"] = "revenue";
    BusinessType["POTENTIAL"] = "potential";
})(BusinessType || (BusinessType = {}));
export class DataTable {
    id;
    data;
    fields;
    constructor(options) {
        this.id = options.id;
        this.data = options.data;
        this.fields = options.fields;
        if (options.json) {
            this.addData(options.json);
        }
    }
    isGeocoded() {
        const indexAccuracy = this.getFieldIndex(BusinessType.GEOCODER_ACCURACY);
        for (let Record of this.data) {
            if (Record.values[indexAccuracy] !== null) {
                return true;
            }
        }
        return false;
    }
    /**
     *
     * @returns {number} Number of records in the dataset
     */
    length() {
        if (this.data) {
            return this.data.length;
        }
        else {
            return 0;
        }
    }
    setPage(pageNumber, pageSize) {
        let pageElementVisibleIndex = 0;
        this.data.forEach((d) => (d.isInPage = false));
        for (let i = (pageNumber - 1) * pageSize; i < this.data.filter((d) => d.visible === undefined || d.visible).length; i++) {
            this.data.filter((d) => d.visible === undefined || d.visible)[i].isInPage = true;
            pageElementVisibleIndex++;
            if (pageElementVisibleIndex === pageSize) {
                break;
            }
        }
    }
    addData(dataInput) {
        this.data = [];
        dataInput.forEach((r) => {
            this.data.push({ selected: false, values: Object.values(r), visible: true });
        });
    }
    isEmpty() {
        return this.data?.length === 0;
    }
    resetDataTable() {
        this.data.forEach((d) => {
            d.visible = true;
        });
        const field = this.fields.find((f) => f.originDataSort);
        if (field) {
            this.sort(field.name, 'up');
        }
    }
    filter(value, fieldName) {
        const field = this.getFieldByName(fieldName);
        if (fieldName && field && field.visible) {
            const fieldIndex = this.getFieldIndex(fieldName);
            if (fieldIndex !== -1) {
                this.data.forEach((d) => {
                    d.visible = this._valueIsIn(d.values[fieldIndex], value, field);
                });
            }
        }
        else {
            this.globalFilter(value);
        }
    }
    globalFilter(value) {
        this.data.forEach((d) => {
            let isIn = false;
            this.fields.forEach((f, i) => {
                if (this._valueIsIn(d.values[i], value, f)) {
                    isIn = true;
                }
            });
            d.visible = isIn;
        });
    }
    _valueIsIn(value, valueToSearch, field) {
        switch (field.type) {
            case FieldType.BOOLEAN:
                return valueToSearch === value;
            case FieldType.DATE:
                return valueToSearch === value;
            default:
                return (value + '').toLowerCase().indexOf((valueToSearch + '').toLowerCase()) > -1;
        }
    }
    hasField(name) {
        return this.fields.find((f) => f.name === name) !== undefined;
    }
    getFieldByName(name) {
        const field = this.fields.find((f) => f.name === name);
        if (!field) {
            throw new Error(`Field with name ${name} not found`);
        }
        return field;
    }
    hasBusinessType(businessType) {
        return this.fields.find((f) => f.businessType === businessType) !== undefined;
    }
    getFieldByBusinessType(businessType) {
        const field = this.fields.find((f) => f.businessType === businessType);
        if (!field) {
            throw new Error(`Field with business type ${businessType} not found`);
        }
        return field;
    }
    getFieldByNameIgnoreCase(name) {
        const field = this.fields.find((f) => f.name.toLowerCase() === name.toLowerCase());
        if (!field) {
            throw new Error(`Field with name ${name} not found`);
        }
        return field;
    }
    getFieldIndex(name) {
        let fieldIndex = -1;
        this.fields.forEach((f, i) => {
            if (f.name === name) {
                fieldIndex = i;
            }
        });
        return fieldIndex;
    }
    getFieldIndexByBusinessType(businessType) {
        let fieldIndex = -1;
        this.fields.forEach((f, i) => {
            if (f.businessType === businessType) {
                fieldIndex = i;
            }
        });
        return fieldIndex;
    }
    /**
     * Get the value of a field in a specific line
     *
     * @param fieldName Name of the field
     * @param lineNumber Line number
     * @returns a value
     */
    getValue(fieldName, lineNumber) {
        const fieldIndex = this.getFieldIndex(fieldName);
        return this.data[lineNumber].values[fieldIndex];
    }
    /**
     * Fill all lines of a field with a value
     *
     * @param fieldName
     * @param value
     * @deprecated Use addField instead
     */
    setValues(fieldName, value) {
        if (this.getFieldByName(fieldName) === undefined) {
            this.fields.push({
                name: fieldName,
                alias: fieldName,
                type: DataTable.getFieldType(value),
            });
            for (const rec of this.data) {
                rec.values.push(value);
            }
        }
        else {
            const fieldIndex = this.getFieldIndex(fieldName);
            this.data.forEach((d) => {
                d.values[fieldIndex] = value;
            });
        }
    }
    static getFieldType(value) {
        if (typeof value === 'string')
            return FieldType.STRING;
        if (typeof value === 'number')
            return FieldType.DOUBLE;
        if (value instanceof Date)
            return FieldType.DATE;
        if (typeof value === 'boolean')
            return FieldType.BOOLEAN;
        if (value instanceof Array)
            return FieldType.ARRAY;
        //if (value instanceof Object) return FieldType.OBJECT;
        console.warn('unknown type', typeof value);
        return FieldType.STRING;
    }
    /**
     * Convert this dataset into a json array
     * @param keepRecordId (Optional) When true, then a property 'recordId' is added to each record
     * @returns {any[]} json
     */
    toJson(opt) {
        const json = [];
        this.data.forEach((d) => {
            const jsonRecord = {};
            this.fields.forEach((f, i) => {
                jsonRecord[f.name] = d.values[i];
                if (opt && opt.keepRecordId) {
                    jsonRecord.recordId = d.recordId;
                }
            });
            json.push(jsonRecord);
        });
        return json;
    }
    static fromJson(json, fieldTypes) {
        if (!fieldTypes) {
            fieldTypes = new Map();
            if (json.length > 0) {
                Object.keys(json[0]).forEach((k) => {
                    // search for a non null value to determine the type of the field
                    for (let i = 0; i < json.length && i < 100; i++) {
                        const value = json[0][k];
                        if (value !== undefined && value !== null) {
                            fieldTypes?.set(k, DataTable.getFieldType(value));
                            break;
                        }
                    }
                });
            }
        }
        const fields = [];
        const data = [];
        json.forEach((r) => {
            Object.keys(r).forEach((k) => {
                if (!fields.find((f) => f.name === k)) {
                    fields.push({
                        name: k,
                        alias: k,
                        type: fieldTypes?.get(k) ?? FieldType.STRING,
                    });
                }
            });
        });
        json.forEach((r) => {
            const values = [];
            fields.forEach((f) => {
                values.push(r[f.name]);
            });
            data.push(values);
        });
        return new DataTable({ id: '', fields, json: data });
    }
    /**
     * Group the dataset by a field.
     * @param fieldName
     * @returns
     */
    groupBy(fieldName) {
        const result = new Map();
        const fieldIdx = this.getFieldIndex(fieldName);
        for (const rec of this.data) {
            const key = rec.values[fieldIdx];
            if (!result.has(key)) {
                result.set(key, []);
            }
            // transform the record into a json object
            const properties = {};
            for (let i = 0; i < this.fields.length; i++) {
                properties[this.fields[i].name] = rec.values[i];
            }
            result.get(key).push(properties);
        }
        return result;
    }
    sort(fieldName, direction) {
        const field = this.getFieldByName(fieldName);
        const fieldIndex = this.getFieldIndex(fieldName);
        switch (direction) {
            case 'down':
                this.data.sort(function (a, b) {
                    if (field.type === 'string') {
                        if ((a.values[fieldIndex] + '').toLowerCase() > (b.values[fieldIndex] + '').toLowerCase())
                            return -1;
                        if ((a.values[fieldIndex] + '').toLowerCase() < (b.values[fieldIndex] + '').toLowerCase())
                            return 1;
                        return 0;
                    }
                    if (field.type === 'integer' || field.type === 'double') {
                        if (a.values[fieldIndex] > b.values[fieldIndex])
                            return -1;
                        if (a.values[fieldIndex] < b.values[fieldIndex])
                            return 1;
                        return 0;
                    }
                    if (field.type === 'date') {
                        return new Date(b.values[fieldIndex]).getTime() - new Date(a.values[fieldIndex]).getTime();
                    }
                    return 0;
                }.bind(this));
                break;
            case 'up':
                this.data.sort(function (a, b) {
                    if (field.type === 'string') {
                        if ((a.values[fieldIndex] + '').toLowerCase() < (b.values[fieldIndex] + '').toLowerCase())
                            return -1;
                        if ((a.values[fieldIndex] + '').toLowerCase() > (b.values[fieldIndex] + '').toLowerCase())
                            return 1;
                        return 0;
                    }
                    if (field.type === 'integer' || field.type === 'double') {
                        if (a.values[fieldIndex] < b.values[fieldIndex])
                            return -1;
                        if (a.values[fieldIndex] > b.values[fieldIndex])
                            return 1;
                        return 0;
                    }
                    if (field.type === 'date') {
                        return new Date(a.values[fieldIndex]).getTime() - new Date(b.values[fieldIndex]).getTime();
                    }
                    return 0;
                }.bind(this));
                break;
            default:
                this.resetDataTable();
                break;
        }
    }
    deleteCollumn(collumnName) {
        const index = this.getFieldIndex(collumnName);
        if (index >= 0) {
            this.fields.splice(index, 1);
            for (const record of this.data) {
                record.values.splice(index, 1);
            }
        }
    }
    /**
     *
     * @param collumnName
     * @param business_type
     * @deprecated Use addField instead
     */
    addCollumn(collumnName, business_type) {
        const index = this.getFieldIndex(collumnName);
        if (index === -1) {
            this.fields.push({ name: collumnName, businessType: business_type });
            for (const record of this.data) {
                record.values.push(null);
            }
        }
    }
    /**
     * Add a field with a default value to the dataset
     * @param field Field to add. If the field already exists, then the default value is set to all records
     * @param defaultValue Optional
     * @returns the index of the field
     */
    addField(field, defaultValue) {
        const index = this.getFieldIndex(field.name);
        if (index === -1) {
            this.fields.push(field);
            for (const record of this.data) {
                record.values.push(defaultValue);
            }
            return this.fields.length - 1;
        }
        else if (defaultValue !== undefined) {
            for (const record of this.data) {
                record.values[index] = defaultValue;
            }
        }
        return index;
    }
    copyCollumn(collumnName, newCollumnName, business_type) {
        const index = this.getFieldIndex(collumnName);
        let indexNew = this.getFieldIndex(newCollumnName);
        if (indexNew === -1) {
            this.addCollumn(newCollumnName, business_type);
            indexNew = this.getFieldIndex(newCollumnName);
        }
        if (index >= 0) {
            for (const record of this.data) {
                record.values[indexNew] = record.values[index];
            }
        }
    }
    clone() {
        const fields = [];
        this.fields.forEach((f) => {
            const cloneField = JSON.parse(JSON.stringify(f));
            fields.push(cloneField);
        });
        const data = [];
        this.data.forEach((r) => {
            const cloneRecord = JSON.parse(JSON.stringify(r));
            data.push(cloneRecord);
        });
        return new DataTable({ id: this.id, fields, data });
    }
}
