
<div class="slds-grid slds-gutters">
    <div class="slds-col slds-size_1-of-2">
        <div class="slds-form-element">
            <label class="slds-form-element__label" for="text-input-id-label">
              <abbr class="slds-required" title="required">* </abbr>Input Label</label>
            <div class="slds-form-element__control">
              <input [(ngModel)]="options.title" type="text" id="text-input-id-label" placeholder="new label" required="" class="slds-input" />
            </div>
          </div>
          <div class="slds-form-element">
            <label class="slds-form-element__label" for="select-field">Champs</label>
            <div class="slds-form-element__control">
              <div class="slds-select_container">
                <select class="slds-select" id="select-field" [(ngModel)]="options.field">
                    <option [ngValue]="''">Choisir un champs</option>
                  <option [ngValue]="'champs1'">champs1</option>
                  <option [ngValue]="'champs2'">champs2</option>
                  <option [ngValue]="'champs3'">champs3</option>
                  <option [ngValue]="'champs4'">champs4</option>
                  <option [ngValue]="'champs5'">champs5</option>
                  <option [ngValue]="'champs6'">champs6</option>
                  <option [ngValue]="'champs7'">champs7</option>
                  <option [ngValue]="'champs8'">champs8</option>
                  <option [ngValue]="'champs9'">champs9</option>
                  <option [ngValue]="'champs10'">champs10</option>
                  <option [ngValue]="'champs11'">champs11</option>
                  <option [ngValue]="'champs12'">champs12</option>
                </select>
              </div>
            </div>
          </div>
          <div class="slds-form-element">
            <label class="slds-form-element__label" for="select-type">Type</label>
            <div class="slds-form-element__control">
              <div class="slds-select_container">
                <select class="slds-select" id="select-type" [(ngModel)]="options.type">
                  <option [ngValue]="'text'">text</option>
                  <option [ngValue]="'checkbox'">multi choise</option>
                  <option [ngValue]="'radio'">mono choise</option>
                  <option [ngValue]="'textarea'">TextArea</option>
                  <option [ngValue]="'select'">Select</option>
                  <option [ngValue]="'geometry'">Geometry</option>
                </select>
              </div>
            </div>
          </div>
    </div>
    <div class="slds-col slds-size_1-of-2" *ngIf="options.type === 'checkbox' || options.type === 'radio' || options.type === 'select'">
        <div class="slds-grid slds-gutters">
            <div class="slds-col slds-size_1-of-2">
        <div class="slds-form-element">
            <label class="slds-form-element__label" for="text-input-id-new_value">new value</label>
            <div class="slds-form-element__control">
              <input [(ngModel)]="newValue" type="text" id="text-input-id-new_value" placeholder="Value" required="" class="slds-input" />
            </div>
          </div>
            </div>
            <div class="slds-col slds-size_1-of-2">
                <div class="slds-form-element">
                    <label class="slds-form-element__label" for="text-input-id-new_title">new title</label>
                    <div class="slds-form-element__control">
                      <input [(ngModel)]="newTitle" type="text" id="text-input-id-new_title" placeholder="title" required="" class="slds-input" />
                    </div>
                  </div>
                  
                  <button class="slds-button slds-button_neutral slds-float_right" (click)="addValue()">
                    <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
                      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#new"></use>
                    </svg>add</button>
            </div>
            
        </div>
        <div class="slds-pill_container">
            <div class="slds-assistive-text" id="listbox-pill-default">Press delete or backspace to remove</div>
            <ul class="slds-listbox slds-listbox_horizontal" role="listbox" aria-label="Selected Options:" aria-orientation="horizontal" aria-describedby="listbox-pill-default">
              <ng-template ngFor let-option [ngForOf]="options.multiList">
                <li 
                class="slds-listbox-item" role="presentation" >
                  <span (click)="checkValue(option)"  class="slds-pill {{option.checked ? 'checked-item' : '' }}" role="option" tabindex="0" aria-selected="true">
                    <span class="slds-pill__label" title="{{option.title}}">{{option.title}}</span>
                    <span class="slds-icon_container slds-pill__remove" title="Remove" (click)="removeValue(option)">
                      <svg class="slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                      </svg>
                    </span>
                  </span>
                </li>
              </ng-template>
                
              
            </ul>
          </div>
      </div>
      <div class="slds-col slds-size_1-of-2" *ngIf="options.type === 'geometry'">
        <div class="slds-form-element">
            <label class="slds-form-element__label" for="select-field">Geometry type</label>
            <div class="slds-form-element__control">
              <div class="slds-select_container">
                <select class="slds-select" id="select-field" [(ngModel)]="options.outputFormat">
                  <option [ngValue]="'point'">Point</option>
                  <option [ngValue]="'polygon'">Polygon</option>
                  <option [ngValue]="'line_string'">Line</option>
                </select>
              </div>
            </div>
          </div>
      </div>
      
  </div>