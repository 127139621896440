<article *ngIf="step" class="slds-card mainCard">
    <div class="slds-card__header mainHeader">
      <header class="slds-media slds-media_center slds-has-flexi-truncate">
        <div class="slds-media__body">
          <h2 class="slds-card__header-title mainTitle">
            
              <span class="step-title slds-align_absolute-center">{{step.label}}</span>
            
          </h2>
          <h5  class="slds-align_absolute-center step-sub-title mainSubTitle">{{step.description}}</h5>
        </div>
        <div class="slds-no-flex step-close">
            <ng-template ngFor let-btn [ngForOf]="step.btns" let-i="index">
                <button *ngIf="btn.position === 'top' && btn.show"  [disabled]="btn.disabled" title="{{btn.label}}" (click)="onActionClick(btn)" class="slds-button slds-button_icon" >
                    <ui-lightning-icon *ngIf="btn.icon" [alternativeText]="btn.label" iconName="utility:{{btn.icon}}" size="small" decoratorClasses="slds-icon-text-white"></ui-lightning-icon>
                    </button>
            </ng-template>
          </div>
      </header>
    </div>
    <div class="slds-card__body">
        <ng-content></ng-content>
    </div>
    <footer class="slds-card__footer mainFooter" style="border: none;">
        <ng-template ngFor let-btn [ngForOf]="step.btns" let-i="index">
            <button *ngIf="btn.position === 'buttom' && btn.show" 
            (click)="onActionClick(btn)" [disabled]="btn.disabled" [ngClass]="[btn.action === 'previous' ? 'slds-button_neutral': 'slds-button_brand' ]"
            class="slds-button step-button mainButton" >{{btn.label}}</button>
        </ng-template>
    </footer>
  </article>