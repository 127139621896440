import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './dropdown.component';
import { DropdownItemComponent } from './dropdown-item/index';
import { DropdownButtonComponent } from './dropdown-button/index';
import { IconModule } from '../icon/icon.module';



@NgModule({
  declarations: [
    DropdownComponent, DropdownButtonComponent, DropdownItemComponent
  ],
  imports: [
    CommonModule,
    IconModule
  ],
  exports: [DropdownComponent, DropdownButtonComponent, DropdownItemComponent]
})
export class DropdownModule { }
