import { BubbleRenderer } from '../renderers/Bubble.js';
import { LabelRenderer } from '../renderers/Label.js';
import { Matches } from './Matches.js';
import { RangeRadius } from './RangeRadius.js';
import { Ranges } from './Ranges.js';

export enum Style {
  Symbol = 'symbol',
  Circle = 'circle',
  Fill = 'fill',
  Line = 'line',
}

export enum GeometryType {
  LineString = 'LineString',
  Polygon = 'Polygon',
  Point = 'Point',
  MultiPolygon = 'MultiPolygon',
  MultiLineString = 'MultiLineString',
}

export class Cluster {
  public clusterMaxZoom: number;
  public clusterRadius: number;
  constructor(options: { clusterMaxZoom?: number; clusterRadius?: number }) {
    this.clusterMaxZoom = options?.clusterMaxZoom ? options.clusterMaxZoom : 10;
    this.clusterRadius = options?.clusterRadius ? options.clusterRadius : 10;
  }
}

export class Heatmap {
  public field: string;
  public radius: number;
  public colors: any[];
  constructor(options: { field: string; radius?: number; colors: any[] }) {
    this.field = options?.field;
    this.radius = options?.radius ?? 8;
    this.colors = options?.colors;
  }
}

export class PieChart {
  public fields: string[];
  constructor(options: { fields: string[] }) {
    this.fields = options?.fields;
  }
}
export class ThirdDimension {
  public colorField: string;
  public heightField: string;
  constructor(options: { colorField: string; heightField: string }) {
    this.colorField = options?.colorField;
    this.heightField = options?.heightField;
  }
}
export class StyleImage {
  public name: string;
  public url: string;
  constructor(options: { name: string; url: string }) {
    this.name = options?.name;
    this.url = options?.url;
  }
}

export class StyleOptions {
  ranges?: Ranges;
  rangeRadius?: RangeRadius;
  matches?: Matches;
  fillColor?: string | any[];
  fillOpacity?: number;
  lineColor?: string | string[];
  lineWidth?: number;
  lineStyle?: string;
  width?: number;
  radius?: number;
  geometryType?: GeometryType;
  type: Style;
  cluster?: Cluster;
  heatmap?: Heatmap;
  bubble?: BubbleRenderer;
  piechart?: PieChart;
  thirdDimension?: ThirdDimension;
  imgIdField?: string;
  imageName?: string;
  images?: StyleImage[];
  anchor?: string;
  offset?: number[];
  popupOffset?: [number, number];
  allowOverlap?: boolean;
  label?: LabelRenderer;

  constructor(options: any) {
    if (options?.ranges) {
      this.ranges = new Ranges(options.ranges.field, options.ranges.classes);
    }
    if (options?.rangeRadius) {
      this.rangeRadius = new RangeRadius(options.ranges.field, options.ranges.classes);
    }
    if (options?.matches) {
      this.matches = new Matches(options.matches.field, options.matches.classes);
    }
    if (options?.cluster) {
      this.cluster = new Cluster(options.cluster);
    }
    if (options?.bubble) {
      this.bubble = new BubbleRenderer(options.bubble);
    }
    if (options?.heatmap) {
      this.heatmap = new Heatmap(options.heatmap);
    }
    if (options?.piechart) {
      this.piechart = new PieChart(options.piechart);
    }
    if (options?.thirdDimension) {
      this.thirdDimension = new ThirdDimension(options.thirdDimension);
    }
    if (options?.label) {
      this.label = new LabelRenderer(options.label);
    }
    this.fillColor = options?.fillColor;
    this.fillOpacity = options?.fillOpacity;
    this.lineColor = options?.lineColor;
    this.lineWidth = options?.lineWidth;
    this.lineStyle = options?.lineStyle;
    this.radius = options?.radius;
    this.imgIdField = options?.imgIdField;
    this.imageName = options?.imageName;
    this.anchor = options?.anchor;
    this.offset = options?.offset;
    this.popupOffset = options?.popupOffset;
    this.type = options?.type;
    this.allowOverlap = options?.allowOverlap !== undefined ? options.allowOverlap : true;
    if (!options?.geometryType) {
      switch (this.type) {
        case Style.Line:
          this.geometryType = GeometryType.LineString;
          break;
        case Style.Circle:
          this.geometryType = GeometryType.Point;
          break;
        case Style.Fill:
          this.geometryType = GeometryType.Polygon;
          break;
      }
    } else {
      this.geometryType = options?.geometryType;
    }
    this.images = options?.images;
  }
}
export { LabelRenderer as Label };
