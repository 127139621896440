import { ClassItem } from './ClassItem.js';

export class Matches {
  classes: ClassItem[];
  field: string;

  constructor(field: string, classes: ClassItem[]) {
    this.field = field;
    this.classes = classes;
  }

  public transformMatch(): any[] {
    const result: any = ['match', ['get', this.field]];
    this.classes.forEach((r, i) => {
      if (i !== this.classes.length - 1) {
        result.push(r.value);
      }
      result.push(r.color);
    });
    return result;
  }
}
