<div *ngIf="layers" class="slds-tabs_scoped">
  <ul class="slds-tabs_scoped__nav" role="tablist">
    <ng-template ngFor let-layer [ngForOf]="layers" let-i="index">
      <li
        *ngIf="layer.features && layer.features.length > 0"
        class="slds-tabs_scoped__item {{ layer.open ? 'slds-is-active' : '' }}"
        title="{{ layer.id }}"
        role="presentation"
      >
        <a
          (click)="activateTab(layer)"
          class="slds-tabs_scoped__link"
          role="tab"
          attr.tabindex="{{ i }}"
          attr[aria-selected]="true"
          attr.aria-controls="layer-{{ i }}"
          id="tab-scoped-layer-{{ i }}"
          >{{ layer.id }}</a
        >
      </li>
    </ng-template>

    <!--li class="slds-tabs_scoped__item slds-tabs_scoped__overflow-button" title="More Tabs" role="presentation">
          <div class="slds-dropdown-trigger slds-dropdown-trigger_click slds-is-open">
            <button class="slds-button" aria-haspopup="true">More
              <svg class="slds-button__icon slds-button__icon_x-small slds-button__icon_right" aria-hidden="true">
                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
              </svg>
            </button>
            <div class="slds-dropdown slds-dropdown_right">
              <ul class="slds-dropdown__list slds-dropdown_length-with-icon-10" role="menu">
                <li class="slds-dropdown__item" role="presentation">
                  <a href="#" role="menuitem" tabindex="-1">
                    <span class="slds-truncate" title="Item Four">Item Four</span>
                  </a>
                </li>
                <li class="slds-dropdown__item" role="presentation">
                  <a href="#" role="menuitem" tabindex="-1">
                    <span class="slds-truncate" title="Item Five">Item Five</span>
                  </a>
                </li>
                <li class="slds-dropdown__item" role="presentation">
                  <a href="#" role="menuitem" tabindex="-1">
                    <span class="slds-truncate" title="Item Six">Item Six</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li-->
  </ul>
  <ng-template ngFor let-layer [ngForOf]="layers" let-i="index">
    <div
      *ngIf="layer.features && layer.features.length > 0"
      id="tab-scoped-layer-{{ i }}"
      class="slds-tabs_scoped__content {{ layer.open ? 'slds-show' : 'slds-hide' }}"
      role="tabpanel"
      attr.aria-labelledby="tab-scoped-layer-{{ i }}"
    >
      <h2 id="element-with-table-label" class="slds-text-heading_medium slds-m-bottom_xx-small">{{ layer.id }}</h2>
      <h3 id="other-element-with-table-label" class="slds-text-title slds-m-bottom_small">
        <code>count </code>({{ layer.features.length }})
      </h3>
      <ng-template ngFor let-feat [ngForOf]="layer.features" let-j="index">
        <table
          *ngIf="j === (page -1)"
          class="slds-table slds-table_cell-buffer slds-table_bordered"
          aria-labelledby="element-with-table-label other-element-with-table-label"
        >
          <tbody>
            <ng-template ngFor let-prop [ngForOf]="feat.tableProperties" let-k="index">
              <tr class="slds-hint-parent">
                <th data-label="key" scope="row">
                  <div class="slds-truncate" title="{{ prop.key }}">
                    <a tabindex="-1">{{ prop.key }}</a>
                  </div>
                </th>
                <td data-label="value" style="max-width: 15rem;">
                  <div class="slds-truncate" title="{{ prop.value }}">{{ prop.value }}</div>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </ng-template>
      <div *ngIf="layer.features.length > 1" class="slds-button-group" role="group">
        <button (click)="prev(layer)" class="slds-button slds-button--neutral">
            Prev
        </button>
        <button class="slds-button slds-button--neutral">
            {{page}} / {{layer.features.length}}
        </button>
        <button (click)="next(layer)" class="slds-button slds-button--neutral">
            Next
        </button>
    </div>
    </div>
  </ng-template>
</div>
