export enum  FileType {
    DATA = 'data',
    IMAGE = 'image'
}

export enum  FileOutputType {
    BASE64 = 'base64',
    BLOB = 'blob',
    FILE = 'file',
    JSON = 'json',
    DATATABLE = 'datatable'
}
export interface FileImportOptions {
    type: FileType;
    dropZone?: boolean;
    multiple?: boolean;
    width?: string;
    height?: string;
    outPutType?: FileOutputType;
    image?: string;
    title?: string;
    tooltip?: string;
}
export interface OutputFile<T> {
    name: string;
    size: number;
    type?: FileOutputType;
    data?: T;
}


