import { BusinessType, DataTable } from "@galigeo-store/shared-models";
import { DataFusion } from "./data-fusion";
import { MergeResult } from "./merge-result";

/**
 * Fusion process that overrides the data
 */
export class OverrideData extends DataFusion {
    override mergeData(originalData: DataTable, newData: DataTable):MergeResult {
        this.buildIndexes(newData);

        // 1. Add new fields
        for(let field of newData.fields) {
            const originalField = this.getMatchedField(originalData.fields, field);
            const matchVisibility = originalField && originalField.visible === false && field.visible === true;
            if (field.businessType === BusinessType.LAT || field.businessType === BusinessType.LONG) {
                continue;
            }
            if(!originalField) {
                originalData.addField(field);
                this.result.numNewColumns++;
            } else if (matchVisibility){
                this.result.numNewColumns++;
                originalField.visible = true;
            } else {
                this.result.numOverridenColumns++;
            }
        }

        // 2. override the data
        const newDataHasLocation = (newData.getFieldByBusinessType(BusinessType.LAT) && newData.getFieldByBusinessType(BusinessType.LONG)) 
        || newData.getFieldByBusinessType(BusinessType.ADDRESS) 
        || newData.getFieldByBusinessType(BusinessType.POSTAL_CODE)
        || newData.getFieldByBusinessType(BusinessType.CITY);
        const origIdIdx = originalData.getFieldIndex(BusinessType.ID);
        const geounitIdx = originalData.getFieldIndexByBusinessType(BusinessType.GEOUNIT_CODE);
        for(let record of originalData.data) {
            const id = record.values[origIdIdx];
            const newRecord = this.getRecord(newData, id);
            if(newRecord) {
                for(let i=0;i<newData.fields.length;i++) {
                    const fieldNewData = newData.fields[i];
                    const originalField = this.getMatchedField(originalData.fields, fieldNewData);
                    if(!originalField) {
                        console.warn('Error, missing field in original data: ' + fieldNewData.name);
                        continue;
                    }
                    const colIdx = originalData.getFieldIndex(originalField.name);
                    record.values[colIdx] = newRecord.values[i];
                }
                if (newDataHasLocation) {
                    // If the new data has location, we need to override the geounit code
                    record.values[geounitIdx] = undefined;
                }
                this.result.numOverridenRecords++;
            }
        }

        // 3. Add new records
        this.addNewRecords(originalData);
        
        return this.result;
    }
    
}