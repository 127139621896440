import { LayerOptions } from '../model/LayerOptions.js';
import { MapBoxLayer } from './MapBoxLayer.js';
import * as maplibregl from 'maplibre-gl';
import { Heatmap } from '../model/StyleOptions.js';
import { MapboxStyle } from '../style/MapboxStyle.js';
import { StyleFactory } from '../style/StyleFactory.js';

export class HeatmapLayer extends MapBoxLayer {
  constructor(map: maplibregl.Map, options: LayerOptions, data?: any) {
    super(map, options, data);
  }

  public addInstances(): any[] {
    const layers: any[] = [];
    const style: MapboxStyle = StyleFactory.getStyle(this.options.style);
    if (!this.options.style.heatmap) throw new Error('Heatmap style is not defined');
    const heatmapOptions: Heatmap = this.options.style.heatmap;

    const heatmapLayer: any = {
      id: this.getId(),
      type: 'heatmap',
      source: this.getSourceId(),
      paint: {
        // Increase the heatmap color weight weight by zoom level
        // heatmap-intensity is a multiplier on top of heatmap-weight
        'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 9, 3],
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        'heatmap-color': HeatmapLayer.getHeatmapColors(heatmapOptions),
        // Adjust the heatmap radius by zoom level
        'heatmap-radius': HeatmapLayer.getHeatmapRadius(heatmapOptions),
        'heatmap-opacity': this.options.style.fillOpacity ? this.options.style.fillOpacity : 0.5,
      },
    };
    if (heatmapOptions && heatmapOptions.field) {
      // Increase the heatmap weight based on frequency and property magnitude
      heatmapLayer['paint']['heatmap-weight'] = ['interpolate', ['linear'], ['get', heatmapOptions.field], 0, 0, 6, 1];
    }
    layers.push(heatmapLayer);
    layers.push(this.addInstance('', style));

    return layers;
  }

  private static getHeatmapRadius(heatmapOptions: Heatmap): any {
    const radius = heatmapOptions && heatmapOptions.radius ? heatmapOptions.radius : 20;
    return [
      'interpolate',
      ['linear'],
      ['zoom'],
      0,
      Math.round(radius / 10),
      Math.round(radius / 8),
      Math.round(radius / 5),
      Math.round(radius / 2),
      radius,
    ];
  }

  private static getHeatmapColors(heatmapOptions: Heatmap): any {
    if (heatmapOptions && heatmapOptions.colors) {
      const result: any = ['interpolate', ['linear'], ['heatmap-density']];

      result.push(0);
      result.push('rgba(255, 255, 255, 0)');

      for (let i = 0; i < heatmapOptions.colors.length; i++) {
        //result.push((i+1) / heatmapOptions.colors.length);
        result.push(heatmapOptions.colors[i]);
      }

      return result;
    } else {
      // default colors
      return [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        0,
        'rgba(33,102,172,0)',
        0.2,
        'rgb(103,169,207)',
        0.4,
        'rgb(209,229,240)',
        0.6,
        'rgb(253,219,199)',
        0.8,
        'rgb(239,138,98)',
        1,
        'rgb(178,24,43)',
      ];
    }
  }
}
