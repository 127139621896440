

<ui-lightning-global-nav *ngIf="options" [options]="options" (onChange)="onNavChange($event)"></ui-lightning-global-nav>
<router-outlet></router-outlet>
<section *ngIf="loadGlobalSpinner" role="dialog" tabindex="-1" aria-modal="true" aria-labelledby="modal-heading-01" class="slds-modal slds-fade-in-open">
    <div class="slds-modal__container">
        <ui-lightning-spinner style=" position: absolute;top: 50%;left: 50%;"></ui-lightning-spinner>  
        <div style=" position: absolute;top: 54%;left: 46%; color: #fff;"> {{loadGlobalSpinnerMessage}}</div>
    </div>
  </section>
  <div *ngIf="loadGlobalSpinner" class="slds-backdrop slds-backdrop_open" role="presentation"></div>
