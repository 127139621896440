import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { GeocodeService } from '@galigeo-store/retail-services';
import { BusinessType, DataTable, PROGRESS_TYPE, ProgressOptions, Record } from '@galigeo-store/shared-models';
import { LanguageService, MessageService, ModalBase } from '@galigeo-store/ui-lightning';
import { LocaleModalAdresseComponent } from '../locales/localeModalAdresseComponent';
import { Network } from '../models/network';
@Component({
  selector: 'retail-modal-address',
  templateUrl: './modal-address.component.html',
  styleUrls: ['./modal-address.component.css']
})
export class ModalAddressComponent extends ModalBase {
  isOpen: boolean = false;
  feature!: any;
  callback!: any;
  selectedAdress!: any;
  adressesReverseGeocode!: any[];
  dataTableGeocode!: DataTable;
  geocodeNotFound: boolean = false;
  network!: Network;
  geocodeMethod!: string;
  enableValidation: boolean = false;
  @ViewChildren('radioContainer') radioContainer!: QueryList<ElementRef>;
  constructor(private host: ElementRef<HTMLElement>,
    private geocodeService: GeocodeService, private languageService: LanguageService,
    private messageService: MessageService) { 
    super();
    this.geocodeMethod = 'reverse';
    this.languageService.addTranslation('ModalAdresseComponent', new LocaleModalAdresseComponent().locale);
  }

  async getGeocodeData() {
    this.messageService.progress(new ProgressOptions({ message: this.languageService.getTranslation('ModalAdresseComponent', 'loading_addresses'),  progressType: PROGRESS_TYPE.START, progressPercent: 50 }));

    if (this.geocodeMethod === 'reverse') {
        await this.handleReverseGeocode();
    } else {
        await this.handleRegularGeocode();
    }
}

  private async handleReverseGeocode() {
      this.dataTableGeocode = await this.geocodeService.reverseGeocode(this.feature.geometry.coordinates[1], this.feature.geometry.coordinates[0]);
      this.addFeatureToDataTable();
      this.handleGeocodeResult();
  }

  private async handleRegularGeocode() {
      this.dataTableGeocode = await this.geocodeService.search(this.feature.address);
      this.handleGeocodeResult();
  }

  private addFeatureToDataTable() {
      this.dataTableGeocode.data.unshift({
          values: [
              this.feature.properties[BusinessType.LAT],
              this.feature.properties[BusinessType.LONG],
              this.feature.properties[BusinessType.ADDRESS],
              this.feature.properties[BusinessType.GEOCODER_ACCURACY],
              this.feature.properties[BusinessType.CITY],
              this.feature.properties[BusinessType.GEOCODER_CITY_CODE],
              this.feature.properties[BusinessType.POSTAL_CODE],
          ]
      });
  }

  private handleGeocodeResult() {
    this.messageService.progress(new ProgressOptions({ message: this.languageService.getTranslation('ModalAdresseComponent', 'loading_addresses'),  progressType: PROGRESS_TYPE.STOP, progressPercent: 100 }));
      if (this.dataTableGeocode.id === 'geocoder-not-found' || this.dataTableGeocode.id === 'reverse-not-found') {
          this.handleGeocoderNotFound();
          this.isOpen = true;
        } else {
          this.adaptGeocodeResults();
          this.isOpen = true;
      }
  }

  private handleGeocoderNotFound() {
    this.geocodeNotFound = true;
    console.log(this.feature)
    this.adressesReverseGeocode = [
      {
        title: this.feature.properties.address + ' ' + this.feature.properties.postal_code + ' ' + this.feature.properties.city,
        value: this.feature.properties.address
      }
    ];
    this.enableValidation = true;
    console.log(this.adressesReverseGeocode)
  }

private adaptGeocodeResults() {
  this.geocodeNotFound = false;
    this.adressesReverseGeocode = this.dataTableGeocode.data.map((record: Record, i : number ) => {
      if(this.geocodeMethod === 'reverse') {
      return {
        title: record.values[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_ADDRESS)],
        value: record.values[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_ADDRESS)]
      };
    } else {
      record.recordId = i;
      return {
        title: record.values[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_ADDRESS)]
          + ' ' + record.values[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_POSTAL_CODE)]
          + ' ' + record.values[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_CITY)],
        value: record
      };
    }
  });
      
      this.setSelectedAddress();
      this.addOldAddress();
      console.log(this.adressesReverseGeocode);
}

private setSelectedAddress() {
    if (this.adressesReverseGeocode && this.adressesReverseGeocode.length > 0) {
        this.selectedAdress = this.adressesReverseGeocode[0];
    }
}

private addOldAddress() {
    if (this.geocodeMethod !== 'reverse') {
        this.adressesReverseGeocode.unshift({
            title: this.feature.oldAdress,
            value: this.feature.oldAdress
        });
    }
}



  setAdress(event: any) {
    this.selectedAdress = event;
  }
  async open() {
    await this.getGeocodeData();
    
  }

  close(save: boolean) {
    this.isOpen = false;
    if (save && this.callback) {
        this.handleSave();
    } else if (!save && this.callback) {
        this.callback(undefined);
    }
    this.destroy();
}

private handleSave() {
    if (this.geocodeNotFound) {
        this.handleGeocodeNotFound();
    } else {
        this.handleGeocodeFound();
    }
}

private handleGeocodeNotFound() {
    const json: any = {};
    json[BusinessType.GEOMETRY] = this.feature.geometry;
    const recordId = this.feature.properties.recordId;
    const recordDtFor = this.getRecordDtFor(recordId);
    if (this.network.pois && recordDtFor) {
        json[BusinessType.NETWORK_ID] = recordDtFor.values[this.network.pois.getFieldIndex(BusinessType.NETWORK_ID)];
    }
    const dataTable = DataTable.fromJson([json]);
    dataTable.data[0].recordId = recordId;
    this.callback(dataTable);
}

private handleGeocodeFound() {
    const recordDtGeocode = this.getRecordDtGeocode();
    if (recordDtGeocode) {
        const recordId = this.feature.properties.recordId;
        const recordDtFor = this.getRecordDtFor(recordId);
        if (recordDtFor) {
            const json = this.createJson(recordDtGeocode, recordDtFor);
            const dataTable = DataTable.fromJson([json]);
            dataTable.data[0].recordId = recordId;
            this.callback(dataTable);
        }
    }
}

  private getRecordDtFor(recordId: any) {
    return this.network.pois?.data?.find((recordDtFor: any) => recordDtFor.recordId === recordId);
  }

  private getRecordDtGeocode() {
    let recordDtGeocode = undefined;
    if (this.geocodeMethod !== 'reverse') {
        recordDtGeocode = this.dataTableGeocode.data[this.selectedAdress.value.recordId];
    } else {
        recordDtGeocode = this.dataTableGeocode.data.find((rdg: any) => rdg.values[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_ADDRESS)] === this.selectedAdress.value);
    }
    return recordDtGeocode;
  }

  private createJson(recordDtGeocode: Record, recordDtFor: Record) {
    const json: any = {};
    if (this.network.pois) {
      json[BusinessType.NETWORK_ID] = recordDtFor.values[this.network.pois.getFieldIndex(BusinessType.NETWORK_ID)];
    }
    json[BusinessType.GEOMETRY] = this.getGeometry(recordDtGeocode);
    json[BusinessType.POSTAL_CODE] = recordDtGeocode.values[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_POSTAL_CODE)];
    json[BusinessType.CITY] = recordDtGeocode.values[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_CITY)];
    json[BusinessType.GEOCODER_CITY_CODE] = this.getCityCode(recordDtGeocode);
    json[BusinessType.ADDRESS] = this.getAddress(recordDtGeocode);
    json[BusinessType.GEOCODER_ACCURACY] = 1;
    return json;
  }
  

  private getGeometry(recordDtGeocode: any) {
    let geometry;
    if (this.geocodeMethod !== 'reverse') {
        geometry = {
            type: 'Point',
            coordinates: [recordDtGeocode.values[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_LONGITUDE)], recordDtGeocode.values[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_LATITUDE)]]
        };
    } else {
        geometry = this.feature.geometry;
    }
    return geometry;
  }

  private getCityCode(recordDtGeocode: any) {
    if (this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_CITY_CODE) && recordDtGeocode.values?.[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_CITY_CODE)] && recordDtGeocode.values[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_CITY_CODE)] !== 'null') {
        return recordDtGeocode.values[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_CITY_CODE)];
    }
    return undefined;
  }

  private getAddress(recordDtGeocode: any) {
      if (this.geocodeMethod === 'reverse') {
          return this.selectedAdress.value;
      } else {
          return recordDtGeocode.values[this.dataTableGeocode.getFieldIndex(BusinessType.GEOCODER_ADDRESS)];
      }
  }

  private destroy() {
      this.host.nativeElement.remove();
    
  }

  clickedRadioContainer(i: any) {
    this.radioContainer.forEach((element: any) => {
      const id = element.nativeElement.firstChild.id;
      if("radio-adress-" + i === id) {
        if (!this.enableValidation) this.enableValidation = true;
        element.nativeElement.firstChild.click();
      }
    });
  }
}
