import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalNavComponent } from './global-nav.component';
import { IconModule } from '../icon/icon.module';
import { DropdownModule } from '../dropdown/dropdown.module';



@NgModule({
  declarations: [
    GlobalNavComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    DropdownModule
  ],
  exports: [GlobalNavComponent]

})
export class GlobalNavModule { }
