import { AbstractMap } from './Map.js';
import { MapboxMap } from './MapboxMap.js';
import { MapOptions } from '../model/MapOptions.js';

export class MapFactory {
  public static getMap(options: MapOptions): Promise<AbstractMap> {
    return new Promise((resolve) => {
      let map: AbstractMap;
      const timeout = setTimeout(() => {
        console.warn('Missed loaded event');
        resolve(map);
      }, 1000);

      map = new MapboxMap(options);
      map.addEventListener('loaded', () => {
        clearTimeout(timeout);
        resolve(map);
      });
    });
  }
}
