import { Component, OnInit } from '@angular/core';
import { LocaleMessage } from '../../locale/lang-components/message';
import { LanguageService } from '../../locale/language.service';
import { MessageService } from '../message.service';
import { ConfirmOptions, MESSAGE_LEVEL } from '@galigeo-store/shared-models';

@Component({
  selector: 'message-confirm',
  templateUrl: './message-confirm.component.html',
  styleUrls: ['./message-confirm.component.css'],
})
export class MessageConfirmComponent implements OnInit {

  public options!: ConfirmOptions;
  public selectedValue!: any;
  constructor(private messageService: MessageService, private languageService: LanguageService) {
    this.setOptions(new ConfirmOptions());
    this.languageService.addTranslation('Message', new LocaleMessage().locale);
  }
  ngOnInit(): void {

  }

  setOptions(opts: ConfirmOptions) {
    this.options = opts;
    if (!this.options.title) {
      this.options.title = this.languageService.getTranslation('Message', 'please_confirm');
    }
    if (!this.options.message) {
      this.options.message = this.languageService.getTranslation('Message', 'do_you_want_to_continue');;
    }
    if (!this.options.level) {
      this.options.level = MESSAGE_LEVEL.ERROR;
    }
    if (!this.options.confirmLabel) {
      this.options.confirmLabel = this.languageService.getTranslation('Message', 'ok_button');
    }
    if (!this.options.cancelLabel) {
      this.options.cancelLabel = this.languageService.getTranslation('Message', 'cancel_button');
    }
  }

  onClickOk() {
    this.messageService.vrc?.remove(); // TODO: search for a better way to remove the component
    if (this.options.callback) {
      if (this.selectedValue && this.options.items) {
        this.options.callback(this.selectedValue);
      } else {
        this.options.callback(true);
      }
    }
  }

  onClickCancel() {
    this.messageService.vrc?.remove(); // TODO: search for a better way to remove the component
    if (this.options.callback) {
      this.options.callback(false);
    }
  }

}
