import { BusinessType } from '../data/dataTable.js';
import { DBTables } from '../db/index.js';
export class DBObject {
    tableNameToVariable(name) {
        const keys = Object.keys(DBTables).filter((x) => DBTables[x] == name);
        return keys.length > 0 ? 'DBTables.' + keys[0] : "'" + name + "'";
    }
    columnsNameToVariable(name) {
        const keys = Object.keys(BusinessType).filter((x) => BusinessType[x] == name);
        return keys.length > 0 ? 'BusinessType.' + keys[0] : "'" + name + "'";
    }
}
