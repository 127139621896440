  <!--PAGE NAVIGATION-->
  <div class="table-header">
      <div *ngIf="dataTable && dataTable.data" class="numbre-of-rows" id="visibleRows">
        {{getNbrElementVisible()}} {{'TableComponent:element' | translate:'element'}} <p *ngIf="atLeastOneChecked()">, {{countChecked()}} {{'TableComponent:element_selected' | translate:'element(s) selected'}}</p>
      </div>
      <div class="slds-form-element table-search-bar slds-col_bump-left">
        <div class="slds-form-element__control slds-input-has-icon slds-input-has-icon_right">
          <svg class="slds-icon slds-input__icon slds-input__icon_right slds-icon-text-default" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
          </svg>
          <input title="{{'TableComponent:tooltip_search' | translate}}" type="text" id="text-input-table" #tableSearchInput (keyup)="searchTableBar($event)" placeholder="{{'TableComponent:search_placeholder' | translate}}" required="" class="slds-input" />
          <div *ngIf="tableSearchInput.value" (click)="tableSearchInput.value = ''; searchTableBar({target : { value : ''}})" class="clear-table-search">
            <ui-lightning-icon variant="light" [iconName]="'utility:clear'" variant="default" size="x-small"></ui-lightning-icon>
          </div>
        </div>
      </div>
    <div *ngIf="pageSize !== undefined && getNbrElementVisible() > pageSize" class="pagination">
      <button title="{{'TableComponent:tooltip_first_page' | translate}}" class="slds-button slds-button_icon slds-button_icon-border-filled" [disabled]="bDisableFirst()" (click)="firstPage()">
        <svg class="slds-button__icon" aria-hidden="true">
          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#jump_to_left"></use>
        </svg></button>
        <button title="{{'TableComponent:tooltip_previous_page' | translate}}" class="slds-button slds-button_icon slds-button_icon-border-filled" [disabled]="bDisableFirst()" (click)="previousPage()">
          <svg class="slds-button__icon" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#chevronleft"></use>
          </svg></button>
          <span class="slds-badge"> {{pageNumber}} / {{totalPages}}</span>
          <button title="{{'TableComponent:tooltip_next_page' | translate}}" class="slds-button slds-button_icon slds-button_icon-border-filled" [disabled]="bDisableLast()" (click)="nextPage()">
        <svg class="slds-button__icon" aria-hidden="true">
          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#chevronright"></use>
        </svg>
      </button>
      <button title="{{'TableComponent:tooltip_last_page' | translate}}" class="slds-button slds-button_icon slds-button_icon-border-filled" [disabled]="bDisableLast()" (click)="lastPage()" >
        <svg class="slds-button__icon" aria-hidden="true">
          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#jump_to_right"></use>
        </svg>
      </button>
  </div>
  </div>
  
<div *ngIf="height" class="slds-table_edit_container slds-is-relative ggo-table-wrapper" [ngStyle]="{'max-height': 'calc(' + height + ' - 40px)'}">
  <table attr.aria-multiselectable="{{options && options.multiSelect}}" id="dynamic-table" class="slds-table 
    slds-table_col-bordered slds-table_bordered
    {{options && options.fixed ? 'slds-table_fixed-layout ' : ''}}
    {{options && options.canEdit ? 'slds-table_edit ' : ''}}
    {{options && options.resizable ? 'slds-table_resizable-cols ' : ''}}
    " role="grid" [style.width]="options && options.width ? options.width : '100%'"
    aria-labelledby="table">
    <thead>
      <tr class="slds-line-height_reset">

        <th *ngIf="options && options.multiSelect" class="slds-text-align_right slds-cell_action-mode" scope="col"
          style="width: 2.5rem;">
          <span id="column-group-header" class="slds-assistive-text">Choose a row</span>
          <div class="slds-th__action slds-th__action_form">
            <div class="slds-checkbox">
              <input (change)="checkAll($event)" type="checkbox" name="options" id="checkbox-unique-id-all"
                value="checkbox-unique-id-all" tabindex="0"
                aria-labelledby="check-select-all-label column-group-header"
                [checked]="this.allChecked"/>
              <label class="slds-checkbox__label" for="checkbox-unique-id-all" id="check-select-all-label">
                <span class="slds-checkbox_faux"></span>
                <span class="slds-form-element__label slds-assistive-text">Select All</span>
              </label>
            </div>
          </div>
        </th>
        

        <ng-template ngFor let-field [ngForOf]="fields">
          <th *ngIf="field.visible === undefined || field.visible"
            attr.aria-label="{{field.alias?field.alias:field.name}}" aria-sort="none"
            class="slds-is-resizable slds-is-sortable" scope="col" (click)="sortTable(field.name)">
            <a class="slds-th__action slds-text-link_reset" role="button" tabindex="-1">
              <span *ngIf="field.sortable || field.sortable === undefined" class="slds-assistive-text">Sort by: </span>
              <div class="slds-grid slds-grid_vertical-align-center slds-has-flexi-truncate">
                <span class="slds-truncate"
                  title="{{field.alias?field.alias:field.name}}">{{field.alias?field.alias:field.name}}</span>
                <span *ngIf="field.sortable || field.sortable === undefined"
                  class="slds-icon_container {{sortAsc ? 'slds-icon-utility-arrowdown' : 'slds-icon-utility-arrowup'}}">
                  <svg *ngIf="sortAsc" class="slds-icon slds-icon-text-default slds-is-sortable__icon "
                    aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#arrowdown"></use>
                  </svg>
                  <svg *ngIf="!sortAsc" class="slds-icon slds-icon-text-default slds-is-sortable__icon "
                    aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#arrowup"></use>
                  </svg>
                </span>
              </div>
            </a>
            <div *ngIf="options && options.resizable" class="slds-resizable">
              <input type="range" aria-label="Name column width" class="slds-resizable__input slds-assistive-text"
                id="cell-resize-handle-540" max="1000" min="20" tabindex="-1" />
              <span class="slds-resizable__handle">
                <span class="slds-resizable__divider"></span>
              </span>
            </div>
          </th>
        </ng-template>
        
      </tr>
    </thead>
    <tbody>
      <ng-template ngFor let-row [ngForOf]="dataTable.data" let-d="index">
        <tr (click)="onClickedRow($event , row)" *ngIf="(pageSize === undefined && (row.visible === undefined || row.visible))
          || (pageSize !== undefined  && row.isInPage) " aria-selected="false"
          class="slds-hint-parent row-index-{{row.recordId}} clickable-row"
          >
          <td  *ngIf="options && options.multiSelect" class="slds-text-align_right" role="gridcell">
            <div class="slds-checkbox">
              <input (change)="getChecked($event, row)" type="checkbox" name="options" [id]="'checkbox-' + d"
                [value]="'checkbox-' + d" tabindex="-1"
                [attr.aria-labelledby]="'check-button-label-' + d + ' column-group-header'" [checked]="row.selected" />
              <label class="slds-checkbox__label" [for]="'checkbox-' + d" [id]="'check-button-label-' + d">
                <span class="slds-checkbox_faux"></span>
                <span class="slds-form-element__label slds-assistive-text">Select item 1</span>
              </label>
            </div>
          </td>
          <ng-template ngFor let-field [ngForOf]="fields" let-e="index">
            <td *ngIf="field.visible === undefined || field.visible" class="slds-cell-edit" role="gridcell">
              <ui-lightning-table-cell class="slds-truncate" (action)="editCell($event)" 
              [options]="options" [row]="row" 
              [tdIndex]="e" [field]="field" [rowIndex]="d" [dataTable]="dataTable"
              [tableName]="name" [lastVisibleCol]="lastVisibleFieldsIndex"></ui-lightning-table-cell>
            </td>
          </ng-template>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>