import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodeViewerComponent } from './code-viewer.component';
import { AceModule } from 'ngx-ace-wrapper';
import { ACE_CONFIG } from 'ngx-ace-wrapper';
import { AceConfigInterface } from 'ngx-ace-wrapper';
const DEFAULT_ACE_CONFIG: AceConfigInterface = {};
@NgModule({
  declarations: [CodeViewerComponent],
  imports: [CommonModule, AceModule],
  providers: [
    {
      provide: ACE_CONFIG,
      useValue: DEFAULT_ACE_CONFIG,
    },
  ],
  exports: [CodeViewerComponent],
})
export class CodeViewerModule { }
