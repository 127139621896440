import { FieldType } from './dataTable.js';
export class DataTableToGeoJson {
    // convert to geojson
    convert(dataTable) {
        const geoJson = {
            type: 'FeatureCollection',
            features: [],
        };
        const features = [];
        const fields = dataTable.fields;
        const data = dataTable.data;
        const geomField = fields.find((field) => field.type === FieldType.GEOMETRY);
        if (!geomField)
            throw new Error('No geometry field found');
        for (const record of data) {
            const properties = {};
            let geometry = null;
            for (let i = 0; i < fields.length; i++) {
                if (fields[i].type === FieldType.GEOMETRY) {
                    geometry = record.values[i];
                    if (typeof geometry === 'string') {
                        geometry = JSON.parse(geometry);
                    }
                }
                else {
                    properties[fields[i].name] = record.values[i];
                }
            }
            properties.recordId = record.recordId;
            features.push({ properties, geometry });
        }
        return { type: 'FeatureCollection', features };
    }
}
