<div class="slds-dropdown-trigger slds-dropdown-trigger_click {{isOpen ? 'slds-is-open' : ''}}" [attr.id]="id" (focusout)="isOpen = false">
    <ui-lightning-dropdown-button 
    [class]="cssClassBtn"
    (onClick)="changeOpen()">
    <div class="{{cssClassImg}}">
      <img *ngIf="urlImgButton" src="{{urlImgButton}}" alt="Image Button"/>
    </div>
    <span *ngIf="title && title !== '' && tooltip" class="slds-truncate dropdown-text" title="{{tooltip}}">{{title}}</span>
    <ui-lightning-icon *ngIf="iconButton" [iconName]="iconButton" size="x-small"
    [decoratorClasses]="iconDecoratorClasses"></ui-lightning-icon>
    
    <ui-lightning-icon *ngIf="icon" iconName="utility:{{icon}}" size="small" alternativeText="{{tooltip}}"></ui-lightning-icon>
    </ui-lightning-dropdown-button>
    <div class="slds-dropdown {{cssClass}}">
      <ul class="slds-dropdown__list" role="menu" aria-label="Show More">
        <ng-content></ng-content>
      </ul>
    </div>
  </div>
