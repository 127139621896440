import { Locale } from '../model';

export class LocaleFileImport extends Locale {
  constructor() {
    super();
    this.languages = {
      en: {
        title: 'File Import',
        description: 'File Import',
        dropZoneText: 'Drop file here',
        tooltip: 'Click to select a file',
        file_type_not_supported: 'File type not supported',
        tooltip_img: 'Click to select an image'
      },
      fr: {
        title: 'Importer un fichier',
        description: 'Importer un fichier',
        dropZoneText: 'Déposez le fichier ici',
        tooltip: 'Cliquez pour sélectionner un fichier',
        file_type_not_supported: 'Type de fichier non supporté',
        tooltip_img: 'Cliquez pour sélectionner une image'
      },
      es: {
        title: 'Importar archivo',
        description: 'Importar archivo',
        dropZoneText: 'Suelta el archivo aquí',
        tooltip: 'Hacer clic para seleccionar un archivo',
        file_type_not_supported: 'Tipo de archivo no soportado',
        tooltip_img: 'Hacer clic para seleccionar una imagen'
      },
      de: {
        title: 'Datei importieren',
        description: 'Datei importieren',
        dropZoneText: 'Datei hier ablegen',
        tooltip: 'Anklicken, um eine Datei auszuwählen',
        file_type_not_supported: 'Dateityp nicht unterstützt',
        tooltip_img: 'Anklicken, um ein Bild auszuwählen'
      },
      pt: {
        title: 'Importar arquivo',
        description: 'Importar arquivo',
        dropZoneText: 'Solte o arquivo aqui',
        tooltip: 'Clique para selecionar um arquivo',
        file_type_not_supported: 'Tipo de arquivo não suportado',
        tooltip_img: 'Clique para selecionar uma imagem'
      },
    };
    this.genrateMap();
  }
}
