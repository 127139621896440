import { Component } from '@angular/core';
import { BusinessType, DataTable, Field, Record } from '@galigeo-store/shared-models';
import { LanguageService, LookupOptions, ModalBase } from '@galigeo-store/ui-lightning';
import { LocaleModalAdresseComponent } from '../locales/localeModalAdresseComponent';
@Component({
  selector: 'retail-modal-address',
  templateUrl: './modal-address-edit.component.html',
  styleUrls: ['./modal-address-edit.component.css']
})
export class ModalAddressEditComponent extends ModalBase {
  isOpen: boolean = false;
  editedAddress!: DataTable;
  customAdress: boolean = false;
  network!: any;
  callback!: any;
  selectedAddress!: any;
  cityInput!: any;
  postalCodeInput!: any;
  addressInput!: any;

  disablePosCheckbox: boolean = true;

  lookupOptions: LookupOptions = {
    label: undefined,
    debounceTime: 150,
    maxLines: 5,
    showCancel: false,
    inputHeight: "32px",
    icon: 'utility:search',
    showAddressOnly: true,
  }
  checkboxPos!: boolean;

  constructor(private languageService: LanguageService) {
    super();
    this.languageService.addTranslation('ModalAdresseComponent', new LocaleModalAdresseComponent().locale);
  }

  ngOnInit(): void {
    console.log('test');
    console.log(this.editedAddress);

    const addressIndex = this.editedAddress.getFieldIndex(this.editedAddress.fields.find((f: Field) => f.businessType === BusinessType.ADDRESS)?.name ?? "address");
    const cityIndex = this.editedAddress.getFieldIndex(this.editedAddress.fields.find((f: Field) => f.businessType === BusinessType.CITY)?.name ?? "city");
    const postalCodeIndex = this.editedAddress.getFieldIndex(this.editedAddress.fields.find((f: Field) => f.businessType === BusinessType.POSTAL_CODE)?.name ?? "postalCode");

    this.cityInput = this.editedAddress.data[0].values[cityIndex];
    this.postalCodeInput = this.editedAddress.data[0].values[postalCodeIndex];
    this.addressInput = this.editedAddress.data[0].values[addressIndex];
  }

  async open() {
    this.isOpen = true;
  }
  
  inputChanged() {
    console.log("input changed");
    this.checkboxPos = false;
    this.disablePosCheckbox = true;
  }

  selectAddress(e: any) {
    console.log("address selected : ", e);
    const addressIndex = e.value.getFieldIndex(e.value.fields.find((f: Field) => f.businessType === BusinessType.GEOCODER_ADDRESS)?.name ?? "geocoder_address");
    const cityIndex = e.value.getFieldIndex(e.value.fields.find((f: Field) => f.businessType === BusinessType.GEOCODER_CITY)?.name ?? "geocoder_city");
    const postalCodeIndex = e.value.getFieldIndex(e.value.fields.find((f: Field) => f.businessType === BusinessType.GEOCODER_POSTAL_CODE)?.name ?? "geocoder_postal_code");
    
    this.cityInput = e.value.data[0].values[cityIndex];
    this.postalCodeInput = e.value.data[0].values[postalCodeIndex];
    this.addressInput = e.value.data[0].values[addressIndex];

    this.selectedAddress = e.value;
    this.disablePosCheckbox = false;
    this.checkboxPos = true;
  }
  
  closeEditModal(save: boolean) {
    if (save) {
      if(this.checkboxPos) {
        console.log("address edited");
        const recordDtGeocode = this.selectedAddress.data[0];
        recordDtGeocode.recordId = 0;
        const recordDtFor = this.editedAddress.data[0];
        const json = this.createJson(recordDtGeocode, recordDtFor);
        const dataTable = DataTable.fromJson([json]);
        dataTable.data[0].recordId = this.editedAddress.data[0].recordId;
        this.callback(dataTable);
      } else {
        console.log("position not edited");
        const recordDtFor = this.editedAddress.data[0];
        const json = this.createJsonNoGeocode(recordDtFor);
        const dataTable = DataTable.fromJson([json]);
        dataTable.data[0].recordId = this.editedAddress.data[0].recordId;
        this.callback(dataTable);
      }
    }
    this.isOpen = false;
  }

  createJsonNoGeocode(recordDtFor: Record) {
    const json: any = {};
    if (this.network.pois) {
      json[BusinessType.NETWORK_ID] = recordDtFor.values[this.network.pois.getFieldIndex(BusinessType.NETWORK_ID)];
    }
    json[BusinessType.POSTAL_CODE] = this.postalCodeInput;
    json[BusinessType.CITY] = this.cityInput;
    json[BusinessType.ADDRESS] = (document.getElementById('lookup-input') as HTMLInputElement).value;
    if (recordDtFor.values[this.network.pois.getFieldIndex(BusinessType.GEOCODER_ACCURACY)]) {
      json[BusinessType.GEOCODER_ACCURACY] = 1;
    }
    return json;
  }

  private createJson(recordDtGeocode: Record, recordDtFor: Record) {
    const json: any = {};
    if (this.network.pois) {
      json[BusinessType.NETWORK_ID] = recordDtFor.values[this.network.pois.getFieldIndex(BusinessType.NETWORK_ID)];
    }
    if (this.checkboxPos) {
      json[BusinessType.GEOMETRY] = this.getGeometry(recordDtGeocode);
    }
    json[BusinessType.POSTAL_CODE] = recordDtGeocode.values[this.selectedAddress.getFieldIndex(BusinessType.GEOCODER_POSTAL_CODE)];
    json[BusinessType.CITY] = recordDtGeocode.values[this.selectedAddress.getFieldIndex(BusinessType.GEOCODER_CITY)];
    json[BusinessType.GEOCODER_CITY_CODE] = this.getCityCode(recordDtGeocode);
    json[BusinessType.ADDRESS] = this.getAddress(recordDtGeocode);
    json[BusinessType.GEOCODER_ACCURACY] = 1;
    return json;
  }

  private getGeometry(recordDtGeocode: any) {
    let geometry;
    geometry = {
      type: 'Point',
      coordinates: [recordDtGeocode.values[this.selectedAddress.getFieldIndex(BusinessType.GEOCODER_LONGITUDE)], recordDtGeocode.values[this.selectedAddress.getFieldIndex(BusinessType.GEOCODER_LATITUDE)]]
    };
    return geometry;
  }

  private getCityCode(recordDtGeocode: any) {
    if (this.selectedAddress.getFieldIndex(BusinessType.GEOCODER_CITY_CODE) && recordDtGeocode.values?.[this.selectedAddress.getFieldIndex(BusinessType.GEOCODER_CITY_CODE)] && recordDtGeocode.values[this.selectedAddress.getFieldIndex(BusinessType.GEOCODER_CITY_CODE)] !== 'null') {
        return recordDtGeocode.values[this.selectedAddress.getFieldIndex(BusinessType.GEOCODER_CITY_CODE)];
    }
    return undefined;
  }

  private getAddress(recordDtGeocode: any) {
    return recordDtGeocode.values[this.selectedAddress.getFieldIndex(BusinessType.GEOCODER_ADDRESS)];
  }
}
