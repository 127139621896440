import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BusinessType, DataTable, Field, FieldType, Record } from '@galigeo-store/shared-models';
import { TableEvent, TableOptions } from '../model';
import { EventService, GgoEvent } from '../../shared/event.service';
import AirDatepicker from 'air-datepicker';
import { createPopper } from '@popperjs/core';
import { LanguageService } from '../../locale/language.service';

@Component({
  selector: 'ui-lightning-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.css']
})
export class TableCellComponent implements OnInit {
  @Input() options!: TableOptions;
  @Input() row!: Record;
  @Input() tdIndex!: number;
  @Input() field!: Field;
  @Input() rowIndex!: number;
  @Input() dataTable!: DataTable;
  @Input() allChecked!: any;
  @Input() tableName!: string;
  @Input() lastVisibleCol!: any;

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('textEdit') textInput!: ElementRef;
  @ViewChild('numberEdit') numberInput!: ElementRef;
  @ViewChild('dateEdit') dateInput!: ElementRef;
  @ViewChild('doubleEdit') doubleInput!: ElementRef;

  cellType!: string;
  public indexEditableCell!: string;

  constructor(public eventService: EventService, public translateService: LanguageService) {
    this.indexEditableCell = "-1";
  }

  ngOnInit(): void {
    this.eventService.listenEvent(this.tableName ? this.tableName : 'table').subscribe((e: GgoEvent) => {
      if (e.action === TableEvent.START_EDIT_CELL && this.indexEditableCell !== e.obj) {
        this.closeEdit();
      }
    });
  }

  openEdit(e: number, d: number) {
    if (this.field.businessType === BusinessType.ADDRESS || this.field.businessType === BusinessType.POSTAL_CODE || this.field.businessType === BusinessType.CITY) {
      const adressDt = new DataTable({ fields: this.dataTable.fields, data: [this.row] })
      this.eventService.emitEvent(this.tableName ? this.tableName : 'table', { action: TableEvent.EDIT_ADDRESS, obj: adressDt });
    } else if (this.field && this.field.type === FieldType.BASE64
      || this.field && this.field.type === FieldType.IMAGELINK) {
      this.eventService.emitEvent(this.tableName ? this.tableName : 'table', {
        action: TableEvent.EDIT_IMAGE, obj: {
          field: this.field,
          row: this.row,
          value: this.row.values[e]
        }
      });
    } else {
      this.indexEditableCell = d + "-" + e;
      this.eventService.emitEvent(this.tableName ? this.tableName : 'table', { action: TableEvent.START_EDIT_CELL, obj: this.indexEditableCell });

      setTimeout(() => {
        if (this.doubleInput) {
          this.doubleInput.nativeElement.focus();
        }
        if (this.textInput) {
          this.textInput.nativeElement.focus();
        }
        if (this.numberInput) {
          this.numberInput.nativeElement.focus();
        }
        if (this.dateInput) {
          this.dateInput.nativeElement.focus();
          const datePicker = new AirDatepicker("#" + this.field.name + "-" + this.tdIndex, {
            container: "#dynamic-table",
            locale: this.options.datePickerLang,
            selectedDates: [this.row.values[e]],
            dateFormat: "yyyy/MM/dd",
            autoClose: true,
            position({ $datepicker, $target, $pointer, done }: { $datepicker: HTMLElement, $target: HTMLElement, $pointer: HTMLElement, done: () => void }) {
              let popper = createPopper($target, $datepicker, {
                placement: 'bottom',
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 10]
                    }
                  },
                  {
                    name: 'arrow',
                    options: {
                      element: $pointer,
                    }
                  },
                ]
              });
              return function completeHide() {
                popper.destroy();
                done();
              }
            }
          })
          datePicker.setViewDate(this.row.values[e]);
          datePicker.show();
        }
      });
    }
  }

  closeEdit() {
    this.indexEditableCell = "-1";
  }

  checkLastVisibleCol() {
    if (this.lastVisibleCol && this.lastVisibleCol.length > 0) {
      return this.lastVisibleCol.includes(this.tdIndex);
    } else {
      return false;
    }
  }

  cellEdited(e: any, fieldIndex: any, rowIndex: any, cellType: any) {
    let newValue;
    if (cellType === 'text' || cellType === null) {
      newValue = e;
      this.closeEdit();
    } else if (cellType === 'date') {
      newValue = new Date(e);
      this.closeEdit();
    } else {
      newValue = e;
      this.closeEdit();
    }
    this.dataTable.data[rowIndex].values[fieldIndex] = newValue;
    this.action.emit({ action: 'edit', obj: { value: newValue, fieldIndex: fieldIndex, rowIndex: rowIndex } });
  }

  isString() {
    return this.dataTable.fields[this.tdIndex].type == 'string';
  }

  validateCellEdit(fieldName: any, fieldIndex: any, rowIndex: any, cellType: any) {
    setTimeout(() => {
      if (this.field.type === 'string') {
        this.cellEdited(this.textInput.nativeElement.value, fieldIndex, rowIndex, cellType);
      }
      if (this.field.type === 'integer') {
        this.cellEdited(this.numberInput.nativeElement.value, fieldIndex, rowIndex, cellType);
      }
      if (this.field.type === 'date') {
        const dateInput = this.dateInput.nativeElement.value;
        console.log(dateInput.substr(0, 4) + "-" + dateInput.substr(5, 2) + dateInput.substr(8, 2) + "-" + "T12:00");
        const formated = new Date(dateInput.substr(0, 4) + "-" + dateInput.substr(5, 2) + "-" + dateInput.substr(8, 2) + "T12:00");
        this.cellEdited(formated, fieldIndex, rowIndex, cellType);
      }
      if (this.field.type === 'double') {
        if (this.doubleInput.nativeElement.value.indexOf(',') > -1) {
          this.doubleInput.nativeElement.value = this.doubleInput.nativeElement.value.replace(',', '.');
        }
        this.cellEdited(parseFloat(this.doubleInput.nativeElement.value), fieldIndex, rowIndex, cellType);
      }
    });
  }

  parseDate(date: any) {
    if (!date || date === null || date === undefined || date === '' || date === 'null' || date === 'undefined') {
      return '';
    } else {
      date = new Date(date);
      switch (this.translateService.lang) {
        case "fr":
          return date.toLocaleDateString('fr-FR');
          break;

        default:
          return date.toISOString().replace(/T.*/, '').replace(/-/g, '/');
          break;
      }
    }
  }
}