import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-lightning-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit {
@Input() cssClass!: string;
@Input() id!: string;
@Input() title!: string;
@Input() tooltip!: string;
@Input() icon!: string;
@Input() iconButton!: string;
@Input() iconDecoratorClasses!: string;
@Input() urlImgButton!: string;
@Input() cssClassBtn!: string;
@Input() cssClassImg!: string;



public url!: string;
public isOpen!: boolean;
  constructor() {
    this.isOpen = false;
    this.cssClass = 'slds-dropdown_left slds-dropdown_small';
   }

  ngOnInit(): void {
    this.url = '/assets/icons/utility-sprite/svg/symbols.svg#' + this.icon;
    if(!this.tooltip) this.tooltip = this.title;
  }
  changeOpen() {
    this.isOpen = !this.isOpen;
    
  }

}
