import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { REST_BUSINESSFIELDS } from '../constants';
import { HttpHeaders } from '@angular/common/http';
import { DataTable } from '@galigeo-store/shared-models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FieldsService {

  constructor(private _http: HttpService) { }
  /*
  getpiobyId
  */
  public getFields(): Observable<DataTable> {
    console.warn("Deprecated: poiService.getBusinessFields() instead");
    return this._http.doGet({ url: REST_BUSINESSFIELDS, where: "visible=true" })
      .pipe(map((response: any) => {
        return new DataTable(response);
      }))
  }

}
