import { Component, Input, OnInit } from '@angular/core';
import { ButtonListItem } from './model';

@Component({
  selector: 'ui-lightning-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.css']
})
export class ButtonGroupComponent implements OnInit {
  @Input() title!: string;
  @Input() type!: string;
  @Input() list!: ButtonListItem[];
  constructor() { }

  ngOnInit(): void {
  }

}
