import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FicheInfoComponent } from './fiche-info.component';



@NgModule({
  declarations: [
    FicheInfoComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [FicheInfoComponent]
})
export class FicheInfoModule { }
