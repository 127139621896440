<span class="slds-grid slds-grid_align-spread slds-truncate" id="{{dataTable.id + '_' + rowIndex + '_' + field.name}}">
  <ng-container *ngIf="!options?.graphicColumns">
    <ng-container [ngSwitch]="field.type">
      <span *ngSwitchDefault class="slds-truncate" title="{{row.values[tdIndex]}}">{{row.values[tdIndex]}}</span>
      <span *ngSwitchCase="'date'">{{parseDate(row.values[tdIndex])}}</span>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="options?.graphicColumns?.[tdIndex]">
    <ng-container *ngIf="field.editable" [ngSwitch]="options.graphicColumns?.[tdIndex]?.type">
      <ui-lightning-checkbox [disabled]="false" *ngSwitchCase="'checkbox'"
        (action)="cellEdited($event, tdIndex, rowIndex, options.graphicColumns?.[tdIndex]?.type)"
        [checked]="row.values[tdIndex]" [checkboxID]="field.name + '-' + rowIndex"></ui-lightning-checkbox>
      <span class="slds-truncate" *ngSwitchCase="'number'">{{row.values[tdIndex]}}</span>
      <span title="{{row.values[tdIndex]}}" class="slds-truncate" *ngSwitchCase="'text'">{{row.values[tdIndex]}}</span>
      <ui-lightning-select *ngSwitchCase="'select'"
        (action)="cellEdited($event, tdIndex, rowIndex, options.graphicColumns?.[tdIndex]?.type)"
        [values]="options.graphicColumns?.[tdIndex]?.values ?? []"
        [defaultVal]="row.values[tdIndex]"></ui-lightning-select>
      <span style="width: 20px" *ngSwitchCase="'image'"><img [src]="row.values[tdIndex]" width="20px"/></span>
      <span *ngSwitchCase="'date'">{{parseDate(row.values[tdIndex])}}</span>
      <span title="{{row.values[tdIndex]}}" class="slds-truncate" *ngSwitchDefault>{{row.values[tdIndex]}}</span>
    </ng-container>
    <ng-container class="slds-truncate" [ngSwitch]="options.graphicColumns?.[tdIndex]?.type" *ngIf="!field.editable">
      <ui-lightning-checkbox *ngSwitchCase="'checkbox'" [checked]="row.values[tdIndex]"
        [checkboxID]="field.name + '-' + rowIndex" [disabled]="true"></ui-lightning-checkbox>
      <span class="slds-truncate" title="{{row.values[tdIndex]}}"
        *ngSwitchCase="'select'">{{row.values[tdIndex]}}</span>
      <span class="slds-truncate" title="{{row.values[tdIndex]}}" *ngSwitchCase="'text'">{{row.values[tdIndex]}}</span>
      <span class="slds-truncate" title="{{row.values[tdIndex]}}"
        *ngSwitchCase="'number'">{{row.values[tdIndex]}}</span>
      <span class="slds-truncate" title="{{row.values[tdIndex]}}" *ngSwitchCase="'icon'"
        style="display: block;margin: auto;">
        <ui-lightning-icon [iconName]="row.values[tdIndex]" [size]="'xx-small'"></ui-lightning-icon>
      </span>
      <span class="slds-truncate" [innerHTML]="row.values[tdIndex] | safeHtml" *ngSwitchCase="'html'"></span>
      <span *ngSwitchCase="'date'">{{parseDate(row.values[tdIndex])}}</span>
      <span style="width: 20px" *ngSwitchCase="'image'"><img [src]="row.values[tdIndex]"  width="20px"/></span>
    </ng-container>
  </ng-container>
  <section *ngIf="(rowIndex + '-' + tdIndex) === indexEditableCell"
    [attr.aria-describedby]="'dialog-body-id-' + rowIndex + '-' + tdIndex"
    class="slds-popover slds-popover slds-popover_edit" role="dialog" [ngStyle]="checkLastVisibleCol() ? {'position':'absolute','top':'0','right':'0.0625rem'} : {'position':'absolute','top':'0','left':'0.0625rem'}">
    <div class="slds-popover__body" [id]="'dialog-body-id-' + rowIndex + '-' + tdIndex">
      <div class="slds-media">
        <div class="slds-media__body">
          <div class="slds-form-element slds-grid slds-wrap">
            <label class="slds-form-element__label slds-form-element__label_edit slds-no-flex"
              [for]="field.name + '-' + tdIndex">
              <span class="slds-assistive-text">{{row.values[tdIndex]}}</span>
            </label>
            <div class="slds-form-element__control slds-grow">
              <ng-container [ngSwitch]="field.type">
                <input #textEdit *ngSwitchCase="'string'" type="text" class="slds-input" [id]="field.name + '-' + tdIndex"
                  value="{{row.values[tdIndex]}}"
                  (keyup.enter)="validateCellEdit($event, tdIndex, rowIndex, options.graphicColumns?.[tdIndex]?.type)"
                  (keyup.esc)="closeEdit()" />
                <input #numberEdit *ngSwitchCase="'integer'" type="number" class="slds-input_counter"
                  [id]="field.name + '-' + tdIndex" value="{{row.values[tdIndex]}}"
                  (keyup.enter)="validateCellEdit($event, tdIndex, rowIndex, options.graphicColumns?.[tdIndex]?.type)"
                  (keyup.esc)="closeEdit()" />
                <input #dateEdit *ngSwitchCase="'date'" type="text" class="slds-input"
                [id]="field.name + '-' + tdIndex" value="{{parseDate(row.values[tdIndex])}}"
                (keyup.esc)="closeEdit()" readonly/>
                <input #doubleEdit *ngSwitchCase="'double'" type="number" class="slds-input_counter"
                [id]="field.name + '-' + tdIndex" value="{{row.values[tdIndex]}}"
                (keyup.enter)="validateCellEdit($event, tdIndex, rowIndex, options.graphicColumns?.[tdIndex]?.type)"
                (keyup.esc)="closeEdit()" />
              </ng-container>
            </div>
            <ui-lightning-icon
              (click)="validateCellEdit(field.name, tdIndex, rowIndex, options.graphicColumns?.[tdIndex]?.type)"
              [iconName]="'utility:check'" [size]="'x-small'" [variant]="'success'"></ui-lightning-icon>
            <ui-lightning-icon (click)="closeEdit()" [iconName]="'utility:close'" [size]="'x-small'"
              [variant]="'error'"></ui-lightning-icon>
          </div>
        </div>
      </div>
    </div>
  </section>
  <button style="display: inline;" *ngIf="field.editable && options && options.canEdit"
    (click)="openEdit(tdIndex, rowIndex)"
    class="slds-button slds-button_icon slds-cell-edit__button slds-m-left_x-small" tabindex="-1" title="Editer">
    <svg class="slds-button__icon slds-button__icon_hint slds-button__icon_edit" aria-hidden="true">
      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#edit"></use>
    </svg>
  </button>
</span>