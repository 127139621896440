 <header class="slds-global-header_container">
        
        <div class="slds-global-header slds-grid slds-grid_align-spread">
          <div class="slds-global-header__item">
            
            <ul class="slds-global-actions">
                <ng-template ngFor let-item [ngForOf]="options.items" let-i="index">
                    <li *ngIf="item.items && item.items.length > 0  && item.position === 'left'" class="slds-global-actions__item">
                        <ui-lightning-dropdown [cssClass]="'slds-dropdown_left slds-dropdown_x-small'" [title]="item.label!" [iconButton]="item.icon!" [icon]="item.chevrondown! ? 'chevrondown' : ''"
                        [iconDecoratorClasses]="item.iconDecoratorClasses!" [urlImgButton]="item.imgUrl!">
                        <ng-template ngFor let-subItem [ngForOf]="item.items" let-j="index">
                          <ui-lightning-dropdown-item>
                              <a href="#" role="menuitem" tabindex="0" (mousedown)="itemClicked(subItem)">
                                <span class="slds-truncate" title="{{subItem.title}}">{{subItem.label}}</span>
                                <ui-lightning-icon *ngIf="subItem.icon" [decoratorClasses]="item.iconDecoratorClasses!" [iconName]="subItem.icon" size="x-small"></ui-lightning-icon>
                              </a>
                          </ui-lightning-dropdown-item>
                        </ng-template>    
                        </ui-lightning-dropdown>
                      </li>
                      <li *ngIf="(item.items === undefined || (item.items && item.items.length === 0))  && item.position === 'left'" class="slds-global-actions__item">
                        <a *ngIf="item.type === 'link'" href="#" role="menuitem" tabindex="0" (mousedown)="itemClicked(item)">
                          <ui-lightning-icon *ngIf="item.icon" [decoratorClasses]="item.iconDecoratorClasses!" [iconName]="item.icon" size="x-small"></ui-lightning-icon>
                          <img *ngIf="item.imgUrl" src="{{item.imgUrl}}"/>  
                          <span *ngIf="item.label" class="slds-truncate" title="{{item.title}}">{{item.label}}</span>
                        </a>
                        <ui-lightning-icon *ngIf="item.type != 'link' && item.icon" [decoratorClasses]="item.iconDecoratorClasses!" [iconName]="item.icon" size="x-small"></ui-lightning-icon>
                        <img *ngIf="item.type != 'link' && item.imgUrl" src="{{item.imgUrl}}"/>  
                        <span *ngIf="item.label && item.type === 'text'" class="slds-truncate" title="{{item.title}}">{{item.label}}</span>
                        <h1 *ngIf="item.type === 'title'" class="title">{{item.label}}</h1>
                      </li>
                </ng-template>
                </ul>
          </div>
          
          <div class="slds-global-header__item">
            <ul class="slds-global-actions">
                <ng-template ngFor let-item [ngForOf]="options.items" let-i="index">
                    <li *ngIf="item.items && item.items.length > 0 && item.position === 'right'" class="slds-global-actions__item">
                        <ui-lightning-dropdown [cssClass]="'slds-dropdown_right slds-dropdown_x-small'" [title]="item.label!" [iconButton]="item.icon!" [icon]="item.chevrondown! ? 'chevrondown' : ''"
                        [iconDecoratorClasses]="item.iconDecoratorClasses!">
                        <ng-template ngFor let-subItem [ngForOf]="item.items" let-j="index">
                            <ui-lightning-dropdown-item>
                                <a href="#" role="menuitem" tabindex="0" (mousedown)="itemClicked(subItem)">
                                  <span class="slds-truncate" title="{{subItem.title}}">{{subItem.label}}</span>
                                  <ui-lightning-icon *ngIf="subItem.icon" [decoratorClasses]="item.iconDecoratorClasses!" [iconName]="subItem.icon" size="x-small"></ui-lightning-icon>
                                </a>
                            </ui-lightning-dropdown-item>
                        </ng-template>    
                        </ui-lightning-dropdown>
                      </li>
                      <li *ngIf="(item.items === undefined || (item.items && item.items.length === 0) ) && item.position === 'right'" class="slds-global-actions__item">
                        <a *ngIf="item.type === 'link'" href="#" role="menuitem" tabindex="0" (mousedown)="itemClicked(item)">
                          <ui-lightning-icon *ngIf="item.icon" [decoratorClasses]="item.iconDecoratorClasses!" [iconName]="item.icon" size="x-small"></ui-lightning-icon>
                        <img *ngIf="item.imgUrl" src="{{item.imgUrl}}"/>  
                        <span *ngIf="item.label" class="slds-truncate" title="{{item.title}}">{{item.label}}</span>
                        </a>
                        <ui-lightning-icon *ngIf="item.type != 'link' && item.icon" [decoratorClasses]="item.iconDecoratorClasses!" [iconName]="item.icon" size="x-small"></ui-lightning-icon>
                        <img *ngIf="item.type != 'link' && item.imgUrl" src="{{item.imgUrl}}"/>  
                        <span *ngIf="item.label && item.type === 'text'" class="slds-truncate" title="{{item.title}}">{{item.label}}</span>
                      </li>
                </ng-template>
            </ul>
          </div>
        </div>
      </header>