import * as maplibregl from 'maplibre-gl';
import { AbstractLayer } from '../layer/AbstractLayer.js';
import { AbstractMap } from './Map.js';
import { MapBoxLayer } from '../layer/MapBoxLayer.js';
import { MapOptions } from '../model/MapOptions.js';
import { LayerOptions, LayerType } from '../model/LayerOptions.js';
import { Extent } from '../model/Extent.js';
import { HeatmapLayer } from '../layer/HeatmapLayer.js';
import { PopupUtils } from '../utils/PopupUtils.js';
import { SimpleLayer } from '../layer/SimpleLayer.js';
import { BuildingLayer } from '../layer/MapBoxBuildingLayer.js';
import { PrintOptions } from '../model/PrintOptions.js';
import { GeometryType } from '../model/StyleOptions.js';
import { TooltipUtils } from '../utils/TooltipUtils.js';

export class MapboxMap extends AbstractMap {
  private map: maplibregl.Map;

  /**
   * @param  {MapOptions} options
   */
  constructor(options: MapOptions) {
    super(options);
    this.layers = [];

    const mapBoxOptions: any = {
      container: options.elementId, // container ID
      style: 'https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json', // style URL
      zoom: options.zoomStart || 8, // starting zoom
      projection: { name: 'globe' }, // display the map as a 3D globe,
      pitch: options.pitch || 0,
      preserveDrawingBuffer: true,
      attributionControl: false,
    };
    if (options.mapTilerKey) {
      mapBoxOptions.style = `https://api.maptiler.com/maps/openstreetmap/style.json?key=${options.mapTilerKey}`;
    }
    if (options.center) mapBoxOptions.center = options.center;
    else if (options.extent) {
      mapBoxOptions.bounds = [
        [options.extent.xmin, options.extent.ymin],
        [options.extent.xmax, options.extent.ymax],
      ];
    } else {
      // set a world extent
      mapBoxOptions.bounds = [
        [-180, -85],
        [180, 85],
      ];
    }

    this.map = new maplibregl.Map(mapBoxOptions);
    if (options.mapControls) {
      const navControl = new maplibregl.NavigationControl();
      this.map.addControl(navControl, 'bottom-right');
    }
    this.registerEvents();
  }
  public bringToFront(layerId: string): void {
    this.map.moveLayer(layerId);
  }
  private registerEvents() {
    this.map.on('style.load', () => {
      //this.map.setFog({}); // Set the default atmosphere style dont work in maplibre
    });
    this.map.once('load', () => {
      this.setLoaded();
    });
    this.map.on('click', (e) => {
      this.fireEvent('click', { lngLat: e.lngLat, point: e.point });
    });
    this.map.on('zoomstart', () => {
      this.fireEvent('zoomstart');
    });
    this.map.on('zoom', () => {
      this.fireEvent('zoom');
    });
    this.map.on('zoomend', () => {
      this.fireEvent('zoomend');
    });
    this.map.on('movestart', () => {
      this.fireEvent('movestart');
    });
    this.map.on('move', () => {
      this.fireEvent('move');
    });
    this.map.on('moveend', () => {
      this.fireEvent('moveend', this.getExtent());
    });
    this.map.on('dragstart', () => {
      this.fireEvent('dragstart');
    });
    this.map.on('drag', () => {
      this.fireEvent('drag');
    });
    this.map.on('dragend', () => {
      this.fireEvent('dragend');
    });
    this.map.on('touchstart', (e) => {
      this.fireEvent('drag', { lngLats: e.lngLats, points: e.points });
    });
    this.map.on('touchend', () => {
      this.fireEvent('dragend');
    });
    this.map.on('dataloading', (e) => {
      this.fireEvent('dataloading', e);
    });
    this.map.on('styledataloading', (e) => {
      this.fireEvent('styledataloading', e);
    });
    this.map.on('styledata', (e) => {
      this.fireEvent('styledata', e);
    });
    this.map.on('idle', (e) => {
      this.fireEvent('drawend', e);
    });
  }
  /**
   * Detect the label layer id for keeping the labels on top
   * @returns
   */
  private detectLabelLayerId() {
    let labelLayerId;
    const layers = this.map.getStyle().layers;

    const getLayerId = (layerId: string) => {
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].id === layerId) {
          return layerId;
        }
      }
    };

    // building-top exists ?
    const buildingTopLayerIdx = getLayerId('building-top');
    if (buildingTopLayerIdx !== undefined) {
      return buildingTopLayerIdx;
    }

    // otherwise, find the first line layer
    for (let i = 0; i < layers.length; i++) {
      const layer = layers[i];
      if (layer.type === 'line') {
        labelLayerId = layer.id;
        break;
      }
    }
    return labelLayerId;
  }
  public loadImages(images: { name: string; url: string }[]): Promise<any[]> {
    return Promise.all(
      images.map(
        (img) =>
          new Promise((resolve) => {
            try {
              this.map
                .loadImage(`${img.url}`)
                .then((res) => {
                  if (!res.data) {
                    //reject(error); // never mind, just ignore
                    console.warn(res);
                  } else if (!this.images.has(img.name)) {
                    this.map.addImage(img.name, res.data);
                    this.images.add(img.name);
                  } else {
                    this.map.updateImage(img.name, res.data);
                  }

                  resolve({ img, res });
                })
                .catch((error) => {
                  console.warn('Skip load image ' + img.url, error);
                  resolve({ img, error });
                });
            } catch (error) {
              console.warn('Skip load image ' + img.url, error);
            }
          }),
      ),
    );
  }

  public getMapBoxMap(): maplibregl.Map {
    return this.map;
  }

  public async addLayer(options: LayerOptions, data?: any): Promise<AbstractLayer> {
    console.log('Add layer', options);
    this.setDataIds(data);
    let layer: MapBoxLayer;
    if (options?.style?.images) {
      await this.loadImages(options.style.images);
    }
    switch (options.type) {
      case LayerType.Simple:
        layer = new SimpleLayer(this.map, options, data);
        break;
      case LayerType.HeatMap:
        layer = new HeatmapLayer(this.map, options, data);
        break;
      case LayerType.Building:
        layer = new BuildingLayer(this.map, options, data);
        break;
      default:
        layer = new SimpleLayer(this.map, options, data);
    }
    layer.setData(data);

    const instances: any[] = layer.addInstances();
    let labelLayerId = undefined;
    if (layer?.getOptions()?.style?.geometryType === GeometryType.Polygon) {
      labelLayerId = this.detectLabelLayerId();
    }
    for (const instanceLayer of instances) {
      if (typeof this.map.getLayer(instanceLayer.id) === 'undefined') {
        this.map.addLayer(instanceLayer, options.displayOnTop ? undefined : labelLayerId);
      }
    }

    this.map.on('render', () => {
      layer.fireEvent('loaded', layer);
    });

    this.layers.push(layer);
    if (options.popupContent) {
      PopupUtils.registerPopup(this.map, layer);
    }
    if (options.tooltipContent) {
      TooltipUtils.registerTooltip(this.map, layer);
    }
    layer.registerEvents();
    return layer;
  }

  /**
   * @param  {string} MapBoxLayer
   */
  public removeLayer(layerId: string): AbstractLayer {
    const layer: MapBoxLayer = this.getLayer(layerId) as MapBoxLayer;
    if (layer.getPopupContent()) {
      PopupUtils.unregisterPopup(this.map, layer);
    }
    if (layer.getTooltipContent()) {
      TooltipUtils.unregisterTooltip(this.map, layer);
    }
    layer.removeInstances();
    //this.map.removeSource(layer.getId() + "-source");
    layer.deleteSource();
    if (layer.getOptions().style.label) {
      this.map.removeSource(layer.getId() + '-label-source');
    }
    this.layers = this.layers.filter((e) => e.getId() !== layerId);
    return layer;
  }

  /**
   * @param  {[number]} coordinates
   * @param  {} number
   * @param  {number} zoom
   */
  public flyTo(coordinates: [number, number], zoom: number) {
    const target = {
      center: coordinates,
      zoom: zoom,
      //bearing: 130,
      //pitch: 75
    };
    this.map.flyTo({ ...target, duration: 500, essential: true });
  }

  public getExtent(): Extent {
    const bounds = this.map.getBounds();
    return new Extent(bounds.getSouthWest().lng, bounds.getSouthWest().lat, bounds.getNorthEast().lng, bounds.getNorthEast().lat);
  }

  public setExtent(extent: Extent): void {
    const sw = new maplibregl.LngLat(extent.xmin, extent.ymin);
    const ne = new maplibregl.LngLat(extent.xmax, extent.ymax);
    const llb = new maplibregl.LngLatBounds(sw, ne);
    this.map.fitBounds(llb, { padding: 40 });
  }

  public getMapAsPng(options?: PrintOptions): string {
    let padding = { top: 0, left: 0, right: 0, bottom: 0 };
    if (options && options.padding) {
      padding = { left: options.padding || 0, top: options.padding || 0, right: options.padding || 0, bottom: options.padding || 0 };
    } else {
      if (options && options.paddingTop) padding.top = options.paddingTop;
      if (options && options.paddingLeft) padding.left = options.paddingLeft;
      if (options && options.paddingRight) padding.right = options.paddingRight;
      if (options && options.paddingBottom) padding.bottom = options.paddingBottom;
    }
    if (padding.top || padding.left || padding.right || padding.bottom) {
      this.map.setPadding(padding);
    }
    return this.map.getCanvas().toDataURL();
  }

  public getZoom(): number {
    return this.map.getZoom();
  }

  public setZoom(zoom: number): void {
    this.map.setZoom(zoom);
  }

  public getCenter(): [number, number] {
    return [this.map.getCenter().lng, this.map.getCenter().lat];
  }

  public resetNorth(): void {
    this.map.resetNorth();
  }

  /**
   * Make sure that the data has an id. If not, it will be added.
   * This is necessary for the selection of the features.
   * @param data
   * @returns
   */
  private setDataIds(data: any) {
    if (!data) return;
    if (data && data.features) {
      data.features?.forEach((f: any, i: number) => {
        if (!f.id) {
          f.id = i;
        }
      });
    }
    return data;
  }

  /*public selectLayers(point: any, layers?: MapBoxLayer[]) {
    const ids = [];
    if (layers && layers.length > 0) {
      layers.forEach((l) => {
        ids.push(l.getId());
      });
    } else {
      this.layers.forEach((l) => {
        ids.push(l.getId());
      });
    }
    const layersFeatures = [];
    ids.forEach((idLayer) => (layersFeatures.push({ id: idLayer, features: [] })));
    const features: any = this.map.queryRenderedFeatures(
      point,
      {
        layers: ids,
      }
    );
    layersFeatures.forEach(l => {
      features.forEach((feature) => {
        if (l.id === feature.layer.id) {
          l.features.push({
            type: "Feature",
            geometry: feature.geometry,
            properties: feature.properties,
          });
        }
      });
    })
    return layersFeatures;
  }*/
}
