<ng-container *ngIf="options" [ngSwitch]="options.type">
  <ng-container *ngSwitchCase="'text'">
    <div
      class="slds-form-element slds-form-element_stacked 
        {{
        form.controls[options.name].invalid &&
        (form.controls[options.name].dirty || form.controls[options.name].touched)
          ? 'slds-has-error'
          : ''
      }}"
    >
      <label class="slds-form-element__label" for="stacked-input-{{ options.name }}">
        <abbr *ngIf="options.required" class="slds-required" title="required">* </abbr>
        {{ options.title }}</label
      >
      <div class="slds-form-element__control">
        <input
        [(ngModel)]="options.value"
          [formControl]="$any(form).controls[options.name]"
          type="text"
          id="stacked-input-{{ options.name }}"
          placeholder="{{ options.placeholder }}"
          class="slds-input"
        />
      </div>
      <div
        *ngIf="
          form.controls[options.name].invalid &&
          (form.controls[options.name].dirty || form.controls[options.name].touched)
        "
        class="slds-form-element__help"
        id="form-error-{{ options.name }}"
      >
        {{ options.msgError }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'textarea'">
    <div
    
      class="slds-form-element slds-form-element_stacked {{
        form.controls[options.name].invalid &&
        (form.controls[options.name].dirty || form.controls[options.name].touched)
          ? 'slds-has-error'
          : ''
      }}"
    >
      <label class="slds-form-element__label" for="stacked-input-{{ options.name }}">
        <abbr *ngIf="options.required" class="slds-required" title="required">* </abbr>
        {{ options.title }}</label
      >
      <div class="slds-form-element__control">
        <textarea
        [(ngModel)]="options.value"
          [formControl]="$any(form).controls[options.name]"
          id="stacked-input-{{ options.name }}"
          placeholder="{{ options.placeholder }}"
          class="slds-textarea"
        ></textarea>
      </div>
      <div
        *ngIf="
          form.controls[options.name].invalid &&
          (form.controls[options.name].dirty || form.controls[options.name].touched)
        "
        class="slds-form-element__help"
        id="form-error-{{ options.name }}"
      >
        {{ options.msgError }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'select'">
    <div *ngIf="options.multiList"
      class="slds-form-element slds-form-element_stacked {{
        form.controls[options.name].invalid &&
        (form.controls[options.name].dirty || form.controls[options.name].touched)
          ? 'slds-has-error'
          : ''
      }}"
    >
      <label class="slds-form-element__label" for="stacked-input-{{ options.name }}">
        <abbr *ngIf="options.required" class="slds-required" title="required">* </abbr>
        {{ options.title }}</label
      >
      <div class="slds-form-element__control">
        <div class="slds-select_container">
          <select class="slds-select" id="stacked-input-{{ options.name }}" 
          [formControl]="$any(form).controls[options.name]"
          required="{{options.required}}"
          [(ngModel)]="options.value"> 
            <option [ngValue]="''">{{options.placeholder}}</option>
            <option *ngFor="let option of options.multiList" [ngValue]="option.value">{{option.title}}</option>
          </select>
        </div>
      </div>
      <div
        *ngIf="
          form.controls[options.name].invalid &&
          (form.controls[options.name].dirty || form.controls[options.name].touched)
        "
        class="slds-form-element__help"
        id="form-error-{{ options.name }}"
      >
        {{ options.msgError }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'checkbox'">
    <div *ngIf="options.multiList"
      class="slds-form-element slds-form-element_stacked {{
        form.controls[options.name].invalid &&
        (form.controls[options.name].dirty || form.controls[options.name].touched)
          ? 'slds-has-error'
          : ''
      }}"
    >
      <label class="slds-form-element__label" for="stacked-input-{{ options.name }}">
        <abbr *ngIf="options.required" class="slds-required" title="required">* </abbr>
        {{ options.title }}</label
      >
      <div class="slds-form-element__control">
        <div class="slds-{{options.type}}_button-group">
          <ng-template ngFor let-option [ngForOf]="options.multiList" let-i="index">
          <span class="slds-button slds-{{options.type}}_button">
            <input [formControl]="$any(form).controls[options.name]" type="{{options.type}}" id="element-{{options.name}}-{{i}}" value="{{option.value}}" name="{{option.name}}" 
            checked="{{option.checked}}"/>
            <label class="slds-{{options.type}}_button__label" for="element-{{options.name}}-{{i}}">
              <span class="slds-{{options.type}}_faux">{{option.title}}</span>
            </label>
          </span>
          </ng-template>
        </div>
      </div>
      <div
        *ngIf="
          form.controls[options.name].invalid &&
          (form.controls[options.name].dirty || form.controls[options.name].touched)
        "
        class="slds-form-element__help"
        id="form-error-{{ options.name }}"
      >
        {{ options.msgError }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'radio'">
    <div *ngIf="options.multiList"
      class="slds-form-element slds-form-element_stacked {{
        form.controls[options.name].invalid &&
        (form.controls[options.name].dirty || form.controls[options.name].touched)
          ? 'slds-has-error'
          : ''
      }}"
    >
      <label class="slds-form-element__label" for="stacked-input-{{ options.name }}">
        <abbr *ngIf="options.required" class="slds-required" title="required">* </abbr>
        {{ options.title }}</label
      >
      <div class="slds-form-element__control">
        <div class="slds-{{options.type}}_button-group">
          <ng-template ngFor let-option [ngForOf]="options.multiList" let-i="index">
          <span class="slds-button slds-{{options.type}}_button">
            <input [formControl]="$any(form).controls[options.name]" type="{{options.type}}" id="element-{{options.name}}-{{i}}" value="{{option.value}}" name="{{option.name}}" 
            checked="{{option.checked}}"/>
            <label class="slds-{{options.type}}_button__label" for="element-{{options.name}}-{{i}}">
              <span class="slds-{{options.type}}_faux">{{option.title}}</span>
            </label>
          </span>
          </ng-template>
        </div>
      </div>
      <div
        *ngIf="
          form.controls[options.name].invalid &&
          (form.controls[options.name].dirty || form.controls[options.name].touched)
        "
        class="slds-form-element__help"
        id="form-error-{{ options.name }}"
      >
        {{ options.msgError }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'geometry'">
    <div style="position: relative;"
      class="slds-form-element slds-form-element_stacked 
        {{
        form.controls[options.name].invalid &&
        (form.controls[options.name].dirty || form.controls[options.name].touched)
          ? 'slds-has-error'
          : ''
      }}"
    >
      <label class="slds-form-element__label" for="stacked-input-{{ options.name }}">
        <abbr *ngIf="options.required" class="slds-required" title="required">* </abbr>
        {{ options.title }}</label
      >
      <div class="mapElement" id="map-{{options.name}}"></div>
      <input
          [formControl]="$any(form).controls[options.name]"
          type="hidden"
          id="stacked-input-{{ options.name }}"
          placeholder="{{ options.placeholder }}"
          class="slds-input"
        />
      <div
        *ngIf="
          form.controls[options.name].invalid &&
          (form.controls[options.name].dirty || form.controls[options.name].touched)
        "
        class="slds-form-element__help"
        id="form-error-{{ options.name }}"
      >
        {{ options.msgError }}
      </div>
    </div>
  </ng-container>
</ng-container>
