import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocaleMessage } from '../../locale/lang-components/message';
import { LanguageService } from '../../locale/language.service';
import { MessageService } from '../message.service';
import { AlertOptions, MESSAGE_LEVEL } from '@galigeo-store/shared-models';



@Component({
  selector: 'message-alert',
  templateUrl: './message-alert.component.html',
  styleUrls: ['./message-alert.component.css'],
})
export class MessageAlertComponent implements OnInit {

  public options!: AlertOptions;
  public sldsTheme = "slds-theme_error";
  public detailedMessage: string = "";
  public detailedMessageCollapsed: boolean = true;

  constructor(private messageService: MessageService, private languageService: LanguageService) {
    this.setOptions(new AlertOptions());
    this.languageService.addTranslation('Message', new LocaleMessage().locale);
  }
  ngOnInit(): void {

  }

  setOptions(options: AlertOptions) {
    console.error('Alert options', options);
    this.options = options;
    if (!this.options.title) {
      if (this.options.level === MESSAGE_LEVEL.SUCCESS) {
        this.options.title = this.languageService.getTranslation('Message', 'success');
      } else if (this.options.level === MESSAGE_LEVEL.ERROR) {
        this.options.title = this.languageService.getTranslation('Message', 'error');
      } else {
        this.options.title = this.languageService.getTranslation('Message', 'default');
      }
    }
    if (this.options.error?.message) {
      this.options.message = this.options.error.message;
    } else if (!this.options.message) {
      this.options.message = "Unknown error";
    }
    if ((this.detailedMessage === undefined || this.detailedMessage.length === 0) && this.options?.error) {
      this.detailedMessage = this.parseDetailedMessage(this.options.error);
    }
    switch (this.options.level) {
      case MESSAGE_LEVEL.ERROR:
        this.sldsTheme = "slds-theme_error";
        break;
      case MESSAGE_LEVEL.WARNING:
        this.sldsTheme = "slds-theme_default";
        break;
      case MESSAGE_LEVEL.SUCCESS:
        this.sldsTheme = "slds-theme_success";
        break;
      default:
        this.sldsTheme = "slds-theme_error";
    }
  }

  onClickOk() {
    this.messageService.vrc?.remove(); // TODO: search for a better way to remove the component
    if (this.options.callback) {
      this.options.callback();
    }
  }

  private parseDetailedMessage(error: any) {
    if (error?.error?.message) {
      return error.error.message;
    } else if (error.message) {
      return error.message;
    } else if (error.error) {
      return error.error;
    } else if (error.statusText) {
      return error.statusText;
    } else {
      return JSON.stringify(error);
    }

  }

}
