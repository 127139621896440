import { MapBoxLayer } from './MapBoxLayer.js';

export class BuildingLayer extends MapBoxLayer {
  /*constructor(map: mapboxgl.Map, options: LayerOptions, data?: any) {
        super(map, options, data);
    }*/
  public addInstances(): any[] {
    const layer: any = {
      id: 'building',
      source: 'openmaptiles',
      'source-layer': 'building',
      type: 'fill-extrusion',
      minzoom: 15,
      filter: ['!=', ['get', 'hide_3d'], true],
      paint: {
        'fill-extrusion-color': ['interpolate', ['linear'], ['get', 'render_height'], 0, 'lightgray', 200, 'royalblue', 400, 'lightblue'],
        'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 16, ['get', 'render_height']],
        'fill-extrusion-base': ['case', ['>=', ['get', 'zoom'], 16], ['get', 'render_min_height'], 0],
      },
    };
    this.layerInstances.set('building', layer);
    return [layer];
  }
}
