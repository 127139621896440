import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonGrpOptions } from './model';

@Component({
  selector: 'ui-lightning-vertical-btn-group',
  templateUrl: './vertical-btn-group.component.html',
  styleUrls: ['./vertical-btn-group.component.css']
})
export class VerticalBtnGroupComponent {
  @Input() btnGroupOptions!: ButtonGrpOptions
  @Output() action = new EventEmitter<any>();
  
  btnClick(action: any) {
    this.action.emit({action: action});
  }
}
