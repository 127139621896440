import { AfterContentInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { StepperOptions } from './model';
import { LanguageService } from '../locale/language.service';
import { LocaleStepper } from '../locale/lang-components/stepper';

@Component({
  selector: 'ui-lightning-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent implements AfterContentInit {

  @Input() options!: StepperOptions;
  public progressValue!: number;
  constructor(private languageService: LanguageService) {
    this.languageService.addTranslation('Stepper', new LocaleStepper().locale);
  }
  ngAfterContentInit(): void {
    setTimeout(() => {
      this.options.init(this.languageService);
    }, 10);
  }


}
