import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-lightning-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.css']
})
export class ModalContentComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
