<div style="height: 100%" class="slds-dropdown-trigger slds-dropdown-trigger_click slds-is-open">
  <button style="height: 100%;" class="slds-button slds-button_icon slds-button_icon-border-filled" (click)="clickMenuBtn(this)"
    aria-haspopup="true" aria-expanded="true" title="{{tooltip}}">
    <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#threedots_vertical"></use>
    </svg>
    <span class="slds-assistive-text">{{tooltip}}</span>
  </button>
  <div *ngIf="showMainMenu" class="slds-dropdown slds-dropdown_left slds-show">
    <ul *ngIf="items.length > 0" class="slds-dropdown__list" role="menu" aria-label="">
      <ng-template ngFor let-item [ngForOf]="items" let-i="index">
        <li *ngIf="item.divider" class="slds-has-divider_top-space" role="separator"></li>
        <li class="slds-dropdown__item {{item.subitems && item.subitems.length > 0 ? 'slds-has-submenu' : ''}}" role="presentation" (click)="item.subitems && item.subitems.length > 0 ? menuClick(i) : menuAction(item.action)" (mouseenter)="itemMouseEnter(item, i)" (mouseleave)="itemMouseLeave(item)">
          <a [style.color]="item.color" role="menuitem" aria-haspopup="true" aria-expanded="true">
            <span class="slds-truncate" title="{{item.label}}">
              <ui-lightning-icon *ngIf="item.icon" [variant]="item.icon_variant ? item.icon_variant : 'default'" [decoratorClasses]="'slds-m-right_x-small'" [iconName]="item.icon" [size]="'x-small'"></ui-lightning-icon>
              {{item.label}}</span>
            <svg *ngIf="item.subitems && item.subitems.length > 0" class="slds-icon slds-icon_x-small slds-icon-text-default slds-m-left_small slds-shrink-none"
              aria-hidden="true">
              <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#right"></use>
            </svg>
          </a>
          <div *ngIf="item.subitems && item.subitems.length > 0 && showSubMenu === i" class="slds-dropdown slds-dropdown_submenu slds-dropdown_submenu-right" style="margin: 1px;">
            <ul class="slds-dropdown__list" role="menu" aria-label="Submenu Item">
              <ng-template ngFor let-subitem [ngForOf]="item.subitems" let-j="index">
                <li *ngIf="subitem.divider" class="slds-has-divider_top-space" role="separator"></li>
                <li class="slds-dropdown__item" role="presentation" (click)="menuAction(subitem.action)">
                  <a role="menuitem" tabindex="0">
                    <span class="slds-truncate" title="{{subitem.label}}">
                      <ui-lightning-icon *ngIf="subitem.icon" [variant]="item.icon_variant ? item.icon_variant : 'default'" [decoratorClasses]="'slds-m-right_x-small'" [iconName]="subitem.icon" [size]="'x-small'"></ui-lightning-icon>{{subitem.label}}</span>
                  </a>
                </li>
              </ng-template>
            </ul>
          </div>
        </li>
      </ng-template>
    </ul>
  </div>
</div>