<ui-lightning-modal  [isOpen]="isOpen" (onClose)="close(false)">
  <ui-lightning-modal-header>
    <h2 class="slds-text-heading_medium">{{'ModalAdresseComponent:header' | translate:'Select an address' }}</h2>
</ui-lightning-modal-header>
<ui-lightning-modal-content>
    <fieldset class="slds-form-element">
        <legend class="slds-form-element__legend slds-form-element__label">{{'ModalAdresseComponent:legend' | translate:'Current address' }}</legend>
        <div class="slds-form-element__control">
          <ng-template ngFor let-radio [ngForOf]="adressesReverseGeocode" let-i="index">
            <span #radioContainer *ngIf="i === 0" class="slds-radio radio-item" (click)="clickedRadioContainer(i)">
              <input [disabled]="geocodeNotFound ? true : null" type="radio" id="radio-adress-{{i}}" value="{{radio.value}}" name="radio-adresse" (change)="setAdress(radio)" />
              <label class="slds-radio__label" for="radio-adress-{{i}}">
                <span *ngIf="!geocodeNotFound" class="slds-radio_faux"></span>
                <span class="slds-form-element__label">{{radio.title}}</span>
              </label>
            </span>

          </ng-template>
        </div>
      </fieldset>
      <fieldset class="slds-form-element">
        <legend class="slds-form-element__legend slds-form-element__label">{{'ModalAdresseComponent:legend_second' | translate:'Suggested addresses' }}</legend>
        <div *ngIf="!geocodeNotFound" class="slds-form-element__control" style="overflow: hidden; overflow-y: auto; height: 140px;">
          <ng-template ngFor let-radio [ngForOf]="adressesReverseGeocode" let-i="index">
            <span #radioContainer *ngIf="i > 0 " class="slds-radio radio-item" (click)="clickedRadioContainer(i)">
              <input type="radio" id="radio-adress-{{i}}" value="{{radio.value}}" name="radio-adresse" (change)="setAdress(radio)" />
              <label class="slds-radio__label" for="radio-adress-{{i}}">
                <span class="slds-radio_faux"></span>
                <span class="slds-form-element__label">{{radio.title}}</span>
              </label>
            </span>

          </ng-template>
        </div>
        <div *ngIf="geocodeNotFound" class="slds-form-element__control">
          <span class="slds-text-color_error slds-radio radio-item">{{'ModalAdresseComponent:geocode_not_found' | translate:'No address found' }}</span>
          <div>{{'ModalAdresseComponent:geocode_not_found_info' | translate:'Confirm, to save the new position without updating the address.' }}</div>
        </div>
      </fieldset>
</ui-lightning-modal-content>
  <ui-lightning-modal-footer>
    <button class="slds-button slds-button_neutral" (click)="close(false)">{{'ModalAdresseComponent:cancel' | translate:'Cancel' }}</button>
    <button class="slds-button slds-button_brand" disabled="{{enableValidation ? null : true}}" (click)="close(true)">{{'ModalAdresseComponent:confirm' | translate:'Confirm' }}</button>
    
</ui-lightning-modal-footer>
</ui-lightning-modal>