import { Locale } from '../components';

export class LocaleModalAdresseComponent extends Locale {
  constructor() {
    super();
    this.languages = {
      en: {
        header: 'Select an address',
        legend: 'Current address',
        legend_second: 'Suggested addresses',
        cancel: 'Cancel',
        confirm: 'Confirm',
        loading_addresses: 'Loading addresses',
        geocoder_not_found: 'Address not found',
        modal_edit_title: 'Edit an address',
        address: 'Address',
        postal_code: 'Postal code',
        city: 'City',
        update_pos_checkbox: 'Update position',
        geocode_not_found: 'No address detected around the location.',
        geocode_not_found_info: 'Confirm, to save the new position without updating the address.',
      },
      fr: {
        header: 'Sélectionner une adresse',
        legend: 'Adresse actuelle',
        legend_second: 'Adresses proposées',
        cancel: 'Annuler',
        confirm: 'Confirmer',
        loading_addresses: 'Chargement des adresses',
        geocoder_not_found: 'Adresse non trouvée',
        modal_edit_title: 'Modifier une adresse',
        address: 'Adresse',
        postal_code: 'Code postal',
        city: 'Ville',
        update_pos_checkbox: 'Mettre à jour la position',
        geocode_not_found: 'Aucune adresse détectée autour de l’emplacement.',
        geocode_not_found_info: 'Confirmer, pour enregistrer la nouvelle position sans mettre à jour l’adresse.',
      },
    };
    this.genrateMap();
  }
}
