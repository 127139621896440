import { ActivatedRoute, Router } from "@angular/router";
import { AuthguardService, GeocodeService, PoiService } from "@galigeo-store/retail-services";
import { AlertOptions, BusinessType, ConfirmOptions, DataTable, Field, FieldType, MESSAGE_LEVEL, POSITION, Record, ToastOptions } from "@galigeo-store/shared-models";
import { EventService, FileImportOptions, FileType, GgoEvent, GraphicColumnType, LanguageService, MessageService, ModalService, TableEvent } from "@galigeo-store/ui-lightning";
import { LocaleNetworkComponent } from "../locales/localeNetworkComponent";
import { ModalAddressEditComponent } from "../modal-address-edit/modal-address-edit.component";
import { ModalAddressComponent } from "../modal-address/modal-address.component";
import { ModalNoGeomWarningComponent } from "../modal-no-geom-warning/modal-no-geom-warning.component";
import { Network } from "./network";

/**
 * Parent class for the network and wizard containers.
 * This class factorizes the common code between the map
 * and the table to manage the events.
 */
export abstract class AbstractContainer {
    public network!: Network;
    public tableOptions: any;
    public createdAt!: string;
    public createdBy!: string;
    public checkedRows!: any;
    public checkedRowsNb: number = 0;
    public geocodeToCheckNb: number = 0;
    public geocodeFailedNb: number = 0;
    public  dialogLogoEdit: any = undefined;
    public rowLogoReadyForEdit: any;
    public networkListOptions!: any;
    public importLogoOptions: FileImportOptions = {
        type: FileType.IMAGE,
        height: '50px',
        width: '100px',
        image: undefined
      };
    // public hasCityAndPostalCode: boolean = false;
    constructor(
        public poiService: PoiService,
        public router: Router,
        public route: ActivatedRoute,
        public translateService: LanguageService,
        public messageService: MessageService,
        public eventService: EventService,
        public authguardService: AuthguardService,
        public geocoderService: GeocodeService,
        public modalService: ModalService
        
    ) {
        this.network = new Network(); // build an empty network by default
        this.translateService.addTranslation('NetworkComponent', new LocaleNetworkComponent().locale);

    }

    public async onTableEvent(event: GgoEvent) {
        console.log('table event', event)
        switch (event.action) {
            case TableEvent.ROW_CLICKED: {
                this.eventService.emitEvent('map', { action: 'highlight', obj: event.obj });
                const geom = event.obj.data[0].values[event.obj.getFieldIndex(BusinessType.GEOMETRY)];
                if (!geom) {
                    const modalNoGeomInstance = this.modalService.inject(ModalNoGeomWarningComponent);
                    modalNoGeomInstance.rowId = event.obj.data[0].recordId;
                    modalNoGeomInstance.open();
                }
            }
                break;
            case TableEvent.CELL_EDIT:
               

                
                    this.updatePoiInNetwork(event.obj);
                
                
                break;
            case TableEvent.REFRESH:
                this.disableGraphicColumns();
                await this.resetNetwork(this.network.id!)
                break;
            case TableEvent.ROW_SELECTED:
                this.checkedRows = event.obj.data;
                this.checkedRowsNb = event.obj.data.length;
                break;
            case TableEvent.EDIT_ADDRESS:
                this.startEditAdress(event.obj);
                break;
            case TableEvent.EDIT_IMAGE: 
                this.updateLogoConfig(event.obj);
        }

    }
    updateLogoConfig(obj: any) {
        this.rowLogoReadyForEdit = obj
        this.dialogLogoEdit = {
            name: 'setPoisLogo',
            title: this.translateService.getTranslation('NetworkComponent', 'step_add_logo_title'),
            label: this.translateService.getTranslation('NetworkComponent', 'step_add_logo_label'),
            description: this.translateService.getTranslation('NetworkComponent', 'step_add_logo_description'),
            btns: [
                {
                    label: this.translateService.getTranslation('NetworkComponent', 'step_add_logo_btn_finish_label'),
                    action: 'savePoisLogo',
                    position: 'buttom',
                    show: true,
                    disabled: false,
                },
            ],
        };
        this.importLogoOptions.image =  undefined;
    }

    startEditAdress(dataTable: DataTable) {
        const modalEditAdress = this.modalService.inject(ModalAddressEditComponent);
        modalEditAdress.editedAddress = dataTable;
        modalEditAdress.network = this.network;
        modalEditAdress.callback = (editData: DataTable) => {
            if (editData) {
                this.updatePoi(editData, this.network.id!);
            }
        }
        modalEditAdress.open();
    }

    async updatePoiInNetwork(dataTable: DataTable) {
        if (!this.network?.id) return;
    
        const hasAddressFields = this.hasAddressFields(dataTable.fields);
        const isIdField = dataTable.fields[0].name === BusinessType.ID;
        if (!isIdField && !hasAddressFields && !this.hasXY(dataTable.fields) && this.checkedRowsNb > 0) {
            this.messageService.confirm(new ConfirmOptions({
                cancelLabel: this.translateService.getTranslation('NetworkComponent', 'cancel_label'), 
                confirmLabel: this.translateService.getTranslation('NetworkComponent', 'confirm_label'), 
                title: this.translateService.getTranslation('NetworkComponent', 'update_modal_title'),
                message: this.translateService.getTranslation('NetworkComponent', 'update_modal_msg'),
                level: MESSAGE_LEVEL.INFO,
                callback: (result: boolean) => {
                    this.updateCheckedRows(dataTable, result);
                }
            }));
        } else if (dataTable.fields && hasAddressFields) {
                this.updatePoiWithGeocoding(dataTable);
            } else {
                const index = this.network.pois?.getFieldIndex(this.network.pois?.fields.find((f: Field) => f.businessType === BusinessType.ID)?.name ?? '');
                if (index !== undefined && this.network.pois && this.detectValueExist(this.network.pois , index, dataTable.data[0].values[0], dataTable.data[0].recordId!)) {
                    this.messageService.alert(new AlertOptions({
                        title: this.translateService.getTranslation('NetworkComponent', 'alert_title_inconsistencies_error'),
                        message: this.translateService.getTranslation('NetworkComponent', 'toast_doublon_id'),
                        level: MESSAGE_LEVEL.ERROR
                    }));
                    this.disableGraphicColumns();
                    await this.resetNetwork(this.network.id);
                    return;
                }else {
                    this.updatePoiWithoutGeocodingCurrentSelected(dataTable);
                }
            }
    }
    
    
    private async updateCheckedRows(dataTable: DataTable, result: boolean): Promise<void> {
        this.eventService.emitEvent('global-spinner', { action: 'show', obj: this.translateService.getTranslation('NetworkComponent','global_spinner_message')})
        //const observables: Observable<DataTable>[] = [];
        
        const dtToUpdate = new DataTable({ fields: dataTable.fields, data: [] });
        if (result) {
            this.checkedRows.forEach((record: Record) => {
                const index = this.network.pois?.getFieldIndex(dataTable.fields[0].name);
                if (index !== undefined) {
                    record.values[index] = dataTable.data[0].values[0];
                    if (record.recordId === dataTable.data[0].recordId) return;
                    dtToUpdate.data.push({ recordId: record.recordId, values: [dataTable.data[0].values[0]] });
                    //observables.push(this.updatePoiWithoutGeocoding(tmpDt));
                }
            });
        }
        dtToUpdate.data.push({ recordId: dataTable.data[0].recordId, values: dataTable.data[0].values });
        this.poiService.updatePOI({ networkId: this.network.id!, pois: dtToUpdate }).subscribe(async (poi: DataTable) => {
        // observables.push(this.updatePoiWithoutGeocoding(dataTable));
        //forkJoin(observables).subscribe((results) => {
            //console.log(results);
            this.eventService.emitEvent('global-spinner', { action: 'close', obj: this.translateService.getTranslation('NetworkComponent','global_spinner_message')})
            this.messageService.toast(new ToastOptions({
                level: MESSAGE_LEVEL.SUCCESS,
                message: this.translateService.getTranslation('NetworkComponent', 'toast_poi_updated'),
                position: POSITION.BOTTOM_RIGHT
            }));
            if(dataTable?.fields[0].name === 'logo_id') {
                this.refreshNetworksList();
                this.disableGraphicColumns();
                await this.resetNetwork(this.network.id!);
            }
            
        }, (err : any) => {
            this.eventService.emitEvent('global-spinner', { action: 'close', obj: this.translateService.getTranslation('NetworkComponent','global_spinner_message')})
            console.log('error', err);
        });
    
    }
    refreshNetworksList() {
        this.poiService.getNetworks().subscribe((networks: DataTable) => {
          this.networkListOptions.items = networks.toJson();
          this.networkListOptions.items.sort((a: any, b: any) => (new Date(a.created_at) > new Date(b.created_at) ? -1 : 1));
        });
      }
    private hasAddressFields(fields: Field[]): boolean {
        return fields.some((f: Field) => f.name === BusinessType.ADDRESS || f.name === BusinessType.POSTAL_CODE || f.name === BusinessType.CITY);
    }

    private hasXY(fields: Field[]): boolean {
        return fields.some((f: Field) => f.name === BusinessType.GEOCODER_LONGITUDE || f.name === BusinessType.GEOCODER_LATITUDE);
    }
    
    private updatePoiWithGeocoding(dataTable: DataTable): void {
        const jsonModification = dataTable.toJson();
    
        if (!this.network?.id) return;
    
        this.poiService.getPOIs(this.network.id).subscribe((oldDataTable: DataTable) => {
            const oldRecord = oldDataTable.data.find((record: Record) => record.recordId === dataTable.data[0].recordId);
            if (oldRecord) {
                const oldRecordCopy = JSON.parse(JSON.stringify(oldRecord));
                const addressToGeocode = this.constructAddressToGeocode(oldDataTable, oldRecordCopy, jsonModification);
                if (addressToGeocode.trim() !== '') {
                    const { adressesIndex, postalCodeIndex, cityIndex } = this.getFieldIndexes(oldDataTable);
                    const modalAddressInstance = this.createModalAddressInstance(oldDataTable, oldRecordCopy, addressToGeocode, adressesIndex!, postalCodeIndex!, cityIndex!);
                    modalAddressInstance.open();
                }
            }
        });
    }

    private getFieldIndexes(dataTable: DataTable): { adressesIndex: number | undefined, postalCodeIndex: number | undefined, cityIndex: number | undefined } {
        return {
            adressesIndex: this.getFieldIndexByName(dataTable, BusinessType.ADDRESS),
            postalCodeIndex: this.getFieldIndexByName(dataTable, BusinessType.POSTAL_CODE),
            cityIndex: this.getFieldIndexByName(dataTable, BusinessType.CITY)
        };
    }

    private getFieldIndexByName(dataTable: DataTable, fieldName: string): number | undefined {
        return dataTable?.getFieldIndex(dataTable?.fields.find((f: Field) => f.name === fieldName)?.name ?? '');
    }

    private constructAddressToGeocode(oldDataTable: DataTable, oldRecordCopy: any, jsonModification: any): string {
        let addressToGeocode = '';
    
        const adressesIndex = this.getFieldIndexByName(oldDataTable, BusinessType.ADDRESS);
        const postalCodeIndex = this.getFieldIndexByName(oldDataTable, BusinessType.POSTAL_CODE);
        const cityIndex = this.getFieldIndexByName(oldDataTable, BusinessType.CITY);
    
        if (jsonModification[0][BusinessType.ADDRESS]) {
            addressToGeocode += jsonModification[0][BusinessType.ADDRESS] || '';
        } else if (adressesIndex !== undefined) {
            addressToGeocode += oldRecordCopy.values[adressesIndex] || '';
        }
    
        if (jsonModification[0][BusinessType.POSTAL_CODE]) {
            addressToGeocode += ' ' + (jsonModification[0][BusinessType.POSTAL_CODE] || '');
        } else if (postalCodeIndex !== undefined) {
            addressToGeocode += ' ' + (oldRecordCopy.values[postalCodeIndex] || '');
        }
    
        if (jsonModification[0][BusinessType.CITY]) {
            addressToGeocode += ' ' + (jsonModification[0][BusinessType.CITY] || '');
        } else if (cityIndex !== undefined) {
            addressToGeocode += ' ' + (oldRecordCopy.values[cityIndex] || '');
        }
    
        return addressToGeocode;
    }

    private createModalAddressInstance(oldDataTable: DataTable, oldRecordCopy: any, addressToGeocode: string, adrIndex: number, postalCodeIndex: number, cityIndex: number): ModalAddressComponent {
        const modalAddressInstance = this.modalService.inject(ModalAddressComponent);
        const fDataTable = new DataTable({ id: 'poi-dataTable', fields: oldDataTable.fields, data: [oldRecordCopy] });
        const fPros: any = fDataTable.toJson();
        fPros.recordId = fDataTable.data[0].recordId;
        modalAddressInstance.feature = {
            geometry: {
                type: 'Point',
                coordinates: [
                    oldDataTable.data[0].values[fDataTable.getFieldIndex(BusinessType.LONG)],
                    fDataTable.data[0].values[oldDataTable.getFieldIndex(BusinessType.LAT)]
                ]
            },
            properties: fPros,
            address: addressToGeocode,
            oldAdress: (oldRecordCopy.values[adrIndex]) + ' ' +
                (oldRecordCopy.values[postalCodeIndex] || '') + ' ' +
                (oldRecordCopy.values[cityIndex] || '')
        };
        modalAddressInstance.geocodeMethod = 'adresse';
        modalAddressInstance.network = this.network;
        modalAddressInstance.callback = (editData: DataTable) => {
            if (editData) {
                const oldIndex = oldDataTable.getFieldIndex(oldDataTable.fields.find((f: Field) => f.name === 'network_id')?.name ?? '');
                this.updatePoi(editData, oldRecordCopy.values[oldIndex]);
            }
        };
        return modalAddressInstance;
    }

    private updatePoi(poiData: DataTable, networkId: number): void {
        this.poiService.updatePOI({ networkId: networkId, pois: poiData }).subscribe((poiwithnewGeom: DataTable) => {
            this.poiService.getPOIs(networkId).subscribe((newDatatable: DataTable) => {
                this.refreshPoiWithNewGeometry(newDatatable, poiData);
            });
            this.refreshLastUpdate(this.network?.id);
        });
    }

    private refreshPoiWithNewGeometry(newDataTable: DataTable, poiData: DataTable): void {
        if (!newDataTable.data) return;
        const newRecord = newDataTable.data.find((record: Record) => record.recordId === poiData.data[0].recordId);
        if (newRecord && this.network?.pois) {
            this.replacePoiInNetwork(new DataTable({ id: 'poi-dataTable', fields: newDataTable.fields, data: [newRecord] }));
            this.disableGraphicColumns();
            if (this.network.pois?.getFieldByBusinessType(BusinessType.GEOCODER_ACCURACY) !== undefined) {
                this.configureColumns();
            }
            this.refreshMap();
            this.eventService.emitEvent('map', { action: 'highlight', obj: new DataTable({ id: 'poi-dataTable', fields: newDataTable.fields, data: [newRecord] }) });
            this.messageService.toast(new ToastOptions({
                level: MESSAGE_LEVEL.SUCCESS,
                message: this.translateService.getTranslation('NetworkComponent', 'toast_poi_coordinates_updated'),
                position: POSITION.BOTTOM_LEFT
            }));
        }
    }

    // private updatePoiWithoutGeocoding(dataTable: DataTable): Observable<DataTable> {
    //     if (!this.network?.id) {
    //         return throwError(() => new Error('network ID is undefined'));
    //     }
    //     return this.poiService.updatePOI({ networkId: this.network.id, pois: dataTable }).pipe();
    // }

    private updatePoiWithoutGeocodingCurrentSelected(dataTable: DataTable): void {
        if (!this.network?.id) return;
        this.poiService.updatePOI({ networkId: this.network.id, pois: dataTable }).subscribe(async (poi: DataTable) => {
            if(dataTable?.fields[0].name === 'logo_id') {
                this.disableGraphicColumns();
                await this.resetNetwork(this.network.id!);
            }else {
                this.replacePoiInNetwork(poi);
                this.messageService.toast(new ToastOptions({
                    level: MESSAGE_LEVEL.SUCCESS,
                    message: this.translateService.getTranslation('NetworkComponent', 'toast_poi_updated'),
                    position: POSITION.BOTTOM_RIGHT
                }));
            }
            
        });
    }

    public replacePoiInNetwork(newDataTableElement: DataTable) {
        if (!this.network?.id || !this.network?.pois || !newDataTableElement?.fields || !newDataTableElement?.data[0]) {
            return;
        }

        for (const dtrec of this.network.pois.data) {
            if (dtrec.recordId === newDataTableElement.data[0].recordId) {
                this.updateRecordValues(dtrec, newDataTableElement);
            }
        }
    }

    private updateRecordValues(dtrec: Record, newDataTableElement: DataTable) {
        for (let i = 0; i < newDataTableElement.fields.length; i++) {
            const fieldName = newDataTableElement.fields[i].name;
            const indexNetworkField = this.network.pois?.getFieldIndex(fieldName);
            if (indexNetworkField !== undefined && indexNetworkField !== -1) {
                dtrec.values[indexNetworkField] = newDataTableElement.data[0].values[i];
            }
        }
    }


    refreshLastUpdate(networkId: any) {
        this.poiService.getPOISet(networkId).subscribe((network: DataTable) => {
          const data = network.toJson()[0];
          this.createdAt = new Date(data.created_at).toLocaleDateString();
          this.createdBy = data.created_by;
        });
      }
    public refreshMap(setExtent: boolean = false) {
        this.eventService.emitEvent('map', { action: 'refresh', obj: { setExtent: setExtent, network: this.network } });
    }

    public onMapEvent(event: any) {
        console.log('onMapEvent', event);
        
        if (!this.network) return;
    
        const networkId = this.network.id;
        
        if (networkId === undefined) {
            throw new Error('network ID is undefined');
        }
    
        switch (event.action) {
            case 'edit':
                this.handleEditEvent(networkId, event.data);
                break;
            case 'delete':
                this.handleDeleteEvent(networkId, event.ggoId);
                break;
            case 'highlight':
                this.handleHighlightEvent(event.obj);
                break;
        }
    }
    
    private handleEditEvent(networkId: number, data: any) {
        if (!this.network.pois) return;
    
        this.poiService.updatePOI({ networkId, pois: data }).subscribe(async (poi: DataTable) => {
            this.disableGraphicColumns();
            const index = this.network.pois?.data.findIndex((record) => record.recordId === poi.data[0].recordId);
            await this.resetNetwork(networkId);
            if (index) {
                this.eventService.emitEvent('table-network', { action: 'highlight', obj: this.network.pois?.data[index] }); 
            }
            this.messageService.toast(new ToastOptions({ level: MESSAGE_LEVEL.SUCCESS, message: this.translateService.getTranslation('NetworkComponent', 'toast_poi_updated'), position: POSITION.BOTTOM_LEFT }));
        });
    }
    
    private handleDeleteEvent(networkId: number, ggoId: any) {
        this.poiService.deletePOIs({ networkId, poiIds: [ggoId] }).subscribe((poi: DataTable) => {
            console.log('poi deleted');
            this.network.pois = poi;
            this.eventService.emitEvent('map', { action: 'refresh', obj: { setExtent: false, network: this.network } });
        });
    }

    private handleHighlightEvent(obj: any) {
        this.eventService.emitEvent('table-network', { action: 'highlight', obj });
    }

    public resetNetwork(networkId: number): Promise<void> {
        this.checkedRows = [];
        this.checkedRowsNb = 0;
        return new Promise((resolve, reject) => {
            this.poiService.getPOISet(networkId).subscribe((network: DataTable) => {
                const data = network.toJson()[0];
                data.id = networkId;
                this.createdAt = new Date(data.created_at).toLocaleDateString();
                this.createdBy = data.created_by;
                this.poiService.getPOIs(data.id).subscribe((pois: DataTable) => {
                    data.pois = pois;
                    this.network = new Network(data);
                    if (!this.network.pois?.getFieldByBusinessType(BusinessType.GEOCODER_ACCURACY)) {
                        this.disableGraphicColumns();
                    } else {
                        this.disableGraphicColumns();
                        this.configureColumns();
                        
                    }
                    this.refreshMap();
                    resolve();
                }, (error: any) => { reject(error); });
            }, (error: any) => { reject(error); });
        });
    }

    public registerTableEvents() {
        this.eventService.offListener('table-network');
        this.eventService.listenEvent('table-network').subscribe((event: GgoEvent) => {
            this.onTableEvent(event);
        });
    }

      getIconeByaccuracy(accuracy?: number) {
        if(accuracy !== undefined) {
        if((accuracy >= 0.5) || (accuracy === null && this.network.pois?.getFieldIndex(this.network.pois?.fields.find((f: Field) => f.businessType === BusinessType.LAT)?.name ?? '') && this.network.pois?.getFieldIndex(this.network.pois?.fields.find((f: Field) => f.businessType === BusinessType.LONG)?.name ?? ''))) {
            return `<span class="slds-icon_container" data="3" title="${this.translateService.getTranslation('NetworkComponent','status_success')}">
            <svg class="slds-icon slds-icon_xx-small slds-icon-text-success" aria-hidden="true">
                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#success"></use>
            </svg>
        </span>`;
        }
        else if(accuracy > 0) {
            this.geocodeToCheckNb++;
          return `<span class="slds-icon_container" data="2" title="${this.translateService.getTranslation('NetworkComponent','status_to_check')}">
          <svg class="slds-icon slds-icon_xx-small slds-icon-text-warning" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#warning"></use>
          </svg>
        </span>`;
        }
        else {
            this.geocodeFailedNb++;
          return `<span class="slds-icon_container" data="1" title="${this.translateService.getTranslation('NetworkComponent','status_failed')}">
          <svg class="slds-icon slds-icon_xx-small slds-icon-text-error" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#error"></use>
          </svg>
        </span>`;
        }
        }else {
            return `<span class="slds-icon_container" data="1" title="${this.translateService.getTranslation('NetworkComponent','status_failed')}">
            <svg class="slds-icon slds-icon_xx-small slds-icon-text-error" aria-hidden="true">
                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#error"></use>
            </svg>
            </span>`;
        }
    }


      disableGraphicColumns() {
        if(this.tableOptions.graphicColumns) {
          this.tableOptions.graphicColumns = undefined;
          //for status column
          this.network.pois?.fields.shift();
          //for Logo column
          this.network.pois?.fields.shift();
          this.network.pois?.data.forEach((record: Record) => {
            //for status column
            record.values.shift();
            //for Logo column
            record.values.shift();
          });
    }
}
    
    configureColumns() {
       
        this.tableOptions.graphicColumns = [];
        this.enableGraphicColumns();
        
        
    }
    public addIconsToDataTable() {
       
        
        
        this.geocodeToCheckNb = 0;
        this.geocodeFailedNb= 0;
        this.network.pois?.fields.unshift({ name: '', sortable: true, alias: this.translateService.getTranslation('NetworkComponent','column_status'), type: FieldType.STRING });
        let indexAccuracy = this.network.pois?.getFieldIndex(this.network.pois?.fields.find((f: Field) => f.businessType === BusinessType.GEOCODER_ACCURACY)?.name ?? '');
        let indexLat = this.network.pois?.getFieldIndex(this.network.pois?.fields.find((f: Field) => f.businessType === BusinessType.LAT)?.name ?? '');
        let indexLng = this.network.pois?.getFieldIndex(this.network.pois?.fields.find((f: Field) => f.businessType === BusinessType.LONG)?.name ?? '');
        this.network.pois?.data.forEach((record: Record) => {
            record.values.unshift('');

            // accuracy null and values lat and lng null
            if(indexAccuracy === undefined && indexLat === undefined && indexLng === undefined) {
                record.values[0] = this.getIconeByaccuracy(0);
            }else 
            // accuracy not null and value null
            if(indexAccuracy && record.values[indexAccuracy] === null && (!indexLat || !indexLng)) {
                record.values[0] = this.getIconeByaccuracy(0);
            } else
            // accuracy not null and value not null
            if(indexAccuracy && record.values[indexAccuracy] !== null) {
                record.values[0] = this.getIconeByaccuracy(record.values[indexAccuracy]);
            } else
            // accuracy null and values lat and lng not null
            if((indexAccuracy === undefined || (indexAccuracy && record.values[indexAccuracy] === null)) && indexLat && indexLng && (record.values[indexLng] !== null && record.values[indexLng] !== ''
            && record.values[indexLng] !== undefined && record.values[indexLng] !== 'null' && record.values[indexLng] !== 'undefined'
            && record.values[indexLng] !== '0' && record.values[indexLng] !== 0
            && record.values[indexLat] !== null && record.values[indexLat] !== ''
            && record.values[indexLat] !== undefined && record.values[indexLat] !== 'null' && record.values[indexLat] !== 'undefined'
            && record.values[indexLat] !== '0' && record.values[indexLat] !== 0)) {
                record.values[0] = this.getIconeByaccuracy(1);
            }else {
                record.values[0] = this.getIconeByaccuracy(0);  
            }
            
        });
        
        this.tableOptions.graphicColumns.unshift({ field: this.network.pois?.fields[0], type: GraphicColumnType.HTML });
      }
    enableGraphicColumns() {
       this.network.pois?.fields.forEach((field: Field) => {
            if(field.name === 'last_updated_by') {
                field.editable = false;
                field.alias =   this.translateService.getTranslation('NetworkComponent', 'last_updated_by')
            }
            if(field.name === 'last_updated_at') {
                field.editable = false;
                field.alias =   this.translateService.getTranslation('NetworkComponent', 'last_updated_at')
            }
            switch (field.type) {
                case FieldType.STRING:
                    this.tableOptions.graphicColumns.push({ field: field, type: GraphicColumnType.TEXT });
                    break;
                case FieldType.INTEGER:
                    this.tableOptions.graphicColumns.push({ field: field, type: GraphicColumnType.NUMBER });
                    break;
                case FieldType.DOUBLE:
                    this.tableOptions.graphicColumns.push({ field: field, type: GraphicColumnType.NUMBER });
                    break;
                case FieldType.DATE:
                    this.tableOptions.graphicColumns.push({ field: field, type: GraphicColumnType.DATE });
                    break;
                case FieldType.BASE64   :
                    this.tableOptions.graphicColumns.push({ field: field, type: GraphicColumnType.IMAGE });
                    break;
                case FieldType.IMAGELINK   :
                        this.tableOptions.graphicColumns?.push({ field: field, type: GraphicColumnType.IMAGE });
                       break;
                default:
                    this.tableOptions.graphicColumns.push({ field: field, type: GraphicColumnType.TEXT });
                    break;
            }
            
        });
        this.addLogoColumn();
        this.addIconsToDataTable();
      
    }
    addLogoColumn() {
        this.tableOptions.graphicColumns.unshift({ field: this.network.pois?.fields[0], type: GraphicColumnType.IMAGE });
       const index = this.network.pois?.getFieldIndex('logo_id');
       if(index !== undefined) {
        this.network.pois?.data.forEach((record: Record) => {
            record.values.unshift(this.poiService.getUrlLogoById(index ? record.values[index]: undefined));
        });
        this.network.pois?.fields.unshift({ name: 'logoImage', editable: true, sortable: false, alias: this.translateService.getTranslation('NetworkComponent', 'logo_column'), type: FieldType.BASE64 });
    }
        
    }
    detectValueExist(dataTable: DataTable, index: number, value: string, recordId: number): boolean {
        return dataTable.data.filter((record: Record) => (recordId !== record.recordId && record.values[index] + '') === (''+ value)).length > 0;

    }

    detectInconsistencies(dataTable: DataTable): any {
        let doublons: any [] = [];
        let nullIdentification = 0;
        let badCoordinates = 0;
        const index = dataTable.getFieldIndex(dataTable?.fields.find((f: Field) => f.businessType === BusinessType.ID)?.name ?? '');
        const indexLat = dataTable.getFieldIndex(dataTable?.fields.find((f: Field) => f.businessType === BusinessType.LAT)?.name ?? '');
        const indexLng = dataTable.getFieldIndex(dataTable?.fields.find((f: Field) => f.businessType === BusinessType.LONG)?.name ?? '');
        const geocodeMaxRecordsLine = this.authguardService.user.geocodeMaxRecordsLine;
        const geocodeMaxRecordsColumn = this.authguardService.user.geocodeMaxRecordsColumn;
        const xyMaxRecordsColumn = this.authguardService.user.xyMaxRecordsColumn;
        const xyMaxRecordsLine = this.authguardService.user.xyMaxRecordsLine;


        if (index !== undefined) {
            dataTable.data.forEach((record: Record, i: number) => {
                const id = record.values[index];
                if(id === null || id === '' || id === undefined || id === 'null' || id === 'undefined') {
                    nullIdentification++;
                }
                if (id) {
                    const founds: Record [] | undefined = dataTable.data.filter((r: Record) => (r.values[index] + '') === (id+''));
                    if (founds && founds.length > 1) {
                        doublons.push(id);
                    }
                }
                
            });
        }
        for (const record of dataTable.data) {
            if(indexLat !== undefined && indexLng !== undefined) {
                
                if(!Number.isNaN(record.values[indexLat]) && !Number.isNaN(record.values[indexLng])
                && (Number.parseFloat(record.values[indexLat]) > 90 || Number.parseFloat(record.values[indexLat]) < -90 
                || Number.parseFloat(record.values[indexLng]) > 180 || Number.parseFloat(record.values[indexLng]) < -180)){

                    badCoordinates++;
                }
            }
        }
        // detect ville cp missing
        

        doublons = [...new Set(doublons)];
        return {
            messageError: this.generateMessageInconsistencies({ doublons, nullIdentification, badCoordinates }),
            doublons, nullIdentification, badCoordinates, //hasCityAndPostalCode : this.hasCityAndPostalCode,
            messageWarning: this.generateMessageWarning({ nullIdentification, /*hasCityAndPostalCode: this.hasCityAndPostalCode*/ }),
            messageLimitError: this.generateMessageLimitError(dataTable, xyMaxRecordsLine, geocodeMaxRecordsLine, xyMaxRecordsColumn, geocodeMaxRecordsColumn)
        }
    }

    generateMessageLimitError(dataTable: DataTable, xyLine?: number, geocodeLine?: number, xyColumn?: number, geocodeColumn?: number) {
        let message = '';
        const hasLatLng = dataTable.getFieldByBusinessType(BusinessType.LAT) && dataTable.getFieldByBusinessType(BusinessType.LONG);
        const countVisibleFields = dataTable.fields.filter((f: Field) => f.visible).length;
        if (hasLatLng && (xyLine && dataTable.data.length > xyLine) || (xyColumn && countVisibleFields > xyColumn)) {
            message = this.translateService.getTranslation('NetworkComponent', 'limit_message');
            message = message.replace('$value', String(xyLine));
            message = message + ' ' + this.translateService.getTranslation('NetworkComponent', 'limit_message_column');
            message = message.replace('$val', String(xyColumn));
        } else if (geocodeLine && dataTable.data.length > geocodeLine || geocodeColumn && countVisibleFields > geocodeColumn) {        
            message = this.translateService.getTranslation('NetworkComponent', 'limit_message');
            message = message.replace('$value', String(geocodeLine));
            message = message + ' ' + this.translateService.getTranslation('NetworkComponent', 'limit_message_column');
            message = message.replace('$val', String(geocodeColumn));
        }
        return message;
    }

    generateMessageWarning(inconsistencies: any) {
        let message = '';
        // if (!inconsistencies.hasCityAndPostalCode) {
        //     message = message!== '' ? message + '. \n ' : '';
        //     message += this.translateService.getTranslation('NetworkComponent', 'city_postal_code_message');
        // }

        if (inconsistencies.nullIdentification > 0) {
            let nullMsg = this.translateService.getTranslation('NetworkComponent', 'null_identification_message');
            nullMsg = nullMsg.replace('$value', inconsistencies.nullIdentification);
            message += nullMsg;
        }
        
        if (message !== '') {
            message = message + ' ' + this.translateService.getTranslation('NetworkComponent', 'alert_inconsistencies_changes_warning') + ' \n' ;
        }
        return message;
    }

    generateMessageInconsistencies(inconsistencies: any) {
        let message = '';
       
        if (inconsistencies.doublons.length > 0) {
            message = message!== '' ? message + '. \n ' : '';
            let doublonList = '(' + inconsistencies.doublons.join(', ') + ')';
            if(inconsistencies.doublons.length > 2) {
                doublonList = '(' + inconsistencies.doublons.slice(0, 2).join(', ') + '...)';
            }
            let doublonMsg = this.translateService.getTranslation('NetworkComponent', 'doublons_message');
            doublonMsg = doublonMsg.replace('$value', inconsistencies.doublons.length);
             message = message + doublonMsg + ' ' + doublonList + ' \n';
        }
        if(inconsistencies.badCoordinates > 0) {
            message = message!== '' ? message + '. \n ' : '';
            message = message + this.translateService.getTranslation('NetworkComponent', 'coordinates_message') + ' \n';
        
        }
        if (message !== '') {
            message = message + this.translateService.getTranslation('NetworkComponent', 'alert_inconsistencies_changes_error') + ' \n';
        }
        return message;
    }
    
}