import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormOption, FormStep } from './model';

@Component({
  selector: 'ui-lightning-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  @Input() options!: FormStep[];
  @Input() mode: string;
  @Input() containerType!: string;
  generatedForm!: FormGroup;
  constructor(private fb: FormBuilder) {
    this.containerType = 'tabs';
    this.mode = 'show';
  }

  ngOnInit(): void {
    const fbGroup: any = {};
    this.options.forEach(fs => {
      fs.formOptions?.forEach(fo => {
        const fc: any = { updateOn: 'change' };
        fc.validators = [];
        if (fo.required) {
          fc.validators.push(Validators.required);
          if (fo.type === 'select' || fo.type === 'checkbox') {
            fc.validators.push(Validators.minLength(1));
          }
        }
        // fc.validators = Validators.maxLength(2);
        fbGroup[fo.name] = new FormControl(fo.value, fc)
      });
    });
    this.generatedForm = this.fb.group(fbGroup);
  }
  activateTab(step: FormStep) {
    this.options.forEach(s => s.open = false);
    step.open = true;
  }
  editFormItem(item: FormOption) {
    this.options.forEach(st => {
      st.formOptions?.forEach(fo => {
        fo.edit = false;
      });
    });
    item.edit = true;
  }
  addStep() {
    this.options.push({
      title: 'newstep',
      text: 'intro step new step',
      open: true,
      formOptions: []
    });
    this.activateTab(this.options[this.options.length - 1]);
  }
  initFormItem(step: FormStep) {
    const item: FormOption = {
      name: 'item' + Math.round(100),
      title: 'New form item',
      value: '',
      field: '',
      outputFormat: 'brut',
      placeholder: 'Ecrir le text',
      type: 'text'
    };
    const fc: any = { updateOn: 'change' };
    fc.validators = [];
    this.generatedForm.addControl(item.name, new FormControl('', fc))
    step.formOptions?.push(item);

  }
  removeFormItem(item: FormOption) {
    let itemToRemove: number = -1;
    this.options.forEach(st => {
      st.formOptions?.forEach((fo, i) => {
        if (fo.name === item.name) {
          itemToRemove = i;
        }
      });
      if (itemToRemove !== -1) {
        st.formOptions?.splice(itemToRemove, 1);
        itemToRemove = -1;
      }
    });

  }
  saveFormItem(item: FormOption) {
    item.edit = false;
  }
  getProgress(step: FormStep) {
    let nbr = 0;
    step.formOptions?.forEach(fo => {
      if (fo.value && fo.value !== '') {
        nbr++;
      }
    });
    if (step.formOptions && step.formOptions.length > 0) {
      return Math.floor((nbr / step.formOptions.length) * 100);
    }
    return 0;
  }
}
