import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-lightning-tabs-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  @Input() isOpen: boolean;
  constructor() { 
    this.isOpen = false;
  }

  ngOnInit(): void {
  }

}
