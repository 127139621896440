<div class="slds-modal slds-fade-in-open">
  <div class="slds-modal__container">
    <div class="slds-modal__header slds-theme_{{options.level}} slds-theme_alert-texture">
      <h2 class="slds-text-heading_medium">{{options.title}}</h2>
    </div>
    <div class="slds-modal__content">
      <span class="slds-text-heading_medium">{{options.message}}</span>
      <fieldset class="slds-form-element" *ngIf="options.items">
        
        <div class="slds-form-element__control">
          <ng-template ngFor let-radio [ngForOf]="options.items" let-i="index">
            <span class="slds-radio">
              <input type="radio" id="radio-adress-{{i}}" value="{{radio.value}}" name="radio-adresse" (change)="selectedValue = radio" checked="{{i===0 ? 'checked': ''}}" />
              <label class="slds-radio__label" for="radio-adress-{{i}}">
                <span class="slds-radio_faux"></span>
                <span class="slds-form-element__label">{{radio.title}}</span>
              </label>
            </span>

          </ng-template>
        </div>
      </fieldset>
    </div>
    <div class="slds-modal__footer">
      <!-- Boutons d'action (par exemple, boutons Annuler et Valider) -->
      <button (click)="onClickCancel()" class="slds-button slds-button_text-destructive">{{options.cancelLabel}}</button>
      <button (click)="onClickOk()" class="slds-button slds-button_success">{{options.confirmLabel}}</button>
    </div>
  </div>
</div>
<div class="slds-backdrop slds-backdrop_open"></div>
