import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileImportComponent } from './file-import.component';
import { UiLightningSharedModule } from '../shared/shared.module';
import { LocaleModule } from '../locale/locale.module';
import { TooltipsModule } from '../tooltips/tooltips.module';
@NgModule({
  declarations: [
    FileImportComponent,
  ],
  imports: [
    CommonModule,
    UiLightningSharedModule,
    LocaleModule,
    TooltipsModule
  ],
  exports: [FileImportComponent]
})
export class FileImportModule { }
