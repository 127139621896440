<section
  role="dialog"
  tabindex="-1"
  aria-modal="true"
  class="slds-modal {{isOpen ? 'slds-fade-in-open': ''}}"
>
  <div class="slds-modal__container">
    <button *ngIf="closeable" class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
    (click)="closeModal()">
      <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
      </svg>
      <span class="slds-assistive-text">{{closeLabel}}</span>
    </button>
    <ng-content></ng-content>
  </div>
</section>
<div class="slds-backdrop {{isOpen ? 'slds-backdrop_open': ''}}" role="presentation"></div>
