import { Component, Input, OnInit } from '@angular/core';
import { Story } from '../models/story';

@Component({
  selector: 'ui-lightning-story-item',
  templateUrl: './story-item.component.html',
  styleUrls: ['./story-item.component.css']
})
export class StoryItemComponent implements OnInit {

  @Input() story: any;

  constructor() {
    this.story = new Story()
  }

  ngOnInit(): void {
  }

}
