import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LookupComponent } from './lookup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '../icon/icon.module';
import { SpinnerModule } from '../spinner/spinner.module';

@NgModule({
  declarations: [LookupComponent],
  imports: [CommonModule
    , FormsModule
    , ReactiveFormsModule
    , IconModule
    , SpinnerModule],
  exports: [LookupComponent],
})
export class LookupModule { }
