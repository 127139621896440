import { NgModule } from '@angular/core';
import { TooltipsComponent } from './tooltips.component';
import { CommonModule } from '@angular/common';
import { IconModule } from '../icon/icon.module';



@NgModule({
  declarations: [TooltipsComponent],
  imports: [
    CommonModule,
    IconModule,
  ], exports: [TooltipsComponent]
})
export class TooltipsModule { }
