import { Injectable, ViewContainerRef } from '@angular/core';
import { ModalBase } from './model/modal-base';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  vrc: ViewContainerRef | null;
  constructor() {
    this.vrc = null;
  }
  public initModals(vrc: ViewContainerRef) {
    console.log('Init Modal');
    this.vrc = vrc;
  }
  public inject(modalComponent: any): any {
    if (this.vrc !== null) {
      const modalComponentRef = this.vrc.createComponent(modalComponent);
      (<ModalBase>(modalComponentRef?.instance))?.openModal();
      return modalComponentRef?.instance;

    }
  }
}
