import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent, uiLightningTabsContentsDirective, UiLightningTabsNavDirective } from './tabs.component';
import { NavItemComponent } from './nav-item/nav-item.component';
import { ContentComponent } from './content/content.component';



@NgModule({
  declarations: [
    TabsComponent,
    NavItemComponent,
    ContentComponent,
    UiLightningTabsNavDirective,
    uiLightningTabsContentsDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TabsComponent,
    NavItemComponent,
    ContentComponent,
    UiLightningTabsNavDirective,
    uiLightningTabsContentsDirective
  ]
})
export class TabsModule { }
