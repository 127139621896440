import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileType, FileImportOptions, OutputFile } from './model';
import { DataTable, DataType } from '@galigeo-store/shared-models';
import { CSVParser } from './parsers/csv-parser';
import { ImageParser } from './parsers/img-parser';
import { Parser } from './parsers/parser';
import { LocaleFileImport } from '../locale/lang-components/file-import';
import { LanguageService } from '../locale/language.service';
import { XLSXParser } from './parsers/xlsx-parser';


@Component({
  selector: 'ui-lightning-file-import',
  templateUrl: './file-import.component.html',
  styleUrls: ['./file-import.component.css'],
})
export class FileImportComponent implements OnInit {
  @Input() options!: FileImportOptions;
  @Output() onChange = new EventEmitter<any>();
  public files!: FileList;
  public locale!: any;
  public accept: string = '*/*';
  public dataOutput?: OutputFile<DataTable>;
  public imageOutput?: OutputFile<String>;

  constructor(private languageService: LanguageService) {
    this.languageService.addTranslation('FileImport', new LocaleFileImport().locale);
  }
  translate(key: string): string {
    return this.languageService.getTranslation('FileImport', key);
  }
  ngOnInit(): void {
    if (!this.options) this.options = { type: FileType.DATA };
    if (!this.options.width) this.options.width = '100%';
    if (!this.options.height) this.options.width = '150px';
    if (!this.options.dropZone) this.options.dropZone = true;
    if (!this.options.multiple) this.options.multiple = false;
    if (!this.options.title) this.options.title = this.languageService.getTranslation('FileImport', 'title');
    if (!this.options.tooltip) this.options.tooltip = this.languageService.getTranslation('FileImport', 'tooltip');

    if (this.options.type === FileType.IMAGE) {
      this.accept = 'image/png';
    } else {
      this.accept = 'text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
  }

  uploadFiles(event: any, isDrag?: boolean): void {
    const files = isDrag ? event : event.target.files;
    if (!files || files.length === 0) return;
    switch (this.options.type) {
      case FileType.IMAGE:
        new ImageParser().parse(files[0]).then((data: string) => {
          this.imageOutput = { name: files[0].name, size: files[0].size, data } as OutputFile<string>;
          this.onChange.emit(this.imageOutput);
          this.options.image = data;
        });
        break;
      case FileType.DATA:
        console.log('Importing data file', files[0].type);
        let parser: Parser<DataTable>;
        let name = files[0].name as string;
        if (files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || files[0].type === 'application/vnd.ms-excel') {
          parser = new XLSXParser();
        } else if (files[0].type === 'text/csv' || name.endsWith('.csv') || name.endsWith('.txt')) {
          parser = new CSVParser();
        }
        else {
          this.dataOutput = { name: this.translate('file_type_not_supported'), size: files[0].size, data: new DataTable({}) } as OutputFile<DataTable>;
          throw new Error('File type not supported: ' + files[0].type);
        }

        parser.parse(files[0]).then((data: DataTable) => {
          DataType.parseDataTable(data);
          this.dataOutput = { name: files[0].name, size: files[0].size, data } as OutputFile<DataTable>;
          this.onChange.emit(this.dataOutput);
        });
        break;
    }
    if (this.dataOutput) {
      this.checkRecord(this.dataOutput.data!);
    }

    //throw new Error('File type not supported: ' + files[0].type);

  }

  /**
     * Check that each record has the same fields as the table
     */
  private checkRecord(dataTable: DataTable): void {

    const numFields = dataTable.fields.length;
    for (let i = 0; i < dataTable.data.length; i++) {
      if (dataTable.data.length !== numFields) {
        throw new Error('Invalid line ' + i + ' in the file');
      }
    }

  }

}
