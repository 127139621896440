import { OutputFile } from '../model';

export abstract class Parser<T> {
    parse(file: File): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            reject('Not implemented');
        });
    }
    static replaceEncodedCharacters(str: string): string {
        const specialChars = [
            { encoded: '&amp;', decoded: '&' },
            { encoded: '&lt;', decoded: '<' },
            { encoded: '&gt;', decoded: '>' },
            { encoded: '&#39;', decoded: '\'' },
            { encoded: '&quot;', decoded: '"' },
            { encoded: 'Ã©', decoded: 'é' },
            { encoded: 'Ã¨', decoded: 'è' },
            { encoded: 'Ã´', decoded: 'ô' },
            { encoded: 'Ã§', decoded: 'ç' },
            { encoded: 'Ãª', decoded: 'ê' },
            { encoded: 'Ã«', decoded: 'ë' },
            { encoded: 'Ã®', decoded: 'î' },
            { encoded: 'Ã¹', decoded: 'ù' },
            { encoded: 'Ã¢', decoded: 'â' },
            { encoded: 'Ã»', decoded: 'û' },
            { encoded: 'Ã¯', decoded: 'ï' },
            { encoded: 'Ã¶', decoded: 'ö' },
            { encoded: 'Ã¼', decoded: 'ü' },
            { encoded: 'Ã¦', decoded: 'æ' },
            { encoded: 'Å', decoded: 'œ' },
            { encoded: 'â¬', decoded: '€' },
            { encoded: 'Â©', decoded: '©' },
            { encoded: 'Â¤', decoded: '¤' },
            { encoded: 'Ã¦', decoded: 'æ' },
            { encoded: 'Ã°', decoded: '°' },
            { encoded: 'Ã', decoded: 'É' },
            { encoded: 'Ã', decoded: 'à' },
            { encoded: 'Â°', decoded: '°' },
            { encoded: 'à', decoded: 'Î' },


        ];
        specialChars.forEach((c) => {
            if (str !== undefined && str !== null)
                str = str.split(c.encoded).join(c.decoded);
        });
        return str;

    }
    static encodeColumnsName(columns: any[]): any[] {
        // rename the same name columns

        for (let i = 0; i < columns.length; i++) {

            let col = columns[i].name;
            let colCount = 0;
            for (let j = 0; j < columns.length; j++) {
                if (i !== j && col === columns[j].name) {
                    colCount++;
                    columns[j].name = col + '_' + colCount;
                    columns[j].alias = col + ' (' + colCount + ')';
                }
            }
            columns[i].name = Parser.replaceEncodedCharacters(columns[i].name);
            // replace all caracters that are not letters or numbers
            // columns[i].name = columns[i].name.replace(/[^a-zA-Z0-9]/g, '_');
        }


        return columns;
    }
}