<div [style.height]="options.height" class="slds-panel slds-panel_docked slds-panel_docked-left slds-is-open" aria-hidden="false">
    <div class="slds-panel__header" select="[ui-lightning-panel-header]">
        <h2 class="slds-panel__header-title slds-text-heading_small slds-truncate" title="{{options.title}}">{{options.title}}</h2>
      <div class="slds-panel__header-actions">
        <button class="slds-button slds-button_icon slds-button_icon-small slds-panel__close" title="Collapse {{options.title}}">
          <svg class="slds-button__icon" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
          </svg>
          <span class="slds-assistive-text">Collapse Panel Header</span>
        </button>
      </div>
    </div>
    <div class="slds-panel__body">
        <ng-content select="ui-lightning-panel-content"></ng-content></div>
  </div>