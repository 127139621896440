import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportImgComponent } from './import-img.component';



@NgModule({
  declarations: [
    ImportImgComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [ImportImgComponent]
})
export class ImportImgModule { }
