import { Component, Directive, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-lightning-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
  

}
@Directive({ selector: 'uiLightning-tabs-nav' })
export class UiLightningTabsNavDirective {}
@Directive({ selector: 'uiLightning-tabs-contents' })
export class uiLightningTabsContentsDirective {}