<div class="slds-form-element">
  <div class="slds-form-element__control">
    <div class="slds-file-selector slds-file-selector_files" (click)="uploader.click()" title="{{this.options.tooltip}}">
      <div uiLightningDropZone (onFileDropped)="uploadFiles($event, true)"
        class="slds-file-selector__dropzone dropzone slds-text-align_center" >
        <button *ngIf="!dataOutput && !options.image" class="slds-button slds-button_neutral" title="{{this.options.tooltip}}">
          <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#upload"></use>
          </svg>{{this.options.title}}</button>
        <div *ngIf="options.image" style="position: relative;" >
          <img [src]="options.image" title="{{imageOutput?.name}}" [style.max-height]="options.height" />
          <div class="edit-img" title="{{'FileImport:tooltip_img' | translate:'Click to select an image' }}">
           
              <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#upload"></use>
              </svg>
          </div>
        </div>
        <div *ngIf="dataOutput" class="slds-text-align_center">
          <span class="slds-icon_container slds-icon-utility-announcement" title="{{dataOutput.name}}">
            <svg class="slds-icon_xx-small slds-icon-text-default" aria-hidden="true">
              <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
            </svg>
            <span class="slds-assistive-text">{{dataOutput.name}}</span>
          </span>

          {{dataOutput.name}}.
        </div>
      </div>

    </div>
  </div>
  <input type="file" [multiple]="options.multiple" (change)="uploadFiles($event)" style="display:none"
    accept="{{accept}}" id="file-upload-input-108"
    aria-labelledby="file-selector-primary-label-105 file-selector-secondary-label106" #uploader />
</div>