import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthguardService, GeocodeService, HttpService, PoiService } from '../../shared/services/index';
import { AlertOptions, BusinessType, ConfirmOptions, DataTable, Field, FieldType, MESSAGE_LEVEL, Record } from '@galigeo-store/shared-models';
import { EventService, FileImportOptions, FileType, GgoEvent, LanguageService, MessageService, ModalService, StepperItem, StepperOptions, TableComponent } from '../../shared/components';
import { LocaleWizardComponent } from 'src/app/shared/locales/localeWizardComponent';
import { Alert } from 'src/app/shared/models/alert';
import { Network } from 'src/app/shared/models/network';
import { MappingFieldsEvent } from '../../shared/mapping-fields/mapping-fields.component';
import { AbstractContainer } from '../../shared/models/abstractContainer';
import { LOGO } from '../../shared/models/logo';

enum StepNames {
  NETWORK_NAME = 'networkName',
  ADD_LOGO = 'addLogo',
  ADD_FILE = 'addFile',
  SELECT_COLUMNS = 'selectColomns',
  // GEOCODAGE = 'geocodage',
}

@Component({
  selector: 'retail-view-container',
  templateUrl: './wizard-add.component.html',
  styleUrls: ['./wizard-add.component.css'],
})
export class WizardAddComponent extends AbstractContainer implements OnInit {
  public stepperOptions!: StepperOptions;
  public StepNames = StepNames;
  importFileOptions!: FileImportOptions;

  alertGeocodage!: Alert[];
  clickedRow!: any;

  @ViewChild(TableComponent)
  table!: TableComponent;
  collection = [];

  optionsLookup;
  originalDataTable!: DataTable;
  geocodage!: boolean;
  fields: Field[] = [];
  showEditCoordinatesModal = false;
  adressesReverseGeocode: any[] = [];
  dataTableReverse!: DataTable;
  dataTableMappingResult!: MappingFieldsEvent;
  networkPoiDraged!: Record | undefined;
  eventDragDataTable!: DataTable | undefined;
  adressSelected: string = 'origine';
  readyToShow: boolean = false;
  nameValid: boolean = false;
  constructor(
    override  poiService: PoiService,
    override  router: Router,
    override  route: ActivatedRoute,
    override  translateService: LanguageService,
    override  messageService: MessageService,
    override  eventService: EventService,
    override  authguardService: AuthguardService,
    override geocoderService: GeocodeService,
    override modalService: ModalService
  ) {
    super(poiService, router, route, translateService, messageService, eventService, authguardService, geocoderService, modalService);
    this.tableOptions = {
      withBorders: true,
      canEdit: true,
      resizable: false,
      width: '100%',
      multiSelect: false,
      pageSize: 20
    };
    this.eventService.listenEvent('table-add').subscribe((event: GgoEvent) => {
      this.onTableEvent(event);
    });

    this.network = new Network();
    // default logo
    this.network.logo = LOGO.DEFAULT_LOGO;
    this.geocodage = false;

    this.optionsLookup = {
      placeholder: 'Search',
    };
    if (this.authguardService.user.lang) {
      if (this.authguardService.user.lang == 'fr') {
        this.translateService.setLang(this.authguardService.user.lang);
      } else {
        this.translateService.setLang('en');
      }
    } else if (navigator.language.startsWith('fr')) this.translateService.setLang('fr');
    this.translateService.addTranslation('WizardComponent', new LocaleWizardComponent().locale);
  }
  initOptions() {
    this.tableOptions.canEdit = this.authguardService.hasCapability('editNetwork');
    this.stepperOptions = new StepperOptions({
      steps: [
        {
          name: StepNames.NETWORK_NAME,
          title: this.translateService.getTranslation('WizardComponent', 'step_network_name_title'),
          label: this.translateService.getTranslation('WizardComponent', 'step_network_name_label'),
          description: this.translateService.getTranslation('WizardComponent', 'step_network_name_description'),
          nextDisabled: true,
        },
        {
          name: StepNames.ADD_LOGO,
          title: this.translateService.getTranslation('WizardComponent', 'step_add_logo_title'),
          label: this.translateService.getTranslation('WizardComponent', 'step_add_logo_label'),
          description: this.translateService.getTranslation('WizardComponent', 'step_add_logo_description'),
        },
        {
          name: StepNames.ADD_FILE,
          title: this.translateService.getTranslation('WizardComponent', 'step_add_file_title'),
          label: this.translateService.getTranslation('WizardComponent', 'step_add_file_label'),
          description:
            this.translateService.getTranslation('WizardComponent', 'step_add_file_description'),
          nextDisabled: true,
        },
        {
          name: StepNames.SELECT_COLUMNS,
          title: this.translateService.getTranslation('WizardComponent', 'step_select_columns_title'),
          label: this.translateService.getTranslation('WizardComponent', 'step_select_columns_label'),
          description:
            this.translateService.getTranslation('WizardComponent', 'step_select_columns_description'),
          nextDisabled: true,
        },
        // {
        //   name: StepNames.GEOCODAGE,
        //   title: this.translateService.getTranslation('WizardComponent', 'step_geocodage_title'),
        //   label: this.translateService.getTranslation('WizardComponent', 'step_geocodage_label'),
        //   description:
        //     this.translateService.getTranslation('WizardComponent', 'step_geocodage_description'),
        // },
      ],
      active: true,
      currentStep: 0,
    });


    // Check if a poiSetId is provided in the url
    // If so, load the network and go to the second step
    this.route.paramMap.subscribe(async (params) => {
      const networkId: any = params.get('poiSetId');
      if (networkId) {
        this.readyToShow = true;
        if (networkId == 'new') {
          this.disableGraphicColumns();
        } else {
          // Only for identifying columns (Since we already have an ID network)
          this.eventService.emitEvent('global-spinner', { action: 'show', obj: this.translateService.getTranslation('WizardComponent', 'loading_spinner') });
          // load existing network
          this.stepperOptions.goToStep(StepNames.SELECT_COLUMNS, true);
          await this.resetNetwork(networkId);
          this.stepperOptions.steps[3].btns![1].show = false;
          this.originalDataTable = new DataTable(JSON.parse(JSON.stringify(this.network.pois)));
          this.eventService.emitEvent('global-spinner', { action: 'hide', obj: this.translateService.getTranslation('WizardComponent', 'loading_spinner') });
        }
      }
    });
  }
  ngOnInit(): void {
    this.poiService.getBusinessFields().subscribe((dt: DataTable) => {
      this.fields = dt.toJson();
    });
    this.initOptions();
    this.importLogoOptions = { type: FileType.IMAGE, height: '50px', width: '100px', title: this.translateService.getTranslation('WizardComponent', 'upload_a_logo') };
    this.importFileOptions = { type: FileType.DATA, title: this.translateService.getTranslation('WizardComponent', 'upload_a_csv') }
  }

  cardStepperClick(event: any) {
    this.disableGraphicColumns();
    const currentStep: StepperItem = this.stepperOptions.getCurrentStep();

    switch (event.btn.action) {
      case 'cancel':
        this.handleCancel();
        break;
      case 'previous':
        this.handlePreviousStep();
        break;
      case 'next':
        this.handleNextStep(currentStep);
        break;
      case 'finish':
        this.handleFinish(currentStep);
        break;
      default:
        console.log('default');
        break;
    }
  }

  private handleCancel() {
    console.log('cancel');
    this.navigateToLatestNetwork();
  }

  private navigateToLatestNetwork() {
    this.poiService.getNetworks().subscribe((networks: DataTable) => {
      let networkItems = networks.toJson();
      if (networkItems.length > 0) {
        const latestNetwork = networkItems.reduce((a, b) => new Date(a['created_at']) > new Date(b['created_at']) ? a : b);
        this.router.navigate(['/network', latestNetwork.network_id], { queryParams: HttpService.getQueryParams() });
      }
    });
  }

  private handlePreviousStep() {
    console.log('previous');
    this.stepperOptions.currentStep = this.stepperOptions.currentStep! - 1;
  }

  private handleNextStep(currentStep: StepperItem) {
    console.log('Go to next step from ' + this.stepperOptions.currentStep);
    switch (currentStep.name) {
      case StepNames.NETWORK_NAME:
      case StepNames.ADD_LOGO:
      case StepNames.ADD_FILE:
        this.stepperOptions.currentStep = this.stepperOptions.currentStep! + 1;
        break;
    }
  }



  private async handleFinish(currentStep: StepperItem) {
    if (currentStep.name === StepNames.SELECT_COLUMNS) {
      this.originalDataTable = this.dataTableMappingResult.getResult();
      await this.handleColumnSelectionFinish();
    }
    this.hideSpinner();
  }

  private async handleColumnSelectionFinish() {
    const inconsistencies = this.detectInconsistencies(this.originalDataTable);
    if (inconsistencies.messageError !== '') {
      this.messageService.alert(new AlertOptions({
        title: this.translateService.getTranslation('WizardComponent', 'alert_title_inconsistencies_error'),
        message: inconsistencies.messageError,
        level: MESSAGE_LEVEL.ERROR
      }));
    } else {
      if (inconsistencies.messageLimitError !== '') {
        this.messageService.alert(new AlertOptions({
          message: inconsistencies.messageLimitError,
          title: this.translateService.getTranslation('WizardComponent', 'alert_title_inconsistencies_error'),
          level: MESSAGE_LEVEL.ERROR,
        }));
      } else if (inconsistencies.messageWarning !== '') {
        this.messageService.confirm(new ConfirmOptions({
          message: inconsistencies.messageWarning,
          title: this.translateService.getTranslation('WizardComponent', 'confirm_modal_title'),
          level: MESSAGE_LEVEL.INFO,
          callback: async (result: boolean) => {
            if (result) {
              this.handleFields();
            }
          }
        }));

      } else {
        this.handleFields();
      }
    }
  }

  private async handleFields() {
    const originalFields = this.originalDataTable.fields;
    await this.saveNetworkAndNavigate(originalFields);
  }

  private async saveNetworkAndNavigate(originalFields: Field[]) {
    if (this.hasLatLongFields()) {
        this.addGeometryAndAccuracyFields();
    }
    this.saveNetwork(originalFields);
}

private hasLatLongFields(): boolean {
    return this.originalDataTable.hasBusinessType(BusinessType.LAT) && this.originalDataTable.hasBusinessType(BusinessType.LONG);
}

private addGeometryAndAccuracyFields() {
    const latIdx = this.originalDataTable.getFieldIndexByBusinessType(BusinessType.LAT);
    const longIdx = this.originalDataTable.getFieldIndexByBusinessType(BusinessType.LONG);
    const geomIdx = this.originalDataTable.addField({ businessType: BusinessType.GEOMETRY, type: FieldType.GEOMETRY, name: 'Geometry', visible: false, editable: true });
    this.originalDataTable.addField({ businessType: BusinessType.GEOCODER_ACCURACY, type: FieldType.STRING, name: 'Geocoding Accuracy', visible: false, editable: false }, 1);

    for (const record of this.originalDataTable.data) {
        const lat = record.values[latIdx];
        const long = record.values[longIdx];
        if (lat && long) {
            record.values[geomIdx] = { type: 'Point', coordinates: [long, lat] };
        }
    }
}

private saveNetwork(originalFields: Field[]) {
    this.poiService.saveNetwork({ id: this.network.id!, name: this.network.name!, logo: this.network.logo, pois: this.originalDataTable }).subscribe(async (res: DataTable) => {
        const networkInfo: any = res.toJson()[0];
        this.network.id = networkInfo.network_id;
        if (!this.hasLatLngFields(originalFields)) {
            this.handleNoLatLngFields(networkInfo);
        } else {
            this.handleHasLatLngFields(networkInfo);
        }
    });
}

  mappingResultWizardAdd(event: MappingFieldsEvent) {
    let errorMsg = event.validMappingFields();
    if (errorMsg.length === 0) {
      this.stepperOptions.enableDone();
      this.dataTableMappingResult = event;
    } else {
      this.stepperOptions.enableDone(false);
    }
  }



  private hasLatLngFields(originalFields: Field[]) {
    return originalFields.some((field: Field) => field.businessType === BusinessType.LAT) && originalFields.some((field: Field) => field.businessType === BusinessType.LONG);
  }

  private async handleNoLatLngFields(networkInfo: any) {
    this.eventService.emitEvent('global-spinner', { action: 'show', obj: this.translateService.getTranslation('WizardComponent', 'loading_spinner') });
    await this.refreshNetwork(this.network.id!);
    this.configureColumns();
    const queryParams = { ...HttpService.getQueryParams(), geocode: 'true' };
    this.router.navigate(['/network', networkInfo.network_id], { queryParams: queryParams });
  }

  private async handleHasLatLngFields(networkInfo: any) {
    this.eventService.emitEvent('global-spinner', { action: 'show', obj: this.translateService.getTranslation('WizardComponent', 'loading_spinner') });
    this.disableGraphicColumns();
    await this.refreshNetwork(this.network.id!);
    const queryParams = { ...HttpService.getQueryParams(), geocode: 'false' };
    this.router.navigate(['/network', networkInfo.network_id], { queryParams: queryParams });
  }

  private hideSpinner() {
    this.eventService.emitEvent('global-spinner', { action: 'hide', obj: null });
  }


  searchTable(event: any) {
    return this.network.pois ? this.network.pois.globalFilter(event) : null;
  }
  onDataLoaded(event: any) {
    this.network.pois = event.data;
    this.network.fileName = event.name;
    if (!this.network.name) this.network.name = event.name;
    this.originalDataTable = new DataTable(JSON.parse(JSON.stringify(event.data)));
    this.stepperOptions.enableNext();
    this.stepperOptions.goToStep(StepNames.SELECT_COLUMNS, false);
  }

  onLogoLoaded(event: any) {
    console.log(event.data);
    this.network.logo = event.data;
  }
  deleteLogo() {
    this.network.logo = undefined;
  }
  setNetworkName(event: any) {
    let value = event.target.value;
    if (value.length > 1 && value.length < 31) {
      this.nameValid = true;
      this.stepperOptions.enableNext();
    } else {
      this.nameValid = false;
      this.stepperOptions.enableNext(false);
    }
  }
  deleteData() {
    this.network.pois = undefined;
    this.stepperOptions.steps[2].btns![2].disabled = true;
  }

  getCoordinates(event: any) {
    this.eventService.emitEvent('map', { action: 'flyTo', obj: event });
  }
  setAdress(value: any) {
    this.adressSelected = value;
  }

  async clickedMapPoint(event: any) {
    if (event.obj) {
      this.eventService.emitEvent('table-add', { action: 'highlight', obj: event.obj });
    }

    if (event) {
      console.log(event)
      switch (event.action) {
        case 'delete':
          console.log('delete');
          break;
        case 'zoom':
          console.log('zoom');
          break;
        case 'select':
          console.log('select');
          break;
        case 'edit':
          console.log('edit');
          if (this.network?.id && this.network.id) {
            this.poiService.updatePOI({ networkId: this.network?.id, pois: event.data }).subscribe(async (res: DataTable) => {
              await this.refreshNetworkWithIcones(this.network?.id!);
            });
          }
          break;
        default:
          console.log('default');
          break;
      }
    }
  }
  async refreshNetwork(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.readyToShow = false;
      this.network = new Network();
      // default logo
      this.disableGraphicColumns();
      this.network.logo = LOGO.DEFAULT_LOGO;
      this.poiService.getPOISet(id).subscribe((res: DataTable) => {
        const networkInfo: any = res.toJson()[0];
        this.network.id = networkInfo.network_id;
        this.network.name = networkInfo.name;
        this.network.logo = networkInfo.logo;
        this.importLogoOptions.image = networkInfo.logo;
        this.poiService.getPOIs(id).subscribe((res: DataTable) => {
          this.network.pois = new DataTable({ id: res.id, data: res.data, fields: res.fields });
          if (!this.network.pois.isGeocoded()) {
            this.router.navigate(['/network', this.network.id], { queryParams: HttpService.getQueryParams() });
          } else {
            this.readyToShow = true;
          }
          resolve();
        });
      });
    });
  }
  async refreshNetworkWithIcones(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.readyToShow = false;
      this.network = new Network();
      // default logo
      this.disableGraphicColumns();
      this.network.logo = LOGO.DEFAULT_LOGO;
      this.poiService.getPOISet(id).subscribe((res: DataTable) => {
        const networkInfo: any = res.toJson()[0];
        this.network.id = networkInfo.network_id;
        this.network.name = networkInfo.name;
        this.network.logo = networkInfo.logo;
        this.importLogoOptions.image = networkInfo.logo;
        this.poiService.getPOIs(id).subscribe((res: DataTable) => {
          this.network.pois = new DataTable({ id: res.id, data: res.data, fields: res.fields });
          this.configureColumns();
          this.readyToShow = true;
          resolve();
        });
      });
    });
  }
}
