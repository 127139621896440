import { Style, StyleOptions } from '../model/StyleOptions.js';
import { MapboxStyle } from './MapboxStyle.js';

export class PointStyle extends MapboxStyle {
  constructor(style: StyleOptions) {
    super(style);
  }

  public getFilter() {
    if (this.style.cluster) {
      return ['!', ['has', 'point_count']];
    }
    return undefined;
  }
  public getLayerLayout() {
    const layout: any = {};
    layout['icon-allow-overlap'] = this.style.allowOverlap !== undefined && this.style.allowOverlap ? this.style.allowOverlap : false;
    //layout["icon-size"] = this.style.width ? this.style.width : 1.0;
    layout['icon-anchor'] = this.style.anchor ? this.style.anchor : 'center';
    if (this.style.label) {
      layout['text-field'] = ['get', 'points_label'];
      layout['text-anchor'] = 'top';
      layout['text-offset'] = [0, 1.5];
      layout['text-size'] = this.style.label.size ? this.style.label.size : 12;
      layout['text-allow-overlap'] = true;
    }
    if (this.style.matches) {
      switch (this.style.type) {
        case Style.Symbol:
          layout['icon-image'] = ['get', this.style.matches.field];
          //layout["icon-size"] = this.style.width ? this.style.width : 1.0;
          return layout;
        default:
          break;
      }
    } else if (this.style.ranges) {
      switch (this.style.type) {
        case Style.Symbol:
          layout['icon-image'] = ['case'];
          this.style.ranges.classes.forEach((range, i) => {
            if (this.style.ranges && this.style.ranges.classes && i !== this.style.ranges.classes.length - 1) {
              layout['icon-image'].push(['<', ['get', this.style.ranges.field], range.value], 'class' + range.label);
            } else {
              layout['icon-image'].push('class' + range.label);
            }
          });
          //layout["icon-size"] = this.style.width ? this.style.width : 1.0;
          layout['icon-anchor'] = this.style.anchor ? this.style.anchor : 'center';
          if (this.style.offset) {
            layout['icon-offset'] = this.style.offset;
          }
          return layout;
        default:
          break;
      }
    } else if (this.style.imgIdField) {
      console.warn('Please stop use imageName instead of imgIdField');
      layout['icon-image'] = ['get', this.style.imgIdField];
      //layout["icon-size"] = this.style.width ? this.style.width : 1.0;
      //layout["icon-anchor"] = this.style.anchor ? this.style.anchor : 'center';
      if (this.style.offset) {
        layout['icon-offset'] = this.style.offset;
      }
      return layout;
    } else if (this.style.imageName) {
      layout['icon-image'] = this.style.imageName;
      //layout["icon-anchor"] = this.style.anchor ? this.style.anchor : 'center';
      if (this.style.offset) {
        layout['icon-offset'] = this.style.offset;
      }
      return layout;
    } else if (this.style.piechart) {
      console.warn('Piecharts not implemented yet');
    }
    return undefined;
  }
  public getLayerType() {
    if (this.style.bubble) {
      return Style.Circle;
    }
    return this.style.type;
  }
  public getLayerPaint() {
    const paint: any = {};

    switch (this.style.type) {
      case Style.Symbol:
        // symbol is defined by the layout
        return undefined;
        break;

      default:
        paint['circle-color'] = this.style.fillColor;
        paint['circle-radius'] = this.style.radius;
        paint['circle-stroke-width'] = this.style.lineWidth;
        paint['circle-stroke-color'] = this.style.lineColor;
        break;
    }

    if (this.style.ranges) {
      paint['circle-color'] = this.style.ranges.transformRangesToSteps();
    }
    if (this.style.rangeRadius) {
      paint['circle-radius'] = this.style.rangeRadius.transformRangesToSteps();
    }
    if (this.style.matches) {
      //paint['circle-color'] = this.style.matches.transformMatch();
    }
    if (this.style.bubble) {
      paint['circle-color'] = ['case', ['<', ['get', this.style.bubble.field], 0], '#ff0000', this.style.fillColor];
      paint['circle-radius'] = this.style.bubble.getCircleRadius();
      paint['circle-stroke-width'] = 1;
      paint['circle-stroke-color'] = '#ffffff';
    }
    return paint;
  }
}
