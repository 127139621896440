import { Directive, Input } from "@angular/core";
import { EventService, GgoEvent } from "../shared/event.service";
import { Observable } from "rxjs";

/**
 * Base class for all components
 */
@Directive()
export abstract class AbstractComponent {
  /**
   * Name of the component. If not set, the default name is used.
   */
  @Input() name!: string;

  constructor(public eventService: EventService) {}

  /**
   * Returns the default name of the component.
   */
  public abstract getDefaultName(): string;

  /**
   *
   * @returns the name of the component. This name is used to emit and listen events.
   */
  public getName() {
    return this.name ? this.name : this.getDefaultName();
  }

  /**
   *
   * @param ggoEvent the event to emit
   */
  public emitEvent(ggoEvent: GgoEvent) {
    this.eventService.emitEvent(this.getName(), ggoEvent);
  }

  /**
   *
   * @returns an observable to listen events
   */
  public listenEvent(): Observable<GgoEvent> {
    return this.eventService.listenEvent(this.getName());
  }

  public showGlobalSpinner(msg: string) {
    this.eventService.emitEvent('global-spinner', { action: 'show', obj: msg  });
  }

  public hideGlobalSpinner() {
    this.eventService.emitEvent('global-spinner', { action: 'hide', obj: '' });
  }
}
