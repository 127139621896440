export enum Lang {
    EN = 'en',
    FR = 'fr',
    ES = 'es',
    DE = 'de',
    PT = 'pt'
}

export class Translations extends Map<string, Map<string, Map<string, string>>>{
    // file / lang / key / value
    public translate(file: string, key: string, lang: Lang): string {
        if(!lang) {
            lang = Lang.EN;
        }
        const locales = this.get(file)?.get(lang);
        if(locales) {
            const result = locales.get(key);
            if(result) {
                return result;
            }
        }
        console.warn(`Translation not found for ${file} / ${lang} / ${key}`);
        return key;
    }


}
export class Locale {
    public  locale : Map <string, Map<string, string>> = new Map<string, Map<string, string>>();
    public  languages: any;
    constructor() {
         
    }
    public  genrateMap(){
        Object.keys(this.languages).forEach((key: string) => {
            this.locale.set(key, new Map<string, string>());
            Object.keys(this.languages[key]).forEach((key2: string) => {
                this.locale.get(key)?.set(key2, this.languages[key][key2]);
            });
        }); 
    }
}