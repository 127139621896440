import { DBObject } from './db-object.js';
export class DBIndex extends DBObject {
    name;
    table;
    column;
    unique;
    constructor(options) {
        super();
        this.table = options.table;
        this.column = options.column;
        this.unique = options.unique ?? false;
        if (options.name) {
            this.name = options.name;
        }
        else {
            if (this.unique) {
                this.name = `pk_${this.table}_${this.column.split(', ').join('_')}`;
            }
            else {
                this.name = `idx_${this.table}_${this.column.split(', ').join('_')}`;
            }
        }
    }
}
