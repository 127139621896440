export class NavBarOptions {
    items?: NavabarItem [];
}
export class NavabarItem {
    name?: string;
    url?: string;
    label?: string;
    title?: string;
    position?: NavabarItemPosition;
    icon?: string;
    imgUrl?: string;
    items?: NavabarItem [];
    open?: boolean;
    iconDecoratorClasses?: string;
    chevrondown?: boolean;
    type?: string;
}
export enum NavabarItemPosition {
    LEFT = "left",
    RIGHT = "right"
  }