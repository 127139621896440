import { Component, Input, OnInit } from '@angular/core';
import { FormOption } from '../model';

@Component({
  selector: 'ui-lightning-form-item-generator',
  templateUrl: './form-item-generator.component.html',
  styleUrls: ['./form-item-generator.component.css']
})
export class FormItemGeneratorComponent implements OnInit {
  @Input() options!: FormOption;
  newValue: string;
  newTitle: string;
  constructor() {
    this.newValue = '';
    this.newTitle = '';
  }

  ngOnInit(): void {
  }
  addValue() {
    this.options.multiList = this.options.multiList && this.options.multiList.length > 0 ? this.options.multiList : [];
    this.options.multiList?.push({ value: this.newValue, title: this.newTitle });
    this.newValue = '';
    this.newTitle = '';
  }
  removeValue(item: any) {
    this.options.multiList = this.options.multiList?.filter(el => el.value !== item.value);
  }
  checkValue(item: any) {
    item.checked = !item.checked;
    console.log(item);

  }

}
