import { fabric } from 'fabric';
import { BubbleRenderer } from '../renderers/Bubble.js';

export class GgoLegendConverter {
  public static getLegendHTML(layerDesc: any): any {
    let html = '<p style="margin: 0px; font-size: 0.8rem;font-family: serif;">';
    const renderer = layerDesc.drawingInfo?.renderer;
    if (renderer?.type === 'simple') {
      html += `<div style="display:flex;align-items:center;">
                        <div style="display:inline-block">${this.getSymbolHtml(layerDesc.drawingInfo.renderer.symbol)}</div>            
                        <div style="display:inline-block"><span style="display:inline-block; max-width: 200px">${layerDesc.name}</span></div>   
                    </div>`;
    } else if (renderer?.type === 'proportionalSymbols') {
      html += `<div>
                        <span style="${this.getTitleCss()}">${layerDesc.name}</span>
                    </div>
                    <div>
                        <img src="${this.getGeoBubbleHtml(renderer)}">
                    </div>`;
    } else if (layerDesc.drawingInfo?.renderer?.classBreakInfos) {
      html += `<span style="${this.getTitleCss()}">${layerDesc.name}</span>
                <ul style="list-style-type: none;margin: 0;padding: 0;height:100%;vertical-align: middle;">`;
      layerDesc.drawingInfo.renderer.classBreakInfos.forEach((classBreakInfo: any) => {
        html += `<li style="height:100%;display:flex;align-content:center;align-items: center;margin-bottom: 5px;">
                      ${this.getSymbolHtml(classBreakInfo.symbol)}
                      <span style="margin-left:10px">${classBreakInfo.label}</span>
                </li>`;
      });
      html += '</ul>';
    } else if (layerDesc.drawingInfo?.renderer?.uniqueValueInfos) {
      html += `<span style="${this.getTitleCss()}">${layerDesc.name}</span>
                <ul style="list-style-type: none;margin: 0;padding: 0;height:100%;vertical-align: middle;">`;
      layerDesc.drawingInfo.renderer.uniqueValueInfos.forEach((uniqueValueInfo: any) => {
        html += `<li style="height:100%;display:flex;align-items: center;align-content:center;margin-bottom: 5px;">
                               ${this.getSymbolHtml(uniqueValueInfo.symbol)}
                               <span style="margin-left:10px">${uniqueValueInfo.label}</span>
                         </li>`;
      });
      html += '</ul>';
    } else {
      console.warn('Unsupported renderer', layerDesc.drawingInfo.renderer);
    }
    html += '</p>';
    return html;
  }

  private static getTitleCss() {
    return 'font-size: 1rem;    font-weight: bold;';
  }

  private static getSymbolHtml(ggoSymbol: any) {
    console.log('getSymbolHtml', ggoSymbol);
    let html = '';
    switch (ggoSymbol.type) {
      case 'esriSFS':
        html += this.getFillSymbolHtml(ggoSymbol);
        break;
      case 'esriSLS':
        html += this.getLineSymbolHtml(ggoSymbol);
        break;
      case 'esriPMS':
        html += this.getPictureMarkerSymbolHtml(ggoSymbol);
        break;
      default:
        console.warn('Unsupported symbol type', ggoSymbol);
    }
    return html;
  }

  private static getFillSymbolHtml(ggoSymbol: any) {
    let html = '';
    html += `<div class="fill-symbol" style="display:inline-block;
            margin-right:5px;
            background-color: ${this.getRgbaColor(ggoSymbol.color)}; 
            border: solid ${this.getRgbaColor(ggoSymbol.outline.color)} ${ggoSymbol.outline.width}px;
            border-radius: 5px;
            width: 30px; height: 20px;">
        </div>`;
    return html;
  }

  private static getLineSymbolHtml(ggoSymbol: any) {
    let html = '';
    html += '<div class="line-symbol" style="background-color:' + this.getRgbaColor(ggoSymbol.color) + '; width:' + ggoSymbol.width + 'px"></div>';
    return html;
  }

  private static getPictureMarkerSymbolHtml(ggoSymbol: any) {
    let html = '';
    html += '<img height="100%" class="picture-marker-symbol" src="data:image/png;base64,' + ggoSymbol.imageData + '" />';
    return html;
  }

  private static getGeoBubbleHtml(renderer: any): string {
    const maxValue = renderer.uniqueValueInfos.reduce((max: number, uniqueValueInfo: any) => Math.max(max, uniqueValueInfo.value), 0);
    const minValue = renderer.uniqueValueInfos.reduce((min: number, uniqueValueInfo: any) => Math.min(min, uniqueValueInfo.value), 0);
    const maxRadius = BubbleRenderer.getRadius(maxValue, renderer.refValue, renderer.refSize, renderer.scaleFactor);
    const minRadius = BubbleRenderer.getRadius(minValue, renderer.refValue, renderer.refSize, renderer.scaleFactor);
    const canvas = new fabric.Canvas('canvas');
    canvas.setWidth(50 + maxRadius * 2);
    canvas.setHeight(10 + maxRadius * (minRadius > 0 ? 4 : 2));
    //renderer.uniqueValueInfos.forEach((uniqueValueInfo: any) => {
    for (let i = renderer.uniqueValueInfos.length - 1; i >= 0; i--) {
      const uniqueValueInfo = renderer.uniqueValueInfos[i];
      if (uniqueValueInfo.value >= 0) {
        const radius = BubbleRenderer.getRadius(uniqueValueInfo.value, renderer.refValue, renderer.refSize, renderer.scaleFactor, maxRadius);
        const color = this.getRgbaColor(renderer.symbol.color);
        const top = 10;
        canvas.add(
          new fabric.Circle({
            top: top + maxRadius * 2 - 2 * radius,
            left: maxRadius - radius,
            radius: radius,
            fill: color,
            stroke: 'white',
            strokeWidth: 1,
          }),
        );
        canvas.add(
          new fabric.Text(uniqueValueInfo.value + '', {
            top: top + (maxRadius * 2 - 2 * radius) - 3,
            left: 10 + maxRadius * 2,
            fontSize: 12,
            fill: 'black',
          }),
        );
        canvas.add(
          new fabric.Line([maxRadius, top + maxRadius * 2 - 2 * radius, 10 + maxRadius * 2, top + maxRadius * 2 - 2 * radius], {
            stroke: 'black',
            strokeWidth: 1,
          }),
        );
      }
    }

    for (let i = 0; i <= renderer.uniqueValueInfos.length - 1; i++) {
      const uniqueValueInfo = renderer.uniqueValueInfos[i];
      if (uniqueValueInfo.value <= 0) {
        const radius = BubbleRenderer.getRadius(uniqueValueInfo.value, renderer.refValue, renderer.refSize, renderer.scaleFactor, maxRadius);
        const color = this.getRgbaColor(renderer.symbolNegative.color);
        const top = 10 + maxRadius * 2;
        canvas.add(new fabric.Circle({ top: top, left: maxRadius - radius, radius: radius, fill: color, stroke: 'white', strokeWidth: 1 }));
        canvas.add(
          new fabric.Text(uniqueValueInfo.value + '', { top: top + 2 * Math.abs(radius) - 3, left: 10 + maxRadius * 2, fontSize: 12, fill: 'black' }),
        );
        canvas.add(
          new fabric.Line([maxRadius, top + 2 * Math.abs(radius), 10 + maxRadius * 2, top + 2 * Math.abs(radius)], {
            stroke: 'black',
            strokeWidth: 1,
          }),
        );
      }
    }

    return canvas.toDataURL();
  }

  private static getRgbaColor(color: number[]) {
    if (color.length === 3) {
      return 'rgb(' + color[0] + ',' + color[1] + ',' + color[2] + ')';
    }
    return 'rgba(' + color[0] + ',' + color[1] + ',' + color[2] + ',' + color[3] + ')';
  }
} // End of GgoLegendConverter
