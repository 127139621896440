import {
  BusinessType,
  DataTable,
  Field,
  FieldType,
} from '@galigeo-store/shared-models';
import { Geocode } from './geocode';

export class GeocodeDataGouv extends Geocode {
  private resultReverseColumnsBusinessType = [
    BusinessType.GEOCODER_LATITUDE,
    BusinessType.GEOCODER_LONGITUDE,
    BusinessType.GEOCODER_ADDRESS,
    BusinessType.GEOCODER_ACCURACY,
    BusinessType.GEOCODER_CITY,
    BusinessType.GEOCODER_CITY_CODE,
    BusinessType.GEOCODER_POSTAL_CODE,
  ];
  private resultColumnsBusinessType = [
    BusinessType.GEOCODER_LATITUDE,
    BusinessType.GEOCODER_LONGITUDE,
    BusinessType.GEOCODER_ADDRESS,
    BusinessType.GEOCODER_ACCURACY,
    BusinessType.GEOCODER_CITY,
    BusinessType.GEOCODER_CITY_CODE,
    BusinessType.GEOCODER_POSTAL_CODE,
  ];
  private resultReverseColumns = [
    'y',
    'x',
    'name',
    'score',
    'city',
    'citycode',
    'postcode',
  ];
  public override search(value: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const fields: Field[] = [];
      this.resultReverseColumnsBusinessType.forEach((bType, i) => {
        fields.push({
          name: bType,
          alias: bType,
          type: FieldType.STRING,
          visible: true,
          originDataSort: false,
          businessType: bType,
        });
      });
      const data: any = [];
      fetch(`https://api-adresse.data.gouv.fr/search/?q=${value}&limit=10`)
        .then((response) => response.json())
        .then((responseData) => {
          responseData.features.forEach((f: any) => {
            f.properties.x = f.geometry.coordinates[0];
            f.properties.y = f.geometry.coordinates[1];
            const reverseData: any = {};
            this.resultColumnsBusinessType.forEach((bType: string, i) => {
              reverseData[bType] = f.properties[this.resultReverseColumns[i]];
            });
            data.push(reverseData);
          });
          const dataTableReverse = new DataTable({
            id: 'search',
            fields: fields,
            json: data,
          });
          resolve(dataTableReverse);
        })
        .catch((error) => console.warn(error));
    });
  }
}
