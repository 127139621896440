import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiLightningSharedModule } from '../shared/shared.module';
import { MessageConfirmComponent } from './confirm/message-confirm.component';
import { MessageService } from './message.service';
import { MessageAlertComponent } from './alert/message-alert.component';
import { MessageProgressComponent } from './progress/message-progress.component';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { ModalModule } from '../modal/modal.module';
import { MessageToastComponent } from './toast/message-toast.component';
import { LocaleModule } from '../locale/locale.module';
@NgModule({
  declarations: [
    MessageConfirmComponent, MessageAlertComponent, MessageProgressComponent, MessageToastComponent
  ],
  imports: [
    CommonModule,
    UiLightningSharedModule,
    ProgressBarModule,
    ModalModule,
    LocaleModule
  ],
  exports: [MessageConfirmComponent, LocaleModule],
  providers: [MessageService]
})
export class MessageModule { }
