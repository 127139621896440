import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormOption } from '../model';

@Component({
  selector: 'ui-lightning-form-item',
  templateUrl: './form-item.component.html',
  styleUrls: ['./form-item.component.css']
})
export class FormItemComponent implements OnInit, AfterViewInit {
  @Input() options!: FormOption;
  @Input() form!: FormGroup;
  geojson: any;
  constructor() {
    this.geojson = {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "properties": {
            "CODADM06": "75012",
            "CODADM07": "075-07",
            "ETAT": "OUVERT",
            "M2": 300,
            "FORMAT": "SPORT",
            "ETAT_EN": "OPEN",
            "LOGO": "FOOT LOCKER",
            "R_SOCIALE": "FOOT LOCKER FRANCE",
            "DATE_OUV": "1.4799456E12",
            "CODADM01": "Île-de-France",
            "CODADM02": "75",
            "CODADM03": "75112",
            "CODADM04": "751124807",
            "CODHEXA": "1874_1111",
            "CODADM05": "751114311069",
            "CAISSE": "4.0",
            "STATUT": "INTEGRE",
            "GROUPE": "FOOT LOCKER FRANCE",
            "X": 602523,
            "YDD": 48.852919,
            "Y": 2428287,
            "XDD": 2.370863,
            "LABELMENU": "Sport",
            "logo": "iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAA0VJREFUOI21lD1LK1sUhp/ZmY8kJiaKKDmKTcRCCxtBEBWsRMTCRhQr0cIfYGkrNhYiCKKljUTTWosgJuqprCJM6RjUGA1JHGcyM7e4ZjgeP24hdzX7g72ftffLWq/M/xRyfaLr+i9FURI/gdm2fZtMJg0frOv6L1mWL38K9jzvVtf1/mQyacgAiqIkFEVJtLW14XkeQggcxyEQCPjj20UkSfowd10XIQT5fD4hSVICMOS/MlKr1VBVFcuyCIVC/ghQq9VQFMU/pyiKvy/L71C8X71lBwgGg7y+vqKqKqurq6ysrPhnFhcX2d3dpVQq0djYiG3b/q8+BQshUFWVUqmELMuEw2FM0+T09BSAnp4eJicnubm54fHxkaamJizLQtM0hBBfgx3HwbIsIpEInufx8vJCKBRidHSUi4sLzs7O0DSNtbU1Xl5eaG5uJhAIIITw9f4ULEkSkiThOA6KouA4DgDz8/MYhsHh4SHn5+ckEgna29txXRfP8zBNE1VVv9e4LgnAw8MDGxsbGIbB5eUlmUyGq6srBgYGSKfTjI2NEYlEsG37A+ODxsFgkPv7e3Z2djg9PeXk5IRQKEQ0GiUej9Pa2ko2myWXyzE+Ps729rZfNf/54pmZGTRNo1gsMjIyQiwWo1AoEI/HEUJQKpUoFovkcjlmZ2fZ3t4mGo1+DTYMg4ODA7LZLN3d3fT29mIYBvBv+ZmmiWmaOI5DLBajWq1ydHTExMQEe3t7RCKRz8Gu61KtVqlUKiwsLNDR0cHm5qYvUzQaJZfLcX19zfDwMF1dXUxNTZFKpb6XIhAIEI/HGRwcpFKpMDQ0xNbWFq+vrwghqNVqBINBWlpaKJfLjI2NUSwW6evr87vwU3AikWBpaQld18lkMriuS2NjI+FwmHK5jOd59Pf3UygUeH5+RtM0jo+Pubq6Ynl5GU3TvpbCsizW19exbZtUKsXx8TGaphGJRGhoaODp6QkhBKZpkk6n2d/fx7IsZFnm7u7uc3C93OqyTE9PMzc357vXn07nOA6u6/qm9a3Gfyept2m9Yf70AyHEB3/4ALZt+9bzvNt8Pv8jo3dd9/c7cDKZNHRd738z6R9FZ2fnbx9chwPGT8H1+AeU53T5toTd7AAAAABJRU5ErkJggg==",
            "TYPEPDV": "GSS",
            "ADR1": "12 RUE DU FBG ST ANTOINE",
            "LINKBRAND": "32. FOOT LOCKER",
            "FID": 214,
            "CODADM10": "PARIS 12E",
            "CP": "75012",
            "CHAINE": "FOOT LOCKER FRANCE",
            "PAYS": "FR",

            "CITY": "PARIS",

          },
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.370863,
              48.852919
            ]
          }
        }
      ]
    }
  }
  ngAfterViewInit(): void {
    if (this.options.type === 'geometry') {
      // this.addMap(this.options.name);
    }
  }

  ngOnInit(): void {
  }

  /*addMap(id: string) {
    const map = MapFactory.getMap({ elementId: 'map-' + id, key: "pk.eyJ1IjoiYWljaGFvdWlhIiwiYSI6ImNqa3I3MmtqcjNyN2Eza2t4bGVraGFwemgifQ.ozRWzQt-iFZEvoao3KJ8jA" });
    map.addEventListener('loaded', () => {
        map.addControles({
          polygon: this.options.outputFormat === 'polygon',
          point: this.options.outputFormat === 'point',
          line_string: this.options.outputFormat === 'line_string',
          trash: true
          }, this.geojson)
          map.addEventListener('drawend', (e) => {
            console.log(e);
            this.options.value = e;
            
          });
      });
  }*/


}
