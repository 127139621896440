<fieldset class="slds-form-element">
    <legend class="slds-form-element__legend slds-form-element__label">{{title}}</legend>
    <div class="slds-form-element__control">
      <div class="slds-{{type}}_button-group">
        <ng-template ngFor let-item [ngForOf]="list" let-i="index">
        <span class="slds-button slds-{{type}}_button">
          <input type="{{type}}" id="element-{{item.name}}-{{i}}" value="{{item.value}}" name="{{item.name}}" />
          <label class="slds-{{type}}_button__label" for="element-{{item.name}}-{{i}}">
            <span class="slds-{{type}}_faux">{{item.title}}</span>
          </label>
        </span>
        </ng-template>
      </div>
    </div>
  </fieldset>