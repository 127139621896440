import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageService } from '../../locale/language.service';
import { MessageService } from '../message.service';
import { PROGRESS_TYPE, ProgressOptions } from '@galigeo-store/shared-models';
import { LocaleMessage } from '../../locale/lang-components/message';

@Component({
  selector: 'message-progress',
  templateUrl: './message-progress.component.html',
  styleUrls: ['./message-progress.component.css'],
})
export class MessageProgressComponent implements OnInit {

  public options!: ProgressOptions;
  public progressValue: number = 0;
  public status: PROGRESS_TYPE = PROGRESS_TYPE.STOP;
  public intervalId: NodeJS.Timeout | undefined = undefined;

  constructor(private messageService: MessageService, private languageService: LanguageService) {
    this.setOptions(new ProgressOptions());
    this.languageService.addTranslation('Message', new LocaleMessage().locale);
  }
  ngOnInit(): void {

  }

  setOptions(opts: ProgressOptions) {
    this.options = opts;
    if (!this.options.message) {
      this.options.message = this.languageService.getTranslation('Message', 'please_wait');
    }
    if (this.options.progressType === undefined) this.options.progressType = PROGRESS_TYPE.START;
    if (this.options.progressType === PROGRESS_TYPE.STOP) {
      this.messageService.vrc?.remove();
      this.stopLoop();
      return;
    }
    this.stopLoop();
    if (this.options.progressPercent === undefined) {
      this.startLoop();
    } else {
      this.progressValue = this.options.progressPercent;
    }
    console.log('Update progress', this.options.message, this.progressValue);
  }

  startLoop() {
    console.log('Start loop progress');
    this.progressValue = 0;
    this.intervalId = setInterval(() => {
      this.progressValue += 1;
      if (this.progressValue >= 100) {
        this.progressValue = 0;
      }
    }, 20);
  }

  stopLoop() {
    if (this.intervalId !== undefined) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  }

}
