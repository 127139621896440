import { Renderer } from './Renderer.js';

export class BubbleRenderer implements Renderer {
  public refValue: number;
  public refSize: number;
  public field: string;
  public calculationType!: string;
  public minValue: number;
  public maxValue: number;
  public minRadius: number;
  public maxRadius: number;
  public scaleFactor: number;
  public maxSizeEnabled: boolean;
  public uniqueValueInfos: any[];
  constructor(options: {
    refValue: number;
    refSize: number;
    field: string;
    calculationType?: string;
    minValue?: number;
    maxValue?: number;
    minRadius?: number;
    maxRadius?: number;
    scaleFactor?: number;
    maxSizeEnabled?: boolean;
    uniqueValueInfos?: any[];
  }) {
    this.refValue = options.refValue;
    this.refSize = options.refSize;
    this.field = options.field;
    this.minValue = options.minValue || 0;
    this.maxValue = options.maxValue || 0;
    this.minRadius = options.minRadius || 0;
    this.maxRadius = options.maxRadius || 0;
    this.scaleFactor = options.scaleFactor || 0.5;
    this.maxSizeEnabled = options.maxSizeEnabled || false;
    this.uniqueValueInfos = options.uniqueValueInfos || [];
  }
  recalculate(features: any[]) {
    const maxValue = this.uniqueValueInfos.reduce((max: number, uniqueValueInfo: any) => Math.max(max, uniqueValueInfo.value), 0);
    const minValue = this.uniqueValueInfos.reduce((min: number, uniqueValueInfo: any) => Math.min(min, uniqueValueInfo.value), 0);
    if (this.maxValue === undefined || this.minValue === undefined) {
      this.maxValue = maxValue;
      this.minValue = minValue;
    }
    if (this.maxRadius === undefined || this.minRadius === undefined) {
      this.maxRadius = BubbleRenderer.getRadius(maxValue, this.refValue, this.refSize, this.scaleFactor, this.maxRadius);
      this.minRadius = BubbleRenderer.getRadius(minValue, this.refValue, this.refSize, this.scaleFactor, this.maxRadius);
    }
    features.forEach((feature) => {
      const value = feature.properties[this.field];
      feature.properties[this.field + '_radius'] = BubbleRenderer.getRadius(value, this.refValue, this.refSize, this.scaleFactor, this.maxRadius);
    });
    if (this.refValue === undefined || this.refSize === undefined) {
      const scaleValues = [5, 4, 3, 2, 1, 0.5, 0.33, 0.25, 0.2, 0.16, 0.14, 0.125];
      for (const k in scaleValues) {
        if ((this.maxValue - this.minValue) * scaleValues[k] > 150) {
          this.scaleFactor = scaleValues[k];
        }
      }
      this.refValue = this.maxValue;
      this.refSize = Math.round(100 / this.scaleFactor);
    }
    return features;
  }
  public getCircleRadius(): any {
    return ['get', this.field + '_radius'];
  }
  public static getRadius(value: number, refValue: number, refSize: number, scaleFactor: number, maxRadius?: number) {
    let radius;
    radius = (8 / 15) * Math.sqrt((scaleFactor * Math.abs(value)) / refValue) * refSize;
    if (maxRadius && radius > maxRadius) {
      radius = maxRadius;
    }
    return radius;
  }
}
