<ui-lightning-modal [isOpen]="true" [closeable]="false">
  <ui-lightning-modal-content>
      <div class="slds-p-around_medium">
          <span *ngIf="options.progressPercent && options.progressPercent>0" class="slds-text-heading_medium slds-m-right_medium">{{progressValue}}%</span>
          <span *ngIf="options.message">{{options.message}}</span>
          <ui-lightning-progress-bar [valueNow]="progressValue"></ui-lightning-progress-bar>
      </div>
  </ui-lightning-modal-content>
</ui-lightning-modal>



