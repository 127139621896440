<div class="map-alerts">
    <div class="slds-grid slds-align_absolute-center slds-p-vertical_medium">
      <article class="slds-card resultsContainer">
        <div class="slds-card__header slds-grid geocodage-alert-header">
          <header class="slds-media_center slds-has-flexi-truncate">
            <div class="slds-media__body">
              <h2 class="slds-card__header-title">
                
                  <span class="geocodage-alert-title slds-align_absolute-center">{{'MapAlertsComponent:step_geocodage_alert_title' | translate:'Smart address search' }}</span>
                
              </h2>
             
            </div>
            <div class="slds-no-flex geocodage-alert-close">
              <button  title="close" (click)="closeAlertGeocodage()" class="slds-button slds-button_icon slds-button_icon-inverse" >
                <ui-lightning-icon decoratorClasses="slds-icon_xx-small" [alternativeText]="'close'" iconName="utility:close" size="xx-small"></ui-lightning-icon>
                </button>
                
              </div>
          </header>
        </div>
        <div class="slds-card__body slds-p-horizontal_medium">
            <span style="font-weight: 500;">{{'MapAlertsComponent:step_geocodage_alert_result_label' | translate:'Results' }} : </span>
            <ng-template ngFor let-alert [ngForOf]="alerts" let-i="index">
                <div class="slds-p-vertical_small slds-grid {{(i!== alerts.length -1)? 'alert-step': 'alert-step-last'}}" >
                    <div>
                        <ui-lightning-icon  
                        decoratorClasses="slds-icon-text-{{alert.icon}}" [variant]="alert.icon === 'success' ? 'success' : alert.icon === 'warning' ? 'warning' : alert.icon === 'error' ? 'error' : null"
                        [alternativeText]="'close'" iconName="utility:{{alert.icon!}}" size="small"></ui-lightning-icon>
                       </div>
                       <div class="slds-p-left_small">
                         <span style=" font-size: 20px; display: block;">{{alert.label}}</span>
                         <span class="slds-text-body_small">{{alert.description}}</span>
                       </div>
                </div>
            </ng-template>
           
        </div>
        <footer class="slds-card__footer alert-footer">
          <button class="slds-button slds-button_brand" (click)="closeAlertGeocodage()">{{'MapAlertsComponent:step_geocodage_alert_footer_btn' | translate:'Ok, I got it' }}</button>
            
        </footer>
      </article>
    </div>
  </div>
