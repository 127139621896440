import { DataTable, Field, FormatUtils } from '@galigeo-store/shared-models';
import { Parser } from './parser';
declare var cptable: any;
import * as XLSX from 'xlsx';
XLSX.set_cptable(cptable);


export class XLSXParser implements Parser<DataTable> {
    parse(file: File): Promise<DataTable> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const json: any[] = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false });
                while (json.length > 0 && json[json.length - 1].every((cell: any) => cell === "")) {
                    json.pop();
                }
                let columns = json[0].map((c: any) => ({ name: c, type: 'string', alias: c }));
                columns = Parser.encodeColumnsName(columns);
                const rows = json.slice(1).map((r: any) => {
                    const row: any = {};
                    columns.forEach((c: any, i: number) => row[Parser.replaceEncodedCharacters(c.name)] = Parser.replaceEncodedCharacters(r[i]));
                    return row;
                });
                const resultDt = DataTable.fromJson(rows);
                resultDt.fields.forEach((f: Field) => {
                    const alias = columns.find((c: any) => c.name === f.name).alias;
                    f.alias = Parser.replaceEncodedCharacters(alias);
                    f.originalName = f.alias;
                    f.name = FormatUtils.encodedFieldNames(f.alias);
                });
                resolve(resultDt);
            };
            reader.readAsArrayBuffer(file);
        });
    }


}