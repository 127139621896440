import { Locale } from '../components';

export class LocaleNetworkComponent extends Locale {
  constructor() {
    super();
    this.languages = {
      en: {
        upload_csv_label: 'Import a file',
        upload_logo_label: 'Import a logo',
        drop_zone_csv_text: 'or drag a file here',
        drop_zone_logo_text: 'or drag a file here',
        step_network_name_title: 'Rename the network',
        step_network_name_label: 'Rename the network',
        step_network_name_description: 'Please, enter a name for the network',
        step_network_name_btn_cancel_label: 'Cancel',
        step_network_name_btn_previous_label: 'Previous',
        step_network_name_btn_next_label: 'Next',
        step_network_name_btn_finish_label: 'Finish',
        step_add_logo_title: 'Chang the logo',
        step_add_logo_label: 'Change the logo',
        step_add_logo_description: 'Files only of image type (jpg, png)',
        step_add_logo_btn_cancel_label: 'Cancel',
        step_add_logo_btn_previous_label: 'Previous',
        step_add_logo_btn_next_label: 'Next',
        step_add_logo_btn_finish_label: 'Finish',
        step_add_file_title: 'Import your stores',
        step_add_file_label: 'Import your stores',
        step_add_file_description:
          'Drag and drop your point of sales file, only CSV',
        step_add_file_btn_cancel_label: 'Cancel',
        step_add_file_btn_previous_label: 'Previous',
        step_add_file_btn_next_label: 'Next',
        step_add_file_btn_finish_label: 'Finish',
        step_select_columns_title: 'Identify your columns',
        step_select_columns_label: 'Identify your columns',
        step_select_columns_description:
          'Select the corresponding value for the columns',
        step_select_columns_btn_cancel_label: 'Cancel',
        step_select_columns_btn_previous_label: 'Previous',
        step_select_columns_btn_next_label: 'Next',
        step_select_columns_btn_finish_label: 'Finish',
        step_geocodage_title: 'Check locations',
        step_geocodage_label: 'Check locations',
        step_geocodage_description:
          'Check the locations of addresses not found',
        step_geocodage_btn_cancel_label: 'Cancel',
        step_geocodage_btn_previous_label: 'Previous',
        step_geocodage_btn_next_label: 'Next',
        step_geocodage_btn_finish_label: 'Finish',
        step_geocodage_alert_succes_label: 'Addresses found',
        step_geocodage_alert_succes_description: '100% accurately positioned',
        step_geocodage_alert_warning_label: 'Addresses to validate',
        step_geocodage_alert_warning_description:
          'Positioned in the centre of the Iris, the POi or the Commune',
        step_geocodage_alert_error_label: 'Addresses not found',
        step_geocodage_alert_error_description:
          'Addresses not found, please correct locations en',
        step_network_name_text_placeholder:
          'E.g.: Stores, Franchises, Own network ',
        step_add_logo_btn_delete_label: 'Deleete',
        step_add_file_success: 'Your file was successfully imported',
        step_add_file_btn_delete_label: 'Delete the file',
        step_add_file_view_file: 'Preview of the file',
        step_geocodage_alert_result_label: 'Result',
        step_geocodage_alert_footer_btn: 'OK',
        step_geocodage_alert_title: 'Address search',
        step_geocodage_loading: 'Please wait while your network loads',
        step_select_columns_col_file: 'File Columns',
        step_select_columns_col_values: 'Corresponding Values',
        step_select_columns_choise_field: 'Select a value',
        add_network: 'New network',
        already_exist: 'Please do not reuse an existing name',
        exceed_name: 'Please do not exceed 30 characters',
        add: 'Add point',
        delete: 'Delete',
        search_placeholder: 'Search',
        network_confirm_pois_delete:
          'Are you sure you want to delete the selected point(s) of sale from this network?',
        rename: 'Rename',
        duplicate: 'Duplicate',
        duplicate_network_confirm:
          'Are you sure you want to duplicate the network ',
        delete_network: 'Delete network',
        delete_network_confirm: 'Are you sure you want to delete the network',
        update_logo: 'Update logo',
        identify_fields: 'Identify fields',
        update_network: 'Update network',
        toast_poi_updated: 'Point of sale updated',
        confirm_change_adress_title: 'Change of address',
        confirm_change_adress_text:
          ' You have changed the address or the postal code or the city, if you wish to change map coordinates, click on "ok"',
        toast_poi_coordinates_updated: 'Point of sale coordinates updated',
        created_by: 'Created by',
        import_logo_title: 'Upload a logo',
        edit: 'Edit',
        column_status: 'Status',
        global_spinner_message: 'Loading Network...',
        global_spinner_message_duplicate: 'Duplicating Network...',
        global_spinner_message_delete: 'Deleting Network...',
        delete_network_success: 'The network has been successfully deleted',
        step_geocodage_alert_error_label_from_wizard: 'Not found addresses',
        step_geocodage_alert_warning_label_from_wizard: 'Addresses to validate',
        step_geocodage_alert_succes_label_from_wizard: 'Found addresses',
        step_geocodage_alert_error_description_from_wizard:
        'Addresses not found, please correct locations',
        step_geocodage_alert_warning_description_from_wizard:
        'Positioned in the center of the Iris, the POi or the Commune',
        step_geocodage_alert_succes_description_from_wizard:
          '100% accurately positioned',
        tooltip_menu: 'Network options',
        tooltip_dropdown: 'Network',
        no_geom_warning_modal_title: 'Important information',
        no_geom_warning_modal_text: 'Unfound addresses do not display on the map,',
        no_geom_warning_modal_text_2: ' re-edit the addresses to view them.',
        no_geom_warning_modal_btn: 'Ok, understood',
        update_modal_title: 'Confirm modification',
        update_modal_msg: 'Do you want to apply the change to the selected lines?',
        last_updated_by: 'Updated by',
        last_updated_at: 'Updated date',
        no_geom_warning_modal_checkbox: 'Do not show again',
        logo_column: 'Logo',
        cancel_label: 'No',
        confirm_label: 'Yes',
        status_success: 'Success',
        status_to_check: 'To check',
        status_failed: 'Failed',
        inconsistencies_message: 'Invalid data',
        null_identification_message: 'The unique identifier column contains ($value) empty cell(s) \n',
        doublons_message: 'The identifier column contains ($value) duplicate(s) \n',
        toast_doublon_id: 'The identifier column does not accept duplicates',
        coordinates_message: 'One or more coordinates are not in the expected format (WGS84)',
        city_postal_code_message: 'Your file does not contain a Postal Code or/and City field, this may affect the geocoding of your points.',
        alert_inconsistencies_changes_error: 'Please correct the file information and try again',
        alert_inconsistencies_changes_warning: 'Do you want to continue ?',
        limit_message: 'The limit of lines or columns of your file has been reached. The limit is $value for lines',
        limit_message_column: 'and it is $val for columns',
        export: 'Export',
      },
      fr: {
        upload_csv_label: 'Importer un fichier',
        upload_logo_label: 'Importer un logo',
        drop_zone_csv_text: 'ou Glissez un fichier ici',
        drop_zone_logo_text: 'ou Glissez un fichier ici',
        step_network_name_title: 'Renommer le réseau',
        step_network_name_label: 'Renommer le réseau',
        step_network_name_description: 'Veuillez entrer un nom pour le réseau',
        step_network_name_btn_cancel_label: 'Annuler',
        step_network_name_btn_previous_label: 'Précédent',
        step_network_name_btn_next_label: 'Suivant',
        step_network_name_btn_finish_label: 'Terminer',
        step_add_logo_title: 'Changer le logo',
        step_add_logo_label: 'Changer le logo',
        step_add_logo_description:
          'Fichiers format image uniquement (jpg, png)',
        step_add_logo_btn_cancel_label: 'Annuler',
        step_add_logo_btn_previous_label: 'Précédent',
        step_add_logo_btn_next_label: 'Suivant',
        step_add_logo_btn_finish_label: 'Terminer',
        step_add_file_title: 'Importez vos magasins',
        step_add_file_label: 'Importez vos magasins',
        step_add_file_description:
          'Glissez votre fichier de point de ventes, CSV uniquement',
        step_add_file_btn_cancel_label: 'Annuler',
        step_add_file_btn_previous_label: 'Précédent',
        step_add_file_btn_next_label: 'Suivant',
        step_add_file_btn_finish_label: 'Terminer',
        step_select_columns_title: 'Identifiez vos colonnes',
        step_select_columns_label: 'Identifiez vos colonnes',
        step_select_columns_description:
          'Sélectionnez la valeur correspondante pour les colonnes',
        step_select_columns_btn_cancel_label: 'Annuler',
        step_select_columns_btn_previous_label: 'Précédent',
        step_select_columns_btn_next_label: 'Suivant',
        step_select_columns_btn_finish_label: 'Terminer',
        step_geocodage_title: 'Vérifiez les emplacements',
        step_geocodage_label: 'Valider les emplacements',
        step_geocodage_description:
          'Vérifiez les emplacements des adresses non trouvées',
        step_geocodage_btn_cancel_label: 'Annuler',
        step_geocodage_btn_previous_label: 'Précédent',
        step_geocodage_btn_next_label: 'Suivant',
        step_geocodage_btn_finish_label: 'Terminer',
        step_geocodage_alert_succes_label: 'Adresses trouvées',
        step_geocodage_alert_succes_description:
          'Positionnées avec précision à 100%',
        step_geocodage_alert_warning_label: 'Adresses à valider',
        step_geocodage_alert_warning_description:
          'Positionnées au centre de l’Iris, du POi ou de la Commune',
        step_geocodage_alert_error_label: 'Adresses non trouvées',
        step_geocodage_alert_error_description:
          'Adresses introuvables, veuillez corrigez les emplacements',
        step_network_name_text_placeholder:
          'Ex: Magasins, Franchises, Réseau propre',
        step_add_logo_btn_delete_label: 'Supprimer',
        step_add_file_success: 'Votre fichier a été importé avec succès',
        step_add_file_btn_delete_label: 'Supprimer le fichier',
        step_add_file_view_file: 'Aperçu du fichier',
        step_geocodage_alert_result_label: 'Résultat',
        step_geocodage_alert_footer_btn: 'Ok',
        step_geocodage_alert_title: 'Recherche d’adresse',
        step_geocodage_loading:
          'Veuillez patienter pendant le chargement de votre réseau',
        step_select_columns_col_file: 'Colonne du fichier',
        step_select_columns_col_values: 'Valeurs correspondantes',
        step_select_columns_choise_field: 'Sélectionner une valeur',
        add_network: 'Nouveau réseau',
        already_exist: 'Veuillez ne pas réutiliser un nom existant',
        exceed_name: 'Veuillez ne pas dépasser 30 caractères maximum',
        add: 'Ajouter un point',
        delete: 'Supprimer',
        search_placeholder: 'Rechercher',
        network_confirm_pois_delete:
          'Êtes-vous sûr de vouloir supprimer le(s) point(s) sélectionné(s) de ce réseau ?',
        rename: 'Renommer',
        duplicate: 'Dupliquer',
        duplicate_network_confirm:
          'Êtes-vous sûr de vouloir dupliquer le réseau ',
        delete_network: 'Supprimer',
        delete_network_confirm: 'Êtes-vous sûr de vouloir supprimer le réseau ',
        update_logo: 'Mettre à jour le logo',
        identify_fields: 'Identifier les colonnes',
        update_network: 'Mettre à jour le réseau',
        toast_poi_updated: 'Point de vente mis à jour',
        confirm_change_adress_title: 'Changement d’adresse',
        confirm_change_adress_text:
          'Vous avez changé l’adresse ou le code postal ou la ville, si vous souhaitez modifier les coordonnées sur la carte, cliquez sur « ok »',
        toast_poi_coordinates_updated:
          'Coordonnées du point de vente mises à jour',
        created_by: 'Créé par',
        import_logo_title: 'Importer un logo',
        edit: 'Modifier',
        column_status: 'Statut',
        global_spinner_message: 'Chargement du réseau...',
        global_spinner_message_duplicate: 'Duplication du réseau...',
        global_spinner_message_delete: 'Suppression du réseau...',
        delete_network_success: 'Le réseau a été supprimé avec succès',
        step_geocodage_alert_error_label_from_wizard: 'Adresses non trouvées',
        step_geocodage_alert_warning_label_from_wizard: 'Adresses à valider',
        step_geocodage_alert_succes_label_from_wizard: 'Adresses trouvées',
        step_geocodage_alert_error_description_from_wizard:
        'Adresses introuvables, veuillez corrigez les emplacements',
        step_geocodage_alert_warning_description_from_wizard:
        'Positionnées au centre de l’Iris, du POi ou de la Commune',
        step_geocodage_alert_succes_description_from_wizard:
        'Positionnées avec précision à 100%',
        tooltip_menu: 'Option du réseau',
        tooltip_dropdown: 'Réseau',
        no_geom_warning_modal_title: 'Information importante',
        no_geom_warning_modal_text: 'Les adresses introuvables ne s’affichent pas sur la carte,',
        no_geom_warning_modal_text_2: ' rééditez les adresses pour les visualiser.',
        no_geom_warning_modal_btn: 'Ok, j’ai compris',
        update_modal_title: 'Confirmer la modification',
        update_modal_msg: 'Voulez-vous appliquer le changement aux lignes sélectionnées ?',
        last_updated_by: 'MAJ par',
        last_updated_at: 'Date de MAJ',
        no_geom_warning_modal_checkbox: 'Ne plus afficher',
        logo_column: 'Logo',
        cancel_label: 'Non',
        confirm_label: 'Oui',
        status_success: 'Succes',
        status_to_check: 'A verifier',
        status_failed: 'Echec',
        inconsistencies_message: 'Données invalides',
        null_identification_message : 'La colonne des identifiants uniques contient ($value) cellule(s) vide(s)',
        doublons_message: 'La colonne des identifiants contient ($value) doublon(s) \n ',
        toast_doublon_id: 'La colonne des identifiants n\'accepte pas les doublons',
        coordinates_message: 'Un ou plusieurs coordonnées ne sont pas au format attendu (WGS84)',
        city_postal_code_message: 'Votre fichier ne contient pas de champ Code Postal ou/et Ville, cela risque d\'affecter le géocodage de vos points.',
        
        alert_inconsistencies_changes_error: 'Veuillez corriger les informations du fichier et recommencer',
        alert_inconsistencies_changes_warning: 'Voulez-vous continuer ?',
        limit_message: 'La limite de lignes ou de colonnes de votre fichier a été atteinte. La limite est de $value pour les lignes',
        limit_message_column: 'et elle est de $val pour les colonnes',
        export: 'Exporter',
      },
    };
    this.genrateMap();
  }
}
