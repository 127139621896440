import { Field } from "@galigeo-store/shared-models";

export interface TableOptions {
     withBorders?: boolean;
     canEdit?: boolean;
     resizable?: boolean;
     fixed?: boolean;
     width?: string;
     multiSelect?: boolean;
     graphicColumns?: GraphicColumn[];
     pageSize?: number;
     height?: string;
     datePickerLang?: object;
}

export enum GraphicColumnType {
     CHECKBOX = 'checkbox',
     TEXT = 'text',
     NUMBER = 'number',
     DATE = 'date',
     SELECT = 'select',
     MULTISELECT = 'multiselect',
     LINK = 'link',
     IMAGE = 'image',
     BUTTON = 'button',
     ICON = 'icon',
     RADIOBOX = 'radiobox',
     TOGGLE = 'toggle',
     HTML = 'html',
}

export interface GraphicColumn {
     field: Field;
     type: GraphicColumnType;
     defaultValue?: any;
     values?: any[];
}

export enum TableEvent {
     ROW_SELECTED = 'rowSelected',
     ROW_CLICKED = 'rowClicked',
     CELL_EDIT = 'cellEdit',
     START_EDIT_CELL = 'startEditCell',
     REFRESH = 'refresh',
     SEARCH = 'search',
     HIGHLIGHT = 'highlight',
     EDIT_IMAGE = 'editImage',
     EDIT_ADDRESS = 'editAddress'
}