import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalBase } from './model/modal-base';

@Component({
  selector: 'ui-lightning-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent extends ModalBase implements OnInit {

  @Input() isOpen: boolean = false;
  @Input() closeLabel: string = 'Close';
  @Input() closeable: boolean = true;
  @Output() onClose = new EventEmitter<any>();
  constructor() {
    super();
  }

  ngOnInit(): void {
  }
  override openModal() {
    this.isOpen = true;
  }
  override closeModal(): void {
    this.isOpen = false;
    this.onClose.emit({ close: true });
  }
  close() {
    this.closeModal();
  }


}
