import { BubbleRenderer } from '../renderers/Bubble.js';
import { ClassItem } from '../model/ClassItem.js';
import { Matches } from '../model/Matches.js';
import { Ranges } from '../model/Ranges.js';
import { LabelRenderer } from '../renderers/Label.js';
import { Cluster, GeometryType, PieChart, Style, StyleOptions } from '../model/StyleOptions.js';
import { Utils } from '../utils/Utils.js';
import { Renderer } from '../renderers/Renderer.js';

export abstract class GgoStyleConverter {
  static getStyleOption(geometryType: string, drawingInfo: any, layerId: string): StyleOptions {
    let style: StyleOptions;
    switch (geometryType) {
      case 'esriGeometryPolygon':
        {
          style = new StyleOptions({ geometryType: GeometryType.Polygon });
          style.type = Style.Fill;
        }
        break;
      case 'esriGeometryPoint':
        {
          style = new StyleOptions({ geometryType: GeometryType.Point });
          style.type = Style.Circle;
        }
        break;
      case 'esriGeometryPolyline':
        {
          style = new StyleOptions({ geometryType: GeometryType.LineString });
          style.type = Style.Line;
        }
        break;

      default:
        throw new Error('Unknown geometry type ' + geometryType);
        break;
    }

    const ggoRenderer: any = drawingInfo.renderer;
    if (ggoRenderer.classBreakInfos) {
      style.ranges = new Ranges(ggoRenderer.field, this.generateRangesClassItem(ggoRenderer));
      style.type = this.getSymbolStyle(ggoRenderer.classBreakInfos[0]?.symbol);
      if (
        ggoRenderer &&
        ggoRenderer.classBreakInfos &&
        ggoRenderer.classBreakInfos[0] &&
        ggoRenderer.classBreakInfos[0].symbol &&
        ggoRenderer.classBreakInfos[0].symbol.outline
      ) {
        style.width = ggoRenderer.classBreakInfos[0].symbol.width;
        style.lineColor = Utils.getColor(ggoRenderer.classBreakInfos[0].symbol.outline.color);
        style.lineWidth = ggoRenderer.classBreakInfos[0].symbol.outline.width;
      }
    } else if (ggoRenderer.refValue && ggoRenderer.refSize) {
      style.bubble = new BubbleRenderer(ggoRenderer);
      style.fillColor = Utils.getColor(ggoRenderer.symbol.color);
    } else if (drawingInfo.thematicType === 'PieChartThematic') {
      style.type = Style.Circle;
      style.piechart = new PieChart({ fields: ['not implemented'] });
    } else if (ggoRenderer.uniqueValueInfos) {
      style.matches = new Matches(ggoRenderer.field1, this.generateMatchesClassItem(ggoRenderer));

      style.type = this.getSymbolStyle(ggoRenderer.uniqueValueInfos[0]?.symbol);

      if (ggoRenderer.uniqueValueInfos[0]?.symbol?.outline) {
        style.width = ggoRenderer.uniqueValueInfos[0].symbol.width;
        style.lineColor = Utils.getColor(ggoRenderer.uniqueValueInfos[0].symbol.outline.color);
        style.lineWidth = ggoRenderer.uniqueValueInfos[0].symbol.outline.width;
      }
      if (ggoRenderer.uniqueValueInfos[0]?.symbol?.type === 'esriPMS') {
        // reduce the default icon size
        style.width = 0.5;
      }
    } else if (ggoRenderer.symbol) {
      style.fillColor = Utils.getColor(ggoRenderer.symbol?.color);
      style.width = ggoRenderer.symbol?.width;
      style.lineColor = Utils.getColor(ggoRenderer.symbol?.outline?.color);
      style.lineWidth = ggoRenderer.symbol?.outline?.width;
      if (ggoRenderer.symbol.imageData) {
        style.type = Style.Symbol;
        style.imageName = layerId + '-image';
        style.images = [{ name: style.imageName, url: 'data:image/png;base64,' + ggoRenderer.symbol.imageData }];
      }
    } else {
      throw new Error('Unsupported renderer');
    }

    if (drawingInfo.labelingInfo && drawingInfo.labelingInfo.length > 0 && drawingInfo.labelingInfo[0].defaultVisibility) {
      style.label = new LabelRenderer({
        text: drawingInfo.labelingInfo[0].symbol.text,
        size: drawingInfo.labelingInfo[0].symbol.size,
        fields: drawingInfo.labelingInfo[0].fields,
        backgroundColor: drawingInfo.labelingInfo[0].symbol.backgroundColor
          ? Utils.getColor(drawingInfo.labelingInfo[0].symbol.backgroundColor)
          : undefined,
        textColor: Utils.getColor(drawingInfo.labelingInfo[0].symbol.color),
      });
    }
    if (drawingInfo.state === 'cluster') {
      const clusterOptions = { clusterMaxZoom: 15 };
      style.cluster = new Cluster(clusterOptions);
    }
    if (drawingInfo.state === 'heatmap') {
      console.log('heatmap', drawingInfo);
      console.log('heatmap', this.getGradient(drawingInfo.renderer));
      const heatmapRadius = 25;

      let heatmapGradient = this.getGradient(drawingInfo.renderer);
      if (!heatmapGradient) {
        heatmapGradient = [
          0.4,
          'rgba(0, 0, 255, 1)',
          0.5,
          'rgba(0, 255, 255, 1)',
          0.6,
          'rgba(50, 205, 50, 1)',
          0.8,
          'rgba(255, 234, 0, 1)',
          0.9,
          'rgba(255, 0, 0, 1)',
        ];
      }

      style.heatmap = {
        field: drawingInfo.renderer?.field,
        radius: heatmapRadius,
        colors: heatmapGradient,
      };
    }
    return style;
  }
  static getGradient(renderer: any): any[] {
    if (renderer.type === 'proportionalSymbols') {
      return [0.1, renderer.symbolNegative.color, 0.5, 'white', 1, renderer.symbol.color];
    }
    if (renderer.type === 'classBreaks') {
      const numClasses = renderer.classBreakInfos.length;
      let middleClass = Math.round(numClasses / 2);
      if (middleClass == numClasses - 1 && numClasses >= 3) middleClass = numClasses - 2;
      const gradient = [];
      gradient.push(0.4);
      gradient.push(renderer.classBreakInfos[0].symbol.color);
      gradient.push(0.65);
      gradient.push(renderer.classBreakInfos[middleClass].symbol.color);
      gradient.push(1);
      gradient.push(renderer.classBreakInfos[numClasses - 1].symbol.color);

      return gradient;
    }

    throw new Error('Unsupported renderer type for gradient');
  }
  static generateMatchesClassItem(ggoRenderer: any): ClassItem[] {
    const items: ClassItem[] = [];
    if (ggoRenderer && ggoRenderer.uniqueValueInfos) {
      (<any[]>ggoRenderer.uniqueValueInfos).forEach((uv) => {
        items.push({
          label: uv.label,
          value: uv.value,
          color: Utils.getColor(uv.symbol.color),
        });
      });
    }
    return items;
  }
  static generateRangesClassItem(ggoRenderer: any): ClassItem[] {
    const items: ClassItem[] = [];
    if (ggoRenderer && ggoRenderer.classBreakInfos) {
      (<any[]>ggoRenderer.classBreakInfos).forEach((cb) => {
        items.push({
          label: cb.label,
          value: cb.classMaxValue,
          color: Utils.getColor(cb.symbol.color),
        });
      });
    }
    return items;
  }
  private static getSymbolStyle(ggoSymbol: any) {
    if (ggoSymbol?.imageData) return Style.Symbol;
    return Style.Fill;
  }
}
