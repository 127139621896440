import { LayerOptions } from '../model/LayerOptions.js';
import { GeometryType, StyleOptions } from '../model/StyleOptions.js';
import { MapboxStyle } from '../style/MapboxStyle.js';
import { StyleFactory } from '../style/StyleFactory.js';
import { MapBoxLayer } from './MapBoxLayer.js';

export class SimpleLayer extends MapBoxLayer {
  constructor(map: maplibregl.Map, options: LayerOptions, data?: any) {
    super(map, options, data);
  }

  public addInstances(): any[] {
    const style: MapboxStyle = StyleFactory.getStyle(this.options.style);

    const layers: any[] = [];

    if (this.options.style?.cluster) {
      const cluster: any = {
        id: this.getId() + '-clusters',
        type: 'circle',
        source: this.getId() + '-source',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#1B96FF',
          'circle-radius': ['step', ['get', 'point_count'], 20, 20, 30, 100, 40],
        },
      };
      if (this.options.style?.cluster.clusterMaxZoom) {
        cluster.maxzoom = this.options.style?.cluster.clusterMaxZoom;
      }
      if (this.options.minzoom) {
        cluster.minzoom = this.options.minzoom;
      }
      layers.push(cluster);
      const clusterCount: any = {
        id: this.getId() + '-cluster-count',
        type: 'symbol',
        source: this.getId() + '-source',
        filter: ['has', 'point_count'],
        paint: {
          'text-color': '#EFEFEF',
        },
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Noto Sans Regular'],
          'text-size': 12,
        },
      };
      if (this.options.maxzoom) {
        clusterCount.maxzoom = this.options.maxzoom;
      }
      if (this.options.minzoom) {
        clusterCount.minzoom = this.options.minzoom;
      }
      layers.push(clusterCount);
    }

    layers.push(this.addInstance('', style));

    if (this.options.style.geometryType === GeometryType.Polygon) {
      const outlineStyleOption: StyleOptions = new StyleOptions({
        lineColor: this.options.style.lineColor,
        lineWidth: this.options.style.lineWidth,
        lineStyle: this.options.style.lineStyle,
        geometryType: GeometryType.LineString,
      });

      const outlineStyle: MapboxStyle = StyleFactory.getStyle(outlineStyleOption);
      layers.push(this.addInstance('-outline', outlineStyle));
    }
    if (this.options.style.label) {
      this.addLabelSource(this.data);
      const labelStyle: MapboxStyle = StyleFactory.getLabelStyle(this.options.style);

      const labelInstanceLayer = this.addInstance('-label', labelStyle);
      if (this.options.maxzoom) {
        labelInstanceLayer.maxzoom = this.options.maxzoom;
      }
      if (this.options.minzoom) {
        labelInstanceLayer.minzoom = this.options.minzoom;
      }
      layers.push(labelInstanceLayer);
    }

    return layers;
  }
}
