import * as maplibregl from 'maplibre-gl';
import { MapBoxLayer } from '../layer/MapBoxLayer.js';

export class PopupUtils {
  public static currentPopup: maplibregl.Popup | undefined;

  public static registerPopup(map: maplibregl.Map, layer: MapBoxLayer): void {
    console.log('registerPopup', layer.getId());
    const onClick = (e: any) => {
      if (PopupUtils.currentPopup) {
        PopupUtils.currentPopup.remove();
        PopupUtils.currentPopup = undefined;
      }

      // Copy coordinates array.
      const description = PopupUtils.getContent(e.features[0].properties, layer.getPopupContent());
      const coordinates = e.features[0].geometry.coordinates.slice();
      if (e.features[0].geometry.type !== 'Point') {
        coordinates[0] = e.lngLat.lng;
        coordinates[1] = e.lngLat.lat;
      }
      const fLngLat = new maplibregl.LngLat(coordinates[0], coordinates[1]);
      PopupUtils.currentPopup = new maplibregl.Popup().setLngLat(fLngLat).setHTML(description).addTo(map);
      if (layer.getOptions().style.popupOffset) {
        PopupUtils.currentPopup.setOffset(layer.getOptions().style.popupOffset);
      }
    };

    const onMouseEnter = () => {
      map.getCanvas().style.cursor = 'pointer';
    };

    const onMouseLeave = () => {
      map.getCanvas().style.cursor = '';
    };

    map.on('click', layer.getId(), onClick);
    map.on('mouseenter', layer.getId(), onMouseEnter);
    map.on('mouseleave', layer.getId(), onMouseLeave);

    // Store the event handlers so they can be removed later
    (layer as any)._onClick = onClick;
    (layer as any)._onMouseEnter = onMouseEnter;
    (layer as any)._onMouseLeave = onMouseLeave;
  }

  public static unregisterPopup(map: maplibregl.Map, layer: MapBoxLayer): void {
    console.log('unregisterPopup', layer.getId());
    const onClick = (layer as any)._onClick;
    const onMouseEnter = (layer as any)._onMouseEnter;
    const onMouseLeave = (layer as any)._onMouseLeave;

    if (onClick) {
      map.off('click', layer.getId(), onClick);
    }
    if (onMouseEnter) {
      map.off('mouseenter', layer.getId(), onMouseEnter);
    }
    if (onMouseLeave) {
      map.off('mouseleave', layer.getId(), onMouseLeave);
    }
  }

  /**
   * FIXME : don't use a singleton here!
   */
  public static closePopup() {
    if (PopupUtils.currentPopup && PopupUtils.currentPopup.isOpen()) {
      PopupUtils.currentPopup.remove();
      PopupUtils.currentPopup = undefined;
    }
  }

  private static formatValue(value: any): string {
    if (value === null || value === undefined) {
      return '';
    } else if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'number') {
      return Intl.NumberFormat().format(value);
    } else if (typeof value === 'object') {
      return JSON.stringify(value);
    } else {
      return value;
    }
  }

  public static getContent(props: any, template?: string) {
    let content = '';
    if (template) {
      content = template;
      for (const i in props) {
        content = content.replaceAll(`{{${i}}}`, this.formatValue(props[i]));
      }
    } else {
      content = '<table>';
      for (const i in props) {
        content += `<tr><td><b>${i}</b></td><td>${props[i]}</td></tr>`;
      }
      content += '</table>';
    }

    return content;
  }
}
