<div class="slds-notify slds-notify_alert {{sldsTheme}} {{position}}"  role="alert" [ngStyle]="{'max-width': maxwidth}">
  <span class="slds-assistive-text">{{options.title}}</span>
  <span class="slds-icon_container slds-icon-utility-error slds-m-right_x-small" title="Description of icon when needed">
    <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#info"></use>
    </svg>
  </span>
  <h2>{{options.message}}.</h2>
  <div class="slds-notify__close">
    <button (click)="onClickOk()" class="slds-button slds-button_icon slds-button_icon-small slds-button_icon-inverse" title="Close">
      <svg class="slds-button__icon" aria-hidden="true">
        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
      </svg>
      <span class="slds-assistive-text">Close</span>
    </button>
  </div>
</div>