import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperComponent } from './stepper.component';
import { StepperCardComponent } from './stepper-card/stepper-card.component';
import { IconModule } from '../icon/icon.module';



@NgModule({
  declarations: [
    StepperComponent,
    StepperCardComponent
  ],
  imports: [
    CommonModule,
    IconModule
  ],
  exports: [StepperComponent,
    StepperCardComponent]
})
export class StepperModule { }
