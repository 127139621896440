import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AceComponent, AceConfigInterface } from 'ngx-ace-wrapper';
import 'brace/mode/javascript';
import 'brace/mode/html';
import 'brace/mode/css';
import 'brace/theme/monokai';
import 'brace/theme/github';
@Component({
  selector: 'ui-lightning-code-viewer',
  templateUrl: './code-viewer.component.html',
  styleUrls: ['./code-viewer.component.css'],
})
/**
 * CodeViewerComponent used to view code in the web page
 */
export class CodeViewerComponent implements OnInit {
  /**
   * inject ace component
   */
  @ViewChild(AceComponent, { static: true }) componentRef?: AceComponent;
  /**
   * input options by parent
   */
  @Input() options: any;
  /**
   * config for script tab using javascript mode and monokai theme and readeOnly
   */
  public configJavascript: AceConfigInterface = {
    mode: 'javascript',
    theme: 'monokai',
    readOnly: true,
  };
  /**
   * config for html tab using html mode and github theme and readeOnly
   */
  public configHtml: AceConfigInterface = {
    mode: 'html',
    theme: 'github',
    readOnly: true,
  };
  /**
   * config for css tab using css mode and github theme and readeOnly
   */
  public configCss: AceConfigInterface = {
    mode: 'css',
    theme: 'github',
    readOnly: true,
  };
  constructor() {
    /**
     * default settings of Component, Script is open by default
     */
    this.options = {
      html: { value: '<html></html>', open: false },
      script: { value: 'const a: any = 0;', open: true },
      css: { value: '.map {position: relative;}', open: false },
    };
  }
  openTab(tag: string) {
    if (this.options.html) {
      this.options.html.open = false;
    }
    if (this.options.script) {
      this.options.script.open = false;
    }
    if (this.options.css) {
      this.options.css.open = false;
    }
    if (this.options[tag]) {
      this.options[tag].open = true;
    }
  }
  ngOnInit(): void {}
}
