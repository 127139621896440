import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavBarOptions, NavabarItemPosition } from './model';

@Component({
  selector: 'ui-lightning-global-nav',
  templateUrl: './global-nav.component.html',
  styleUrls: ['./global-nav.component.css'],
})
export class GlobalNavComponent implements OnInit {
  @Input() options!: NavBarOptions;
  @Output() onChange = new EventEmitter<any>();
  constructor() {

  }

  ngOnInit(): void {

  }
  itemClicked(value: any) {
    console.log(value);
    this.onChange.emit(value);

  }

}
