export class LookupOptions {
    label?: string;
    debounceTime?: number;
    maxLines?: number;
    placeholder?: string;
    icon?: string;
    showCancel?: boolean;
    inputHeight?: string;
    showAddressOnly?: boolean;
    constructor(options : LookupOptions) {
        Object.assign(this, options);
    }
}
export class LookupItem {
    value: any;
    label?: string;
    icon?: string;
    constructor(options : LookupItem) {
        Object.assign(this, options);
    }
}