<div class="slds-form">
    <form *ngIf="generatedForm" [formGroup]="generatedForm" novalidate>
        <ng-container [ngSwitch]="containerType">
        
            <div class="slds-tabs_scoped"
            *ngSwitchCase="'tabs'" >
                <ul class="slds-tabs_scoped__nav" role="tablist">
                <ng-template ngFor let-step [ngForOf]="options" let-i="index">
                    <li style="min-width: 120px;"
                    class="slds-tabs_scoped__item {{ step.open ? 'slds-is-active' : '' }}"
                    title="{{ step.title }}"
                    role="presentation"
                    >
                    <a
                        (click)="activateTab(step)"
                        class="slds-tabs_scoped__link"
                        role="tab"
                        attr.tabindex="{{ i }}"
                        attr[aria-selected]="true"
                        attr.aria-controls="step-{{ i }}"
                        id="tab-scoped-step-{{ i }}"
                        >{{ step.title }}</a
                    >
                    
                    <div class="circle" attr.data-progress="{{getProgress(step)}}%" [ngStyle]="{'--progress':(getProgress(step) + '%')}">{{getProgress(step)}}%</div>
                </li>
                   
                </ng-template>
                <li *ngIf="mode === 'edit'" class="slds-tabs_scoped__item" (click)="addStep()">
                    <span class="slds-icon_container slds-icon-utility-new slds-current-color" title="add step" style="
                    margin: 10px;
                ">
                        <svg class="slds-icon slds-icon_xx-small" aria-hidden="true">
                          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#new"></use>
                        </svg>
                        <span class="slds-assistive-text">add step</span>
                      </span>
                </li>
                </ul>
            </div>
            <ng-template ngFor let-step [ngForOf]="options" let-i="index">
                <div style="position: relative;"
                id="tab-scoped-step-{{ i }}"
                class="slds-tabs_scoped__content {{ step.open ? 'slds-show' : 'slds-hide' }}"
                role="tabpanel"
                attr.aria-labelledby="tab-scoped-step-{{ i }}"
                >
                <h3 id="other-element-with-table-label" class="slds-text-title slds-m-bottom_small">
                    {{ step.text }}
                </h3>
                <div *ngIf="mode === 'edit'"  style="position: absolute; top: 10px;right: 10px;">
                    <button class="slds-button slds-button_neutral" (click)="initFormItem(step)">add form item</button>
                    </div>
                <ng-template ngFor let-option [ngForOf]="step.formOptions"  let-l="index">
                    <div class="slds-grid slds-gutters">
                        <div class="{{mode === 'edit' ? 'slds-col slds-size_11-of-12' : 'slds-col'}}">
                            <ui-lightning-form-item *ngIf="!option.edit" [options]="option" [form]="generatedForm"></ui-lightning-form-item>
                            <ui-lightning-form-item-generator [options]="option" *ngIf="mode === 'edit' && option.edit"></ui-lightning-form-item-generator>
                        </div>
                        <div class="{{mode === 'edit' ? 'slds-col slds-size_1-of-12' : 'slds-hide'}}" style="position: relative;">
                          
                            <span *ngIf="!option.edit" style="position: absolute;top: 30px;left: 4px;" class="slds-icon_container slds-icon-utility-edit slds-current-color" title="edit" (click)="editFormItem(option)">
                                <svg class="slds-icon slds-icon_xx-small" aria-hidden="true">
                                  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#edit"></use>
                                </svg>
                                <span class="slds-assistive-text">edit</span>
                              </span>
                              <span *ngIf="option.edit" style="position: absolute;top: 30px;left: 4px;" class="slds-icon_container slds-icon-utility-save slds-current-color" title="save" (click)="saveFormItem(option)">
                                <svg class="slds-icon slds-icon_xx-small" aria-hidden="true">
                                  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#save"></use>
                                </svg>
                                <span class="slds-assistive-text">save</span>
                              </span>
                              <span style="position: absolute;top: 30px;left: 25px;" class="slds-icon_container slds-icon-utility-edit slds-current-color" title="delete" (click)="removeFormItem(option)">
                                <svg class="slds-icon slds-icon_xx-small" aria-hidden="true">
                                  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#delete"></use>
                                </svg>
                                <span class="slds-assistive-text">delete</span>
                              </span>
                      
                        </div>
                    </div>
                </ng-template>
                
                </div>
                
        </ng-template>
        </ng-container>
    </form>
</div>
