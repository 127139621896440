import { Locale } from '../model';

export class LocaleMessage extends Locale {
  constructor() {
    super();
    this.languages = {
      en: {
        success: 'Operation successful',
        error: 'An error occured',
        default: 'A message for you',
        please_wait: 'Please wait...',
        please_confirm: 'Please confirm',
        do_you_want_to_continue: 'Do you want to continue?',
        ok_button: 'OK',
        cancel_button: 'Cancel',
        close_button: 'Close',
        detailed_message: 'Detailed message',
      },
      fr: {
        success: 'Opération réussie',
        error: 'Une erreur est survenue',
        default: 'Message de l\'application',
        please_wait: 'Veuillez patienter...',
        please_confirm: 'Une confirmation est nécessaire',
        do_you_want_to_continue: 'Voulez-vous continuer ?',
        ok_button: 'OK',
        cancel_button: 'Annuler',
        close_button: 'Fermer',
        detailed_message: 'Message détaillé',
      },
    };
    this.genrateMap();
  }
}
