import { StyleOptions } from '../model/StyleOptions.js';

export abstract class MapboxStyle {
  protected style: StyleOptions;

  constructor(style: StyleOptions) {
    this.style = style;
  }

  public abstract getLayerLayout(): any;
  public abstract getLayerType(): any;
  public abstract getLayerPaint(): any;
  public abstract getFilter(): any;
}
