import { LanguageService } from "../locale/language.service";

export class StepperItem {
    index: number = 0;
    name!: string;
    icon?: string;
    title?: string;
    label?: string;
    description?: string;
    nextDisabled: boolean = false;
    btns?: any[];


}
export class StepperOptions {
    steps!: StepperItem[];
    active?: boolean;
    currentStep?: number;

    constructor(opts: any) {
        this.steps = opts.steps;
        this.currentStep = opts.currentStep || 0;
        console.log(this.steps);
    }

    public goToNextStep(): void {
        if (this.currentStep && this.currentStep < this.steps.length) {
            this.currentStep++;
        } else {
            console.error('Already on the last step');
        }
    }

    public init(languageService: LanguageService) {

        for (let i = 0; i < this.steps.length; i++) {
            const step: StepperItem = this.steps[i];
            if (!step.title) step.title = step.label;
            step.index = i;
            const nextDisabled = step.nextDisabled !== undefined && step.nextDisabled;
            if (step.btns === undefined || step.btns && step.btns.length === 0) {
                if (i === 0) {
                    step.btns = [
                        { label: languageService.getTranslation('Stepper', 'cancel'), action: 'cancel', position: 'top', show: true, disabled: false, icon: 'close' },
                        { label: languageService.getTranslation('Stepper', 'previous'), action: 'previous', position: 'buttom', show: false, disabled: false },
                        { label: languageService.getTranslation('Stepper', 'next'), action: 'next', position: 'buttom', show: true, disabled: nextDisabled },
                    ];
                } else if (i === this.steps.length - 1) {
                    step.btns = [
                        { label: languageService.getTranslation('Stepper', 'cancel'), action: 'cancel', position: 'top', show: true, disabled: false, icon: 'close' },
                        { label: languageService.getTranslation('Stepper', 'previous'), action: 'previous', position: 'buttom', show: true, disabled: false },
                        { label: languageService.getTranslation('Stepper', 'end'), action: 'finish', position: 'buttom', show: true, disabled: nextDisabled },
                    ];
                } else {
                    step.btns = [
                        { label: languageService.getTranslation('Stepper', 'cancel'), action: 'cancel', position: 'top', show: true, disabled: false, icon: 'close' },
                        { label: languageService.getTranslation('Stepper', 'previous'), action: 'previous', position: 'buttom', show: true, disabled: false },
                        { label: languageService.getTranslation('Stepper', 'next'), action: 'next', position: 'buttom', show: true, disabled: nextDisabled },
                    ];
                }
            }
        }
    }

    public getCurrentStep(): StepperItem {
        const currentStep = this.steps.find((s: StepperItem) => s.index === this.currentStep);
        if (currentStep) return currentStep;
        else throw new Error('Step not found');
    }

    public enableNext(enabled: boolean = true): void {
        const currentStep = this.getCurrentStep();
        currentStep.nextDisabled = false;
        const nextBtn = currentStep.btns?.find((btn: any) => btn.action === 'next');
        if (nextBtn) nextBtn.disabled = !enabled;

    }

    public enableDone(enabled: boolean = true): void {
        const currentStep = this.getCurrentStep();
        const nextBtn = currentStep.btns?.find((btn: any) => btn.action === 'finish');
        if (nextBtn) nextBtn.disabled = !enabled;
    }

    public goToStep(step: string, enabled: boolean = true): void {
        const index = this.steps.findIndex((s: StepperItem) => s.name === step);
        if (index !== -1) {
            this.currentStep = index;
            const nextBtn = this.steps[index].btns?.find((btn: any) => btn.action === 'next');
            if (nextBtn) nextBtn.disabled = !enabled;
        } else {
            console.error('Step not found');
        }
    }
}
