/**
 * @ignore
 */
class Listener {
    
    private _listeners: Map<string, ((arg1?: any) => void) []> = new Map<string, ((arg1?: any) => void)[]>();


    addEventListener(event:string, listener:((arg1?: any) => void)) {
        let funcs:((arg1?: any) => void)[] = this._listeners.get(event) || [];
        if(funcs.length === 0) {
            this._listeners.set(event, funcs);
        }
        funcs.push(listener);
    }
    
    removeEventListner(event:string, listener: ((arg1?: any) => void)):boolean {
        let funcs:((arg1?: any) => void)[] | undefined = this._listeners.get(event);
        if(!funcs) return false;
        funcs = funcs.filter((e)=>e !== listener);
        return true;
    }
    fireEvent(event:string, value?:any) {
        const funcs:((arg1?: any) => void)[] | undefined = this._listeners.get(event);
        if(funcs) {
            for(const f of funcs) {
                f(value);
            }
        }
    }
    
}

export default Listener;