import { Locale } from "../components";

export class LocaleAppComponent extends Locale {
    constructor() {
      super();
      this.languages = {
        en: {
            network_header_title: 'Network',
            global_spinner_message: 'Loading...',
            app_title: 'Network',
        },
        fr: {
            network_header_title: 'Réseau',
            global_spinner_message: 'Chargement...',
            app_title: 'Réseau',
        },
      };
      this.genrateMap();
    }
  }
  