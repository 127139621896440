export class FormOption {
    title?: string;
    name!: string;
    required?: boolean;
    value?: string | string[] | number | number [];
    field?: string;
    outputFormat?: string;
    placeholder?: string;
    type!: string;
    multiList?: any [];
    msgError?:string;
    edit?:boolean;
}
export enum FormElementType {
    checkbox = "checkbox",
    radio = "radio",
    textarea = "textarea",
    select = "select",
    text = "text",
  }

  export class FormStep {
    title?: string;
    text?: string;
    formOptions?: FormOption [];
    open?: boolean;
}