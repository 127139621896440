import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepperItem } from '../model';

@Component({
  selector: 'ui-lightning-stepper-card',
  templateUrl: './stepper-card.component.html',
  styleUrls: ['./stepper-card.component.css']
})
export class StepperCardComponent implements OnInit {
  @Input() step: StepperItem | undefined;
  @Output() actionClick = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  onActionClick(btn: any): void {
    this.actionClick.emit({ step: this.step, btn: btn });
  }


}
