import { DBObject } from './db-object.js';
export class DBColumn extends DBObject {
    table;
    name;
    type;
    defaultValue;
    isNullable;
    constructor(options) {
        super();
        this.table = options.table;
        this.name = options.name;
        this.type = options.type;
        this.defaultValue = options.defaultValue ?? '';
        this.isNullable = options.isNullable ?? true;
    }
}
