<div>
  <div class="slds-checkbox" *ngIf="!disabled">
    <input [checked]="checked" (change)="Check($event)" type="checkbox" name="options" [id]="'checkbox-' + checkboxID"
      [value]="'checkbox-' + checkboxID" tabindex="-1"
      [attr.aria-labelledby]="'check-button-label-' + checkboxID + ' column-group-header'" />
    <label class="slds-checkbox__label" [for]="'checkbox-' + checkboxID" [id]="'check-button-label-' + checkboxID">
      <span class="slds-checkbox_faux"></span>
      <span class="slds-form-element__label slds-assistive-text">Select item 1</span>
    </label>
  </div>
  <div *ngIf="disabled">
    <div class="slds-form-element__control">
      <span style="color:#0176d3" *ngIf="checked" class="slds-icon_container slds-icon-utility-check slds-current-color" title="True">
        <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
        </svg>
        <span class="slds-assistive-text">True</span>
      </span>
      <span *ngIf="!checked" class="slds-icon_container null slds-icon__svg--default"><svg
          class="slds-icon slds-icon-text-error slds-icon_x-small" aria-hidden="true">
          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
        </svg>
        <span class="slds-assistive-text">False</span>
      </span>
    </div>
  </div>
</div>