import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-lightning-tabs-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.css']
})
export class NavItemComponent implements OnInit {
@Input() isOpen: boolean;
  constructor() { 
    this.isOpen = false;
  }

  ngOnInit(): void {
  }

}
