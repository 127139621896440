import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-lightning-dropdown-button',
  templateUrl: './dropdown-button.component.html',
})
export class DropdownButtonComponent implements OnInit{
  @Output() onClick = new EventEmitter<any>();
  @Input() class!: string;
  constructor() {
    this.class = '';
  }
  ngOnInit(): void {
  }
  clickBtn() {
    this.onClick.emit('clicked');
  }

}
