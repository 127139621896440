<div class="slds-form-element">
  <div class="slds-form-element__control">
    <div class="slds-select_container">
      <select (change)="Select($event)" class="slds-select" id="select-01">
        <ng-template ngFor let-value [ngForOf]="values" let-e="index">
          <option *ngIf="value.alias != defaultVal; else selected" value="{{value.value}}">{{value.alias}}</option>
          <ng-template #selected>
            <option selected="selected" value="{{value.value}}">{{value.alias}}</option>
          </ng-template>
        </ng-template>
      </select>
    </div>
  </div>
</div>
