import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
/**
 * LookupService used to call extra api
 */
export class LookupService {
  /**
   * LookupService constructor
   */
  constructor() {}
}
