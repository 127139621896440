export var DBTables;
(function (DBTables) {
    DBTables["ORGANIZATION"] = "ggo_organization";
    /*
      TENANT = 'ggo_tenant',
      */
    DBTables["POI"] = "ggo_poi";
    DBTables["POI_TEST"] = "ggo_poi_test";
    DBTables["NETWORK"] = "ggo_network";
    DBTables["LOGO"] = "ggo_logo";
    DBTables["NETWORK_TYPE"] = "ggo_network_type";
    DBTables["NETWORK_FIELD"] = "ggo_network_field";
    DBTables["FIELD_BUSINESS_TYPE"] = "ggo_field_business_type";
    DBTables["ZONE_TYPE"] = "ggo_zone_type";
    DBTables["ZONE"] = "ggo_zone";
    DBTables["ZONE_GEOUNIT"] = "ggo_zone_geounit";
    DBTables["LOG"] = "ggo_log";
    DBTables["JOB"] = "ggo_job";
    DBTables["PARAMS"] = "ggo_params";
    DBTables["GEOLEVEL"] = "ggo_geolevel";
    DBTables["POTENTIAL"] = "ggo_potential";
    DBTables["SIMULATION"] = "ggo_simulation";
    DBTables["SIMULATION_CONFIGURATION"] = "ggo_simu_config";
    DBTables["SIMULATION_ZONE"] = "ggo_simu_zone";
    DBTables["SIMULATION_COMPETITOR"] = "ggo_simu_competitor";
    DBTables["SIMULATION_JOB"] = "ggo_simu_job";
    DBTables["SIMULATION_CANNIBALIZATION"] = "ggo_simu_cannibalization";
    DBTables["SIMULATION_UNIVERS"] = "ggo_simu_univers";
    DBTables["SIMULATION_UNIVERS_NETWORK"] = "ggo_simu_univers_network";
    DBTables["SIMULATION_UNIVERS_CONFIGURATION"] = "ggo_simu_univers_config";
    DBTables["SIMULATION_GEOLEVEL_KPI"] = "ggo_simu_geolevel_kpi";
    DBTables["CUSTDATA_TYPE"] = "ggo_geoleveldata_type";
    DBTables["CUSTDATA"] = "ggo_geoleveldata";
    DBTables["GEOLEVEL_CONTEXT"] = "ggo_geolevel_context";
    DBTables["RZI_CONTEXT"] = "ggo_rzi_context";
    DBTables["RZI_UNIVERS"] = "ggo_rzi_univers";
    DBTables["RZI_UNIVERS_CONTEXT"] = "ggo_rzi_univers_context";
    DBTables["RZI_UNIVERS_NETWORK"] = "ggo_rzi_univers_network";
    DBTables["RZI_STUDY"] = "ggo_rzi_study";
    DBTables["RZI_STUDY_ZONE"] = "ggo_rzi_study_zone";
})(DBTables || (DBTables = {}));
export var DBNetworkTypes;
(function (DBNetworkTypes) {
    DBNetworkTypes["POINT_OF_SALES"] = "point_of_sales";
    DBNetworkTypes["COMPETITOR"] = "competitor";
})(DBNetworkTypes || (DBNetworkTypes = {}));
export { DBObject } from './db-object.js';
export { DBTable } from './db-table.js';
export { DBColumn } from './db-column.js';
export { DBForeignKey } from './db-foreign-key.js';
export { DBIndex } from './db-index.js';
