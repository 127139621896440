<div class="slds-progress">
    <ol class="slds-progress__list">
        <ng-template ngFor let-step [ngForOf]="options.steps" let-i="index">
            <li class="slds-progress__item {{ (i < options.currentStep!) ? 'slds-is-completed': (i === options.currentStep) ? 'slds-is-active' : ''}} ">
                <button class="slds-button slds-button_icon slds-progress__marker slds-progress__marker_icon" title="{{step.title}}">
                  <svg *ngIf="i < options.currentStep!" class="slds-button__icon slds-icon-text-success" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#success"></use>
                  </svg>
                  <span class="slds-assistive-text">{{step.label}}</span>
                </button>
              </li>
        </ng-template>
    </ol>
    <div class="slds-progress-bar slds-progress-bar_x-small" aria-valuemin="0" aria-valuemax="100" attr.aria-valuenow="{{100 / (this.options.steps.length -1) * (this.options.currentStep! )}}"  role="progressbar">
      <span class="slds-progress-bar__value" style="width:{{100 / (this.options.steps.length -1) * (this.options.currentStep! )}}%">
        <span class="slds-assistive-text">Progress: {{100 / (this.options.steps.length -1) * (this.options.currentStep! )}}%</span>
      </span>
    </div>
  </div>