import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoryComponent } from './story.component';
import { StoryItemComponent } from './story-item/story-item.component';
import { SpinnerModule } from '../spinner/spinner.module';
import { IconModule } from '../icon/icon.module';



@NgModule({
  declarations: [
    StoryComponent,
    StoryItemComponent,
  ],
  imports: [
    CommonModule,
    SpinnerModule,
    IconModule
  ],
  exports: [
    StoryComponent,
    StoryItemComponent,
  ]
})
export class StoryModule { }
