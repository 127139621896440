<div class="pannel-bar-container">
  <div style="display:flex;width:100%;height:100%">
    <div id="the-story-panel" class="story-panel">
      <div style="width: 100%; height: 100%;">
        <div class="marges"></div>
        <ng-content></ng-content>
        <div class="marges"></div>
      </div>
    </div>
    <div class="slide_bar">
      <div class="slide-bar-centerer">
        <div id="{{'slider-bullet-' + story.id}}" class="slide-bar-bullet" *ngFor="let story of stories" (click)='scrollToStep("item-" + story.id);'>
          <p>{{story.title}}</p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="demo-only demo-only_viewport" style="height:100%;">
    <ui-lightning-spinner [size]="'medium'" [alternativeText]="'Loading'"></ui-lightning-spinner>
  </div>
</div>
