<ui-lightning-modal [isOpen]="isOpen ? true : false" (onClose)="closeEditModal(false)">
  <ui-lightning-modal-header>
    <h2 class="slds-text-heading_medium">{{'ModalAdresseComponent:modal_edit_title' | translate:'Edit an address' }}</h2>
  </ui-lightning-modal-header>
  <ui-lightning-modal-content>
    <div>
      <div class="slds-form-element">
        <label class="slds-form-element__label" for="adress-input">{{'ModalAdresseComponent:address' | translate:'Address' }}</label>
        <ui-lightning-search-adress id="address-input" title="edit address" style="width: 100%;" #controlLookup class="control-lookup"
          [lookupOptions]="lookupOptions" (onChange)="inputChanged()" (onSelect)="selectAddress($event)" [defaultValue]="addressInput"></ui-lightning-search-adress>
        <label class="slds-form-element__label" for="postal-code-input">{{'ModalAdresseComponent:postal_code' | translate:'Postal code' }}</label>
        <div class="slds-form-element__control">
          <input [(ngModel)]="postalCodeInput" (keydown)="inputChanged()" type="text" id="postal-code-input" class="slds-input"/>
        </div>
        <label class="slds-form-element__label" for="city-input">{{'ModalAdresseComponent:city' | translate:'City' }}</label>
        <div class="slds-form-element__control">
          <input [(ngModel)]="cityInput" (keydown)="inputChanged()" type="text" id="city-input" class="slds-input"/>
        </div>
        <div class="slds-form-element__control slds-m-top_medium">
          <div class="slds-checkbox">
            <input [(ngModel)]="checkboxPos" type="checkbox" name="update-pos-checkbox" [id]="'update-pos-checkbox' + editedAddress.data[0].recordId" [value]="'update-pos-checkbox' + editedAddress.data[0].recordId" [checked]="checkboxPos ? true : null" [disabled]="disablePosCheckbox"/>
            <label class="slds-checkbox__label" [for]="'update-pos-checkbox' + editedAddress.data[0].recordId">
              <span class="slds-checkbox_faux"></span>
              <span class="slds-form-element__label">{{'ModalAdresseComponent:update_pos_checkbox' | translate:'Update position' }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </ui-lightning-modal-content>
  <ui-lightning-modal-footer>
    <button class="slds-button slds-button_neutral" (click)="closeEditModal(false)">{{'ModalAdresseComponent:cancel' | translate:'Cancel' }}</button>
    <button class="slds-button slds-button_brand" (click)="closeEditModal(true)">{{'ModalAdresseComponent:confirm' | translate:'Confirm' }}</button>
  </ui-lightning-modal-footer>
</ui-lightning-modal>