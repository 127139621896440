import { GeometryType, StyleOptions } from '../model/StyleOptions.js';
import { LabelStyle } from './LabelStyle.js';
import { LineStyle } from './LineStyle.js';
import { PointStyle } from './PointStyle.js';
import { PolygonStyle } from './PolygonStyle.js';

export class StyleFactory {
  public static getStyle(styleOptions: StyleOptions) {
    if (styleOptions === undefined) throw new Error('Missing property LayerOptions.style');
    if (styleOptions.geometryType === undefined) throw new Error('Missing property LayerOptions.style.geometryType');

    switch (styleOptions.geometryType) {
      case GeometryType.LineString:
        return new LineStyle(styleOptions);

      case GeometryType.Point:
        return new PointStyle(styleOptions);

      case GeometryType.Polygon:
        return new PolygonStyle(styleOptions);

      default:
        throw new Error('Unknown geometry type ' + styleOptions.geometryType);
    }
  }
  public static getLabelStyle(styleOptions: StyleOptions) {
    styleOptions.geometryType = GeometryType.Point;
    return new LabelStyle(styleOptions);
  }
}
