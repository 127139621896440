import { Locale } from '../components';

export class LocaleMapComponent extends Locale {
  constructor() {
    super();
    this.languages = {
      en: {
        delete: 'Delete',
        confirm_delete_poi: 'Are you sure you want to delete this POI?',
        last_update: 'Last update',
        last_update_by: 'by',
        search_placeholder: 'Search for address / city / postal code',
        global_spinner_message: 'Loading...',
        poi_added: 'POI added',
        button_search: 'Search address',
        button_zoom_network: 'Zoom réseau',
        button_zoom_in: 'Zoom in',
        button_zoom_out: 'Zoom out',
        button_reset_north: 'Reset map to north',
        click_to_add_poi: 'Or click directly on the map to add a point',
        geocoder_not_found: 'Address not found',
        help_center: 'Help center',
      },
      fr: {
        delete: 'Supprimer',
        confirm_delete_poi: 'Etes-vous sûr de vouloir supprimer ce point ?',
        last_update: 'Mis à jour le',
        last_update_by: 'par',
        search_placeholder: 'Recherche d\'adresse / ville / code postal',
        global_spinner_message: 'Chargement...',
        poi_added: 'PDV ajouté',
        button_search: 'Recherche d\'adresse',
        button_zoom_network: 'Zoom réseau',
        button_zoom_in: 'Zoomer',
        button_zoom_out: 'Dézoomer',
        button_reset_north: 'Recentrer la carte vers le nord',
        click_to_add_poi: 'Ou cliquez directement sur la carte pour ajouter un point',
        geocoder_not_found: 'Adresse non trouvée',
        help_center: 'Centre d\'aide',
      },
    };
    this.genrateMap();
  }
}
