import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { REST_LOGIN, REST_POI_API } from '../constants';
import { HttpService } from '../data/http.service';

@Injectable({
  providedIn: 'root',
})
export class AuthguardService {
  public user: any;
  public token: any; // short token from Galigeo Cloud
  public queryParams: any;

  constructor(
    private _http: HttpClient,
    private _ggohttp: HttpService,
    private router: Router,
  ) {
    this.queryParams = HttpService.getQueryParams();
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return new Promise((resolve) => {
      if (this.token && this.user) {
        resolve(true);
      } else {
        this.login()
          .then(async (res: any) => {
            console.log('Logged in', res);
            this.user = res;
            resolve(true);
          })
          .catch((err) => {
            console.log('Failed to log in', err);
            //this.router.navigate(['notfound']);
            resolve(false);
          });
      }
    });
  }
  public syncSchema(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._ggohttp.doGet({ url: REST_POI_API + '/syncSchema' }).subscribe(
        (response: any) => {
          resolve(true);
        },
        (error) => {
          reject(error);
        },
      );
    });
  }
  login(): Promise<any> {
    if (
      this.queryParams.ggo_token !== undefined &&
      this.queryParams.ggo_token !== null &&
      this.queryParams.ggo_token !== 'undefined' &&
      this.queryParams.ggo_token !== 'null' &&
      this.queryParams.ggo_token !== '' &&
      this.queryParams.ggo_token !== localStorage.getItem('ggo_token')
    ) {
      localStorage.setItem('ggo_token', this.queryParams.ggo_token);
      this.token = this.queryParams.ggo_token;
    } else if (localStorage.getItem('ggo_token')) {
      this.token = localStorage.getItem('ggo_token');
    }

    return firstValueFrom(
      this._ggohttp.doGet({
        url: REST_LOGIN + '?token=' + encodeURIComponent(this.token),
        errorMessage: 'Failed to log in',
      }),
    );
  }
  private getCookie(request: any, name: string): string {
    const cookies: any = {};
    if (request.headers.cookie) {
      const decoded = decodeURIComponent(request.headers.cookie);
      decoded.split(';').forEach((cookie) => {
        const parts = cookie.split('=');
        cookies[parts[0].trim()] = (parts[1] || '').trim();
      });
    }
    return cookies[name];
  }
  public hasCapability(capability: string): boolean {
    if (!this.user) {
      return false;
    }
    return this.user.capabilities.split(',').includes(capability);
  }
}
