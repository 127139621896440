import { Locale } from '../model';

export class LocaleTable extends Locale {
  constructor() {
    super();
    this.languages = {
      en: {
        element: 'Total',
        element_selected: 'Selected',
        element_geocode_to_check: 'To check',
        element_geocode_failed: 'Failed',
        tooltip_first_page: 'First page',
        tooltip_last_page: 'Last page',
        tooltip_next_page: 'Next page',
        tooltip_previous_page: 'Previous page',
        tooltip_search: 'Search in the table',
        search_placeholder: 'Search',
      },
      fr: {
        element: 'Total',
        element_selected: 'Sélectionné(s)',
        element_geocode_to_check: 'A vérifier',
        element_geocode_failed: 'Echec',
        tooltip_first_page: 'Première page',
        tooltip_last_page: 'Dernière page',
        tooltip_next_page: 'Page suivante',
        tooltip_previous_page: 'Page précédente',
        tooltip_search: 'Rechercher dans le tableau',
        search_placeholder: 'Rechercher',
      },
    };
    this.genrateMap();
  }
}
