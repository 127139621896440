export abstract class ModalBase {
    

    constructor() {
    }
    closeModal() {
        
    }
    openModal() {
        
    }
}