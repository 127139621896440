import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-lightning-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.css']
})
export class SwitchComponent implements OnInit {
  @Input() defaultChecked!: boolean | undefined;
  @Input() label: string = '';
  @Output() switchState = new EventEmitter<boolean>();
  ngOnInit(): void {
      
  }

  onSwitchChange(event: any) {
    console.log(event.target.checked)
    this.switchState.emit(event.target.checked);
  }
}
