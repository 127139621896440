import { AfterContentInit, Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { TableEvent, TableOptions } from './model';
import { DataTable, Record } from '@galigeo-store/shared-models';
import { EventService, GgoEvent } from '../shared/event.service';
import { AbstractComponent } from '../model/abstract-component';
import { LanguageService } from '../locale/language.service';
import { LocaleTable } from '../locale/lang-components/table';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'ui-lightning-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent extends AbstractComponent implements OnInit, AfterContentInit {
  @Input() options!: TableOptions;
  @Input() dataTable!: DataTable;
  fields!: any[];
  data!: any[];
  checkedRows!: DataTable;
  pageSize!: number; //No.of records to be displayed per page
  totalPages!: number; //Total no.of pages
  pageNumber: number = 1;
  rec!: Record;
  lastVisibleFieldsIndex!: any;
  public height!: string;
  public sortAsc!: boolean;
  public indexEditableCell!: string;
  foundField: any;
  foundFieldType: any;
  allChecked: any;
  indexToShow: any;
  private readonly searchInput = new Subject<string | undefined>();
  searchSubscription: Subscription | undefined;
  @ViewChild('tableSearchInput') tableSearchInput!: ElementRef;


  constructor(override eventService: EventService, private languageService: LanguageService, private vrc: ViewContainerRef) {
    super(eventService);
    this.indexEditableCell = "-1";
    this.checkedRows = new DataTable({ fields: [] });
    this.indexToShow = 0;
    this.languageService.addTranslation('TableComponent', new LocaleTable().locale);
  }
  ngAfterContentInit(): void {
    this.height = this.vrc.element.nativeElement.parentElement.style.height;
    console.log(this.height);
  }

  public getDefaultName(): string {
    return "table";
  }

  ngOnInit(): void {
    this.listenEvent().subscribe((e: GgoEvent) => {
      if (e.action === TableEvent.HIGHLIGHT) this.highlightRecord(e.obj);
      if (e.action === TableEvent.ROW_CLICKED) this.highlightRecord(e.obj.data[0]);
      if (e.action === TableEvent.SEARCH) this.searchTable(e.obj);
    });
    if (!this.dataTable) throw new Error('Missing input dataTable');
    if (!this.dataTable.fields) throw new Error('Missing input dataTable.fields');
    if (!this.dataTable.data) throw new Error('Missing input dataTable.data');

    this.searchSubscription = this.searchInput
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe((results) => {
        this.eventService.emitEvent('table-network', { action: TableEvent.SEARCH, obj: results });
      });
    /*for(let field of this.dataTable.fields) {
      if(!field.alias) field.alias = field.name;
      if(field.visible === undefined) field.visible = true;
    }
    for(let record of this.dataTable.data) {
      if(record.visible === undefined) record.visible = true;
      if(record.selected === undefined) record.selected = false;    
    }*/
    this.fields = this.dataTable.fields;
    this.data = this.dataTable.data;
    if (this.options?.pageSize) {
      this.pageSize = this.options.pageSize;
    }
    if (this.pageSize === undefined && this.dataTable?.data?.length > 100) {
      this.pageSize = 100;
    }
    if (this.pageSize) {
      this.firstPage();
    }
    const lastThreeVisible = this.dataTable.fields.filter((f: any) => f.visible === true).slice(-3);
    this.lastVisibleFieldsIndex = lastThreeVisible.map((f: any) => this.dataTable.getFieldIndex(f.name));
  }

  ngOnChanges(changes: SimpleChanges) {
    // input change detected
    console.log('Changes detected', changes);
    this.fields = this.dataTable.fields;
    this.data = this.dataTable.data;
    if (this.options?.pageSize) {
      this.pageSize = this.options.pageSize;
    }
    if (this.pageSize === undefined && this.dataTable?.data?.length > 100) {
      this.pageSize = 100;
    }
    if (this.pageSize) {
      this.firstPage();
    }
    this.allChecked = false;
    const lastThreeVisible = this.dataTable.fields.filter((f: any) => f.visible === true).slice(-3);
    this.lastVisibleFieldsIndex = lastThreeVisible.map((f: any) => this.dataTable.getFieldIndex(f.name));
  }

  getNbrElementVisible() {
    return this.dataTable.data.filter((d: any) => d.visible === undefined || d.visible).length
  }

  sortTable(fieldName: string) {
    if (this.dataTable.fields[this.dataTable.getFieldIndex(fieldName)].sortable || this.dataTable.fields[this.dataTable.getFieldIndex(fieldName)].sortable === undefined) {
      if (this.sortAsc) {
        this.dataTable.sort(fieldName, "down")
        this.sortAsc = false;
      } else {
        this.dataTable.sort(fieldName, "up")
        this.sortAsc = true;
      }
      if (this.pageSize) {
        this.firstPage();
      }
    }
  }
  searchTable(value: any) {
    console.log('Seach table', value);
    this.dataTable.globalFilter(value);
    //this.eventService.emitEvent('map', { action: 'refresh', obj: undefined });
    if (this.pageSize) {
      this.firstPage();
    }
  }

  searchTableBar(event: any) {
    const searchQuery = (event.target as HTMLInputElement).value;
    this.searchInput.next(searchQuery?.trim());
  }

  editCell(e: any) {
    const recordId = this.dataTable.data[e.obj.rowIndex].recordId;
    const record = { recordId: recordId, values: [e.obj.value] };
    const dt = new DataTable({
      fields: [this.dataTable.fields[e.obj.fieldIndex]],
      data: [record]
    });
    this.emitEvent({ action: TableEvent.CELL_EDIT, obj: dt });
  }


  atLeastOneChecked() {
    return this.dataTable.data.filter((d: any) => d.selected).length > 0;
  }

  countChecked() {
    return this.dataTable.data.filter((d: any) => d.selected).length;
  }

  onClickedRow(event: any, record: any) {
    switch (event?.target?.tagName) {
      case 'INPUT':
      case 'BUTTON':
      case 'svg':
        return;
    }
    if (event?.target?.className === 'slds-checkbox_faux') {
      return;
    }
    const clickedRowDT = new DataTable({
      fields: this.dataTable.fields,
      data: [record]
    })
    this.emitEvent({ action: TableEvent.ROW_CLICKED, obj: clickedRowDT });
  }

  highlightRecord(record: Record) {
    this.resetHighlight();
    this.rec = record;
    if (this.dataTable) {
      if (this.pageSize) {
        for (let i = 0; i < this.dataTable.data.filter((d: any) => d.visible === undefined || d.visible).length; i++) {
          if (this.dataTable.data[i].recordId === this.rec.recordId) {
            this.pageNumber = Math.ceil((i + 1) / this.pageSize);
            this.paginationHelper();
            break;
          }
        }
      }
      const interval = setInterval(() => {
        let rowIndex = document.getElementsByClassName("row-index-" + this.rec.recordId);
        if (rowIndex) {
          for (const element of Array.from(rowIndex)) {
            if (element.classList.contains('table-row-highlight') === false) {
              element.classList.add('table-row-highlight');
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else {
              element.classList.remove('table-row-highlight');
            }
          }
          clearInterval(interval);
        }
      }, 100);


    }
  }

  resetHighlight() {
    if (this.rec) {
      let rowIndex = document.getElementsByClassName("row-index-" + this.rec.recordId)
      for (const element of Array.from(rowIndex)) {
        element.classList.remove('table-row-highlight')
      }
    }
  }

  getChecked(e: any, row: any) {
    this.checkedRows = new DataTable({ data: [] });
    row.selected = e.target.checked;
    this.checkedRows.fields = this.dataTable.fields;
    this.checkedRows.data = this.dataTable.data.filter((d: any) => d.selected);
    const rowElement = document.getElementsByClassName("row-index-" + row.recordId);
    if (rowElement) {
      rowElement[0].classList.toggle('slds-is-selected');
    }
    console.log(this.checkedRows);
    this.emitEvent({ action: TableEvent.ROW_SELECTED, obj: this.checkedRows });
  }

  checkAll(e: any) {
    this.checkedRows = new DataTable({ data: [] });
    this.allChecked = e.target.checked;
    this.dataTable.data.forEach((d: any) => {
      d.selected = d.visible && this.allChecked;
    });
    this.checkedRows.fields = this.dataTable.fields;
    this.checkedRows.data = this.dataTable.data.filter((d: any) => d.selected);
    this.emitEvent({ action: TableEvent.ROW_SELECTED, obj: this.checkedRows });
  }
  bDisableFirst() {
    return this.pageNumber == 1;
  }
  bDisableLast() {
    return this.pageNumber == this.totalPages;
  }
  handleRecordsPerPage(event: any) {
    this.pageSize = event.target.value;
    this.paginationHelper();
  }
  previousPage() {
    this.pageNumber = this.pageNumber - 1;
    this.paginationHelper();
  }
  nextPage() {
    this.pageNumber = this.pageNumber + 1;
    this.paginationHelper();
  }
  firstPage() {
    this.pageNumber = 1;
    this.paginationHelper();
  }
  lastPage() {
    this.pageNumber = this.totalPages;
    this.paginationHelper();
  }
  // JS function to handel pagination logic 
  paginationHelper() {
    let numberOfElements = Math.ceil(this.dataTable.data.filter((r: any) => r.visible === undefined || r.visible).length);
    // calculate total pages
    if (this.pageSize && this.pageSize > 0) {
      this.totalPages = Math.ceil(numberOfElements / this.pageSize);
      // set page number 
      if (this.pageNumber <= 1) {
        this.pageNumber = 1;
      } else if (this.pageNumber >= this.totalPages) {
        this.pageNumber = this.totalPages;
      }
      this.dataTable.setPage(this.pageNumber, this.pageSize);
    }
  }
}
