import { BusinessType, DataTable, FieldType } from "@galigeo-store/shared-models";
import { DataFusion } from "./data-fusion";
import { MergeResult } from "./merge-result";

export class ReplaceAll extends DataFusion {
    override mergeData(originalData: DataTable, newData: DataTable):MergeResult {
        // just replace the original data with the new data        
        originalData.fields = newData.fields;
        originalData.data = newData.data;
        const latIdx = newData.getFieldIndexByBusinessType(BusinessType.LAT);
        const longIdx = newData.getFieldIndexByBusinessType(BusinessType.LONG);
        const geomIdx = newData.addField({ businessType: BusinessType.GEOMETRY, type: FieldType.GEOMETRY, name: 'Geometry', visible: false, editable: true });
        newData.addField({ businessType: BusinessType.GEOCODER_ACCURACY, type: FieldType.STRING, name: 'Geocoding Accuracy', visible: false, editable: false }, 1);
    
        for (const record of newData.data) {
            const lat = record.values[latIdx];
            const long = record.values[longIdx];
            if (lat && long) {
                record.values[geomIdx] = { type: 'Point', coordinates: [long, lat] };
            }
        }
        return this.result;
    }
    
}