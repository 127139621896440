import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GeocodeModule } from '@galigeo-store/retail-services';
import { IconModule, LocaleModule, LookupModule, ModalModule, SpinnerModule, UiLightningGeocodeModule, VerticalBtnGroupModule } from '@galigeo-store/ui-lightning';
import { HasCapabilityDirective } from './directives/has-capability.directive';
import { EditMapComponent } from './edit-map/edit-map.component';
import { MapAlertsComponent } from './map-alerts/map-alerts.component';
import { MappingFieldsComponent } from './mapping-fields/mapping-fields.component';
import { ModalAddressComponent } from './modal-address/modal-address.component';
import { ModalNoGeomWarningComponent } from './modal-no-geom-warning/modal-no-geom-warning.component';
import { ModalAddressEditComponent } from './modal-address-edit/modal-address-edit.component';


@NgModule({
  declarations: [
    MappingFieldsComponent,
    EditMapComponent,
    MapAlertsComponent,
    ModalAddressComponent,
    HasCapabilityDirective,
    ModalNoGeomWarningComponent,
    ModalAddressEditComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SpinnerModule,
    IconModule,
    GeocodeModule,
    ModalModule,
    LocaleModule,
    LookupModule,
    UiLightningGeocodeModule,
    VerticalBtnGroupModule
  ],
  exports: [
    MappingFieldsComponent,
    EditMapComponent,
    MapAlertsComponent,
    ModalAddressComponent,
    HasCapabilityDirective
  ]
})
export class SharedModule { }
