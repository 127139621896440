export class FormatUtils {
    static formatRevenue(revenue) {
        // Replace commas with an empty string
        const stringWithoutCommas = revenue.replace(/,/g, '').replace(/\s/g, '');
        // Replace periods with a standard decimal point
        const stringWithStandardDecimal = stringWithoutCommas.replace(/\./g, '.');
        // Try to convert the modified string to a number
        const parsedNumber = parseFloat(stringWithStandardDecimal);
        // Check if the conversion was successful
        if (!isNaN(parsedNumber)) {
            return parsedNumber;
        }
        else {
            // If the conversion was not successful, remove all periods and try again
            const stringWithoutPoints = revenue.replace(/\./g, '');
            const converted = parseFloat(stringWithoutPoints.replace(/,/g, '.'));
            if (!isNaN(converted)) {
                return converted;
            }
            else {
                return null;
            }
        }
    }
    static encodedFieldNames(name) {
        // Normalize the string to decompose accented characters
        let normalizedString = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        // Remove special characters
        normalizedString = normalizedString.replace(/[^a-zA-Z0-9\s]/g, '');
        // Replace spaces with underscores
        normalizedString = normalizedString.replace(/\s+/g, '_');
        return normalizedString;
    }
}
