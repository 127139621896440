import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthguardService } from './shared/services';
import { NetworkContainerComponent } from './network/container/network.component';
import { WizardAddComponent } from './wizard/wizard-add/wizard-add.component';
import { WizardUpdateComponent } from './wizard/wizard-update/wizard-update.component';
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'network/default'
  },
{
  path: 'add/:poiSetId',
  component: WizardAddComponent, canActivate: [AuthguardService]
},
{
  path: 'update/:poiSetId',
  component: WizardUpdateComponent, canActivate: [AuthguardService]
},
{
  path: 'network/:poiSetId',
  component: NetworkContainerComponent, canActivate: [AuthguardService]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
