import { Utils } from '../utils/Utils.js';
import { MapboxStyle } from './MapboxStyle.js';

export class PolygonStyle extends MapboxStyle {
  public getFilter() {
    return undefined;
  }
  public getLayerLayout() {
    const layout: any = {};

    return layout;
  }
  public getLayerType() {
    return 'fill';
  }
  public getLayerPaint() {
    const fillOpacity = this.style?.fillOpacity ?? 0.8;
    const paint: any = {
      //'fill-color': this.style.fillColor ? this.style.fillColor: '#dddddd',
      //'fill-extrusion-opacity': this.style.fillOpacity ? this.style.fillOpacity : 0.5,
      //'fill-extrusion-opacity': 0.5
      'fill-color': this.style?.fillColor ?? '#2ecc71',
      'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], fillOpacity + 0.1, fillOpacity],
    };
    if (this.style.ranges) {
      paint['fill-color'] = this.style.ranges.transformRangesToSteps();
      //paint['fill-extrusion-height'] = this.style.ranges.transformRangesToHeight();
    } else if (this.style.matches) {
      paint['fill-color'] = this.style.matches.transformMatch();
    } else {
      /*
      else if (this.style.thirdDimension) {
        paint['fill-extrusion-color'] = ['get', this.style.thirdDimension.colorField];
        paint['fill-extrusion-height'] = ['get', this.style.thirdDimension.heightField];
      } 
      */
      const colorOpacity = Utils.getColorOpacity(this.style.fillColor);
      if (colorOpacity && colorOpacity > 0) {
        paint['fill-opacity'] = ['case', ['boolean', ['feature-state', 'hover'], false], 1, colorOpacity];
      }
      /*
      else {
        paint['fill-color'] = 'transparent';
      }
      */
    }

    return paint;
  }
}
