import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropZoneDirective } from './drop-zone.directive';
import { SafeHtmlPipe } from './safe-html.pipe';
import { EventService } from './event.service';



@NgModule({
  declarations: [
    DropZoneDirective,
    SafeHtmlPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [EventService],
  exports: [DropZoneDirective,
    SafeHtmlPipe]
})
export class UiLightningSharedModule { }
