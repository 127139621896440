import * as turf from '@turf/turf';
import { LngLatBoundsLike } from 'maplibre-gl';
import * as maplibregl from 'maplibre-gl';
export class Utils {
  public static zoomToGeoJSON(map: maplibregl.Map, geojson: any) {
    const map_padding = {
      left: 400,
    };
    const bbox = turf.bbox(geojson);
    const m_padding = { top: 50, right: 50, bottom: 50, left: 50 };
    map.fitBounds(bbox as LngLatBoundsLike, { padding: { ...m_padding, ...map_padding } });
  }

  public static getColor(color: any = '#50b88c'): string | string[] {
    if (Array.isArray(color)) {
      if (color[0] === 'get') return color;
      return `rgba(${color.join(',')})`;
    }
    return color as string;
  }

  public static getColorOpacity(color: any): number | null {
    if (Array.isArray(color) && color.length === 4) {
      return color[3];
    }
    if (typeof color === 'string' && color.startsWith('rgba')) {
      const colorArray: string = color.substring(5, color.length - 1);
      const opacity = colorArray.split(',')[3];
      return parseInt(opacity);
    }
    return null;
  }

  public static mergeLogoWithPin(logo: string) {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject('Could not get 2d context');
        return;
      }
      const outsidePin = new Image();
      const outsidePinBorder = new Image();
      if (logo) {
        const innerLogo = new Image();
        outsidePin.onload = () => {
          canvas.width = 40;
          canvas.height = 40;
          ctx.drawImage(outsidePin, 0, 0, 40, 40);
          ctx.drawImage(outsidePinBorder, 0, 0, 40, 40);
          ctx.globalCompositeOperation = 'destination-over';
          innerLogo.onload = () => {
            ctx.drawImage(innerLogo, 12.5, 7, 15, 15);
            ctx.fillStyle = '#fff';
            ctx.fillRect(10, 3, 20, 25);
            resolve(canvas.toDataURL());
          };
          innerLogo.style.borderRadius = '10px';
          innerLogo.src = logo;
          innerLogo.style.zIndex = '-1';
        };
      } else {
        outsidePin.onload = () => {
          canvas.width = 40;
          canvas.height = 40;
          ctx.drawImage(outsidePin, 0, 0, 40, 40);
          resolve(canvas.toDataURL());
        };
      }
      outsidePin.src = 'data:image/svg+xml,' + Utils.getMarkerIntermediate();
      outsidePinBorder.src = 'data:image/svg+xml,' + Utils.getMarkerBorder();
    });
  }

  public static getMarkerIntermediate() {
    const svgXml = `<svg data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 20 27">
            <path fill="RGB(27,150,255)" d="m17.13,2.96C15.25,1.06,12.74.02,10.06,0h-.05C4.49,0,0,4.45,0,9.94v.05c0,2.87,1.76,6.68,5.08,11.02,1.84,2.4,3.72,4.83,4.92,5.99,1.19-1.18,3.08-3.59,4.92-5.99,3.34-4.34,5.08-8.16,5.08-11.01,0-2.45-.86-4.76-2.45-6.58-.14-.16-.42-.46-.42-.46h0Zm-7.12,12.94c-3.31,0-6-2.67-6-5.96s2.69-5.96,6-5.96,6,2.67,6,5.96-2.69,5.96-6,5.96Z"/>
        </svg>`;
    return svgXml;
  }
  public static getMarkerBorder() {
    const svgXml = `<svg data-name="Calque 2" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 20.5 27.6">
            <path fill="RGB(0,112,210)" d="m10.25,27.6l-.18-.17c-1.18-1.14-2.94-3.4-4.94-6.01C1.77,17.02,0,13.16,0,10.24,0,4.57,4.6,0,10.27,0c2.79.02,5.37,1.09,7.29,3.04h0s.28.3.43.46c1.65,1.9,2.52,4.23,2.51,6.75,0,2.93-1.77,6.79-5.13,11.17-1.86,2.43-3.75,4.84-4.94,6.01l-.17.17Zm.06-27.1C4.88.5.5,4.85.5,10.19c0,2.86,1.74,6.61,5.03,10.92,1.9,2.47,3.57,4.62,4.72,5.79,1.18-1.21,2.96-3.49,4.72-5.79,3.29-4.28,5.03-8.04,5.03-10.86,0-2.4-.82-4.62-2.39-6.42-.13-.15-.39-.42-.41-.44-1.83-1.85-4.28-2.87-6.89-2.89Z"/>
        </svg>`;
    return svgXml;
  }
  public static getZoomLevel(value: any) {
    const scales = {
      '0': 295312500,
      '1': 147656250,
      '2': 73828125,
      '3': 36914062.5,
      '4': 18457031.25,
      '5': 9228515.62500001,
      '6': 4614257.8125,
      '7': 2307128.90625,
      '8': 1153564.45312499,
      '9': 576782.2265625,
      '10': 288391.11328125,
      '11': 144195.556640625,
      '12': 72097.7783203123,
      '13': 36048.8891601563,
      '14': 18024.444580078,
      '15': 9012.22229003966,
      '16': 4506.11114501941,
      '17': 2253.05557250988,
      '18': 1126.52778625488,
    };
    for (const key in scales) {
      if (Math.abs(scales[key as keyof typeof scales] - value) < 1) {
        return parseInt(key);
      }
    }

    return undefined;
  }
}
