import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { AuthguardService, HttpService } from '@galigeo-store/retail-services';
import { AlertOptions, MessageOptions } from '@galigeo-store/shared-models';
import { EventService, GgoEvent, LanguageService, MessageService, ModalService, NavabarItemPosition } from '@galigeo-store/ui-lightning';
import { LocaleAppComponent } from './shared/locales/localeAppComponent';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  subscription: any;
  title = 'retail-MyNetwork';
  networkItems!: any;
  options: any; 
  loadGlobalSpinner!: boolean;
  loadGlobalSpinnerMessage!: string;
  constructor(private translateService: LanguageService,
    private httpService: HttpService,
    private messageService: MessageService,
    private modalService: ModalService,
    private vrc: ViewContainerRef,
    private authguardService: AuthguardService,
    private eventService: EventService,
    private titleService: Title) {
      this.translateService.addTranslation('AppComponent', new LocaleAppComponent().locale);
  }
  ngOnInit(): void {
    // Link the messages from the http service and the angular message service
    // Don't forget to call this chunk in every angular apps
    this.loadGlobalSpinnerMessage = this.translateService.getTranslation('AppComponent', 'global_spinner_message');
    this.messageService.initMessages(this.vrc);
    this.modalService.initModals(this.vrc);
    this.httpService.event.subscribe((event: MessageOptions) => {
      if (event instanceof AlertOptions){
        if (event.error.message === 'Failed to sync schema') {
          return;
        }
      }
        this.messageService.event.next(event);
    });
    this.eventService.listenEvent('global-spinner').subscribe((event: GgoEvent) => {
      if(event.action === 'show') {
      this.loadGlobalSpinner = true;
      
      }else {
        this.loadGlobalSpinner = false;
      }
      if(event.obj) {
        this.loadGlobalSpinnerMessage = event.obj;
      }else {
        this.loadGlobalSpinnerMessage = this.translateService.getTranslation('AppComponent', 'global_spinner_message');
      }
      
    });
    
    const intervalLogin = setInterval(() => {
      if (this.authguardService.user) {
        if (this.authguardService.user.lang == 'fr') {
          this.translateService.setLang(this.authguardService.user.lang);
        } else {
          this.translateService.setLang('en');
          document.querySelector('html')?.setAttribute('lang', this.authguardService.user.lang);
        }
        this.titleService.setTitle(this.translateService.getTranslation('AppComponent', 'app_title'));
        const newItem: any = 
        this.options = {
          items: [
            {
              name: 'logo',
              title: 'Galigeo Retail my network',
              imgUrl: '/assets/imgs/galigeo.png',
              chevrondown: false,
              position: NavabarItemPosition.LEFT,
            },
            {
              name: 'logo',
              title: 'Galigeo Retail my network',
              imgUrl: '/assets/imgs/network.png',
              chevrondown: false,
              position: NavabarItemPosition.LEFT,
            },
            {
              name: 'network',
              title: 'Network',
              label: this.translateService.getTranslation('AppComponent', 'network_header_title'),
              type: 'title',
              chevrondown: false,
              position: NavabarItemPosition.LEFT,
            },{
              name: 'network',
              title: 'Network',
              label: this.authguardService.user.user + ' - ' + 'organisation' + ' : ' + this.authguardService.user.orgId,
              type: 'text',
              chevrondown: false,
              position: NavabarItemPosition.RIGHT,
              items: []
            },
            {
              name: 'user',
              title: 'User Profile',
              icon: 'action:user',
              type: 'icon',
              position: NavabarItemPosition.RIGHT,
            }
          ],
        };        // Get the second last index
        const secondLastIndex = this.options.items.length - 2;
  
        // Insert the new item before the second last item
        this.options.items.splice(secondLastIndex, 0, newItem);
        clearInterval(intervalLogin);
        
      }
    });
    
  }
  onNavChange(event: any) {
    if (['fr', 'en'].includes(event.name)) {
      this.translateService.setLang(event.name);
      this.options.items[2].label = event.name.toUpperCase();
    }
  }
  
}
