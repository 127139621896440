import { BusinessType, FieldType } from './dataTable.js';
import { FormatUtils } from './formatUtils.js';
export class DataType {
    static parseType(value) {
        if (value === undefined || value === null || value === '')
            return null;
        if (typeof value === 'string') {
            // fixed size integers must by typed as string
            if (value.startsWith('0') && value.indexOf('.') === -1 && value.indexOf(',') === -1) {
                return FieldType.STRING;
            }
            if (value.indexOf(',') === -1 && value.indexOf('.') === -1 && !isNaN(Number(value))) {
                return FieldType.INTEGER;
            }
            if (!isNaN(Number(value.replace(',', '.')))) {
                // check is number
                return FieldType.DOUBLE;
            }
            if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
                // check is boolean
                return FieldType.BOOLEAN;
            }
            if (this.isDate(value)) {
                // check is date
                return FieldType.DATE;
            }
        }
        else if (typeof value === 'number') {
            return FieldType.INTEGER;
        }
        return FieldType.STRING;
    }
    static isDate(input) {
        // Liste des expressions régulières pour différents formats de date
        const datePatterns = [
            /^\d{4}-\d{2}-\d{2}$/, // Format ISO 8601 : yyyy-MM-dd
            /^\d{2}\/\d{2}\/\d{4}$/, // Format européen : dd/MM/yyyy
            /^\d{2}-\d{2}-\d{4}$/, // Format alternatif : dd-MM-yyyy
            /^\d{4}\/\d{2}\/\d{2}$/, // Format inversé : yyyy/MM/dd
            /^\w{3}, \d{1,2} \w{3} \d{4}$/, // Format textuel : Wed, 05 Dec 2024
            /^\d{1,2} \w{3} \d{4}$/, // Format textuel sans jour : 5 Dec 2024
            /^\d{8}$/, // Format compact : yyyyMMdd
        ];
        // Tester chaque pattern sur l'entrée
        return datePatterns.some((pattern) => pattern.test(input));
    }
    static setType(value, type) {
        if (value === undefined || value === null)
            return null;
        switch (type) {
            case FieldType.DOUBLE:
                return this.convertToDouble(value, type);
            case FieldType.BOOLEAN:
                return this.convertToBoolean(value);
            case FieldType.DATE:
                return this.convertToDate(value);
            case FieldType.STRING:
                return this.convertToString(value);
        }
        return value;
    }
    static parseDataTable(data) {
        for (let i = 0; i < data.fields.length; i++) {
            // get a sample of 50 values
            const sampleValues = data.data
                .slice(0, 50)
                .map((r) => this.parseType(r.values[i]))
                .filter((v) => v !== null && v !== undefined);
            const numStrings = sampleValues.filter((v) => v === FieldType.STRING).length;
            const numDoubles = sampleValues.filter((v) => v === FieldType.DOUBLE).length;
            const numDates = sampleValues.filter((v) => v === FieldType.DATE).length;
            const numBooleans = sampleValues.filter((v) => v === FieldType.BOOLEAN).length;
            const numIntegers = sampleValues.filter((v) => v === FieldType.INTEGER).length;
            if (sampleValues.length === 0) {
                data.fields[i].type = FieldType.STRING;
                continue;
            }
            // Priority rules
            if (numStrings > 0) {
                data.fields[i].type = FieldType.STRING;
            }
            else if (numDoubles > 0 && (numDoubles + numIntegers === sampleValues.length)) {
                data.fields[i].type = FieldType.DOUBLE;
            }
            else if (numBooleans === sampleValues.length) { // On ne voit pas de string
                data.fields[i].type = FieldType.BOOLEAN;
            }
            else if (numDates === sampleValues.length) {
                data.fields[i].type = FieldType.DATE;
            }
            else if (numIntegers === sampleValues.length) {
                data.fields[i].type = FieldType.INTEGER;
            }
            else {
                data.fields[i].type = FieldType.STRING;
            }
            const type = data.fields[i].type;
            if (type) {
                for (const record of data.data) {
                    record.values[i] = this.setType(record.values[i], type);
                }
            }
        }
    }
    static convertToBoolean(value) {
        if (typeof value === 'string') {
            return value.toLowerCase() === 'true';
        }
        return false;
    }
    static convertToInteger(value) {
        if (typeof value === 'string') {
            return parseInt(value);
        }
        return value;
    }
    static isValidDate(dateObject) {
        return dateObject.toString() !== 'Invalid Date';
    }
    static convertToDate(value) {
        if (!value || value === null || value === undefined || value === '' || value === 'null' || value === 'undefined') {
            return null;
        }
        else if (typeof value === 'string') {
            return new Date(value);
        }
        else {
            return new Date(value + '');
        }
    }
    static convertToString(value) {
        if (value && typeof value !== 'string') {
            return value + '';
        }
        return value;
    }
    static convertToDouble(value, businessType) {
        if (value == null) {
            return NaN;
        }
        if (typeof value === 'number') {
            return value;
        }
        if (typeof value === 'string') {
            if (BusinessType.REVENUE === businessType) {
                const revenue = FormatUtils.formatRevenue(value);
                if (revenue) {
                    return revenue;
                }
            }
            return parseFloat(value.replace(',', '.'));
        }
        return NaN;
    }
}
