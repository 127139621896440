import { Locale } from '../model';

export class LocaleStepper extends Locale {
  constructor() {
    super();
    this.languages = {
      en: {
        previous: 'Previous',
        next: 'Next',
        end: 'Finish',
        close: 'Close',
        cancel: 'Cancel',
      },
      fr: {
        previous: 'Précédent',
        next: 'Suivant',
        end: 'Terminer',
        close: 'Fermer',
        cancel: 'Annuler',
      },
    };
    this.genrateMap();
  }
}
