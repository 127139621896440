import { Injectable } from '@angular/core';
import { HttpService } from '../data/http.service';
import { REST_GEOCODE_ADDRESS, REST_GEOCODE_REVERSE } from '../constants';
import { DataTable } from '@galigeo-store/shared-models';

@Injectable({
  providedIn: 'root'
})
export class GeocodeService {

  constructor(private readonly _http: HttpService) { }
  public search(address: string): Promise<DataTable> {
    return new Promise((resolve, reject) => {
      this._http.doGet({ url: REST_GEOCODE_ADDRESS + '?q=' + encodeURIComponent(address) })
        .subscribe({
          next: (response: any) => {
            resolve(new DataTable(response));
          },
          error: (error: any) => {
            reject(new Error(error));
          }
        });
    });
  }
  public reverseGeocode(lat: number, lon: number): Promise<DataTable> {
    return new Promise((resolve, reject) => {
      this._http.doGet({ url: REST_GEOCODE_REVERSE + '/' + lat + '/' + lon })
        .subscribe({
          next: (response: any) => {
            resolve(new DataTable(response));
          },
          error: (error: any) => {
            reject(new Error(error));
          }
        });
    });
  }
}
