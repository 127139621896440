import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-lightning-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css']
})
export class IconComponent implements OnInit {
  @Input() set iconName(iconName: string) {
    this._iconName = this.normalizeIconName(iconName);
  }
  get iconName() {
    return this._iconName;
  }
  /**
   * The appearance of a `utility` icon.
   */
  @Input() variant: 'default' | 'warning' | 'error' | 'light' | 'inverse' | 'success' | null = 'default';

  /**
   * The size of the icon.
   */
  @Input() size!: 'xx-small' | 'x-small' | 'small' | 'medium' | 'large';

  /**
   * Text used to describe the icon for accessibility.
   */
  @Input() alternativeText!: string;


  /**
   * CSS classes that are applied to the SVG.
   */
  @Input() svgClass!: string | string[] | Set<string> | { [klass: string]: any };

  @Input() decoratorClasses!: string;
  
  public _iconName!: string;
  public _containerClasses!: string;
  public _svgClasses!: string;
  public iconPath!: string;
  constructor() {
    this._containerClasses = '';
    this._svgClasses = '';
    this.variant = 'default';
    this.decoratorClasses = '';
   }

  ngOnInit(): void {
    const [category, icon] = this.iconName.split(':');
    const kebabCaseName = icon.replace(/_/g, '-');
    const isUtility = category === 'utility';
    const isDefaultOrInverse = this.variant === 'default' || this.variant === 'inverse';

    const classesSvg: any [] = [
        this.size ? `slds-icon_${this.size}`: `slds-icon_medium`,
        (isDefaultOrInverse ?
        (this.variant === 'default' ? isUtility : !isUtility)
        : this.variant) ? `slds-icon-text-${isDefaultOrInverse ? 'default' : this.variant}`  : ''
    ];
    
    classesSvg.forEach((c: String) => {
      if(c!=='') {
        this._svgClasses = this._svgClasses + c + ' ';
      }
    });
    const classesContainer: any [] = [
       category !== 'utility' ? `slds-icon_container`: '',
       category === 'action' ? `slds-icon_container_circle`: '',
       category !== 'utility' && category !== 'doctype' ? `slds-icon-${category}-${kebabCaseName}`: '',
    ];
    
    classesContainer.forEach((c: String) => {
      if(c!=='') {
        this._containerClasses = this._containerClasses + c + ' ';
      }
    });

    this.iconPath = `/assets/icons/${category}-sprite/svg/symbols.svg#${icon}`;
  
  }
  normalizeIconName(iconName: string) {
    return iconName.indexOf(':') > -1 ? iconName : `utility:${iconName}`;
  }

}
