import { Extent } from './Extent.js';

export class MapOptions {
  public elementId: string | HTMLDivElement;
  public key?: string;
  public style?: string;
  public center?: [number, number];
  public zoomStart?: number;
  public projection?: any;
  public pitch?: number;
  public extent?: Extent;
  public mapTilerKey?: string;
  public mapControls?: boolean;

  constructor(options: {
    elementId: string;
    key?: string;
    style?: string;
    center?: [number, number];
    zoomStart?: number;
    projection?: any;
    pitch?: number;
    extent?: Extent;
    mapTilerKey?: string;
    mapControls?: boolean;
  }) {
    this.elementId = options.elementId;
    this.key = options.key;
    this.center = options.center;
    this.zoomStart = options.zoomStart;
    this.projection = options.projection;
    this.pitch = options.pitch;
    this.mapTilerKey = options.mapTilerKey;
    this.extent = options.extent;
    this.mapControls = options.mapControls;
  }
}
