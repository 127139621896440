import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-lightning-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {
  /**
   * The size of the spinner.
   */
  @Input() size!: 'xx-small' | 'x-small' |  'small' | 'medium' | 'large';

  /**
   * The variant changes the appearance of the spinner.
   */
  @Input() variant!: 'brand' | 'inverse' | undefined;

  /**
   * The alternative text used to describe the reason for the wait and need for a spinner.
   */
  @Input() alternativeText!: string;
  constructor() { 
    this.size = 'medium';
    this.variant = undefined;
    this.alternativeText = '';
  }

  ngOnInit(): void {
  }

}
