import { StyleOptions } from '../model/StyleOptions.js';
import { Utils } from '../utils/Utils.js';
import { MapboxStyle } from './MapboxStyle.js';

export class LineStyle extends MapboxStyle {
  constructor(style: StyleOptions) {
    super(style);
  }

  public getFilter() {
    return undefined;
  }
  public getLayerLayout() {
    return undefined;
  }
  public getLayerType() {
    return 'line';
  }
  public getLayerPaint() {
    // TODO handle line color by range
    const paint: any = {
      'line-color': this.style.lineColor ? Utils.getColor(this.style.lineColor) : '#ffffff',
      'line-width': this.style.lineWidth ? this.style.lineWidth : 1,
    };
    if (this.style.ranges) {
      paint['line-color'] = this.style.ranges.transformRangesToSteps();
    } else if (this.style.matches) {
      paint['line-color'] = this.style.matches.transformMatch();
    }
    if (this.style.lineStyle === 'dash') {
      paint['line-dasharray'] = [2, 2];
    }
    return paint;
  }
}
