import { Locale } from '@galigeo-store/ui-lightning';

export class LocaleMappingFieldsComponent extends Locale {
  constructor() {
    super();
    this.languages = {
      en: {
        step_select_columns_col_file: 'File Columns',
        step_select_columns_col_values: 'Corresponding Value',
        step_select_columns_chosen_field: 'Select a value',
        step_select_columns_duplicate: 'Warning, you have assigned the same value twice ',
        step_select_columns_chosen_field_number: 'Integer',
        step_select_columns_chosen_field_double: 'Decimal',
        step_select_columns_chosen_field_boolean: 'Boolean',
        step_select_columns_chosen_field_date: 'Date',
        step_select_columns_chosen_field_text: 'Text',
      },
      fr: {
        step_select_columns_col_file: 'Colonnes du fichier',
        step_select_columns_col_values: 'Valeur correspondante',
        step_select_columns_chosen_field: 'Sélectionner une valeur',
        step_select_columns_duplicate: 'Attention, vous avez attribuez 2 fois la même valeur ',
        step_select_columns_chosen_field_number: 'Entier',
        step_select_columns_chosen_field_double: 'Décimal',
        step_select_columns_chosen_field_boolean: 'Booléen',
        step_select_columns_chosen_field_date: 'Date',
        step_select_columns_chosen_field_text: 'Texte',


      },
    };
    this.genrateMap();
  }
}
