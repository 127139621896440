import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CollectionValue, DataTable } from '@galigeo-store/shared-models';

@Component({
  selector: 'ui-lightning-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit {
  @Input() values!: CollectionValue[];
  @Input() defaultVal!: string;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {

  }

  Select(e : any){
    console.log(e.target.value);
    this.action.emit(e.target.value);
  }
}
