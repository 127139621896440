export class ButtonGrpOptions {
    items!: Button[];
}

export class Button {
    icon?: string;
    label?: string;
    action!: string;
    disabled?: boolean;
    title?: string;
}