import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthguardService, FieldsService, GeocodeService, HttpService, PoiService } from '@galigeo-store/retail-services';
import { AlertOptions, BusinessType, ConfirmOptions, DataTable, Field, MESSAGE_LEVEL } from '@galigeo-store/shared-models';
import { EventService, FileImportOptions, FileType, LanguageService, MessageService, ModalService, StepperItem, StepperOptions } from '@galigeo-store/ui-lightning';
import { LocaleWizardUpdateComponent } from 'src/app/shared/locales/localeWizardUpdateComponent';
import { AbstractContainer } from 'src/app/shared/models/abstractContainer';
import { Alert } from 'src/app/shared/models/alert';
import { Network } from 'src/app/shared/models/network';
import { MappingFieldsEvent } from '../../shared/mapping-fields/mapping-fields.component';
import { AddOnly } from './merge/add-only';
import { DataFusion } from './merge/data-fusion';
import { OverrideData } from './merge/override-data';

enum StepNames {
  ADD_FILE = 'addFile',
  SELECT_COLUMNS = 'selectColomns',
  FUSION = 'fusion',
}

enum MergeType {
  MERGEAPP = 'mergeApp',
  MERGEFILE = 'mergeFile',
  REPLACE = 'replace',
}

@Component({
  selector: 'retail-wizard-update',
  templateUrl: './wizard-update.component.html',
  styleUrls: ['./wizard-update.component.css']
})
export class WizardUpdateComponent extends AbstractContainer implements OnInit {

  public avoidSpamClick: boolean = false;
  public previewDtMergeApp!: DataTable;
  public previewDtMergeFile!: DataTable;
  public alertGeocodage!: Alert[];
  public stepperOptions!: StepperOptions;
  public StepNames = StepNames;
  public MergeType = MergeType;
  public currentNetwork!: DataTable;
  public newNetwork!: DataTable;
  public mergeType: MergeType = MergeType.REPLACE;
  public networkId!: number;
  public countApp = <{ countUpdated: number, countAdded: number }>{};
  public countColumnAdded = 0;
  public columnUpdated: string[] = [];
  public columnAdded: string[] = [];
  public dataTableMappingResult!: MappingFieldsEvent;
  public originalData!: DataTable;
  //temporaire
  public tooltipsTmp: string = "";

  importFileOptions!: FileImportOptions;

  fields: Field[] = [];
  ready: boolean = false;
  // geocodageAlertActive: boolean = false;

  constructor(
    private fieldsService: FieldsService,
    override  poiService: PoiService,
    override  router: Router,
    override  route: ActivatedRoute,
    override  translateService: LanguageService,
    override  messageService: MessageService,
    override  eventService: EventService,
    override  authguardService: AuthguardService,
    override geocoderService: GeocodeService,
    override modalService: ModalService
  ) {
    super(poiService, router, route, translateService, messageService, eventService, authguardService, geocoderService, modalService);
    this.network = new Network();
    this.tableOptions = {
      withBorders: true,
      canEdit: true,
      resizable: false,
      width: '100%',
      multiSelect: false,
      pageSize: 20
    };
    if (this.authguardService.user.lang) {
      if (this.authguardService.user.lang == 'fr') {
        this.translateService.setLang(this.authguardService.user.lang);
      } else {
        this.translateService.setLang('en');
      }
    } else if (navigator.language.startsWith('fr')) this.translateService.setLang('fr');
    this.translateService.addTranslation('WizardUpdateComponent', new LocaleWizardUpdateComponent().locale);
  }

  ngOnInit(): void {
    this.fieldsService.getFields().subscribe((dt: DataTable) => {
      this.fields = dt.toJson();
    });
    this.route.paramMap.subscribe((params) => {
      if (params.get('poiSetId') === undefined) throw new Error('poiSetId is undefined');
      this.networkId = parseInt(params.get('poiSetId')!);
      this.poiService.getPOIs(this.networkId).subscribe((res: DataTable) => {
        this.currentNetwork = res;
      });

    });
    this.initOptions();
    this.importFileOptions = { title: this.translateService.getTranslation('WizardUpdateComponent', 'upload_a_csv'), type: FileType.DATA };
  }

  initOptions() {
    this.tableOptions.canEdit = this.authguardService.hasCapability('editNetwork');
    this.stepperOptions = new StepperOptions({
      steps: [
        {
          name: StepNames.ADD_FILE,
          title: this.translateService.getTranslation('WizardUpdateComponent', 'step_add_file_title'),
          label: this.translateService.getTranslation('WizardUpdateComponent', 'step_add_file_label'),
          description: this.translateService.getTranslation('WizardUpdateComponent', 'step_add_file_description'),
          nextDisabled: true,
        },
        {
          name: StepNames.SELECT_COLUMNS,
          tittitle: this.translateService.getTranslation('WizardUpdateComponent', 'step_select_columns_title'),
          label: this.translateService.getTranslation('WizardUpdateComponent', 'step_select_columns_label'),
          description: this.translateService.getTranslation('WizardUpdateComponent', 'step_select_columns_description'),
          nextDisabled: true,
        },
        {
          name: StepNames.FUSION,
          title: this.translateService.getTranslation('WizardUpdateComponent', 'step_merge_title'),
          label: this.translateService.getTranslation('WizardUpdateComponent', 'step_merge_label'),
          description: this.translateService.getTranslation('WizardUpdateComponent', 'step_merge_description'),
        },
      ],
      active: true,
      currentStep: 0,
    });

  }

  cardStepperClick(event: any) {
    const currentStep: StepperItem = this.stepperOptions.getCurrentStep();
    switch (event.btn.action) {
      case 'cancel':
        this.handleCancelAction();
        break;
      case 'previous':
        this.handlePreviousAction();
        break;
      case 'next':
        this.handleNextAction(currentStep);
        break;
      case 'finish':
        this.handleFinishAction(currentStep);
        break;
      default:
        console.log('default');
        break;
    }
  }

  private handleCancelAction() {
    console.log('cancel');
    this.router.navigate(['/network', this.networkId], { queryParams: HttpService.getQueryParams() });
  }

  private handlePreviousAction() {
    console.log('previous');
    this.stepperOptions.currentStep = this.stepperOptions.currentStep! - 1;
  }

  private handleNextAction(currentStep: StepperItem) {
    console.log('Go to next step from ' + this.stepperOptions.currentStep);
    switch (currentStep.name) {
      case StepNames.ADD_FILE:
        this.stepperOptions.currentStep = this.stepperOptions.currentStep! + 1;
        break;
      case StepNames.SELECT_COLUMNS:
        this.handleNextForSelectColumns();
        break;
      case StepNames.FUSION:
        break;
    }
  }

  private handleNextForSelectColumns() {
    // detect doublon
    // detect null identification in this.originalData
    const inconsistencies = this.detectInconsistencies(this.originalData);
    if (inconsistencies.messageError !== '') {
      this.messageService.alert(new AlertOptions({
        title: this.translateService.getTranslation('WizardUpdateComponent', 'alert_title_inconsistencies_error'),
        message: inconsistencies.messageError,
        level: MESSAGE_LEVEL.ERROR
      }));
    } else if (inconsistencies.messageLimitError !== '') {
        this.messageService.alert(new AlertOptions({
          title: this.translateService.getTranslation('WizardUpdateComponent', 'alert_title_inconsistencies_error'),
          message: inconsistencies.messageLimitError,
          level: MESSAGE_LEVEL.ERROR
        }));
    } else {
      this.newNetwork = this.dataTableMappingResult.getResult();
      this.previewDtMergeApp = this.currentNetwork.clone();
      this.previewDtMergeFile = this.currentNetwork.clone();
      this.mergingData(this.previewDtMergeApp, false);
      this.mergingData(this.previewDtMergeFile, true);

      //Waiting for tooltips
      this.updateTooltips();
      if (inconsistencies.messageWarning !== '') {
        this.messageService.confirm(new ConfirmOptions({
          title: this.translateService.getTranslation('WizardUpdateComponent', 'confirm_modal_title'),
          message: inconsistencies.messageWarning,
          level: MESSAGE_LEVEL.INFO,
          callback: (result: boolean) => {
            if (result) {
              this.stepperOptions.currentStep = this.stepperOptions.currentStep! + 1;
            }
          }
        }));
      } else {
        this.stepperOptions.currentStep = this.stepperOptions.currentStep! + 1;
      }
    } 
  }

  private updateTooltips() {
    if (this.countApp.countUpdated !== 0 || this.countApp.countAdded !== 0) {
      const updatedStr = this.columnUpdated.length !== 0 ? `Updated: ${this.columnUpdated.join(', ')}` : '';
      const addedStr = this.columnAdded.length !== 0 ? `Added: ${this.columnAdded.join(', ')}` : '';

      this.tooltipsTmp = updatedStr + '\n' + addedStr;
    }
  }


  private handleFinishAction(currentStep: StepperItem) {
    if (currentStep.name === StepNames.FUSION) {
      this.handleFusionFinish();
    }
  }

  private handleFusionFinish() {
    switch (this.mergeType) {
      case MergeType.MERGEAPP:
        this.handleMergeApp();
        break;
      case MergeType.MERGEFILE:
        this.handleMergeFile();
        break;
      case MergeType.REPLACE:
        this.handleReplace();
        break;
      default:
        break;
    }
  }

  private handleMergeApp(): void {
    if (this.avoidSpamClick) return;
    this.eventService.emitEvent('global-spinner', { action: 'show', obj: this.translate('global_spinner_message') });
    this.poiService.replacePOI({ networkId: this.networkId, pois: this.previewDtMergeApp, overrideGeocoding: false, overrideLatLong: false, replaceAll: false }).subscribe(() => {
      this.eventService.emitEvent('global-spinner', { action: 'hide', obj: this.translate('global_spinner_message') });
      this.navigateToNetwork();
    });
    this.avoidSpamClick = true;
  }

  private handleMergeFile(): void {
    if (this.avoidSpamClick) return;
    this.eventService.emitEvent('global-spinner', { action: 'show', obj: this.translate('global_spinner_message') });
    const overrideGeocoding = this.newNetwork.fields.some((f: Field) => f.businessType === BusinessType.ADDRESS || f.businessType === BusinessType.CITY || f.businessType === BusinessType.POSTAL_CODE);
    const overrideLatLong = this.newNetwork.fields.some((f: Field) => f.businessType === BusinessType.LAT || f.businessType === BusinessType.LONG);
    this.poiService.replacePOI({ networkId: this.networkId, pois: this.previewDtMergeFile, overrideGeocoding, overrideLatLong, replaceAll: false }).subscribe(() => {
      this.eventService.emitEvent('global-spinner', { action: 'hide', obj: this.translate('global_spinner_message') });
      this.navigateToNetwork();
    });
    this.avoidSpamClick = true;
  }

  private handleReplace(): void {
    if (this.avoidSpamClick) return;
    this.eventService.emitEvent('global-spinner', { action: 'show', obj: this.translate('global_spinner_message') });
    this.poiService.replacePOI({ networkId: this.networkId, pois: this.newNetwork, overrideGeocoding: false, overrideLatLong: false, replaceAll: true }).subscribe(() => {
      this.eventService.emitEvent('global-spinner', { action: 'hide', obj: this.translate('global_spinner_message') });
      this.navigateToNetwork();
    });
    this.avoidSpamClick = true;
  }

  private navigateToNetwork(): void {
    this.router.navigate(['/network', this.networkId], { queryParams: HttpService.getQueryParams() });
  }
  translate(key: string) {
    return this.translateService.getTranslation('WizardUpdateComponent', key);
  }
  mergingData(dataTable: DataTable, canUpdate: boolean) {

    let dataFusion: DataFusion;
    if (canUpdate) dataFusion = new OverrideData();
    else dataFusion = new AddOnly();

    let result = dataFusion.mergeData(dataTable, this.newNetwork);
    this.countColumnAdded = result.numNewColumns;
    this.countApp.countAdded = result.numNewRecords;
    this.countApp.countUpdated = result.numOverridenRecords;
  }

  onDataLoaded(event: any) {
    this.originalData = event.data;
    this.defaultBusinessType();
    this.stepperOptions.enableNext();
    this.stepperOptions.goToStep(StepNames.SELECT_COLUMNS, false);
  }

  mappingResultWizardUpdate(event: any) {
    let valid = !event.isValid().hasDuplicate;
    if (valid) {
      this.stepperOptions.enableNext();
      this.dataTableMappingResult = event;
    } else {
      this.stepperOptions.enableNext(false);
    }
  }

  isThereABussinessTypeId() {
    return this.newNetwork.fields.find((f: Field) => f.businessType === BusinessType.ID) && this.currentNetwork.fields.find((f: Field) => f.businessType === BusinessType.ID);
  }

  countUpdate() {
    if (this.currentNetwork.getFieldByBusinessType(BusinessType.ID) && this.newNetwork.getFieldByBusinessType(BusinessType.ID)) {
      const currentlds = new Map<string, boolean>();
      const idIdx = this.currentNetwork.fields.indexOf(this.currentNetwork.getFieldByBusinessType(BusinessType.ID));
      const newIdIdx = this.newNetwork.fields.indexOf(this.newNetwork.getFieldByBusinessType(BusinessType.ID));
      for (let record of this.currentNetwork.data) {
        currentlds.set(record.values[idIdx], true);
      }
      let countUpdated = 0;
      let countAdded = 0;
      for (let record of this.newNetwork.data) {
        if (currentlds.has(record.values[newIdIdx])) {
          countUpdated++;
        } else {
          countAdded++;
        }
      }
      return { countUpdated, countAdded };
    }
    return { countUpdated: 0, countAdded: 0 };
  }

  defaultBusinessType() {
    this.originalData?.fields?.forEach((field: Field) => {
      const correspondingField = this.currentNetwork.fields.find((f: Field) => f.alias === field.name);
      if (correspondingField?.businessType) {
        field.businessType = correspondingField.businessType;
      }
    });
  }

  onMergeTypeChange(event: any) {
    this.avoidSpamClick = false;
    switch (event.target.value) {
      case MergeType.MERGEAPP:
        this.mergeType = MergeType.MERGEAPP;
        break;
      case MergeType.MERGEFILE:
        this.mergeType = MergeType.MERGEFILE;
        break;
      case MergeType.REPLACE:
        this.mergeType = MergeType.REPLACE;
        break;
    }
  }
}
