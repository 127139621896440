import * as maplibregl from 'maplibre-gl';
import { MapBoxLayer } from '../layer/MapBoxLayer.js';
import { PopupUtils } from './PopupUtils.js';
import { DragHandler } from './DragHandler.js';

export class TooltipUtils {
  private static timeout: any;
  private static popup: maplibregl.Popup;

  public static registerTooltip(map: maplibregl.Map, layer: MapBoxLayer): void {
    // Create a popup, but don't add it to the map yet.
    if (this.popup) this.popup.remove();
    this.popup = new maplibregl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    const onMouseMove = (e: any) => {
      // Change the cursor style as a UI indicator.
      clearTimeout(this.timeout);

      // no tooltip when dragging
      if (DragHandler.dragInProgress) return;

      map.getCanvas().style.cursor = 'pointer';

      const description = PopupUtils.getContent(e.features[0].properties, layer.getTooltipContent());

      // Populate the popup and set its coordinates
      // based on the feature found.
      this.popup.setLngLat(e.lngLat).setHTML(description).addTo(map);

      this.timeout = setTimeout(() => {
        this.popup.remove();
      }, 1000);
    };

    const onMouseLeave = () => {
      map.getCanvas().style.cursor = '';
      this.popup.remove();
    };

    map.on('mousemove', layer.getId(), onMouseMove);
    map.on('mouseleave', layer.getId(), onMouseLeave);
    map.on('click', layer.getId(), onMouseLeave);

    // Store the event handlers so they can be removed later
    (layer as any)._onMouseMove = onMouseMove;
    (layer as any)._onMouseLeave = onMouseLeave;
    (layer as any)._onClick = onMouseLeave;

    // highlight feature on hover
    let hoveredStateId: any = null;
    map.on('mousemove', layer.getId(), (e: any) => {
      if (DragHandler.dragInProgress) return;
      if (e.features.length > 0) {
        if (hoveredStateId) {
          map.setFeatureState({ source: layer.getSourceId(), id: hoveredStateId }, { hover: false });
        }
        hoveredStateId = e.features[0].id;
        map.setFeatureState({ source: layer.getSourceId(), id: hoveredStateId }, { hover: true });
      }
    });

    // When the mouse leaves the state-fill layer, update the feature state of the
    // previously hovered feature.
    map.on('mouseleave', layer.getId(), () => {
      if (hoveredStateId) {
        map.setFeatureState({ source: layer.getSourceId(), id: hoveredStateId }, { hover: false });
      }
      hoveredStateId = null;
    });
  }

  public static unregisterTooltip(map: maplibregl.Map, layer: MapBoxLayer): void {
    const onMouseMove = (layer as any)._onMouseMove;
    const onMouseLeave = (layer as any)._onMouseLeave;
    const onClick = (layer as any)._onClick;

    if (onMouseMove) {
      map.off('mousemove', layer.getId(), onMouseMove);
    }
    if (onMouseLeave) {
      map.off('mouseleave', layer.getId(), onMouseLeave);
    }
    if (onClick) {
      map.off('click', layer.getId(), onClick);
    }
  }
}
