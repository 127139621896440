import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-lightning-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css'],
})
export class ProgressBarComponent implements OnInit {
  @Input() backgroundClass: string;
  @Input() valueNow!: number;
  @Input() valueMin!: number;
  @Input() valueMax!: number;
  @Input() placeholder: string;
  @Input() loop: boolean;
  constructor() {
    this.backgroundClass = '';
    this.valueNow = 0;
    this.valueMin = 0;
    this.valueMax = 100;
    this.placeholder = '';
    this.loop = false;
  }

  ngOnInit(): void {
    if(this.loop) {
      this.valueNow = 0;
      setInterval(()=> {
        if(this.valueNow > 100) {
          this.valueNow = 0;
        }else {
          this.valueNow = this.valueNow + this.valueMax / 100;
        }
      }, 100);
    }
  }
}
