import { StyleOptions } from './StyleOptions.js';

export enum LayerType {
  Cluster = 'Cluster',
  HeatMap = 'HeatMap',
  Ranges = 'Ranges',
  Matches = 'Matches',
  Geobubble = 'Geobubble',
  Simple = 'Simple',
  Building = 'Building',
}

export class LayerOptions {
  id: string;
  style: StyleOptions;
  type: LayerType;
  hoverPopup?: boolean;
  popup?: boolean;
  afterLayer?: string;
  popupContent?: string;
  tooltipContent?: string;
  dragable?: boolean;
  maxzoom?: number;
  minzoom?: number;
  displayOnTop?: boolean;

  constructor(options: {
    id: string;
    style: StyleOptions;
    type: LayerType;
    hoverPopup?: boolean;
    popup?: boolean;
    afterLayer?: string;
    popupContent?: string; // set a template for the popup
    tooltipContent?: string; // set a template for the tooltip
    dragable?: boolean; // allow to drag a point
    maxzoom?: number;
    minzoom?: number;
    displayOnTop?: boolean;
  }) {
    this.id = options.id;
    if (options.style instanceof StyleOptions) {
      this.style = options.style;
    } else {
      this.style = new StyleOptions(options.style);
    }
    this.type = options.type;
    this.hoverPopup = options.hoverPopup;
    this.popup = options.popup;
    this.afterLayer = options.afterLayer;
    this.popupContent = options.popupContent;
    this.tooltipContent = options.tooltipContent;
    this.dragable = options.dragable;
    this.maxzoom = options.maxzoom;
    this.minzoom = options.minzoom;
    this.displayOnTop = options.displayOnTop;
  }
}
