import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form.component';
import { TabsModule } from '../tabs/tabs.module';
import { FormItemComponent } from './form-item/form-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormItemGeneratorComponent } from './form-item-generator/form-item-generator.component';



@NgModule({
  declarations: [
    FormComponent,
    FormItemComponent,
    FormItemGeneratorComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TabsModule,
    FormsModule
  ],
  exports: [FormComponent,
    FormItemComponent]
})
export class FormModule { }
