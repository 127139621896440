import { Renderer } from './Renderer.js';
import * as turf from '@turf/turf';
export class LabelRenderer implements Renderer {
  public text: string;
  public size: number;
  public fields: any[];
  public backgroundColor?: string | string[];
  public textColor?: string | string[];
  constructor(options: { text: string; fields: any[]; size?: number; backgroundColor?: string | string[]; textColor?: string | string[] }) {
    this.text = options?.text;
    this.fields = options?.fields;
    this.size = options?.size ?? 12;
    this.backgroundColor = options?.backgroundColor;
    this.textColor = options?.textColor;
  }
  recalculate(features: any[], options?: any): any {
    const featuresLabel: any[] = [];
    features.forEach((feature) => {
      let geom = undefined;

      if (feature.geometry.type === 'MultiPolygon') {
        const multiPolygon = turf.multiPolygon(feature.geometry.coordinates);
        geom = turf.centroid(multiPolygon);
      } else if (feature.geometry.type === 'Polygon') {
        const polygon = turf.polygon(feature.geometry.coordinates);
        geom = turf.centroid(polygon);
      } else if (feature.geometry.type === 'MultiLineString') {
        const multiLineString = turf.multiLineString(feature.geometry.coordinates);
        geom = turf.centroid(multiLineString);
      } else if (feature.geometry.type === 'LineString') {
        const lineString = turf.lineString(feature.geometry.coordinates);
        geom = turf.centroid(lineString);
      } else if (feature.geometry.type === 'Point') {
        geom = feature;
      }
      featuresLabel.push({
        type: 'Feature',
        geometry: geom.geometry,
        properties: { points_label: this.convertLabel(this.text, options?.fields, feature.properties) },
      });
    });
    return featuresLabel;
  }
  convertLabel(text: string, fields: any[], properties: any) {
    if (text) {
      let value;
      text = text.split('</br>').join('\n');
      text = text.split('<br>').join('\n');
      if (fields) {
        let transformed = false;
        for (let i = 0; i < fields.length; i++) {
          const fieldName = fields[i].name;
          const alias = fields[i].alias;
          if (text.indexOf('[' + alias + ']') >= 0) {
            value = properties[fieldName];
            /*if (value !== '' && !isNaN(value) && (this.fields[i].type === 'esriFieldTypeDouble' || this.fields[i].type === 'esriFieldTypeInteger')) {
							value = this.formater.format(value);
						}*/
            /*if (value !== '' && (this.fields[i].type === 'esriFieldTypeDate')) {
							value = this.dateFormater.format(value);
						}*/
            text = text.split('[' + alias + ']').join(value);
            transformed = true;
          }
        }
        if (transformed) return text;
      }

      for (const rawName in properties) {
        if (text.indexOf('[' + rawName + ']') >= 0) {
          value = properties[rawName];
          //if (value !== '' && !isNaN(value)) value = this.formater.format(value);
          if (value && value !== 'undefined') {
            text = text.split('[' + rawName + ']').join(value);
          } else {
            text = text.split('[' + rawName + ']').join('');
          }
        }
        if (text.indexOf('[') < 0) return text;
      }

      return text;
    }
    return '';
  }
}
