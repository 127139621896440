import { ClassItem } from './ClassItem.js';

export class RangeRadius {
  field: string;
  classes: ClassItem[];

  constructor(field: string, classes: ClassItem[]) {
    this.field = field;
    this.classes = classes;
  }

  public transformRangesToSteps(): any[] {
    const result: any = ['step', ['get', this.field]];
    this.classes.forEach((r: ClassItem, i) => {
      result.push(r.value);
      if (i !== this.classes.length - 1) {
        result.push(r.value);
      }
    });
    return result;
  }
}
