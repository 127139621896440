import { Locale } from '../model';

export class LocaleSearchAddress extends Locale {
  constructor() {
    super();
    this.languages = {
      en: {
        search_placeholder: 'Search for address',
        cancel: 'Cancel',
      },
      fr: {
        search_placeholder: 'Recherche d\'adresse',
        cancel: 'Annuler',
      },
    };
    this.genrateMap();
  }
}
