import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';
import { LookupItem, LookupOptions } from './model';
import { BusinessType, Field } from '@galigeo-store/shared-models';
@Component({
  selector: 'ui-lightning-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.css'],
})
/**
 * LookupComponent : component to display a list of suggesting
 */
export class LookupComponent implements AfterViewInit {
  @Input() showCancel!: boolean;

  @ViewChild('lookupInput') input!: ElementRef<HTMLInputElement>;

  inputText: string = '';
  /**
   * send string when user write in the input
   */
  @Output() onChange = new EventEmitter<string>();
  /**
   * send object item when user click in list
   */
  @Output() onClick = new EventEmitter<any>();
  /**
   * list of objects data viewed in the list of suggesting, should have value attribute
   * sample: [
    { value: 'Paris', ob1: [2.04, 48.73] },
    { value: 'Reims', ob1: [3.98, 49.25] },
    { value: 'Nancy', ob1: [6.13, 48.68] },
    { value: 'Nantes', ob1: [-1.6, 47.23] },
    { value: 'Bordeaux', ob1: [-0.65, 44.86] },
  ]
  */
  @Input() items!: LookupItem[];
  /**
   * options
   * -  label: label of the input
   * -  debounceTime: time in ms after write to send the searched word
   * -  maxLines: number of max lines showed in the list , default 10
   * -  placeholder
   */
  @Input() options!: LookupOptions;
  /**
   * form controller of the lookup input
   */
  /** loading by server
  */
  @Input() loading!: boolean;


  lookupForm: FormGroup = new FormGroup({
    lookup: new FormControl(),
  });
  /**
   * listener of user write
   */
  public obs: Subscription | undefined;

  /**
   * used when user click in the list
   */
  public isClick: boolean = false;
  /**
   * component construct
   */

  @Input() defaultValue!: string;

  firstOpen = true;

  constructor() {
    this.loading = false;
    this.options = new LookupOptions({
      label: 'Lookup',
      debounceTime: 300,
      maxLines: 3,
      placeholder: 'Search'
    });
  }

  clearInput() {
    this.inputText = '';
  }

  onEscape() {
    this.isClick = true;
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    console.log(event)
    this.isClick = true;
  }


  /**
   * listen the formControl after init dom
   * @returns void
   */
  ngAfterViewInit(): void {
    if (this.defaultValue) {
      this.inputText = this.defaultValue;
      this.isClick = true;
    };

    this.obs = this.lookupForm.valueChanges
      .pipe(debounceTime(this.options.debounceTime ? this.options.debounceTime : this.options.debounceTime!))
      .subscribe((data) => {
        if (this.options.showAddressOnly) {
          if (!this.items.find((item) => item.value.data[0].values[item.value.getFieldIndex(item.value.fields.find((f: Field) => f.businessType === BusinessType.GEOCODER_ADDRESS)?.name)] === data.lookup)) {
            this.onChange.emit(data.lookup);
            if (!this.firstOpen) {
              this.isClick = false;
            }
          }
        } else if (!this.items.find((item) => item.label === data.lookup)) {
          this.onChange.emit(data.lookup);
          if (!this.firstOpen) {
            this.isClick = false;
          }
        }
        if (this.firstOpen) {
          this.firstOpen = false;
        }
      });
    setTimeout(() => this.input.nativeElement.focus());
  }
  /**
   * on click on the list item
   * @param  {any} item
   */
  clickItem(item: any) {
    if (this.options.showAddressOnly) {
      this.isClick = true;
      this.onClick.emit(item);
      const address = item.value.data[0].values[item.value.getFieldIndex(BusinessType.GEOCODER_ADDRESS)]
      this.lookupForm.patchValue({
        lookup: address,
        fullAddress: item.label,
      });
    } else {
      this.isClick = true;
      this.onClick.emit(item);
      this.lookupForm.patchValue({
        lookup: item.label,
        fullAddress: item.label,
      });
    }
  }
  onEnter() {
    this.isClick = true;
    if (this.items && this.items.length > 0) {
      this.clickItem(this.items[0]);
    }
  }
  /**
   * destroy the listener
   */
  ngOnDestroy() {
    this.obs?.unsubscribe();
  }
}
