import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from './language.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  constructor(private langageService: LanguageService) { }

  transform(key: string, ...args: unknown[]): unknown {
    if (key.split(':').length > 1) {
      const value = this.langageService.getTranslation(key.split(':')[0], key.split(':')[1]);
      if (value) {
        return value;
      }
    }
    console.log('key ' + key + ' not found');
    return args && args[0] ? args[0] : key;
  }

}
