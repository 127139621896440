import { Style, StyleOptions } from '../model/StyleOptions.js';
import { MapboxStyle } from './MapboxStyle.js';

export class LabelStyle extends MapboxStyle {
  constructor(style: StyleOptions) {
    super(style);
  }

  public getFilter() {
    if (this.style.cluster) {
      return ['!', ['has', 'point_count']];
    }
    return undefined;
  }
  public getLayerLayout() {
    const layout: any = {};

    layout['text-field'] = ['get', 'points_label'];
    layout['text-variable-anchor'] = ['top', 'bottom', 'left', 'right'];
    layout['text-radial-offset'] = 0.5;
    layout['text-size'] = this.style?.label?.size ? this.style.label.size : 12;
    layout['text-justify'] = 'auto';
    layout['text-allow-overlap'] = false;

    return layout;
  }
  public getLayerType() {
    return Style.Symbol;
  }
  public getLayerPaint() {
    const paint: any = {};
    if (this.style?.label?.backgroundColor) {
      paint['text-halo-color'] = this.style.label.backgroundColor;
      paint['text-halo-width'] = 10;
    }
    if (this.style?.label?.textColor) {
      paint['text-color'] = this.style.label.textColor;
    }
    return paint;
  }
}
