import { Injectable } from '@angular/core';
import { Lang, Translations } from './model';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public lang!: Lang;
  public translations: Translations;
  constructor() {

    this.translations = new Translations();
  }
  public setLang(lang: string) {
    this.lang = lang as Lang;
  }
  public addTranslation(file: string, locales: Map<string, Map<string, string>>) {
    if (!this.translations.has(file)) {
      this.translations.set(file, locales);
    }
  }
  public getTranslation(file: string, key: string) {
    return this.translations.translate(file, key, this.lang);
  }
}
