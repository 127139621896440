import * as turf from '@turf/turf';
import * as maplibregl from 'maplibre-gl';
import { MapboxMap } from '../map/MapboxMap.js';
import { MapOptions } from '../model/MapOptions.js';

/**
 * Usage :
 *
 *  const container = MapPrinter.getPrintContainer();
 *  const map = MapFactory.getMap(new MapOptions({ elementId: container.id, width, height, extent }));
 *  map.addLayer(..your layer optionshere..), geojson;
 *  return MapPrinter.print(map, { width, height, container });
 *
 *
 */
export class MapPrinter {
  public static getPrintContainer(opts: { width: number; height: number }): HTMLDivElement {
    const container = document.createElement('div');
    container.style.width = `${opts.width}px`;
    container.style.height = `${opts.height}px`;
    document.body.appendChild(container);
    return container;
  }

  public static async print(map: MapboxMap, opts: { width: number; height: number; container: HTMLDivElement }): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      map.addEventListener('loaded', () => {});
      setTimeout(() => {
        map
          .getMapBoxMap()
          .getCanvas()
          .toBlob((blob: Blob | null) => {
            if (blob) {
              const url = URL.createObjectURL(blob);
              const img = new Image();
              img.src = url;
              img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = opts.width;
                canvas.height = opts.height;
                const context = canvas.getContext('2d');
                if (context) {
                  context.drawImage(img, 0, 0, opts.width, opts.height);
                  const dataUrl = canvas.toDataURL('image/png');
                  document.body.removeChild(opts.container);
                  resolve(dataUrl);
                }
              };
            }
          });
      }, 2000);
    });
  }

  public static async print2(geojson: any, opts: { width: number; height: number }): Promise<string> {
    console.log('Printing map', geojson, opts.width, opts.height);
    return new Promise<string>((resolve, reject) => {
      // Créer un conteneur pour la carte
      const container = document.createElement('div');
      container.style.width = `${opts.width}px`;
      container.style.height = `${opts.height}px`;
      document.body.appendChild(container);

      // Créer une nouvelle instance de la carte
      const map = new maplibregl.Map({
        container: container,
        style: 'https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json',
        center: [0, 0],
        zoom: 1,
        interactive: false,
      });

      // Ajouter la source et la couche pour le geojson
      map.on('load', () => {
        map.addSource('geojson-source', {
          type: 'geojson',
          data: geojson,
        });

        map.addLayer({
          id: 'geojson-layer',
          type: 'fill',
          source: 'geojson-source',
          paint: {
            'fill-color': '#888888',
            'fill-opacity': 0.5,
          },
        });

        // Ajuster la vue pour contenir le geojson
        const bounds = new maplibregl.LngLatBounds();
        geojson.features.forEach((feature: any) => {
          feature.geometry.coordinates[0].forEach((coord: any) => {
            bounds.extend(coord);
          });
        });
        map.fitBounds(bounds, { padding: 20 });

        // Capturer l'image de la carte
        map.once('idle', () => {
          map.getCanvas().toBlob((blob: Blob | null) => {
            if (blob) {
              const url = URL.createObjectURL(blob);
              const img = new Image();
              img.src = url;
              img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = opts.width;
                canvas.height = opts.height;
                const context = canvas.getContext('2d');
                if (context) {
                  context.drawImage(img, 0, 0, opts.width, opts.height);
                  const dataUrl = canvas.toDataURL('image/png');
                  document.body.removeChild(container);
                  resolve(dataUrl);
                }
              };
            }
          });
        });
        map.on('error', (e) => {
          document.body.removeChild(container);
          reject(e.error);
        });
      });
    });
  }
}
