import { Component } from '@angular/core';
import { MessageService } from '../message.service';
import { LanguageService } from '../../locale/language.service';
import { MESSAGE_LEVEL, POSITION, ToastOptions } from '@galigeo-store/shared-models';

@Component({
  selector: 'ui-lightning-message-toast',
  templateUrl: './message-toast.component.html',
  styleUrls: ['./message-toast.component.css']
})
export class MessageToastComponent {

  public options!: ToastOptions;
  public sldsTheme = "slds-theme_error";
  public maxwidth = "300px";
  public position = "top-center"
  public timeout = 6000;
  constructor(private messageService: MessageService, private languageService: LanguageService) {
    this.setOptions(new ToastOptions());
  }

  ngOnInit(): void {

  }

  setOptions(options: ToastOptions) {
    console.error('Toast options', options);
    this.options = options;
    if (!this.options.title) {
      if (this.options.level === MESSAGE_LEVEL.SUCCESS) {
        this.options.title = "Succes";
      } else if (this.options.level === MESSAGE_LEVEL.ERROR) {
        this.options.title = "An error occured";
      } else if (this.options.level === MESSAGE_LEVEL.INFO) {
        this.options.title = "Information";
      } else if (this.options.level === MESSAGE_LEVEL.WARNING) {
        this.options.title = "Warning";
      }
    }
    if (!this.options.message) {
      this.options.message = " --- ";
    }
    switch (this.options.level) {
      case MESSAGE_LEVEL.ERROR:
        this.sldsTheme = "slds-theme_error";
        break;
      case MESSAGE_LEVEL.WARNING:
        this.sldsTheme = "slds-theme_warning";
        break;
      case MESSAGE_LEVEL.SUCCESS:
        this.sldsTheme = "slds-theme_success";
        break;
      case MESSAGE_LEVEL.INFO:
        this.sldsTheme = "slds-theme_info";
        break;
      default:
        this.sldsTheme = "slds-theme_error";
    }

    switch (this.options.position) {
      case POSITION.TOP_RIGHT:
        this.position = "top-right";
        break;
      case POSITION.TOP_CENTER:
        this.position = "top-center";
        break;
      case POSITION.TOP_LEFT:
        this.position = "top-left";
        break;
      case POSITION.BOTTOM_RIGHT:
        this.position = "bottom-right";
        break;
      case POSITION.BOTTOM_CENTER:
        this.position = "bottom-center";
        break;
      case POSITION.BOTTOM_LEFT:
        this.position = "bottom-left";
        break;
      default:
        this.position = "top-center";
    }

    if (this.options.maxwidth) {
      this.maxwidth = this.options.maxwidth;
    }

    if (this.options.timeout) {
      setTimeout(() => {
        this.onClickOk();
      }, this.options.timeout);
    } else {
      setTimeout(() => {
        this.onClickOk();
      }, this.timeout);
    }
  }

  onClickOk() {
    this.messageService.vrc?.remove(); // TODO: search for a better way to remove the component
    if (this.options.callback) {
      this.options.callback();
    }
  }

  private parseError(error: any) {
    if (error?.error?.message) {
      return error.error.message;
    } else if (error.message) {
      return error.message;
    } else if (error.error) {
      return error.error;
    } else if (error.statusText) {
      return error.statusText;
    } else {
      return JSON.stringify(error);
    }

  }

}
