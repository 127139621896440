import { DBObject } from './db-object.js';
export class DBForeignKey extends DBObject {
    name;
    table;
    column;
    foreignTable;
    foreignColumn;
    constructor(options) {
        super();
        this.table = options.table;
        this.column = options.column;
        this.foreignTable = options.foreignTable;
        this.foreignColumn = options.foreignColumn;
        if (options.name) {
            this.name = options.name;
        }
        else {
            this.name = `fk_${this.table}_${this.foreignTable}_${this.column}`;
        }
    }
}
