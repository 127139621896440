import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  TooltipsModule,
  ButtonGroupModule,
  UiLightningSharedModule,
  MenuModule,
  SwitchModule,
  StepperModule,
  FileImportModule,
  TableModule,
  DropdownModule,
  IconModule,
  LocaleModule,
  LookupModule,
  ModalModule,
  SpinnerModule,
  UiLightningGeocodeModule,
  VerticalBtnGroupModule,
} from './components/index';
import { HasCapabilityDirective } from './directives/has-capability.directive';
import { EditMapComponent } from './edit-map/edit-map.component';
import { MapAlertsComponent } from './map-alerts/map-alerts.component';
import { MappingFieldsComponent } from './mapping-fields/mapping-fields.component';
import { ModalAddressComponent } from './modal-address/modal-address.component';
import { ModalNoGeomWarningComponent } from './modal-no-geom-warning/modal-no-geom-warning.component';
import { ModalAddressEditComponent } from './modal-address-edit/modal-address-edit.component';
import { GeocodeModule, DataModule, UserModule } from './services/index';


@NgModule({
  declarations: [
    MappingFieldsComponent,
    EditMapComponent,
    MapAlertsComponent,
    ModalAddressComponent,
    HasCapabilityDirective,
    ModalNoGeomWarningComponent,
    ModalAddressEditComponent
  ],
  imports: [
    FormsModule,
    SpinnerModule,
    IconModule,
    ModalModule,
    LocaleModule,
    LookupModule,
    UiLightningGeocodeModule,
    VerticalBtnGroupModule,
    GeocodeModule,
    CommonModule,
    TableModule,
    DropdownModule,
    FileImportModule,
    StepperModule,
    SwitchModule,
    MenuModule,
    UiLightningSharedModule,
    ButtonGroupModule,
    TooltipsModule,
    DataModule,
    UserModule,
  ],
  exports: [
    MappingFieldsComponent,
    EditMapComponent,
    MapAlertsComponent,
    ModalAddressComponent,
    HasCapabilityDirective,
  ]
})
export class SharedModule { }
