import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import { ModalContentComponent } from './modal-content/modal-content.component';



@NgModule({
  declarations: [
    ModalComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalContentComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [ModalComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalContentComponent]
})
export class ModalModule { }
