export class ImageLoader {
    public static getImages(data: any):{name:string, url:string}[] {
        const result:{name:string, url:string}[] = [];
        if(data?.drawingInfo?.renderer?.uniqueValueInfos) {
            for(const uniqueValue of data.drawingInfo.renderer.uniqueValueInfos) {
                if(uniqueValue.symbol?.imageData) {
                    result.push({name: uniqueValue.value, url: 'data:image/png;base64,' + uniqueValue.symbol?.imageData});
                }
            }
        }

        if(data?.drawingInfo?.renderer?.classBreakInfos) {
            for(const classValue of data.drawingInfo.renderer.classBreakInfos) {
                if(classValue.symbol?.imageData) {
                    result.push({name: 'class'+classValue.label, url: 'data:image/png;base64,' + classValue.symbol?.imageData});
                }
            }
        }
        if(data?.drawingInfo?.renderer?.symbol?.imageData) {
            result.push({name: data.uniqueId + '-image', url: 'data:image/png;base64,' + data.drawingInfo.renderer.symbol.imageData})
        }
        if(data?.imageMap) {
            for(const [key, value] of data.imageMap) {
                result.push({name: key, url: 'data:image/png;base64,' + value});
            }
        }
        return result;
    }
}