
import { Parser } from './parser';

export class ImageParser implements Parser<string> {
    parse(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                try {
                    let data: string = e.target.result;
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            };
            reader.readAsDataURL(file);
        });
    };


}