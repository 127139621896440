import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export class GgoEvent {
  action!: string;
  obj: any;
}
@Injectable({
  providedIn: 'root'
})
export class EventService {

  public eventMap: Map<string, Subject<GgoEvent>> = new Map<string, Subject<GgoEvent>>();
  constructor() { }
  emitEvent(caller: string , event: GgoEvent) {
    let subject = this.eventMap.get(caller);
    if(!subject){
      subject = new Subject<GgoEvent>();
      this.eventMap.set(caller, subject);
    }
    subject.next(event);
    
    
  }
  listenEvent(caller: string) {
    let subject = this.eventMap.get(caller);
    if(!subject) {
      subject = new Subject<GgoEvent>();
      this.eventMap.set(caller, subject);
    } 
    return subject.asObservable();
  }

  offListener(caller: string) {
    let subject = this.eventMap.get(caller);
    if(subject) {
      subject.unsubscribe();
      this.eventMap.delete(caller);
    }
  }
}
