import * as turf from '@turf/turf';
import * as maplibregl from 'maplibre-gl';
import { Extent } from '../model/Extent.js';
import { LayerOptions } from '../model/LayerOptions.js';
import { AbstractLayer } from './AbstractLayer.js';
import { MapboxStyle } from '../style/MapboxStyle.js';
import { Utils } from '../utils/Utils.js';
import { DragHandler } from '../utils/DragHandler.js';

export abstract class MapBoxLayer extends AbstractLayer {
  protected map: maplibregl.Map;
  protected data?: any;
  protected hoverPopup: boolean;
  protected popup!: maplibregl.Popup;
  protected layerInstances: Map<string, any> = new Map();
  protected options: LayerOptions;

  constructor(map: maplibregl.Map, options: LayerOptions, data?: any) {
    super();
    this._id = options.id;
    this.map = map;
    this.options = options;
    this.data = data;
    this.hoverPopup = this.options?.hoverPopup ?? false;
    if (this.hoverPopup) {
      this.popup = new maplibregl.Popup({
        closeButton: false,
        closeOnClick: false,
      });
    }
  }
  public getOptions(): LayerOptions {
    return this.options;
  }
  public getSourceId(): string {
    return this.getId() + '-source';
  }
  public getLabelSourceId(): string {
    return this.getId() + '-label-source';
  }
  public deleteSource(): void {
    this.map.removeSource(this.getSourceId());
  }
  public deleteLabelSource(): void {
    this.map.removeSource(this.getLabelSourceId());
  }
  public addLabelSource(data: any) {
    this.map.addSource(this.getLabelSourceId(), {
      type: 'geojson',
      data: { features: data.labelFeatures, type: 'FeatureCollection' } as GeoJSON.FeatureCollection<GeoJSON.Geometry>,
    });
  }
  public setData(data: any): void {
    if (data === undefined) {
      data = {
        type: 'FeatureCollection',
        features: [],
      } as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
    }

    this.data = data;

    if (!this.map.getSource(this.getSourceId())) {
      const source: any = {
        type: 'geojson',
        //generateId: true
      };

      source.data = typeof data === 'string' ? data : (data as GeoJSON.FeatureCollection<GeoJSON.Geometry>);

      if (this.options.style?.cluster) {
        source.cluster = true;
        source.clusterMaxZoom = this.options.style.cluster.clusterMaxZoom;
        source.clusterRadius = this.options.style.cluster.clusterRadius;
      }
      this.map.addSource(this.getSourceId(), source);
    } else {
      (<maplibregl.GeoJSONSource>this.map.getSource(this.getSourceId())).setData(data);
    }
  }

  public getData(): any {
    return this.data;
  }

  public moveFeature(propName: string, propValue: any, coordinates: number[]): void {
    if (propValue === undefined) throw new Error('propValue is undefined');
    for (const feature of this.data.features) {
      if (feature.properties[propName] === propValue) {
        feature.geometry.coordinates = coordinates;
        const source = this.map.getSource(this.getSourceId()) as maplibregl.GeoJSONSource;
        source.setData(this.data);
        return;
      }
    }
    throw new Error('Feature not found');
  }

  public setVisible(visible: boolean): void {
    if (visible) {
      this.map.setLayoutProperty(this.getId(), 'visibility', 'visible');
    } else {
      this.map.setLayoutProperty(this.getId(), 'visibility', 'none');
    }
  }
  public isVisible(): boolean {
    const visibility = this.map.getLayoutProperty(this.getId(), 'visibility');
    return visibility === 'visible';
  }

  public removeInstances(): void {
    this.layerInstances.forEach((value: any) => {
      this.map.removeLayer(value.id);
    });
    for (const layer in this.layerInstances.values()) {
      this.map.removeLayer(layer);
    }
    this.layerInstances.clear();
  }
  public getExtent(): Extent {
    const bbox = turf.bbox(this.data);
    return new Extent(bbox[0], bbox[1], bbox[2], bbox[3]);
  }

  public getPopupContent(): string | undefined {
    if (this.options.popupContent) return this.options.popupContent;
    return undefined;
  }

  public getTooltipContent(): string | undefined {
    if (this.options.tooltipContent) return this.options.tooltipContent;
    return undefined;
  }

  public registerEvents() {
    // Generic click on layer
    this.map.on('click', this.getId(), (e) => {
      e.originalEvent.preventDefault();
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: [this.getId()],
      });
      const res = [];
      for (const feature of features) {
        res.push({ properties: feature.properties, point: e.point });
      }
      this.fireEvent('click', res);
    });

    if (this.options.dragable) {
      console.log('Make layer dragable');
      const dragHandler = new DragHandler(this.map, this); // DragHandler dont work in maplibre-gl
      dragHandler.registerDrag();
    }

    if (this.options.style?.cluster) {
      // inspect a cluster on click
      this.map.on('click', this.getId() + '-clusters', async (e) => {
        e.originalEvent.preventDefault();
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: [this.getId() + '-clusters'],
        });
        const { cluster_id, point_count } = features[0].properties;
        const geojsonSource: maplibregl.GeoJSONSource = this.map.getSource(this.getId() + '-source') as maplibregl.GeoJSONSource;
        const featuresClusterLeaves = await geojsonSource.getClusterLeaves(cluster_id, point_count, 0);
        Utils.zoomToGeoJSON(this.map, turf.featureCollection(featuresClusterLeaves));
      });

      this.map.on('mouseenter', this.getId() + '-clusters', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseleave', this.getId() + '-clusters', () => {
        this.map.getCanvas().style.cursor = '';
      });
    }
    this.map.on('mouseenter', this.getId(), () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', this.getId(), () => {
      this.map.getCanvas().style.cursor = '';
    });
  }

  /*protected registerPopup() {
    this.map.on('mouseenter', this.getId(), (e: any) => {
      this.map.getCanvas().style.cursor = 'pointer';
      const features: any = this.map.queryRenderedFeatures(e.point, {
        layers: [this.getId()],
      });
      const sourcesFeatures: any[] = [];
      features.forEach((feature: any) =>
        sourcesFeatures.push({
          type: 'Feature',
          geometry: feature.geometry,
          properties: feature.properties,
        }),
      );
      if (this.hoverPopup && this.popup) {
        const coordinates = e.features[0].geometry.coordinates.slice();
        let html = '';
        for (const key in e.features[0].properties) {
          if (Object.prototype.hasOwnProperty.call(e.features[0].properties, key)) {
            html = html + key + ':   ' + e.features[0].properties[key] + '</br>';
          }
        }
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        //popup.setLngLat(coordinates).setHTML(description).addTo(map);
        this.popup.setLngLat(coordinates).setHTML(html).addTo(this.map);
      }
      this.fireEvent('mouseenter', {
        point: e.point,
        features: sourcesFeatures,
      });
    });
    this.map.on('mouseleave', this.getId(), () => {
      this.map.getCanvas().style.cursor = '';
      if (this.popup) {
        this.popup.remove();
      }
    });
  }*/

  /**
   * Create all mapbox sublayers of this abstract layer
   */
  public abstract addInstances(): any[];

  /**
   * Create a sub mapbox layer that compose this layer.
   * For example, a polygone is composed of 2 parts:
   * fill + outline
   *
   * @param suffixId
   * @param style
   * @returns a mapbox layer
   */
  protected addInstance(suffixId: string, style: MapboxStyle): any {
    const layer: any = {
      id: this.options.id + suffixId,
      type: style.getLayerType(),
      source: `${this.options.id}-source`,
    };
    if (suffixId === '-label') {
      layer.source = `${this.options.id}-label-source`;
    }
    if (style.getFilter()) {
      layer.filter = style.getFilter();
      console.log(this.getId() + suffixId + ' filter=' + JSON.stringify(layer.filter));
    }
    if (style.getLayerLayout()) {
      layer.layout = style.getLayerLayout();
      console.log(this.getId() + suffixId + ' layout=' + JSON.stringify(layer.layout));
    }
    if (style.getLayerPaint()) {
      layer.paint = style.getLayerPaint();
    }

    if (this.options.maxzoom) {
      layer.maxzoom = this.options.maxzoom;
    }
    if (this.options.minzoom) {
      layer.minzoom = this.options.minzoom;
    }
    this.layerInstances.set(this.getId() + suffixId, layer);
    return layer;
  }

  public addSource() {
    const source: any = {
      type: 'geojson',
      //generateId: true
    };

    source.data = typeof this.data === 'string' ? this.data : (this.data as GeoJSON.FeatureCollection<GeoJSON.Geometry>);

    if (this.options.style?.cluster) {
      source.cluster = true;
      source.clusterMaxZoom = this.options.style.cluster.clusterMaxZoom;
      source.clusterRadius = this.options.style.cluster.clusterRadius;
    }
    this.map.addSource(this.getId() + '-source', source);
  }
}
