import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { REST_POI_API } from '../constants';
import { DataTable } from '@galigeo-store/shared-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class PoiService {

  constructor(private _http: HttpService) { }
  public getBusinessFields(): Observable<DataTable> {
    return this._http.doGet({ url: REST_POI_API + "/businessFields", where: "visible=true" })
      .pipe(map((response: any) => {
        return new DataTable(response);
      }))
  }

  /**
   * Get the network with the given ID.
   * @param networkId 
   * @returns 
   */
  public getPOISet(networkId: number): Observable<DataTable> {
    return this._http.doGet({ url: REST_POI_API, where: 'network_id=' + networkId })
      .pipe(map((response: any) => {
        return new DataTable(response);
      }));
  }


  /**
   * Get the POIs for a given network.
   * @param poiSetId Id of the network
   * @returns A datatable with the individual POIs
   */
  public getPOIs(poiSetId: number): Observable<DataTable> {
    return this._http.doGet({ url: `${REST_POI_API}/${poiSetId}/pois`, errorMessage: 'Failed to get POIs' })
      .pipe(map((response: any) => {
        return new DataTable(response);
      }));
  }

  /**
   * Save or update a network.
   * If an ID is defined, then the network is updated, otherwise it is created.
   * @param opt An object with name, pois and logo of the network to save
   * @returns A DataTable with the saved network info (pois are not part of the response)
   */
  public saveNetwork(opt: { id?: number | undefined, name: string | undefined, pois: DataTable | undefined, logo?: string | undefined, isDefault?: boolean | undefined }): Observable<DataTable> {
    let url!: string;
    if (opt.id && Number.isInteger(Number(opt.id))) {
      url = `${REST_POI_API}/update/${opt.id}`;
    } else if (opt.name) {
      url = `${REST_POI_API}/add?name=${encodeURIComponent(opt.name)}`;
    }
    let body = '';
    if (opt.pois) {
      body = "data=" + encodeURIComponent(JSON.stringify(opt.pois));
    }
    if (opt.name) {
      body += "&name=" + encodeURIComponent(opt.name);
    }
    if (opt.logo) {
      body += "&logo=" + encodeURIComponent(opt.logo);
    }
    if (opt.isDefault !== undefined) {
      body += "&isDefault=" + encodeURIComponent(opt.isDefault);
    }
    return this._http.doPostForm({ url, body, errorMessage: 'Failed to save network ' + name, async: true })
      .pipe(map((response: any) => {
        return new DataTable(response);
      }));
  }

  public updatePOI(opt: { networkId: number, pois: DataTable }): Observable<DataTable> {
    let url: string = `${REST_POI_API}/${opt.networkId}/pois/update`;
    let body = "data=" + encodeURIComponent(JSON.stringify(opt.pois));
    return this._http.doPostForm({ url, body, errorMessage: 'Failed to update POIs' })
      .pipe(map((response: any) => {
        return new DataTable(response);
      }));
  }

  public replacePOI(opt: { networkId: number, pois: DataTable, overrideGeocoding: boolean, replaceAll: boolean }): Observable<DataTable> {
    let url: string = `${REST_POI_API}/${opt.networkId}/pois/replace`;
    let body = "data=" + encodeURIComponent(JSON.stringify(opt.pois)) + "&overrideGeocoding=" + opt.overrideGeocoding + "&replaceAll=" + opt.replaceAll;
    return this._http.doPostForm({ url, body, errorMessage: 'Failed to replace POIs' })
      .pipe(map((response: any) => {
        return new DataTable(response);
      }));
  }

  public deletePOIs(opt: { networkId: number, poiIds: number[] }): Observable<DataTable> {
    let url: string = `${REST_POI_API}/${opt.networkId}/pois/delete`;
    return this._http.doPostForm({ url, body: "poiIds=" + opt.poiIds.join(','), errorMessage: 'Failed to delete POI' })
      .pipe(map((response: any) => {
        return new DataTable(response);
      }));
  }
  public deleteNetwork(networkId: number): Observable<DataTable> {
    let url: string = `${REST_POI_API}/${networkId}/delete`;
    return this._http.doGet({ url, errorMessage: 'Failed to delete network' })
      .pipe(map((response: any) => {
        return new DataTable(response);
      }));
  }

  public duplicateNetwork(networkId: number): Observable<DataTable> {
    let url: string = `${REST_POI_API}/${networkId}/duplicate`;
    return this._http.doGet({ url, errorMessage: 'Failed to duplicate network' })
      .pipe(map((response: any) => {
        return new DataTable(response);
      }));
  }

  public getNetworks(): Observable<DataTable> {
    return this._http.doGet({ url: REST_POI_API })
      .pipe(map((response: any) => {
        return new DataTable(response);
      }));
  }

  public addPOI(opt: { networkId: number, poi: DataTable }): Observable<DataTable> {
    let url: string = `${REST_POI_API}/${opt.networkId}/pois/add`;
    let body = "data=" + encodeURIComponent(JSON.stringify(opt.poi));
    return this._http.doPostForm({ url, body, errorMessage: 'Failed to add POI' })
      .pipe(map((response: any) => {
        return new DataTable(response);
      }));
  }
  public getUrlLogoById(id: number): string {
    return this._http.getBaseUrl() + '/' + `${REST_POI_API}/logos/${id}/image` + '?ggo_token=' + localStorage.getItem('ggo_token');;
  }
  public getLogoById(id: number): Observable<DataTable> {
    return this._http.doGet({ url: `${REST_POI_API}/logos/${id}` }).pipe(map((response: any) => {
      return new DataTable(response);
    }));
  }
  public addLogo(base64: string, name?: string): Observable<DataTable> {
    let url: string = `${REST_POI_API}/logos`;
    let body = "logo=" + encodeURIComponent(base64) + (name ? "&name=" + encodeURIComponent(name) : '');
    return this._http.doPostForm({ url, body, errorMessage: 'Failed to add logo' })
      .pipe(map((response: any) => {
        return new DataTable(response);
      }));

  }
}
