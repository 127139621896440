import { Directive } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { PanelOptions } from './model';

@Component({
  selector: 'ui-lightning-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit {
  @Input() options!: PanelOptions;

  constructor() {
    this.options = {
      title: 'New Panel',
      height: '100vh',
      margin: 'slds-m-around_xxx-small',
      padding: 'slds-p-around_xxx-small',

    }
  }

  ngOnInit(): void {
  }

}

@Directive({ selector: 'ui-lightning-panel-content' })
export class PanelContentDirective { }
