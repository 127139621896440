import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table.component';
import { CheckboxModule } from "../checkbox/checkbox.module";
import { SelectModule } from "../select/select.module";
import { TableCellComponent } from "./table-cell/table-cell.component";
import { IconModule } from '../icon/icon.module';
import { UiLightningSharedModule } from '../shared/shared.module';
import { LocaleModule } from "../locale/locale.module";

@NgModule({
    declarations: [
        TableComponent,
        TableCellComponent,
    ],
    exports: [TableComponent],
    imports: [
        CommonModule,
        CheckboxModule,
        SelectModule,
        IconModule,
        UiLightningSharedModule,
        LocaleModule
    ]
})
export class TableModule { }
