import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-lightning-fiche-info',
  templateUrl: './fiche-info.component.html',
  styleUrls: ['./fiche-info.component.css']
})
export class FicheInfoComponent implements OnInit {
@Input() layers!: any [];
@Output() onSelect = new EventEmitter<any>();
public page: number;
  constructor() {
    this.page = 1;
   }

  ngOnInit(): void {
    if(this.layers && this.layers.length > 0) {
      for (let i = 0; i < this.layers.length; i++) {
        if(this.layers[i].features && this.layers[i].features.length > 0) {
          this.layers[i].open = true;
          this.layers[i].features[0].open = true;
          this.onSelect.emit({layerId: this.layers[i].id , feature: this.layers[i].features[0]});
          break;
        }
        
      }
      for (let i = 0; i < this.layers.length; i++) {
        if(this.layers[i].features && this.layers[i].features.length > 0) {
          this.layers[i].features.forEach((f: any) => {
            f.tableProperties = [];
            for (const key in f.properties) {
             
              if (Object.prototype.hasOwnProperty.call(f.properties, key)) {
                 
                 f.tableProperties.push({key: key, value: f.properties[key]});
              }
            }
          })
        }
        
      }
      console.log(this.layers);
      
    }
  }
  activateTab(layer: any) {
    this.layers.forEach(l => {l.open = false;});
    this.page = 1;
    layer.open = true;
    this.onSelect.emit({layerId: layer.id , feature: layer.features[0]});
  }
  next(layer: any) {
    if(this.page < layer.features.length) {
      this.page = (this.page + 1)
    }
    this.onSelect.emit({layerId: layer.id , feature: layer.features[this.page - 1 ]});
  }
  prev(layer: any) {
    if(this.page > 1) {
      this.page = (this.page - 1);
    }
    this.onSelect.emit({layerId: layer.id , feature: layer.features[this.page - 1 ]});
  }

}
