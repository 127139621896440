import { Point } from './Point.js';

export class Extent {
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;

  constructor(xmin?: number, ymin?: number, xmax?: number, ymax?: number) {
    this.xmin = xmin ?? -180;
    this.ymin = ymin ?? -90;
    this.xmax = xmax ?? 180;
    this.ymax = ymax ?? 90;
    if (this.xmin < -180) this.xmin = -180;
    if (this.ymin < -90) this.ymin = -90;
    if (this.xmax > 180) this.xmax = 180;
    if (this.ymax > 90) this.ymax = 90;
    if (this.ymin > this.ymax) this.ymin = -90;
    if (this.xmin > this.xmax) this.xmin = -180;
  }

  public factor(factor: number): Extent {
    const center: Point = this.getCenter();
    const width = this.getWidth() * factor;
    const height = this.getHeight() * factor;
    return new Extent(center.x - width / 2, center.y - height / 2, center.x + width / 2, center.y + height / 2);
  }
  public getCenter(): Point {
    return new Point(this.centerX(), this.centerY());
  }
  public centerX(): number {
    return (this.xmin + this.xmax) / 2;
  }

  public centerY(): number {
    return (this.ymin + this.ymax) / 2;
  }
  public getWidth() {
    return this.xmax - this.xmin;
  }

  public getHeight() {
    return this.ymax - this.ymin;
  }
}
