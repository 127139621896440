export var MESSAGE_LEVEL;
(function (MESSAGE_LEVEL) {
    MESSAGE_LEVEL["INFO"] = "info";
    MESSAGE_LEVEL["SUCCESS"] = "success";
    MESSAGE_LEVEL["WARNING"] = "warning";
    MESSAGE_LEVEL["ERROR"] = "error";
})(MESSAGE_LEVEL || (MESSAGE_LEVEL = {}));
export var MESSAGE_TYPE;
(function (MESSAGE_TYPE) {
    MESSAGE_TYPE["ALERT"] = "alert";
    MESSAGE_TYPE["CONFIRM"] = "confirm";
    MESSAGE_TYPE["PROGRESS"] = "progress";
    MESSAGE_TYPE["TOAST"] = "toast";
})(MESSAGE_TYPE || (MESSAGE_TYPE = {}));
export var PROGRESS_TYPE;
(function (PROGRESS_TYPE) {
    PROGRESS_TYPE["START"] = "start";
    PROGRESS_TYPE["UPDATE"] = "update";
    PROGRESS_TYPE["STOP"] = "stop";
})(PROGRESS_TYPE || (PROGRESS_TYPE = {}));
export var POSITION;
(function (POSITION) {
    POSITION["TOP_RIGHT"] = "top-right";
    POSITION["TOP_CENTER"] = "top-center";
    POSITION["TOP_LEFT"] = "top-left";
    POSITION["BOTTOM_RIGHT"] = "bottom-right";
    POSITION["BOTTOM_CENTER"] = "bottom-center";
    POSITION["BOTTOM_LEFT"] = "bottom-left";
})(POSITION || (POSITION = {}));
export class MessageOptions {
    constructor(opts) {
        if (opts) {
            this.title = opts.title;
            this.message = opts.message;
            this.level = opts.level;
            this.type = opts.type;
            this.items = opts.items;
            this.timeout = opts.timeout;
            this.maxwidth = opts.maxwidth;
            this.position = opts.position;
            this.cancelLabel = opts.cancelLabel;
            this.confirmLabel = opts.confirmLabel;
        }
    }
    title;
    message;
    level = MESSAGE_LEVEL.WARNING;
    type = MESSAGE_TYPE.ALERT;
    items;
    timeout;
    maxwidth;
    position;
    cancelLabel;
    confirmLabel;
}
export class ConfirmOptions extends MessageOptions {
    callback;
    constructor(opts) {
        if (opts) {
            super(opts);
            this.callback = opts.callback;
        }
        else {
            super();
        }
        this.type = MESSAGE_TYPE.CONFIRM;
    }
}
export class AlertOptions extends MessageOptions {
    callback;
    error;
    constructor(opts) {
        if (opts) {
            super(opts);
            this.callback = opts.callback;
            this.error = opts.error;
        }
        else {
            super();
        }
        this.type = MESSAGE_TYPE.ALERT;
    }
}
export class ToastOptions extends MessageOptions {
    callback;
    error;
    constructor(opts) {
        if (opts) {
            super(opts);
            this.callback = opts.callback;
            this.error = opts.error;
        }
        else {
            super();
        }
        this.type = MESSAGE_TYPE.TOAST;
    }
}
export class ProgressOptions extends MessageOptions {
    progressPercent; // number between 0 and 100
    progressType;
    error;
    constructor(opts) {
        if (opts) {
            super(opts);
            this.progressPercent = opts.progressPercent || opts.progress;
            this.error = opts.error;
            this.progressType = opts.progressType;
        }
        else {
            super();
        }
        this.type = MESSAGE_TYPE.PROGRESS;
    }
}
