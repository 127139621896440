<div class="slds-tabs_default">
  <ul class="slds-tabs_default__nav" role="tablist">
    <li
    *ngIf="options.script"
      class="slds-tabs_default__item {{ options.script.open ? 'slds-is-active' : '' }} "
      title="Script"
      role="presentation"
      (click)="openTab('script')"
    >
      <a
        class="slds-tabs_default__link"
        role="tab"
        tabindex="0"
        aria-selected="true"
        aria-controls="tab-default-1"
        id="tab-default-1__item"
      >
        <span class="slds-tabs__left-icon">
          <span class="slds-icon_container slds-icon-action-script" title="Script">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="/assets/icons/action-sprite/svg/symbols.svg#script"></use>
            </svg>
          </span> </span
        >Script</a
      >
    </li>
    <li
    *ngIf="options.html"
      class="slds-tabs_default__item {{ options.html.open ? 'slds-is-active' : '' }} "
      title="HTML"
      role="presentation"
      (click)="openTab('html')"
    >
      <a
        class="slds-tabs_default__link"
        role="tab"
        tabindex="-1"
        aria-selected="false"
        aria-controls="tab-default-2"
        id="tab-default-2__item"
      >
        <span class="slds-tabs__left-icon">
          <span class="slds-icon_container slds-icon-doctype-html" title="HTML">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="/assets/icons/doctype-sprite/svg/symbols.svg#html"></use>
            </svg>
          </span> </span
        >HTML</a
      >
    </li>
    <li
    *ngIf="options.css"
      class="slds-tabs_default__item {{ options.css.open ? 'slds-is-active' : '' }} "
      title="CSS"
      role="presentation"
      (click)="openTab('css')"
    >
      <a
        class="slds-tabs_default__link"
        role="tab"
        tabindex="-1"
        aria-selected="false"
        aria-controls="tab-default-3"
        id="tab-default-3__item"
      >
        <span class="slds-tabs__left-icon">
          <span class="slds-icon_container slds-icon-standard-brand" title="CSS">
            <svg class="slds-icon slds-icon_small" aria-hidden="true">
              <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#brand"></use>
            </svg>
          </span> </span
        >CSS</a
      >
    </li>
  </ul>
  <div
    *ngIf="options.script && options.script.value"
    id="tab-default-1"
    class="slds-tabs_default__content {{ options.script.open ? 'slds-show' : 'slds-hide' }}"
    role="tabpanel"
    aria-labelledby="tab-default-1__item"
    style="height: 350px; width: 100%"
  >
    <ace [config]="configJavascript" [(value)]="options.script.value"></ace>
  </div>
  <div
    *ngIf="options.html && options.html.value"
    id="tab-default-2"
    class="slds-tabs_default__content {{ options.html.open ? 'slds-show' : 'slds-hide' }}"
    role="tabpanel"
    aria-labelledby="tab-default-2__item"
    style="height: 350px; width: 100%"
  >
    <ace [config]="configHtml" [(value)]="options.html.value"></ace>
  </div>
  <div
    *ngIf="options.css && options.css.value"
    id="tab-default-3"
    class="slds-tabs_default__content {{ options.css.open ? 'slds-show' : 'slds-hide' }}"
    role="tabpanel"
    aria-labelledby="tab-default-3__item"
    style="height: 350px; width: 100%"
  >
    <ace [config]="configCss" [(value)]="options.css.value"></ace>
  </div>
</div>
