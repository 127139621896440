import { LayerOptions, LayerType } from '../model/LayerOptions.js';
import { Utils } from '../utils/Utils.js';
import { GgoStyleConverter } from './GgoStyleConverter.js';

export abstract class GgoLayerConverter {
  static getLayerOptions(ggoLayer: any): LayerOptions {
    let layerType: LayerType;
    if (ggoLayer.drawingInfo.state === 'heatmap') {
      ggoLayer.drawingInfo.thematicType = 'HeatMapThematic';
    }
    switch (ggoLayer.drawingInfo.thematicType) {
      case 'RangeValueThematic':
      case 'UniqueValueThematic':
      case 'SimpleSymbolThematic':
      case 'PropSymbolsThematic':
      case 'PieChartThematic':
        {
          layerType = LayerType.Simple;
        }
        break;
      case 'HeatMapThematic':
        {
          layerType = LayerType.HeatMap;
        }
        break;
      default:
        {
          layerType = LayerType.Simple;
        }
        break;
    }

    layerType = LayerType.Simple; // default value

    if (ggoLayer.maxScale !== undefined) {
      ggoLayer.drawingInfo.maxzoom = Utils.getZoomLevel(ggoLayer.maxScale);
    }
    if (ggoLayer.minScale !== undefined) {
      ggoLayer.drawingInfo.minzoom = Utils.getZoomLevel(ggoLayer.minScale);
    }
    const layerOptions: LayerOptions = new LayerOptions({
      id: ggoLayer.uniqueId,
      style: GgoStyleConverter.getStyleOption(ggoLayer.geometryType, ggoLayer.drawingInfo, ggoLayer.uniqueId),
      type: layerType,
    });

    layerOptions.style.fillOpacity = ggoLayer.drawingInfo?.transparency || 0.5;
    layerOptions.popup = false;
    if (ggoLayer.drawingInfo.maxzoom !== undefined) {
      layerOptions.maxzoom = ggoLayer.drawingInfo.maxzoom;
    }
    if (ggoLayer.drawingInfo.minzoom !== undefined) {
      layerOptions.minzoom = ggoLayer.drawingInfo.minzoom;
    }
    return layerOptions;
  }
}
