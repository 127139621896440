<div
  class="slds-progress-bar {{ backgroundClass }}"
  attr.aria-valuemin="{{ valueMin }}"
  attr.aria-valuemax="{{ valueMax }}"
  attr.aria-valuenow="{{ valueNow }}"
  attr.aria-label="{{ placeholder }}"
  role="progressbar"
>
  <span class="slds-progress-bar__value" [style.width]="valueNow + '%'">
    <span class="slds-assistive-text">Progress: {{ valueNow }}%</span>
  </span>
</div>
