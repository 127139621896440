import { DBObject } from './db-object.js';
/**
 * Represents a table in the database
 */
export class DBTable extends DBObject {
    name;
    columns;
    indexes = [];
    foreignKeys = [];
    defaultValues = [];
    _defaultValuesAddOnlyIfTableIsEmpty = false;
    _uniqueField = '';
    constructor(options) {
        super();
        this.name = options.name;
        this.columns = options.columns ?? [];
    }
    getColumn(name) {
        return this.columns.find((column) => column.name === name);
    }
    hasColumn(name) {
        return this.columns.some((column) => column.name === name);
    }
    getIndex(column) {
        return this.indexes.find((index) => index.column === column);
    }
    /**
     * Get the foreign key for a column
     *
     * @param column Name of the column
     * @returns
     */
    getForeignKey(column) {
        return this.foreignKeys.find((foreignKey) => foreignKey.column === column);
    }
    /**
     *
     * @param name Name of the foreign key
     * @returns
     */
    getForeignKeyByName(name) {
        return this.foreignKeys.find((foreignKey) => foreignKey.name === name);
    }
    hasForeignKey(column) {
        return this.foreignKeys.some((foreignKey) => foreignKey.column === column);
    }
    addDefaultValues(vals, uniqueField, addIfTableEmpty = false) {
        this.defaultValues = vals;
        this._uniqueField = uniqueField ?? this.columns[0].name;
        this._defaultValuesAddOnlyIfTableIsEmpty = addIfTableEmpty;
        return this;
    }
}
