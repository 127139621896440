import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataTable } from '@galigeo-store/shared-models';

@Component({
  selector: 'ui-lightning-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit {
  @Input() checkboxID!: any;
  @Input() checked!: boolean;
  @Input() disabled!: boolean;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
      
  }

  Check(e : any){
    this.action.emit(e.target.checked)
  }
}
