import { DataTable, FieldType } from './dataTable.js';
export class CsvToDataTable {
    convert(csv, fileTextseparator) {
        let dataTable = null;
        const fields = [];
        const lines = csv.split('\n');
        if (lines.length > 0) {
            const headers = lines[0].split(fileTextseparator);
            headers.forEach((h) => {
                h = h.replaceAll('"', '');
                fields.push({ name: h.replaceAll('"', ''), alias: h.replaceAll('"', ''), type: FieldType.STRING, visible: true, originDataSort: false });
            });
            const data = [];
            for (let i = 1; i < lines.length - 1; i++) {
                const obj = {};
                const currentline = lines[i].split(fileTextseparator);
                for (let j = 0; j < headers.length; j++) {
                    obj[headers[j].replaceAll('"', '')] = currentline[j] ?? currentline[j].replaceAll('"', '').replaceAll(String.fromCharCode(92), '');
                }
                data.push(obj);
            }
            dataTable = new DataTable({ id: 'csv', fields, json: data });
        }
        return dataTable;
    }
}
