export class ClassItem {
  value: any;
  label?: string;
  imageId?: string;
  color?: string | string[];
  radius?: number;

  constructor(options: { value: any; label?: string; imageId?: string; color?: string | string[]; radius?: number }) {
    this.value = options.value;
    this.label = options.label;
    this.imageId = options.imageId;
    this.color = options.color;
    this.radius = options.radius;
  }
}
