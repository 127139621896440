import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerticalBtnGroupComponent } from './vertical-btn-group.component';
import { IconModule } from '../icon/icon.module';


@NgModule({
  declarations: [VerticalBtnGroupComponent],
  imports: [
    CommonModule,
    IconModule
  ], exports: [VerticalBtnGroupComponent]
})
export class VerticalBtnGroupModule { }
