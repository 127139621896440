<div class="slds-form-element">
  <label
    *ngIf="options && options.label"
    class="slds-form-element__label"
    for="lookup-input"
    id="combobox-label-id-3"
    >{{ options.label }}</label
  >
  <div class="slds-form-element__control">
    <div class="slds-combobox_container">
      <div
        class="slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click {{
          items && items.length > 0 ? 'slds-is-open' : ''
        }}"
      >
      
        <div class="input-container slds-combobox__form-element slds-input-has-icon slds-input-has-icon_left" role="none">
          <form [formGroup]="lookupForm">
            <input
              #lookupInput
              type="text"
              class="slds-input slds-combobox__input"
              id="lookup-input"
              aria-autocomplete="list"
              aria-controls="listbox-id-1"
              aria-expanded="true"
              aria-haspopup="listbox"
              autoComplete="off"
              role="combobox"
              placeholder="{{options.placeholder}}"
              formControlName="lookup"
              (keyup.enter)="onEnter()"
              [(ngModel)]="inputText"
              autofocus
              [ngStyle]="{'padding-right': showCancel ? '6rem' : '0'}"
              [ngStyle]="{'height': options.inputHeight ? options.inputHeight : '2.5rem'}"
              (keyup.escape)="onEscape()"
            />
          </form>
          <button *ngIf="inputText.length > 0" (click)="clearInput()" [ngStyle]="{'right': showCancel ? '5rem' : '1rem'}" class="slds-icon_container slds-icon-utility-clear slds-input__icon slds-input__icon_right clear-input-btn" title="Clear">
            <svg class="slds-icon slds-icon slds-icon_x-small slds-icon-text-light" aria-hidden="true">
              <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#clear"></use>
            </svg>
          </button>
          <span *ngIf="!loading" class="slds-icon_container slds-icon-utility-search slds-input__icon slds-input__icon_left">
            <svg class="slds-icon slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
              <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
            </svg>
          </span>
          <div *ngIf="loading" class="slds-spinner_container slds-input__icon_left" style="width: 28px;right: 100%;height: 28px; top: 2px;margin-right: -30px;">
            <div role="status" class="slds-spinner slds-spinner_x-small">
              <span class="slds-assistive-text">Loading</span>
              <div class="slds-spinner__dot-a"></div>
              <div class="slds-spinner__dot-b"></div>
            </div>
          </div>
        </div>
        <div *ngIf="!isClick"
          id="listbox-id-1"
          class="slds-dropdown slds-dropdown_length-with-icon-7 slds-dropdown_fluid"
          role="listbox"
        >
          <ul class="slds-listbox slds-listbox_vertical" role="presentation">
            <ng-template ngFor let-item [ngForOf]="items" let-i="index">
              <li
                *ngIf="i < (options.maxLines ? options.maxLines : 3)"
                role="presentation"
                class="slds-listbox__item"
                (click)="clickItem(item)"
                class="{{i==0 ? 'focus' : ''}}"
              >
                <div
                  id="option-{{ i }}"
                  class="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta"
                  role="option"
                >
                <ui-lightning-icon *ngIf="item.icon" [iconName]="item.icon" [size]="'small'"></ui-lightning-icon>
                  <span class="slds-media__body">
                    <span class="slds-listbox__option-text slds-listbox__option-text_entity">{{ item.label }}</span>
                  </span>
                </div>
              </li>
            </ng-template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
