import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[uiLightningDropZone]'
})
export class DropZoneDirective {

  constructor() { }
  @Output() onFileDropped = new EventEmitter<File[]>();

  @HostBinding('style.opacity') private opacity = '1';
  @HostBinding('style.border') private border = 'none';

  @HostListener('dragover', ['$event']) public onDragOver(evt: any): any {
    evt.preventDefault();
    evt.stopPropagation();
    evt.currentTarget.classList.add('slds-has-drag-over');
    //this.opacity = '0.8';
    console.log('evt', evt);
    //this.border = 'dotted 2px #FF4D2A';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: any): any {
    evt.preventDefault();
    evt.stopPropagation();
    evt.currentTarget.classList.remove('slds-has-drag-over');
    //this.opacity = '1';
    console.log('evt', evt);
    //this.border = 'none';
  }

  @HostListener('drop', ['$event']) public ondrop(evt: any): any {
    evt.preventDefault();
    evt.stopPropagation();
    console.log('evt', evt);
    
    evt.currentTarget.classList.remove('slds-has-drag-over');
    //this.opacity = '1';
    //this.border = 'none';
    console.log('evt', evt);
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files);
    }
  }
}
