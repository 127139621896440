import { Extent } from '../model/Extent.js';
import Listener from '../utils/Listener.js';
import { PopupUtils } from '../utils/PopupUtils.js';

export abstract class AbstractLayer extends Listener {
  protected _id!: string;
  constructor() {
    super();
  }

  /**
   *
   * @param visible Set the visibility of the layer
   */
  public abstract setVisible(visible: boolean): void;

  /**
   * Check if the layer is visible
   */
  public abstract isVisible(): boolean;

  /**
   * Get the extent of the layer
   */
  public abstract getExtent(): Extent;

  /**
   * Set the data of the layer. The data can be a GeoJSON string or a GeoJSON object
   * @param data
   */
  public abstract setData(data: any): void;

  /**
   * Get the data of the layer
   */
  public abstract getData(): any;

  /**
   * Move a feature to a new location
   * @param propName Name of the property that identifies the feature
   * @param propValue Value of the property that identifies the feature
   * @param coordinates New coordinates of the feature
   */
  public abstract moveFeature(propName: string, propValue: any, coordinates: number[]): void;

  public getId(): string {
    return this._id;
  }

  public closePopup(): void {
    PopupUtils.closePopup();
  }
}
