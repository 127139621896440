<div class="demo-only">
  <section role="dialog" tabindex="0" aria-modal="true" aria-labelledby="prompt-heading-id" aria-describedby="prompt-message-wrapper" class="slds-modal slds-fade-in-open slds-modal_prompt">
    <div class="slds-modal__container">
      <div class="slds-modal__header {{sldsTheme}} slds-theme_alert-texture">
        <h1 class="slds-text-heading_medium" id="prompt-heading-id">{{options.title}}</h1>
      </div>
      <div class="slds-modal__content slds-p-around_medium" id="prompt-message-wrapper">
        <p class="slds-text-heading_medium">{{options.message}}.</p>
        <div *ngIf="detailedMessage.length > 0" (click)="detailedMessageCollapsed=!detailedMessageCollapsed" 
          class="slds-grid slds-gutters slds-p-top_medium" 
          style="cursor:pointer">
          <div class="slds-col">
            <span class="slds-icon_container">
              <svg class="slds-button__icon slds-button__icon_left" aria-hidden="true">
                <use *ngIf="detailedMessageCollapsed" xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#chevronright"></use>
                <use *ngIf="!detailedMessageCollapsed" xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#chevrondown"></use>
              </svg>
            </span>
            <span>{{'Message:detailed_message' | translate}}</span>
          </div>
        </div>
        <div *ngIf="detailedMessage.length > 0 && !detailedMessageCollapsed" class="slds-grid slds-gutters">
          <div class="slds-col">
            <textarea class="slds-textarea" rows="4">{{detailedMessage}}</textarea>
          </div>
        </div>
      </div>
      <div class="slds-modal__footer slds-theme_default">
        <button (click)="onClickOk()" class="slds-button slds-button_neutral">{{'Message:close_button' | translate}}</button>
      </div>
    </div>
  </section>
  <div class="slds-backdrop slds-backdrop_open" role="presentation"></div>
</div>