import { cluster } from 'd3';
import { AbstractMap } from '../map/Map.js';
import { MapFactory } from '../map/MapFactory.js';
import { ClassItem } from '../model/ClassItem.js';
import { Extent } from '../model/Extent.js';
import { MapOptions } from '../model/MapOptions.js';
import { Matches } from '../model/Matches.js';
import { Style } from '../model/StyleOptions.js';
import { GgoLayerConverter } from './GgoLayerConverter.js';
import { GgoLegendConverter } from './GgoLegendConverter.js';
import { ImageLoader } from './ImageLoader.js';

export class GgoServiceConverter {
  public static async getMap(options: {
    url: string;
    token: string;
    elementId: string;
    legendElementId?: string;
    mapTilerKey?: string;
  }): Promise<AbstractMap> {
    try {
      console.info('GgoServiceConverter.getMap', options);
      const service = await this.fetchJson(options.url, options.token);
      const extent = new Extent(
        service.initialExtent.xmin,
        service.initialExtent.ymin,
        service.initialExtent.xmax,
        service.initialExtent.ymax,
      ).factor(1.1);

      const map = await MapFactory.getMap(new MapOptions({ extent: extent, elementId: options.elementId, mapTilerKey: options.mapTilerKey }));
      const layersWithLabelIds: string[] = [];
      //map.setExtent(extent);
      const layers: any[] = await this.getVisibleLayers(options.url, options.token, service);
      for (let k = 0; k < layers.length; k++) {
        const layerDesc = layers[k];
        try {
          const data = await (await fetch(options.url + '/' + layerDesc.uniqueId + '/query?outSR=4326&f=geojson&ggo_token=' + options.token)).json();
          if (data.code === 500) continue;

          // check for imageData
          const imageMap: Map<string, string> = new Map();
          for (let i = 0; i < data.features.length; i++) {
            const feature = data.features[i];
            if (feature.properties?.imageData) {
              imageMap.set('img_' + i, feature.properties.imageData);
              delete feature.properties.imageData;
              feature.properties.imageIndex = 'img_' + i;
            }
          }

          const layerOption = GgoLayerConverter.getLayerOptions(layerDesc);
          layerOption.popup = true;
          if (layerOption.style.bubble) {
            data.features = layerOption.style.bubble.recalculate(data.features);
          }
          if (layerOption.style.label && layerDesc.drawingInfo.state !== 'heatmap') {
            data.labelFeatures = layerOption.style.label.recalculate(data.features);
            layersWithLabelIds.push(layerOption.id);
          }
          if (options.legendElementId && layerDesc.drawingInfo.state !== 'heatmap') {
            const legendElement = document.getElementById(options.legendElementId);
            if (legendElement) {
              legendElement.innerHTML = GgoLegendConverter.getLegendHTML(layerDesc) + legendElement.innerHTML;
            }
          }

          if (imageMap.size > 0) {
            const classItems: ClassItem[] = [];
            for (const key of imageMap.keys()) {
              const image = imageMap.get(key);
              classItems.push(new ClassItem({ value: key, imageId: image, label: 'Image ' + key }));
            }
            layerOption.style.matches = new Matches('imageIndex', classItems);
            layerOption.style.type = Style.Symbol;
            layerDesc.imageMap = imageMap;
          }

          await map.loadImages(ImageLoader.getImages(layerDesc));
          map.addLayer(layerOption, data);
          console.info('Layer added', layerDesc);
          if (k === layers.length - 1) {
            setTimeout(() => {
              layersWithLabelIds.forEach((layerId) => {
                map.bringToFront(layerId + '-label');
              });
            }, 1000);
          }
        } catch (e) {
          console.error(e);
        }
      }

      /*map.addEventListener('drawend', () => {
                return map;
            });*/

      return map;
    } catch (e: any) {
      console.error(e);
      throw new Error('Failed to get map ' + options.url + ' ' + e.message);
    }
  }

  /**
   * Get the map as base64 image
   * @param options
   * @returns
   */
  public static async getImage(options: { url: string; token: string; width: number; height: number; mapTilerKey: string }): Promise<string> {
    const div = document.createElement('div');
    div.style.display = 'block';
    div.style.position = 'absolute';
    div.style.width = options.width + 'px';
    div.style.height = options.height + 'px';
    const elementId = 'ggo-image-div';
    div.id = elementId;
    try {
      document.body.appendChild(div);
      const map = await this.getMap({ url: options.url, token: options.token, elementId, mapTilerKey: options.mapTilerKey });
      await this.timeout(2000);
      const image = map.getMapAsPng();
      return image;
    } finally {
      document.getElementById(elementId)?.remove();
    }
  }

  static async loadDomToImage() {
    if (typeof window === "undefined") return null; // Prevent running in Node.js

    const module = await import("dom-to-image");
    return module.default ?? module;
  }

  public static async getLegend(options: { url: string; token: string; width?: string }): Promise<string> {
    let html = '';
    const layers = await this.getVisibleLayers(options.url, options.token);
    for (let i = layers.length - 1; i >= 0; i--) {
      const layer = layers[i];
      const legend = GgoLegendConverter.getLegendHTML(layer);
      html += legend;
    }

    const div = document.createElement('div');
    div.style.display = 'none';
    div.style.width = options.width ?? '250px';
    div.innerHTML = html;
    const elementId = 'ggo-legend-div';
    div.id = elementId;
    document.body.appendChild(div);
    return new Promise<string>((resolve, reject) => {
      const node = document.getElementById(elementId);
      if (node) {
        div.style.display = 'block';
        // Use the library safely
        this.loadDomToImage().then((domToImage: any) => {
          if (!domToImage) return; // Prevent errors in non-browser environments

          domToImage.toPng(node)
            .then(function (dataUrl: any) {
              document.getElementById(elementId)?.remove();
              resolve(dataUrl);
            })
            .catch(function (error: any) {
              //document.getElementById(elementId).remove();
              reject(error);
            });
        });
      }
    });
  }

  public static timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private static async getVisibleLayers(url: string, token: string, service?: any): Promise<any[]> {
    if (!service) service = await this.fetchJson(url, token);
    const result = [];
    for (const layer of service.layers) {
      if (layer.type !== 'Feature Layer' || !layer.defaultVisibility) {
        continue;
      }
      try {
        console.info('Get layer...', layer);
        const layerDesc = await this.getLayerDesc(url, token, layer.uniqueId);
        result.push(layerDesc);
      } catch (e) {
        console.error(e);
      }
    }
    return result.sort((a, b) => {
      // clusters on top
      if (a.drawingInfo.state === 'cluster' && b.drawingInfo.state !== 'cluster') return 1;
      if (a.drawingInfo.state !== 'cluster' && b.drawingInfo.state === 'cluster') return -1;

      // then point layers
      if (a.geometryType === 'esriGeometryPoint' && b.geometryType !== 'esriGeometryPoint') return 1;
      if (a.geometryType !== 'esriGeometryPoint' && b.geometryType === 'esriGeometryPoint') return -1;
      return 0;
    });
  }

  private static getLayerDesc(url: string, token: string, layerId: number): Promise<any> {
    return this.fetchJson(url + '/' + layerId, token);
  }

  private static async fetchJson(url: string, token: string): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log('fetch ', url, token);
      const now = new Date().getTime();
      fetch(url + '?ggo_token=' + encodeURIComponent(token))
        .then((response) => response.json())
        .then((data) => {
          if (data.code === 500) {
            reject(data);
            return;
          }
          console.log('Fetched', url, data, (new Date().getTime() - now) / 1000, 's');
          resolve(data);
        })
        .catch((error) => {
          console.error('Failed to fetch', url, error);
          reject(error);
        });
    });
  }
}
