<div class="slds-grid mainContainer">
  <div
    *ngIf="
      stepperOptions &&
      stepperOptions.currentStep &&
      stepperOptions.currentStep === 3 &&
      network.pois
    "
    class="slds-col slds-size_1-of-2 showFileContainer"
  >
    <div class="slds-grid slds-grid_vertical">
      <div class="slds-col">
        <span
          class="slds-text-heading_medium slds-align_absolute-center filePreviewText"
        >
          {{ 'WizardComponent:step_add_file_view_file' | translate }}</span
        >
      </div>
      <div
        class="slds-col"
        [ngStyle]="{
          height: 'calc( 100vh - 110px)',
          position: 'relative',
          top: '50%',
          transform: 'translateY(10%)',
        }"
      >
        <ui-lightning-table
          *ngIf="network.pois"
          [dataTable]="network.pois"
          [options]="tableOptions"
        ></ui-lightning-table>
      </div>
    </div>
  </div>
  <div
    class="slds-col
  {{
      stepperOptions.currentStep && stepperOptions.currentStep >= 3
        ? ' slds-size_1-of-2 step-only'
        : 'step-only'
    }} "
  >
    <div
      *ngIf="stepperOptions && readyToShow"
      class="{{
        stepperOptions.currentStep &&
        (stepperOptions.currentStep === 2 || stepperOptions.currentStep === 3)
          ? 'card-container-only'
          : stepperOptions.currentStep === 4
            ? 'card-container-only-big'
            : 'card-container-only'
      }} "
    >
      <div
        class="stepper-margin"
        *ngIf="
          stepperOptions &&
          stepperOptions.currentStep !== undefined &&
          stepperOptions.currentStep < 4
        "
      >
        <ui-lightning-stepper [options]="stepperOptions"></ui-lightning-stepper>
      </div>
      <ng-template
        ngFor
        let-step
        [ngForOf]="stepperOptions.steps"
        let-i="index"
      >
        <div class="stepper-card">
          <ui-lightning-stepper-card
            *ngIf="i === stepperOptions.currentStep"
            [step]="step"
            (actionClick)="cardStepperClick($event)"
          >
            <ng-container [ngSwitch]="step.name">
              <ng-container *ngSwitchCase="StepNames.NETWORK_NAME">
                <div>
                  <div class="slds-form-element">
                    <div class="slds-form-element__control">
                      <input
                        type="text"
                        id="text-input-id-47"
                        placeholder="{{
                          'WizardComponent:step_network_name_text_placeholder'
                            | translate
                        }}"
                        (keydown)="this.stepperOptions.enableNext(false)"
                        (keyup)="setNetworkName($event)"
                        [(ngModel)]="network.name"
                        required=""
                        class="slds-input add-network-input slds-size_full"
                      />
                      <span *ngIf="!this.nameValid">{{
                        'WizardComponent:step_network_name_text_error'
                          | translate
                      }}</span>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="StepNames.ADD_LOGO">
                <div>
                  <ui-lightning-file-import
                    [options]="importLogoOptions"
                    (onChange)="onLogoLoaded($event)"
                  ></ui-lightning-file-import>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="StepNames.ADD_FILE">
                <div>
                  <ui-lightning-file-import
                    [options]="importFileOptions"
                    (onChange)="onDataLoaded($event)"
                  ></ui-lightning-file-import>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="StepNames.SELECT_COLUMNS">
                <div
                  class="slds-scoped-notification slds-media slds-media_center slds-scoped-notification_light"
                  role="status"
                >
                  <div class="slds-media__figure">
                    <span
                      class="slds-icon_container slds-icon-utility-info"
                      title="information"
                    >
                      <ui-lightning-icon
                        iconName="utility:info"
                        size="small"
                      ></ui-lightning-icon>
                      <span class="slds-assistive-text">information</span>
                    </span>
                  </div>
                  <div class="slds-media__body slds-text-align_left">
                    <p>
                      {{
                        'WizardComponent:step_select_columns_info'
                          | translate
                            : 'Warning, you have not chosen an identifier'
                      }}
                    </p>
                  </div>
                </div>
                <div>
                  <retail-mapping-fields
                    *ngIf="network.pois && fields"
                    [businessFields]="fields"
                    [dataTable]="this.originalDataTable"
                    [isAddStep]="true"
                    (mappingResult)="mappingResultWizardAdd($event)"
                  ></retail-mapping-fields>
                </div>
              </ng-container>
            </ng-container>
          </ui-lightning-stepper-card>
        </div>
      </ng-template>
    </div>
  </div>
</div>
