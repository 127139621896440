<div class="slds-grid mainContainer">
    <div *ngIf="stepperOptions && 
    stepperOptions.currentStep === 1 &&
    originalData"
    class="slds-col slds-size_1-of-2 showFileContainer">
    <div class="slds-grid slds-grid_vertical">
        <div class="slds-col">
            <span class="slds-text-heading_medium slds-align_absolute-center filePreviewText">
                {{'WizardUpdateComponent:step_add_file_view_file' | translate:'File preview'}}</span>
            </div>
        <div class="slds-col" style="height: 60vh;">
          <ui-lightning-table *ngIf="originalData" [dataTable]="originalData"
          [options]="tableOptions"></ui-lightning-table>
        </div>
      </div>
    </div>

    <div *ngIf="stepperOptions && 
    stepperOptions.currentStep === 2 &&
    originalData"
    class="slds-col slds-size_1-of-2 showFileContainer">
    <div class="slds-grid slds-grid_vertical">
        <div class="slds-col">
            <span class="slds-text-heading_medium slds-align_absolute-center filePreviewText">
                {{'WizardUpdateComponent:step_add_file_view_file' | translate:'File preview'}}</span>
            </div>
        <div class="slds-col" [ngStyle]="{'height': 'calc( 100vh - 110px)'}">
        <ng-container [ngSwitch]="this.mergeType">
            <ng-container *ngSwitchCase="MergeType.MERGEAPP">
                <ui-lightning-table *ngIf="previewDtMergeApp" [dataTable]="previewDtMergeApp"
                [options]="tableOptions"></ui-lightning-table>
            </ng-container>
            <ng-container *ngSwitchCase="MergeType.MERGEFILE">
                <ui-lightning-table *ngIf="previewDtMergeFile" [dataTable]="previewDtMergeFile"
                [options]="tableOptions"></ui-lightning-table>
            </ng-container>
            <ng-container *ngSwitchCase="MergeType.REPLACE">
                <ui-lightning-table *ngIf="newNetwork" [dataTable]="newNetwork"
                [options]="tableOptions"></ui-lightning-table>
            </ng-container>
        </ng-container>
        </div>
      </div>
    </div>
    <div class="slds-col
  {{
      stepperOptions.currentStep && stepperOptions.currentStep === 1
        ? ' slds-size_1-of-2 step-only'
        : 'step-only'
    }} " >
        <div *ngIf="stepperOptions" class="{{ 'card-container-only'}} ">
            <div class="stepper-margin"
                *ngIf="stepperOptions && stepperOptions.currentStep !== undefined">
                <ui-lightning-stepper [options]="stepperOptions"></ui-lightning-stepper>
            </div>
            <ng-template ngFor let-step [ngForOf]="stepperOptions.steps" let-i="index">
                <div class="stepper-card">
                <ui-lightning-stepper-card *ngIf="i === stepperOptions.currentStep" [step]="step"
                    (actionClick)="cardStepperClick($event)">
                    <ng-container [ngSwitch]="step.name">
                        <ng-container *ngSwitchCase="StepNames.ADD_FILE">
                            <div>
                                <ui-lightning-file-import [options]="importFileOptions"
                                    (onChange)="onDataLoaded($event)"></ui-lightning-file-import>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="StepNames.SELECT_COLUMNS">
                            <div class="slds-scoped-notification slds-media slds-media_center slds-scoped-notification_light" role="status">
                                <div class="slds-media__figure">
                                  <span class="slds-icon_container slds-icon-utility-info" title="information">
                                    <ui-lightning-icon iconName="utility:info" size="small"></ui-lightning-icon>
                                    <span class="slds-assistive-text">information</span>
                                  </span>
                                </div>
                                <div class="slds-media__body">
                                  <p>{{'WizardUpdateComponent:step_select_columns_info' | translate:'Warning, you have not chosen an identifier'}}</p>
                                </div>
                              </div>             
                            <div>
                                <retail-mapping-fields *ngIf="originalData && fields" [businessFields]="fields"
                                    [dataTable]="originalData" [isLocationRequired]="false" (mappingResult)="mappingResultWizardUpdate($event)"></retail-mapping-fields>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="StepNames.FUSION">
                            <div>
                                <fieldset class="slds-form-element slds-align_absolute-center ">
                                    <legend></legend>
                                    <div class="slds-form-element__control">
                                        <div class="slds-visual-picker slds-visual-picker_vertical">
                                            <input type="radio" id="visual-picker-76" value="mergeApp" name="example-unique-name-36" (change)="onMergeTypeChange($event)" [disabled]="!isThereABussinessTypeId() || this.countApp.countAdded === 0"/>
                                            <label for="visual-picker-76">
                                                <span
                                                class="slds-visual-picker__figure slds-visual-picker__text">
                                                <span class="span-icon">
                                                    <p>
                                                        <img src="/assets/imgs/add.png" alt="add" />
                                                    </p>
                                                    <div>
                                                        <span class="slds-text-heading_medium slds-m-bottom_x-small">{{'WizardUpdateComponent:step_merge_first_choice_title' | translate:'Add'}}</span>
                                                        <span *ngIf="countApp.countAdded > 0" class="slds-text-title">{{this.countColumnAdded + " "}} {{'WizardUpdateComponent:step_merge_first_choice_label_add_column' | translate:'new column(s) and'}} {{" " + countApp.countAdded + " "}}{{'WizardUpdateComponent:step_merge_first_choice_label_add' | translate:'point(s) of sale will be added'}}</span>
                                                    </div>
                                                </span>
                                            </span>
                                                <span class="slds-icon_container slds-visual-picker__text-check">
                                                    <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
                                                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                        <div class="slds-visual-picker slds-visual-picker_vertical">
                                            <input type="radio" id="visual-picker-77" value="mergeFile" name="example-unique-name-36" (change)="onMergeTypeChange($event)" [disabled]="!isThereABussinessTypeId()"/>
                                            <label title="{{this.tooltipsTmp}}" for="visual-picker-77">
                                                <span
                                                class="slds-visual-picker__figure slds-visual-picker__text">
                                                <span class="span-icon">
                                                    <p>
                                                        <img src="/assets/imgs/update.png" alt="update" />
                                                    </p>
                                                    <div>
                                                        <span class="slds-text-heading_medium slds-m-bottom_x-small">{{'WizardUpdateComponent:step_merge_second_choice_title' | translate:'Add and replace'}} </span>
                                                        
                                                        <span class="slds-text-title">{{countApp.countUpdated + " "}}{{'WizardUpdateComponent:step_merge_second_choice_label_update' | translate:''}} {{" " + countApp.countAdded + " "}}{{'WizardUpdateComponent:step_merge_second_choice_label_add' | translate:''}}  {{" " + this.countColumnAdded + " "}} {{'WizardUpdateComponent:step_merge_second_choice_label_add_column'| translate:''}} </span>
                                                    </div>
                                                </span>
                                            </span>
                                                <span class="slds-icon_container slds-visual-picker__text-check">
                                                    <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
                                                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                        <div class="slds-visual-picker slds-visual-picker_vertical">
                                            <input type="radio" id="visual-picker-78" value="replace" name="example-unique-name-36" (change)="onMergeTypeChange($event)" checked/>
                                            <label for="visual-picker-78">
                                                <span
                                                class="slds-visual-picker__figure slds-visual-picker__text">
                                                <span class="span-icon">
                                                    <p>
                                                        <img src="/assets/imgs/replace.png" alt="replace" />
                                                    </p>
                                                    <div>
                                                        <span class="slds-text-heading_medium slds-m-bottom_x-small">{{'WizardUpdateComponent:step_merge_third_choice_title' | translate:'Replace all'}}</span>
                                                        <span class="slds-text-title">{{'WizardUpdateComponent:step_merge_third_choice_label_info' | translate:'' }}</span>
                                                    </div>
                                                </span>
                                            </span>
                                                <span class="slds-icon_container slds-visual-picker__text-check">
                                                    <svg class="slds-icon slds-icon-text-check slds-icon_x-small" aria-hidden="true">
                                                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </ng-container>
                    </ng-container>
                </ui-lightning-stepper-card>
            </div>
            </ng-template>
        </div>
    </div>
</div>