
import { DataTable, Field, FieldType, FormatUtils, Record } from '@galigeo-store/shared-models';
import { Parser } from './parser';
import * as Papa from 'papaparse';
export class CSVParser implements Parser<DataTable> {
  parse(file: File): Promise<DataTable> {
    return new Promise<DataTable>((resolve, reject) => {

      const reader = new FileReader();
      reader.onload = (e: any) => {
        try {
          let text: string = e.target.result;
          const separator = CSVParser.detectSeparator(text);
          console.log(`Detected separator: ${separator}`);
          const csv: { data: any[], errors: any[], meta: any } = Papa.parse(text, { delimiter: separator });
          while (csv.data.length > 0 && csv.data[csv.data.length - 1].every((cell: any) => cell === "")) {
            csv.data.pop();
          }
          let columns = csv.data[0].map((c: any) => ({ name: c, type: 'string', alias: Parser.replaceEncodedCharacters(c) }));
          columns = Parser.encodeColumnsName(columns);
          const rows = csv.data.slice(1).map((r: any) => {
            const row: any = {};
            columns.forEach((c: any, i: number) => row[Parser.replaceEncodedCharacters(c.name)] = Parser.replaceEncodedCharacters(r[i]));
            return row;
          });
          const resultDt = DataTable.fromJson(rows);
          resultDt.fields.forEach((f: Field) => {
            const alias = columns.find((c: any) => c.name === f.name).alias;
            f.alias = Parser.replaceEncodedCharacters(alias);
            f.originalName = f.alias;
            f.name = FormatUtils.encodedFieldNames(f.alias);
            // f.alias = f.alias.replace(/[^a-zA-Z0-9!@#\$%\^\&\*\(\)\[\]\{\}\.\-\_,;:!?\s]/g, '');
          });
          resolve(resultDt);
        } catch (error) {
          reject(error);
        }
      };
      reader.readAsBinaryString(file);
    });
  };

  private static detectSeparator(data: any): string {
    const possibleSeparators = [',', ';', '\t', '|'];
    let firstLine = data.split('\n')[0];
    // delete text inside quotes
    const regex = /"(.*?)"/g;
    const matches = firstLine.match(regex);
    if (matches) {
      matches.forEach((match: any) => {
        firstLine = firstLine.replace(match, '');
      });
    }
    const separatorCount: any = {};

    possibleSeparators.forEach(sep => {
      separatorCount[sep] = (firstLine.split(sep).length - 1);
    });

    const detectedSeparator = Object.keys(separatorCount).reduce((a, b) => {
      return separatorCount[a] > separatorCount[b] ? a : b;
    });

    return detectedSeparator;
  }
}