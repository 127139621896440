import { AbstractLayer } from '../layer/AbstractLayer.js';
import { Extent } from '../model/Extent.js';
import { LayerOptions } from '../model/LayerOptions.js';
import { MapOptions } from '../model/MapOptions.js';
import { PrintOptions } from '../model/PrintOptions.js';
import Listener from '../utils/Listener.js';

export abstract class AbstractMap extends Listener {
  protected parameters: MapOptions;
  protected layers: AbstractLayer[] = [];
  protected images: Set<string> = new Set<string>();
  private _loaded = false;

  constructor(options: MapOptions) {
    super();
    this.parameters = options;
  }

  protected setLoaded(): void {
    this._loaded = true;
    this.fireEvent('loaded', this);
  }

  public abstract bringToFront(layerId: string): void;

  public abstract loadImages(images: any[]): Promise<any[]>;

  public abstract addLayer(options: LayerOptions, data?: any): Promise<AbstractLayer>;

  public abstract removeLayer(layerId: string): AbstractLayer;

  /**
   * Get the map as a PNG image
   */
  public abstract getMapAsPng(options?: PrintOptions): string;

  public getLayers(): AbstractLayer[] {
    return this.layers;
  }

  public getLayer(layerId: string): AbstractLayer | null {
    for (const layer of this.layers) {
      if (layer.getId() === layerId) return layer;
    }
    return null;
  }

  /**
   * @param  {[number, number]} coordinates
   * @param  {number} zoom
   */
  public abstract flyTo(coordinates: [number, number], zoom: number): void;

  /**
   * Return the visible bounds of the map
   */
  public abstract getExtent(): Extent;

  /**
   * Set the extent of the map
   *
   * @param extent
   */
  public abstract setExtent(extent: Extent): void;

  /**
   * Get zoom of the map
   */
  public abstract getZoom(): number;

  /**
   * Set zoom of the map
   *
   * @param zoom
   */
  public abstract setZoom(zoom: number): void;

  /**
   * Get center of the map
   */
  public abstract getCenter(): [number, number];

  /**
   * Reset north
   */
  public abstract resetNorth(): void;
}
